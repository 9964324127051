import { PDFDownloadLink  } from '@react-pdf/renderer';
import React, { Component, Fragment } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom';
import DocumentPDF from '../../component/Seguimiento/DocumentPDF';
import DocumentPDFInst from '../../component/Seguimiento/DocumentPDFInst';
import DocumentPDFPre from '../../component/Seguimiento/DocumentPDFPre';

import AuthSeguimientoContext from '../../context/SeguimientoContext';
import ctrlControlAnexo03 from '../../controller/Seguimiento/ctrlControlAnexo03.ctrl';
import ctrlSeguimientoCursos from '../../controller/Seguimiento/ctrlCursoSeguimiento.ctrl';
import { ObtenerFecha } from '../../lib/source'
//import { IAnexo03Schema } from '../../models/mongo/anexo.schema';
import { IAnexo03 } from '../../models/Seguimiento/anexo03.model';
import { ICursoSeguimiento } from '../../models/Seguimiento/cursoseguimiento.model';
//import Anexo03 from './Anexo03';

interface IProps extends RouteComponentProps {

}

interface IState {

  anexo:IAnexo03
  active:boolean
}

export default class ReporteAnexo extends Component<IProps, IState> {
   static contextType = AuthSeguimientoContext

  constructor(props:IProps){
    super(props)

    this.state = {
   
       anexo:{
              periodo:'', 
              nrc:'',
              lc:'',
              tipo:'',
              curso:'',
              id_inst:'',
              instructor:'',
              zonal:'',
              campus:'',
              cod_curso:'',
              supervisorId:'',
              supervisor:'',
              alumnosbb:0,
              alumnos_asiste: 0,
              inicio:new Date(),
              fin:new Date(),
              curso_cerrado:'',
              pregunta1:'',
              pregunta2:'',
              pregunta3:'',
              pregunta4:'',
              pregunta5:'',
              pregunta6:'',
              pregunta7:'',
              pregunta8:'',
              pregunta9:'',
              pregunta10:'',
              pregunta11:'',
              pregunta12:'',
              pregunta13:'',
              pregunta14:'',
              pregunta15:'',
              pregunta16:'',
              description1:'',
              description2:'',
              description3:'',
              description:'',
              statusInst:false
            
       },
       active:true
    }


 //   window.location.hash = "no-back-button"
 //   window.location.hash = "Again-No-back-button"

  }

  async componentDidMount(){

    this.setState({active:true})

    const {courseId, semana ,instructorId, tipo}= this.context

    const { anexo03 } = await ctrlControlAnexo03.ValidarFile(courseId, semana) 
    const curso  =  anexo03[0]

    const controlAlumno:ICursoSeguimiento = await ctrlSeguimientoCursos.getCursoSeguimiento(courseId, instructorId )
    const supervisor:string = await ctrlSeguimientoCursos.getEspecialistaSeguimientoxId_Name(curso.id_seguimiento)

    const anexo: IAnexo03 ={
                        periodo:curso.periodo,
                        nrc:curso.nrc,
                        lc:'',
                        curso:curso.cod_curso,
                        id_inst:curso.id_instructor,
                        tipo,
                        instructor: controlAlumno.instructor,
                        zonal:controlAlumno.zonal,
                        campus:controlAlumno.campus,
                        cod_curso:curso.cod_curso,
                        supervisorId:curso.id_seguimiento,
                        supervisor,
                        alumnosbb:controlAlumno.inscritosbb,
                        alumnos_asiste: parseInt(curso.asistencia),
                        inicio:new Date(curso.createdAt) ?? new Date(),
                        fin:new Date(curso.createdAt) ?? new Date(),
                        curso_cerrado:curso.curso_cerrado,
                        pregunta1:'4',
                        pregunta2:'4',
                        pregunta3:'4',
                        pregunta4:'4',
                        pregunta5:'4',
                        pregunta6:'4',
                        pregunta7:'4',
                        pregunta8:'4',
                        pregunta9:'4',
                        pregunta10:'4',
                        pregunta11:'4',
                        pregunta12:'4',
                        pregunta13:'4',
                        pregunta14:'4',
                        pregunta15:'4',
                        pregunta16:'4',
                        description1:'',
                        description2:'',
                        description3:'',
                        description:curso.descripcion,
                        statusInst:curso.statusInst
                      }

    
    this.setState({
      anexo
    })

    if(curso.statusInst){

      const {details} = await ctrlControlAnexo03.getDetails(curso._id)

      console.log(details)

      const _anexo: IAnexo03 ={
                        periodo:curso.periodo,
                        nrc:curso.nrc,
                        lc:'',
                        curso:curso.cod_curso,
                        tipo:tipo,
                        id_inst:curso.id_instructor,
                        instructor: controlAlumno.instructor,
                        zonal:controlAlumno.zonal,
                        campus:controlAlumno.campus,
                        cod_curso:curso.cod_curso,
                        supervisorId:curso.id_seguimiento,
                        supervisor,
                        alumnosbb:controlAlumno.inscritosbb,
                        alumnos_asiste: parseInt(curso.asistencia),
                        inicio:new Date(curso.createdAt) ?? new Date(),
                        fin:new Date(curso.createdAt) ?? new Date(),
                        curso_cerrado:curso.curso_cerrado,
                        pregunta1:details.find((d:any) => d.question ==='1').answer,
                        pregunta2:details.find((d:any) => d.question ==='2').answer,
                        pregunta3:details.find((d:any) => d.question ==='3').answer,
                        pregunta4:details.find((d:any) => d.question ==='4').answer,
                        pregunta5:details.find((d:any) => d.question ==='5').answer,
                        pregunta6:details.find((d:any) => d.question ==='6').answer,
                        pregunta7:details.find((d:any) => d.question ==='7').answer,
                        pregunta8:details.find((d:any) => d.question ==='8').answer,
                        pregunta9:details.find((d:any) => d.question ==='9').answer,
                        pregunta10:details.find((d:any) => d.question ==='10').answer,
                        pregunta11:details.find((d:any) => d.question ==='11').answer,
                        pregunta12:details.find((d:any) => d.question ==='12').answer,
                        pregunta13:details.find((d:any) => d.question ==='13').answer,
                        pregunta14:details.find((d:any) => d.question ==='14').answer,
                        pregunta15:details.find((d:any) => d.question ==='15').answer,
                        pregunta16:details.find((d:any) => d.question ==='16').answer,
                        description1:details.find((d:any) => d.question ==='description1').answer,
                        description2:details.find((d:any) => d.question ==='description2').answer,
                        description3:details.find((d:any) => d.question ==='description3').answer,
                        description:curso.descripcion,
                        statusInst:curso.statusInst
                      }
        
      this.setState({
        anexo:_anexo,
      
      })
    }

  this.setState({
    active:false
  })
}  


  render() {
/*
    window.onhashchange = function () {
      window.location.hash = "no-back-button"
    }
*/
  

    return (
      <Fragment>
        <div className="xcontend-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>SEGUIMIENTO</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="#">Seguimiento</Link>
                    </li>
                    <li className="breadcrumb-item active">Anexo03</li>
                  </ol>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
        </div>

        {/* Main content */}
        <section className="content">
          <div className="error-page">
            <h2 className="headline text-danger">
              <i className="fas fa-network-wired text-primary"></i>
            </h2>
            <div className="error-content w-100">
              <h3>
                <i className="fas fa-network-wired text-primary"></i>{" "}
                Se ha completado el reporte de seguimiento
              </h3>
              <p>
                Los datos pueden ser descargado por PDF. Cualquier incoveniente estamos
                trabajando para solucionarlo de inmediato.
              </p>
            </div>
            <div>
              <p className="text-right">
                {
                    !this.state.active ? 
                      this.state.anexo.statusInst ?
                        this.state.anexo.tipo ==='VIR'?
                          <PDFDownloadLink
                            document={<DocumentPDF anexo={this.state.anexo} />}
                            fileName={
                              this.state.anexo.cod_curso +
                              "_" +
                              ObtenerFecha(new Date())
                            }
                          >
                            <button className="btn btn-outline-primary">
                              <i className="far fa-file-pdf font-weight-bold"></i>{" "} DESCARGAR PDF
                            </button>
                          </PDFDownloadLink>
                        :
                          <PDFDownloadLink
                              document={<DocumentPDFPre anexo={this.state.anexo} />}
                              fileName={
                                this.state.anexo.cod_curso +
                                "_" +
                                ObtenerFecha(new Date())
                              }
                            >
                              <button className="btn btn-outline-primary">
                                <i className="far fa-file-pdf font-weight-bold"></i>{" "} DESCARGAR PDF
                              </button>
                            </PDFDownloadLink>

                      :
                      
                        <PDFDownloadLink
                            document={<DocumentPDFInst anexo={this.state.anexo} />}
                            fileName={
                              this.state.anexo.cod_curso +
                              "_" +
                              ObtenerFecha(new Date())
                            }
                          >
                            <button className="btn btn-outline-danger">
                              {
                                this.state.active?
                                <>
                                  <i className="fa-solid fa-spinner fa-spin"></i> GENERANDO PDF...
                                </>
                                :
                                <>
                                  <i className="far fa-file-pdf font-weight-bold"></i>{" "} DESCARGAR PDF
                                </>
                              }
                              
                            </button>
                        </PDFDownloadLink>
                    :
                        <button className='btn btn-outline-secondary'>
                          <i className="fa-solid fa-spinner fa-spin"></i> GENERANDO PDF...
                        </button>

                 }

              </p>
            </div>
          </div>
          {/* /.error-page */}
        </section>
        {/* /.content
        <div style={{ width: "80%", marginLeft: "10%", minHeight: "70vh" }}>
          {<PDFViewer style={{ width: "100%", minHeight: "70vh" }}>
            <DocumentPDF anexo={anexo03Context} />
          </PDFViewer>}
        </div>*/} 
      </Fragment>
    );
  }
}
