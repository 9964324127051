import { IPkiNota } from "../../models/Grafica/PkiNota.model"

class CtrlDataPkiNotas {

  async getDataPki() {

    let datos: IPkiNota[] = []

    await fetch('/data/JSON_KPI_NOTAS.json')
      .then((db) => db.json())
      .then((data) => {
        datos = data

      })

    return datos
  }

  async getPkiNotas() {
    return await this.getDataPki() as IPkiNota[]
  }

}

const ctrlDataPkiNotas = new CtrlDataPkiNotas()
export default ctrlDataPkiNotas