import React, { Component, Fragment } from 'react'
import cpBlackboardApex from '../../controller/system/cpBbApex.ctrl'
import ContenidoBody from '../ContenidoBody'

import  '../../styles/style.css'

interface IProps {

}
interface IState {
  activar:boolean
}

export default class SystemOracle extends Component<IProps, IState> {

  constructor(props:IProps){
    super(props)

    this.state ={
      activar:false
    }

    this.handleProcesoCopiar = this.handleProcesoCopiar.bind(this)
  }

  async handleProcesoCopiar(select:string){

    this.handleActivar()


    await cpBlackboardApex.copySystem(select)


    this.handleDesActivar()

  }

  handleActivar(){
    this.setState({
      activar:true
    })
  }

  handleDesActivar(){
    this.setState({
      activar:false
    })
  }

  render() {
    return (
      <Fragment>
        <ContenidoBody
          title={"REPORTE CONTROL ALUMNOS"}
          link={"System"}
          page={"Oracle"}
        >
          <div className="row justify-content-md-center mt-2 ">
            <div className="col-lg-12">
              <div className="card direct-chat direct-chat-warning">
                <div className="card-header">
                  <h3 className="card-title">
                    <span>
                      {this.state.activar ? (
                        <i className="fas fa-spinner fa-spin"></i>
                      ) : (
                        <i className="fas fa-journal-whills"></i>
                      )}
                    </span>{" "}
                    SYSTEMA - CARGA DE BASEDATOS ORACLE
                  </h3>
                  <div className="card-tools">
                    {/*Registros:*/}
                    <span
                      data-toggle="tooltip"
                      title="3 New Messages"
                      className="badge badge-warning"
                    ></span>
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-toggle="tooltip"
                      title="Contacts"
                      data-widget="chat-pane-toggle"
                    >
                      <i className="fas fa-comments" />
                    </button>
                  </div>
                </div>
                <div className="card-body container-fluid">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-header bg-info">
                          <h3 className="card-title">
                            <i className="far fa-copy"></i> COPIAR
                          </h3>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="col-md-3 col-sm-6 col-12 m-2">
                                  <div
                                    className="info-box onclick"
                                    onClick={() =>
                                      this.handleProcesoCopiar("apexbb")
                                    }
                                  >
                                    <span className="info-box-icon bg-info">
                                      <i className="fad fa-copy"></i>
                                    </span>
                                    <div className="info-box-content">
                                      <span className="info-box-text">
                                        COPIAR
                                      </span>
                                      <span className="info-box-number">
                                        Apex-Blackboard
                                      </span>
                                      <span className="info-box-number">
                                        {" "}
                                        Oradata/Extdir
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-6 col-12 m-2">
                                  <div
                                    className="info-box onclick"
                                    onClick={() =>
                                      this.handleProcesoCopiar("apex")
                                    }
                                  >
                                    <span className="info-box-icon bg-info">
                                      <i className="fad fa-copy"></i>
                                    </span>
                                    <div className="info-box-content">
                                      <span className="info-box-text">
                                        COPIAR
                                      </span>
                                      <span className="info-box-number">
                                        Apex
                                      </span>
                                      <span className="info-box-number">
                                        {" "}
                                        Oradata/Extdir
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-6 col-12 m-2">
                                  <div
                                    className="info-box onclick"
                                    onClick={() =>
                                      this.handleProcesoCopiar("bb")
                                    }
                                  >
                                    <span className="info-box-icon bg-info">
                                      <i className="fad fa-copy"></i>
                                    </span>
                                    <div className="info-box-content">
                                      <span className="info-box-text">
                                        COPIAR
                                      </span>
                                      <span className="info-box-number">
                                        Blackboard
                                      </span>
                                      <span className="info-box-number">
                                        {" "}
                                        Oradata/Extdir
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-3 col-sm-6 col-12 m-2">
                                  <div
                                    className="info-box onclick"
                                    onClick={() =>
                                      this.handleProcesoCopiar("reuniones")
                                    }
                                  >
                                    <span className="info-box-icon bg-primary">
                                      <i className="far fa-chalkboard-teacher"></i>
                                    </span>
                                    <div className="info-box-content">
                                      <span className="info-box-text">
                                        COPIAR
                                      </span>
                                      <span className="info-box-number">
                                        Reuniones
                                      </span>
                                      <span className="info-box-number">
                                        Oradata/Extdir
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-6 col-12 m-2">
                                  <div
                                    className="info-box onclick"
                                    onClick={() =>
                                      this.handleProcesoCopiar("teams")
                                    }
                                  >
                                    <span className="info-box-icon bg-purple">
                                      <i className="fad fa-text"></i>
                                    </span>
                                    <div className="info-box-content">
                                      <span className="info-box-text">
                                        COPIAR
                                      </span>
                                      <span className="info-box-number">
                                        Teams
                                      </span>
                                      <span className="info-box-number">
                                        {" "}
                                        Oradata/Extdir
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-6 col-12 m-2">
                                  <div
                                    className="info-box onclick"
                                    onClick={() =>
                                      this.handleProcesoCopiar("reportes")
                                    }
                                  >
                                    <span className="info-box-icon bg-success">
                                      <i className="far fa-file-excel"></i>
                                    </span>
                                    <div className="info-box-content">
                                      <span className="info-box-text">
                                        COPIAR
                                      </span>
                                      <span className="info-box-number">
                                        Oradata/Extdir
                                      </span>
                                      <span className="info-box-number">
                                        {" "}
                                        ReporteBb
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/** */}
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-header bg-success">
                          <h3 className="card-title">
                            <i className="far fa-database"></i> BBDD
                            APEX-POSTGRES
                          </h3>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="col-md-3 col-sm-6 col-12 mt-2">
                                  <div
                                    className="info-box onclick"
                                    onClick={() =>
                                      this.handleProcesoCopiar("apexbb")
                                    }
                                  >
                                    <span className="info-box-icon bg-success">
                                      <i className="fad fa-file-spreadsheet"></i>
                                    </span>
                                    <div className="info-box-content">
                                      <span className="info-box-text">
                                        Base Datos
                                      </span>
                                      <span className="info-box-number">
                                        Apex-Blackboard
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-6 col-12 mt-2">
                                  <div
                                    className="info-box onclick"
                                    onClick={() =>
                                      this.handleProcesoCopiar("apex")
                                    }
                                  >
                                    <span className="info-box-icon bg-success">
                                      <i className="fad fa-file-spreadsheet"></i>
                                    </span>
                                    <div className="info-box-content">
                                      <span className="info-box-text">
                                        Base Datos
                                      </span>
                                      <span className="info-box-number">
                                        Apex
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-6 col-12 mt-2">
                                  <div
                                    className="info-box onclick"
                                    onClick={() =>
                                      this.handleProcesoCopiar("bb")
                                    }
                                  >
                                    <span className="info-box-icon bg-success">
                                      <i className="fad fa-file-spreadsheet"></i>
                                    </span>
                                    <div className="info-box-content">
                                      <span className="info-box-text">
                                        Base Datos
                                      </span>
                                      <span className="info-box-number">
                                        Blackboard
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-6 col-12 mt-2">
                                  <div
                                    className="info-box onclick"
                                    onClick={() =>
                                      this.handleProcesoCopiar("bb")
                                    }
                                  >
                                    <span className="info-box-icon bg-success">
                                      <i className="fad fa-file-spreadsheet"></i>
                                    </span>
                                    <div className="info-box-content">
                                      <span className="info-box-text">
                                        Base Datos
                                      </span>
                                      <span className="info-box-number">
                                        Ni Apex - Blackboard
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/** */}
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-header bg-purple">
                          <h3 className="card-title">
                            <i className="far fa-hockey-mask"></i> BBDD - JSON
                          </h3>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="col-md-3 col-sm-6 col-12 mt-2">
                                  <div
                                    className="info-box onclick"
                                    onClick={() =>
                                      this.handleProcesoCopiar("json")
                                    }
                                  >
                                    <span className="info-box-icon bg-purple">
                                      <i className="fab fa-js-square"></i>
                                    </span>
                                    <div className="info-box-content">
                                      <span className="info-box-text">
                                        Base Datos
                                      </span>
                                      <span className="info-box-number">
                                        JSON - DATA
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/** */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContenidoBody>
      </Fragment>
    );
  }
}
