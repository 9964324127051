import ControlAlumnos from "../../data/JSON_ControlAlumnos.json"
import {  Obtener_LcNrc, Obtener_NrcLc, Obtener_Periodo, Ordenar, removeDuplicados } from "../../lib/source"
import { IJSONControlAlumno } from "../../models/JSON_Data/JSONControlAlumno.model"


class CtrlJSON_ControlAlumnos{

  async getControlAlumnos():Promise<IJSONControlAlumno[]>{

    return  await ControlAlumnos  as IJSONControlAlumno[]
  }

  async FindxNrc(nrc:string):Promise <IJSONControlAlumno[]|void>{

    const controlAlumnos = await this.getControlAlumnos()

    return controlAlumnos.filter((controlAlumno: IJSONControlAlumno) => controlAlumno.nrc === nrc)

  }

  async FindxPeriodoxNrc(periodo:string,nrc:string):Promise <IJSONControlAlumno[]|void>{

    const controlAlumnos = await this.getControlAlumnos()

    return controlAlumnos.filter((controlAlumno: IJSONControlAlumno) => controlAlumno.nrc === nrc && controlAlumno.periodo === periodo)

  }

  async FindxLc(lc:string):Promise <IJSONControlAlumno[]|void>{

    const controlAlumnos = await this.getControlAlumnos()

    return controlAlumnos.filter((controlAlumno: IJSONControlAlumno) => controlAlumno.lc === lc)

  }

  async FindxPeriodoxLc(periodo:string, lc:string):Promise <IJSONControlAlumno[]|void>{

    const controlAlumnos = await this.getControlAlumnos()

    return controlAlumnos.filter((controlAlumno: IJSONControlAlumno) => controlAlumno.lc === lc && controlAlumno.periodo === periodo)

  }

  async FindxCursoBB(curso:string):Promise <IJSONControlAlumno[]|void>{

    const controlAlumnos = await this.getControlAlumnos()

    return controlAlumnos.filter((controlAlumno: IJSONControlAlumno) => controlAlumno.cod_cursobb.includes(curso))

  }

  async ListaAlumnosPeriodos(){

    const data:IJSONControlAlumno[] = await this.getControlAlumnos()

    return Ordenar(removeDuplicados(data,'periodo'),'periodo')

  }

   async ListaAlumnosZonales(){

    const data:IJSONControlAlumno[] = await this.getControlAlumnos()

    return Ordenar(removeDuplicados(data,'zonal'),'zonal')

  }

  async ListaCursoInstructorId(courseId:string, instructorId:string){
    const listacontrol:IJSONControlAlumno[] = await this.getControlAlumnos()

    if(Obtener_LcNrc(courseId)==='NRC')
      return  listacontrol.filter((lc:IJSONControlAlumno) => lc.periodo === Obtener_Periodo(courseId))
                          .filter((lc:IJSONControlAlumno) => lc.nrc === Obtener_NrcLc(courseId))
                          .filter((lc:IJSONControlAlumno) => lc.id_inst === instructorId)
    else
      return  listacontrol.filter((lc:IJSONControlAlumno) => lc.periodo === Obtener_Periodo(courseId))
                          .filter((lc:IJSONControlAlumno) => lc.lc === Obtener_NrcLc(courseId))
                          .filter((lc:IJSONControlAlumno) => lc.tipo_nrc === 'PADRE')
                          .filter((lc:IJSONControlAlumno) => lc.id_inst === instructorId)

  }
}

const ctrlJSON_ControlAlumnos = new CtrlJSON_ControlAlumnos()
export default ctrlJSON_ControlAlumnos