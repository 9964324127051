import React, { Component, Fragment } from 'react'
import { DetectarLcNrc } from '../../lib/source'
import { IJSONControlAlumno } from '../../models/JSON_Data/JSONControlAlumno.model'

interface IProps {
  reportes:IJSONControlAlumno[]
  periodo:string
  dato:string

}
interface IState {

}

export default class TablaControlAlumnos extends Component<IProps, IState> {

  render() {
    let dato:IJSONControlAlumno[]


    const NrcLc:string = DetectarLcNrc(this.props.dato)



    if(NrcLc === 'NRC')
      dato =  this.props.reportes
                          //    .filter(rpt=> rpt.tipo_nrc!=='HIJO')
                              .filter(rpt=> rpt.periodo === this.props.periodo)
                              .filter(rpt=> rpt.nrc === this.props.dato)

    else
       dato =  this.props.reportes
                          //    .filter(rpt=> rpt.tipo_nrc!=='HIJO')
                              .filter(rpt=> rpt.periodo === this.props.periodo)
                              .filter(rpt=> rpt.lc === this.props.dato)




     const list = dato.map((rpt:IJSONControlAlumno,i:number)=>
                                        <Fragment key={'tbl-controlalumno-' + i.toString()}>
                                          <tr className={i % 2 === 0 ? 'bg-light' :''} key={'rpt-a'+ i.toString()}>
                                            <td align='center'>{ rpt.zonal }</td>
                                            <td align='center'>{rpt.periodo}</td>
                                            <td align='center' colSpan={3}>{rpt.cod_cursobb}</td>
                                            <td align='center' rowSpan={2} className='align-middle'>{rpt.titulo} </td>
                                            <td align='center' colSpan={2}>{ rpt.estado_cursobb}</td>
                                            <td align='center'>{rpt.alumnos_sinfo_lc}</td>
                                            <td align='center'>{rpt.aptos_sinfo_lc}</td>
                                            <td align='center' rowSpan={2} className='align-middle'>{rpt.alumnos_en_bb}</td>
                                          </tr>
                                          <tr className={i % 2 === 0 ? 'bg-light' : ''} key={'rpt-b' + i.toString()}>
                                            <td align='center'>{rpt.campus}</td>
                                            <td align='center'>{rpt.parte_periodo}</td>
                                            <td align='center'>{rpt.nrc}</td>
                                            <td align='center'>{rpt.lc}</td>
                                            <td align='center'>{rpt.tipo_nrc}</td>
                                            <td align='center'>{rpt.padre_cerrado_bb}</td>
                                            <td align='center'>{rpt.padre_visible_bb}</td>
                                            <td align='center'>{rpt.alumnos_sinfo_nrc}</td>
                                            <td align='center'>{rpt.aptos_sinfo_nrc}</td>
                                          </tr>
                                        </Fragment>
                                      )

    return (
      <Fragment>
        <table className='table table-sm table-bordered ' style={{fontSize:'small'}}>
          <thead className='bg-primary text-white'>
            <tr>
              <td align='center' colSpan={6}>DATOS DE CURSO</td>
              <td align='center' colSpan={2}>ESTADO</td>
              <td align='center' >ALUMNOS</td>
              <td align='center' >APTOS</td>
              <td width='5%' align='center' className='align-middle'>MATRICU</td>
            </tr>
            <tr>
              <td width='20%' align='center'>ZONAL</td>
              <td width='5%'  align='center'>PERIODO</td>
              <td             align='center' colSpan={3}>ID CURSO</td>
              <td             align='center' rowSpan={2} className='align-middle'>CURSO </td>
              <td width='5%' align='center' rowSpan={2} className='align-middle'>CERRADO</td>
              <td width='5%' align='center' rowSpan={2} className='align-middle'>VISIBLE</td>
              <td width='5%'  align='center'>LC</td>
              <td width='5%'  align='center'>LC</td>
              <td width='5%' align='center' rowSpan={2} className='align-middle'>Bb</td>
            </tr>
            <tr>
              <td            align='center'>CAMPUS</td>
              <td            align='center'>P PERIODO</td>
              <td width='5%' align='center'>NRC</td>
              <td width='5%' align='center'>LC</td>
              <td width='5%' align='center'>TIPO</td>
              <td            align='center'>NRC</td>
              <td            align='center'>RNC</td>
            </tr>
          </thead>
          <tbody>
            { list }
          </tbody>

        </table>

      </Fragment>
    )
  }
}
