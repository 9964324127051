import React, { Component } from 'react'
import Chart from 'react-google-charts'
import ctrlData from '../../controller/Graficas/CtrlData.ctrl'

import { Ordenar } from '../../lib/source'
import { IMatricula } from '../../models/Grafica/Matriculas.model'


interface IProps {

  reportes: IMatricula[]
  zonal: string
  tperiodo:string
  pperiodo:string
  calendarizacion:string
}
interface IState {

}

export default class GraficaBarCharCampus extends Component<IProps, IState> {

  render() {

    let  matricula: IMatricula[] = []
    let datos: any = []

    datos = [

        ['Campus', 'Matriculados', { role: 'style' }, { role: 'annotation' }],
      ]



      matricula = Ordenar(this.props.reportes.filter((rpt: IMatricula) => rpt.PartePeriodo === this.props.pperiodo)
                                             .filter((rpt: IMatricula) => rpt.TipoPeriodo === this.props.tperiodo)
                                             .filter((rpt: IMatricula) => rpt.Zonal === this.props.zonal)
                                             .filter((rpt: IMatricula) => rpt.calendarizacion === this.props.calendarizacion)
                                             .filter((rpt: IMatricula) => rpt.Campus !== "TODOS")
                                             .filter((rpt: IMatricula) => rpt.Zonal !== 'ep')
                                             .filter((rpt: IMatricula) => rpt.Zonal !== 'nd')
                                             ,'Porc_Mat').reverse()
      


      if(matricula.length !== 0){

        matricula.map((rpt: IMatricula, i: number) =>
          datos.push([
            ctrlData.getCampusxCode(rpt.Campus),
            rpt.Porc_Mat ,
            rpt.Color_Mat,
            rpt.Porc_Mat + '%'
          ])
        )
      }
      else
          datos.push(['Empty',0,'#fff','0%'])




  return (

	  <Chart
        width={'100%'}
        height={'60vh'}
        chartType="BarChart"
        loader={<div>Loading Chart</div>}
        data={datos}
        options={{
         // title: titulo,
          titlePosition: 'none', //'out',
         // titleTextStyle:{   bold: "True", fontSize:"small"},
          hAxis: {
                title: '% matriculas',
                titleTextStyle:{ fontSize:'small'}
           //     minValue: 0,
           //     maxValue:100,
              },
          legend: { position: "none" },
          bar: { groupWidth: "auto" , background:'#333'},//35%
          chartArea: { left: 250, width: "50%", height: "100%" },
          gridlines: {color: 'transparent' },
          animation: {
            duration: 1000,
            easing: "out",
            startup: true,
          },
          annotations:{alwaysOutside: true}

  }}


      />
    )
  }
}

