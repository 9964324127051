  import React from 'react'
  import Chart  from 'react-apexcharts'
  import { IPendienteMatricula } from '../../models/Grafica/PendienteMatricula.model'

  interface IProps {
    reportes:IPendienteMatricula[]
    zonal:string
    tperiodo:string
    pperiodo:string
    calendarizacion:string
  }
  interface IState {
    series:any
    data:any

  }

   export default class GraficaApexRadarPendMatricula extends React.Component<IProps, IState> {

    render() {

          let data:any = null
          
          const categories = [
            "Fuera de curricula",
            "Sin frecuencia",
            "Sin instructor",
            "Sin contrato",
            "Incumple horas diseño",
            "Solo sin usuario",
            "Sin usuario",
            "Falta pago",
            "Exceso horas",
            "Fuera de rango",
      //      "Curso finalizado"
          ];
//Sin frecuencia, Sin instructor y Fuera de rango
//sin pago y sin patrón
          this.props.reportes
            .filter((rpt: IPendienteMatricula) => rpt.Zonal === this.props.zonal)
            .filter((rpt: IPendienteMatricula) => rpt.Campus === "TODOS")
            .filter((rpt: IPendienteMatricula) => rpt.TipoPeriodo === this.props.tperiodo)
            .filter((rpt: IPendienteMatricula) => rpt.PartePeriodo === this.props.pperiodo)
            .filter((rpt: IPendienteMatricula) => rpt.Calendarizacion === this.props.calendarizacion)
            .map(
              (rpt: IPendienteMatricula) =>
                (data = [
                  rpt.Porc_Sin_Patron,
                  rpt.Porc_Frecuencia,
                  rpt.Porc_Sin_Instructor,
                  rpt.Porc_Sin_Contrato,
                  rpt.Porc_Incumple_diseno,
                  rpt.Porc_Solo_Sin_Usuario,
                  rpt.Porc_Sin_Usuario,
                  rpt.Porc_Falta_Pago,
                  rpt.Porc_Exceso_Horas,
                  rpt.Porc_Fuera_De_Rango,
       //         rpt.Curso_Finalizado
                ])
            );

          return (
              <Chart  options={{
                                  chart: {
                                    height: '100%',
                                    width:'100%',
                                    type: 'radar',
                                  },
                                  title: {
                                  //  text: 'Porcentaje de Pendientes ',

                                  },
                                  subtitle:{
                                    //text: subtitle
                                  },
                                  markers: {
                                    size: 5,
                                  },
                                  xaxis: {
                                    categories,
                                    max:100,
                                    min:0,
                                    labels:{
                                      style:{
                                        fontSize:'small'
                                      }
                                    }
                                  },
                                  /*muestra las etiqueda*/
                                  dataLabels: {
                                    enabled: false
                                  },
                                  plotOptions: {
                                    radar: {
                                      //size: 140,
                                      polygons: {
                                        strokeColors: '#e9e9e9',
                                        fill: {
                                          colors: ['#f8f8f8', '#fff']
                                        }
                                      }
                                    }
                                  },
                                }}
                      series={[
                              {
                                name: 'Porcentaje: ',
                                data:data
                              }]
                            }
                      type="radar"
                      height={'600px'}
                />
       )}
  }