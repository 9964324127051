import React, { Component, Fragment } from 'react'
import { RouteComponentProps } from 'react-router'
import ContenidoBody from '../../component/ContenidoBody'
import TablaControlAlumnos from '../../component/Oracle/TablaControlAlumnos'
import { BbTermContext } from '../../context/PeriodoProvider'

import { IControlAlumno } from '../../models/oracle/controlalumnos.model'

import { batchUID } from '../../lib/source'

import TablaControlAlumnosInst from '../../component/Oracle/TablaControlAlumnosInst'
import ctrlJSON_ControlAlumnos from '../../controller/JsonCrtl/ControlAlumnos.ctrl'
import { IJSONControlAlumno } from '../../models/JSON_Data/JSONControlAlumno.model'


interface IProps extends RouteComponentProps {

}

interface IState {
  activar: boolean
  reportes: IJSONControlAlumno[]
  dato: string
  auth: Boolean
  periodos:IJSONControlAlumno[]
  periodo:string
  select: string
  [data: string]: any
}

export default class ControlAlumnos extends Component<IProps, IState> {

  static contextType = BbTermContext

  private Datos:any = {}

  constructor(props: IProps) {
    super(props)

    this.state = {
      activar: false,
      reportes: [],
      periodos:[],
      dato: '',
      auth:false,
      periodo:'TODOS',
      select: ''
    }

    this.handleInput = this.handleInput.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleNrc = this.handleNrc.bind(this)
    this.handleLc = this.handleLc.bind(this)
    this.handleInstructor = this.handleInstructor.bind(this)
  }

  async componentDidMount() {

    this.handleActivar()


    const reportes:IJSONControlAlumno[] = await ctrlJSON_ControlAlumnos.getControlAlumnos()

    const periodos = await ctrlJSON_ControlAlumnos.ListaAlumnosPeriodos()

    this.setState({
      reportes,
      periodos,
    })

    this.handleDesactivar()

  }

  handleActivar(){
    this.setState({
      activar:true
    })
  }

  handleDesactivar() {
    this.setState({
      activar: false
    })
  }

  handleInput({ target }: React.ChangeEvent<HTMLInputElement>) {

    this.setState({
      [target.name]: target.value.trim().toUpperCase()
    })

  }

  async handleSelect({ target }: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      activar:true,
      [target.name]: target.value
    })

    //if(target.name ==='periodo')
      //await this.memorization(target.value)

    this.setState({
      activar:false
    })
  }
/*
  componentDidUpdate(prevProps:IProps, prevState:IState){

    if(!this.state.auth){

      this.props.history.push('/login')

      return

    }
  }

  async memorization(arg:string){

    if (!this.Datos[arg]){
      const {reportes, auth } = await bbControlAlumnos.FindxPeriodo(arg)

      this.setState({
        auth
      })

      this.Datos[arg] = reportes
    }
  }
*/
  handleNrc(){

    if(this.state.periodo==='TODOS' || this.state.dato==='') return

    this.handleActivar()

    this.setState({
      dato:'',
      select:'nrc',
    })

    this.handleDesactivar()
  }

  handleLc(){

    if (this.state.periodo === '0' || this.state.dato === '') return

    this.setState({
      activar: true
    })

    this.setState({
      reportes: this.Datos[this.state.periodo].filter((rep: IControlAlumno) => rep.LC === this.state.dato.toUpperCase()),
      dato: '',
      select: 'nrc',
      activar: false
    })
  }

  handleInstructor(){
    if (this.state.periodo === '0' || this.state.dato === '') return

    this.setState({
      activar: true
    })

    this.setState({
      reportes: this.Datos[this.state.periodo].filter((rep: IControlAlumno) => rep.ID_INST === batchUID(this.state.dato)),
      dato: '',
      select: 'nrc',
      activar: false
    })
  }


  render() {

    const listPeriodos = this.state.periodos.map((rpt:IJSONControlAlumno,i:number)=>
                            <option value={rpt.periodo} key={'p-'+ i.toString()}>{rpt.periodo}</option>
                          )

    return (
      <ContenidoBody title={'REPORTE CONTROL ALUMNOS'} link={'Control'} page={'Alumnos'}>
        <div className="row justify-content-md-center">
          <div className="col-md-9">
            <div className="card direct-chat direct-chat-warning">
              <div className="card-header">
                <h3 className="card-title">
                  <span>
                    {this.state.activar
                      ? <i className="fas fa-spinner fa-spin"></i>
                      : <i className="fas fa-journal-whills"></i>}
                  </span> Consulta - REPORTE CONTROL ALUMNOS
                </h3>
                <div className="card-tools">
                  {/*Registros:*/}
                  <span data-toggle="tooltip"
                    title="3 New Messages"
                    className="badge badge-warning">
                  </span>
                  <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus" />
                  </button>
                  <button type="button" className="btn btn-tool" data-toggle="tooltip" title="Contacts" data-widget="chat-pane-toggle">
                    <i className="fas fa-comments" />
                  </button>
                </div>
              </div>
              <div className="card-body" style={{ margin: "10px 0px " }}>
                <button className="btn btn-app"

                        disabled={this.state.activar}
                >
                  <span className="badge bg-orange"></span>
                  <i className="fa fa-book"></i> NRC
                </button>
                <button className="btn btn-app"

                        disabled={this.state.activar}
                >
                  <span className="badge bg-orange"></span>
                  <i className="far fa-books"></i> LISTA CRUZADA
                </button>
                <button className="btn btn-app"
                        disabled={this.state.activar}

                >
                  <span className="badge bg-orange"></span>
                  <i className="fas fa-chalkboard-teacher"></i>Instrutor
                </button>
              </div>
              <div className="card-footer d-flex flex-row">
                    <div className="input-group mb-3 mr-3">
                        <select className='form-control'
                          name='periodo'
                          onChange={this.handleSelect}
                          value={this.state.periodo}
                          disabled={this.state.activar}
                        >
                          <option value="TODOS">---PERIODO---</option>
                          {listPeriodos}
                        </select>
                    </div>
                    <div className="input-group mb-3">
                      <input type="text"
                        name='dato'
                        minLength={2}
                        className="form-control"
                        placeholder=" NRC / LC "
                        aria-label=""
                        aria-describedby="basic-addon1"
                        value={this.state.dato}
                        onChange={this.handleInput}
                        disabled={this.state.activar}
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>


        <div className="row justify-content-md-center">
          <div className="col-md-11">
            <div className="card direct-chat direct-chat-warning">
              <div className="card-header">
                <h3 className="card-title">
                  <span>
                    {this.state.activar
                      ? <i className="fas fa-spinner fa-spin"></i>
                      : <i className="fas fa-journal-whills"></i>}
                  </span> RESULTADO - REPORTE REPF01
                </h3>
              </div>
              <div className='card-body'>
                {
                  <Fragment>
                    <TablaControlAlumnosInst  reportes={this.state.reportes}
                                              periodo={this.state.periodo}
                                              dato={this.state.dato}
                                              />
                    <br />
                    <TablaControlAlumnos    reportes={this.state.reportes}
                                            periodo={this.state.periodo}
                                            dato={this.state.dato}
                    />
                  </Fragment>
                }

              </div>
            </div>
          </div>
        </div>
      </ContenidoBody>
    )
  }
}
