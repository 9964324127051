import React,{Component, Fragment} from 'react'
import { RouteComponentProps } from 'react-router-dom'
import AnexoSchema from '../../context/AnexoContext'
import ctrlControlAnexo03 from '../../controller/Seguimiento/ctrlControlAnexo03.ctrl'
import { IAnexo03Schema } from '../../models/mongo/anexo.schema'
import { ICursoSeguimiento } from '../../models/Seguimiento/cursoseguimiento.model'
import ModalSaveSinIntructor from '../ModalSaveSinInstructor'

interface IProps extends RouteComponentProps{
    controlAlumno:ICursoSeguimiento
    especialistaId:string
    semana:string
    tipo:string
}

interface IState{
  modalInst:boolean
  statusInst:boolean
  descripcion:string
}


export default class Links extends Component<IProps,IState> {

  static contextType?: React.Context<any> | undefined = AnexoSchema
  
  constructor(props:IProps){
    super(props)

    this.state ={
      modalInst:false,
      statusInst:false,
      descripcion:''
    }

    this.handleSave = this.handleSave.bind(this)
    this.handleModal = this.handleModal.bind(this)
    this.handleModalInstClose = this.handleModalInstClose.bind(this)

  }

  handleModal(){
    
     this.setState({  modalInst:true })

  }


  async handleSave(descripcion:string){

    const { asistencia } = this.context

    if(asistencia.length ===0 ) {
      alert('Debe completar Asistencia ' )
      this.setState({
      modalInst: false,
      statusInst:false
    })
      return
    }

    const valueDefault:IAnexo03Schema = {
                  asistencia,
                  zonal: this.props.controlAlumno.zonal,
                  campus:this.props.controlAlumno.campus,
                  alumnosbb:this.props.controlAlumno.inscritosbb,
                  curso:this.props.controlAlumno.curso,
                  cod_curso:this.props.controlAlumno.cod_curso,
                  curso_horario:this.props.controlAlumno.curso_horario,
                  curso_cerrado:this.props.controlAlumno.curso_cerrado,
                  id_instructor:this.props.controlAlumno.id_inst,
                  instructor:this.props.controlAlumno.instructor,
                  id_seguimiento:this.props.especialistaId,
                  nota:'0',
                  semana:this.props.semana,
                  nrc:this.props.controlAlumno.nrc,
                  periodo:this.props.controlAlumno.periodo,
                  statusInst:false,
                  descripcion: descripcion,
                  tipo:this.props.tipo,
                  porcentaje:'' 
                }

    /**
     * grabacion de anexo
     */
    await ctrlControlAnexo03.Create(valueDefault)
    
    this.setState({
      modalInst: false,
      statusInst:false
    })

    this.props.history.push('/seguimiento/reporte')
                
  }

  handleModalInstClose(){
    this.setState({ modalInst:false })
  }


  render() {
    return (
              <Fragment>
                <ModalSaveSinIntructor 
                  open={this.state.modalInst}
                  Close={this.handleModalInstClose} 
                  status={this.state.statusInst}
                  save={this.handleSave}
                />
                <div className="row justify-content-center mt-5">
                  <div className="col-lg-8">
                    <table className="table table-bordered table-striped table-md">
                      <tbody>
                        <tr>
                          <td align="center" width="50%">
                            <a
                              href={
                                "https://senati.blackboard.com/ultra/courses/_" +
                                this.props.controlAlumno.pk1_curso + "_1/outline"
                              }
                              target="_blank"
                              className="btn btn-outline-primary"
                              rel="noreferrer"
                            >
                              Ir a Blackboard
                            </a>
                          </td>
                          <td align="center">
                            <a
                              href={this.props.controlAlumno.url_teams}
                              target="_blank"
                              className="btn btn-outline-primary"
                              rel="noreferrer"
                            >
                              Sala Teams
                            </a>
                          </td>
                          <td align="center">
                            <button
                              className="btn btn-outline-danger"
                              onClick={this.handleModal}
                              
                            >
                              No asistio el instructor
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
             </Fragment>
  )
  }
}

  
