import { ICursoSeguimiento } from "../../models/Seguimiento/cursoseguimiento.model"
import { IEspecialistaSeguimiento } from "../../models/Seguimiento/especialistaSeguimiento.model"

export class CtrlJsonReadAnexo03 {

  private static getData: CtrlJsonReadAnexo03

  static getFetchConnection() {

    if (!CtrlJsonReadAnexo03.getData) {
      CtrlJsonReadAnexo03.getData = new CtrlJsonReadAnexo03()
    }

    return CtrlJsonReadAnexo03.getData
  }

  public async dataSeguimiento() {

    let seguimiento: ICursoSeguimiento[] = []

    await fetch('/data/JSON_ControlCursos_Supervision.json')
      .then((db) => db.json())
      .then(data =>
        seguimiento = data
      )

    return seguimiento

  }

  async getSeguimiento() {

    return await this.dataSeguimiento()

  }

  async getSeguimientoxCurso(cod_curso:string) {

    const data = await this.dataSeguimiento()

    const rst = data.find((r:ICursoSeguimiento)=> r.cod_curso === cod_curso) as ICursoSeguimiento

    return rst 

  }

  private async dataEspecialistaSeguimiento() {

    let espcialistaSeguimiento: IEspecialistaSeguimiento[] = []

    await fetch('/data/JSON_ESpecialistas_Seguimiento.json')
      .then((db) => db.json())
      .then(data =>
        espcialistaSeguimiento = data
      )

    return espcialistaSeguimiento

  }

  async getEspecialistaSeguimiento() {

    return await this.dataEspecialistaSeguimiento()

  }



}
const crtrlJsonReadAnexo03 = CtrlJsonReadAnexo03.getFetchConnection()
export default crtrlJsonReadAnexo03
