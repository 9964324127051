import React, { Component, Fragment } from 'react'
import { Link} from 'react-router-dom'


import imagen from '../Image/fondo2.jpg'
import { AuthenticatedTemplate, UnauthenticatedTemplate,  } from '@azure/msal-react'


interface IProps  {

  title: String
  page:String
  link:String
}

interface IState {


}

export default class ContenidoBody extends Component<IProps,IState> {


  /*
  c
  
  onstructor(props:IProps){
    super(props)
/*
    const { search } = this.props.location
    const query = new URLSearchParams(search)
/
  }
*/
  render() {

    
    const fondo = {
                    backgroundImage:`url(${imagen})`,
                    backgroundSize:'cover',
                    backgroundRepeat:'no-repeat',
                    backgroundPosition:'center',

                    border:'1px solid blue',
                    with:'100%',
                    height:'100vh'
                  }

    return (
      <Fragment>
          <>
          <UnauthenticatedTemplate>
            <div style={ fondo } >
              <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
                <Link className="navbar-brand" to="#">
                  ANALITICA ACADEMICA
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
              </nav>
              {this.props.children}
            </div>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <>
              {this.props.children}
            </>
          </AuthenticatedTemplate>
          
          </>
           {/* <div className="wrapper">
                    <PrivateNavbar/>
                    <Aside />
                    <div style={fondo} className="content-wrapper " >
                      {/** /}
                      <div className="content-header">
                        <AuthenticatedTemplate>
                        <div className="container-fluid">
                          <div className="row mb-2">
                            <div className="col-sm-6">
                              <h1 className="m-0 text-dark">
                                {this.props.title.toUpperCase()}
                              </h1>
                            </div>
                            <div className="col-sm-6">
                              <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                  <Link to="/#">Home </Link>
                                </li>
                                <li className="breadcrumb-item">{ this.props.link }</li>
                                <li className="breadcrumb-item active">{this.props.page.toLowerCase()}</li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        </AuthenticatedTemplate>
                      </div>
                      {/** /}
                      <section className="content">
                        <div className="container-fluid">
                          {this.props.children}
                        </div>
                      </section>
                    </div>
                    <Footer />
            </div>*/}
          
      </Fragment>
    )
  }
}
