import React, { Component } from 'react'
import Chart from 'react-google-charts'
import ctrlData from '../../controller/Graficas/CtrlData.ctrl'
import { IPendienteMatricula } from '../../models/Grafica/PendienteMatricula.model'

import { IGZonal } from '../../models/Grafica/zonal.model'

interface IProps {
  reportes:IPendienteMatricula[]
  pperiodo:string
  tperiodo:string
  zonal:string
  calendarizacion:string

}
interface IState {

}

export default class GraficaBubbleMotivos extends Component<IProps, IState> {

  render() {

    let titulo = 'Porcentaje de casos vs días sin migrar.\n'

    ctrlData.getZonalxId_Name(this.props.zonal).map((z:IGZonal) =>
      titulo += z.name_abrev + '\n'
    )

    let rst:IPendienteMatricula[] = this.props.reportes.filter((rpt:IPendienteMatricula) => rpt.Zonal        === this.props.zonal)
                                                       .filter((rpt:IPendienteMatricula) => rpt.PartePeriodo === this.props.pperiodo)
                                                       .filter((rpt:IPendienteMatricula) => rpt.TipoPeriodo  === this.props.tperiodo)
                                                       .filter((rpt:IPendienteMatricula) => rpt.Calendarizacion  === this.props.calendarizacion) 
                                                       .filter((rpt:IPendienteMatricula) => rpt.Campus  === 'TODOS')

    titulo += this.props.tperiodo.replace('TODOS','Programa todos')
    titulo += ' - Periodo ' + this.props.pperiodo.replace('TODOS','todos')

/*
    const datos = [
      ["ID", "Life Expectancy", "Fertility Rate", "Region", "Population"],
      ["CAN", 80.66, 1.67, "North America", 33739900],
      ["DEU", 79.84, 1.36, "Europe", 81902307],
      ["DNK", 78.6, 1.84, "Europe", 5523095],
      ["EGY", 72.73, 2.78, "Middle East", 79716203],
      ["GBR", 80.05, 2, "Europe", 61801570],
      ["IRN", 72.49, 1.7, "Middle East", 73137148],
      ["IRQ", 68.09, 4.77, "Middle East", 31090763],
      ["ISR", 81.55, 2.96, "Middle East", 7485600],
      ["RUS", 68.6, 1.54, "Europe", 141850000],
      ["USA", 78.09, 2.05, "North America", 307007000],
    ]
 */
    const datos = [

           ['ID', 'Días sin migrar', '% Casos','Motivo', 'Matriculas pendientes'],
           ['EH',       rst[0].Antig_Exceso_Horas     , rst[0].Porc_Exceso_Horas      , 'Exceso de horas' , rst[0].Exceso_Horas],
           ['FP',       rst[0].Antig_Falta_pago       , rst[0].Porc_Falta_Pago        , 'Falta de pago' , rst[0].Falta_de_pago],
           ['SF',       rst[0].Antig_Frecuencia       , rst[0].Porc_Frecuencia        , 'Sin frecuencia' , rst[0].Sin_Frecuencia],
           ['FR',       rst[0].Antig_Fuera_de_rango   , rst[0].Porc_Fuera_De_Rango    , 'Fuera de rango' , rst[0].Fuera_de_rango],
           ['SI',       rst[0].Antig_Sin_Instructor   , rst[0].Porc_Sin_Instructor    , 'Sin instructor' , rst[0].Sin_Instructor],
           ['FC',       rst[0].Antig_Sin_patron       , rst[0].Porc_Sin_Patron        , 'Fuera de curricula' , rst[0].Sin_patron],
           ['SU',       rst[0].Antig_Sin_Usuario      , rst[0].Porc_Sin_Usuario       , 'Sin usuario' , rst[0].Sin_usuario],
           ['ID',       rst[0].Antig_Incumple_diseno  , rst[0].Porc_Incumple_diseno   , 'Incumple horas diseño' , rst[0].Incumple_Hrs_Diseno],
           ['SC',       rst[0].Antig_Sin_Contrato     , rst[0].Porc_Sin_Contrato      , 'Sin contrato' , rst[0].Sin_Contrato],
           ['SSU',      rst[0].Antig_Solo_Sin_Usuario , rst[0].Porc_Solo_Sin_Usuario  , 'Solo sin usuario' , rst[0].Solo_sin_Usuario],
         ]

  /*
    let datos:any = [

   //     ['En proceso',            rst[0].Antig_En_proceso       , rst[0].Porc_En_Proceso        , rst[0].Zonal , rst[0].En_proceso],
        ['Exceso de horas',       rst[0].Antig_Exceso_Horas     , rst[0].Porc_Exceso_Horas      , ctrlData.getZonalxId_Name(rst[0].Zonal)[0].name_zonal ?? '' , rst[0].Exceso_Horas],
        ['Falta de pago',         rst[0].Antig_Falta_pago       , rst[0].Porc_Falta_Pago        , ctrlData.getZonalxId_Name(rst[0].Zonal)[0].name_zonal ?? '' , rst[0].Falta_de_pago],
        ['Sin frecuencia',        rst[0].Antig_Frecuencia       , rst[0].Porc_Frecuencia        , ctrlData.getZonalxId_Name(rst[0].Zonal)[0].name_zonal ?? '' , rst[0].Sin_Frecuencia],
        ['Fuera de rango',        rst[0].Antig_Fuera_de_rango   , rst[0].Porc_Fuera_De_Rango    , ctrlData.getZonalxId_Name(rst[0].Zonal)[0].name_zonal ?? '' , rst[0].Fuera_de_rango],
        ['Sin instructor',        rst[0].Antig_Sin_Instructor   , rst[0].Porc_Sin_Instructor    , ctrlData.getZonalxId_Name(rst[0].Zonal)[0].name_zonal ?? '' , rst[0].Sin_Instructor],
        ['Fuera de curricula',    rst[0].Antig_Sin_patron       , rst[0].Porc_Sin_Patron        , ctrlData.getZonalxId_Name(rst[0].Zonal)[0].name_zonal ?? '' , rst[0].Sin_patron],
        ['Sin usuario',           rst[0].Antig_Sin_Usuario      , rst[0].Porc_Sin_Usuario       , ctrlData.getZonalxId_Name(rst[0].Zonal)[0].name_zonal ?? '' , rst[0].Sin_usuario],
        ['Incumple horas diseño', rst[0].Antig_Incumple_diseno  , rst[0].Porc_Incumple_diseno   , ctrlData.getZonalxId_Name(rst[0].Zonal)[0].name_zonal ?? '' , rst[0].Incumple_Hrs_Diseno],
        ['Sin contrato',          rst[0].Antig_Sin_Contrato     , rst[0].Porc_Sin_Contrato      , ctrlData.getZonalxId_Name(rst[0].Zonal)[0].name_zonal ?? '' , rst[0].Sin_Contrato],
        ['Solo sin usuario',      rst[0].Antig_Solo_Sin_Usuario , rst[0].Porc_Solo_Sin_Usuario  , ctrlData.getZonalxId_Name(rst[0].Zonal)[0].name_zonal ?? '' , rst[0].Solo_sin_Usuario],
      ]
/*
      let datos:any = [

        //     ['En proceso',            rst[0].Antig_En_proceso       , rst[0].Porc_En_Proceso        , rst[0].Zonal , rst[0].En_proceso],
             ['',        rst[0].Antig_Exceso_Horas     , rst[0].Porc_Exceso_Horas      ,  rst[0].Exceso_Horas],
             ['',        rst[0].Antig_Falta_pago       , rst[0].Porc_Falta_Pago        ,  rst[0].Falta_de_pago],
             ['',        rst[0].Antig_Frecuencia       , rst[0].Porc_Frecuencia        ,  rst[0].Sin_Frecuencia],
             ['',        rst[0].Antig_Fuera_de_rango   , rst[0].Porc_Fuera_De_Rango    ,  rst[0].Fuera_de_rango],
             ['',        rst[0].Antig_Sin_Instructor   , rst[0].Porc_Sin_Instructor    ,  rst[0].Sin_Instructor],
             ['',        rst[0].Antig_Sin_patron       , rst[0].Porc_Sin_Patron        ,  rst[0].Sin_patron],
             ['',        rst[0].Antig_Sin_Usuario      , rst[0].Porc_Sin_Usuario       ,  rst[0].Sin_usuario],
             ['',        rst[0].Antig_Incumple_diseno  , rst[0].Porc_Incumple_diseno   ,  rst[0].Incumple_Hrs_Diseno],
             ['',        rst[0].Antig_Sin_Contrato     , rst[0].Porc_Sin_Contrato      ,  rst[0].Sin_Contrato],
             ['',        rst[0].Antig_Solo_Sin_Usuario , rst[0].Porc_Solo_Sin_Usuario  ,  rst[0].Solo_sin_Usuario],
           ]
*/
      //datos.unshift()

              
     



    return (
            <Chart
                width={'100%'}
                height={'60vh'}
                chartType="BubbleChart"
                loader={<div>Loading Chart</div>}
                data={datos}
                options={{
                  //title:titulo,
                  titleTextStyle:{
                    fontSize:'small',
                  },
                  vAxis: { title: '%casos'},
                  hAxis: { title: 'días sin migrar'},
                  bubble: { 
                    textStyle: { 
                      fontSize: '8px' ,

                    },
                    opacity:1,
                  },
                 /* legend:{
                    //position:'right'
                  },*/
                  chartArea: { /*left: 200,*/ width: "60%", height: "auto" },
                  is3D:true,
                  animation: {
                    duration: 1000,
                    easing: "out",
                    startup: true,
                  },
                  
                }}
            />
    )
  }
}
