import { IKpiFueraRango } from "../../models/Grafica/PkiFueraRango.model"

class CtrlDataPkiFueraRango {

  async getDataPkiIniciados() {

    let datos: IKpiFueraRango[] = []

    await fetch('/data/JSON_KPI_fuera_rango_iniciados.json')
      .then((db) => db.json())
      .then((data) => {
        datos = data

      })

    return datos
  }

  async getDataPkiProximos() {

    let datos: IKpiFueraRango[] = []

    await fetch('/data/JSON_KPI_fuera_rango_proximos.json')
      .then((db) => db.json())
      .then((data) => {
        datos = data

      })

    return datos
  }

  async getPkiFueraRangoIniciados() {
    return await this.getDataPkiIniciados() as IKpiFueraRango[]
  }

  async getPkiFueraRangoProximos() {
    return await this.getDataPkiProximos() as IKpiFueraRango[]
  }

}

const ctrlDataPkiFueraRango = new CtrlDataPkiFueraRango()
export default ctrlDataPkiFueraRango