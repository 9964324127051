import React, { Component, ComponentType } from "react";
import {   Route } from "react-router-dom";

interface IProps {
  path: string
  component: ComponentType<any>
}


export default class GeneralRoute extends Component<IProps & Readonly<any>,{}> {

  render(){
    return (
      <Route  exact
              path={this.props.path}
              render={
                (props)=>

                    React.createElement(this.props.component,{...props})

              }
      />
    )
  }
}