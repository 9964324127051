import coloresInstitucional from '../../data/JSON_Colores.json'
import coloresEtiqueta from '../../data/JSON_ColoresEtiquetas.json'
import { IColores } from '../../models/Grafica/colores.model'


class CtrlColores{

    getDataColoresInstitucional(){
        return coloresInstitucional

    }

    getInstitucional(){
        return this.getDataColoresInstitucional().institucion as IColores[]

    }

    getDataColoresEtiqueta(){
        return coloresEtiqueta

    }

    getEtiqueta(){
        return this.getDataColoresEtiqueta().etiquetas as IColores[]

    }

    
    getColoresInstitucionalxId(id:number){

        const colores = this.getInstitucional()

        const color = colores.filter((c:IColores)=> c.id === id)

        return {etiqueta: (color.length >0) ? color[0].color : '#333', letra:(color.length >0) ? color[0].letra : '#333'}
    }

    getAsignacionColorInstitucional_Fondo(valor:number){

        const numero = Math.trunc(valor)

        if(numero === 100) return this.getColoresInstitucionalxId(4).etiqueta
        else if (numero < 100 && numero >=90) return this.getColoresInstitucionalxId(3).etiqueta
        else if (numero < 90  && numero >= 70) return this.getColoresInstitucionalxId(2).etiqueta
        else if (numero < 70) return this.getColoresInstitucionalxId(1).etiqueta
    }

  getAsignacionColorInstitucional_Letra(valor:number){

    const numero = Math.round(valor)

    if(numero === 100) return this.getColoresInstitucionalxId(4).letra
    else if (numero < 100 && numero >=90) return this.getColoresInstitucionalxId(3).letra
    else if (numero < 90  && numero >= 70) return this.getColoresInstitucionalxId(2).letra
    else if (numero < 70) return this.getColoresInstitucionalxId(1).letra
  }

  /****************/

  getColoresEtiquetasxId(id:number){
    const colores = this.getEtiqueta()

    const color = colores.filter((c:IColores)=> c.id === id)

    return {etiqueta: (color.length >0) ? color[0].color : '#333', letra:(color.length >0) ? color[0].letra : '#333'}
  }

  getAsignacionColorEtiqueta_Fondo(valor:number){

    const numero = Math.trunc(valor)
    
    if(numero === 100)                     return this.getColoresEtiquetasxId(100).etiqueta
    else if (numero < 100 && numero >=95)  return this.getColoresEtiquetasxId(95).etiqueta
    else if (numero < 95  && numero >= 90) return this.getColoresEtiquetasxId(90).etiqueta
    else if (numero < 90  && numero >= 85) return this.getColoresEtiquetasxId(85).etiqueta
    else if (numero < 85  && numero >= 80) return this.getColoresEtiquetasxId(80).etiqueta
    else if (numero < 80  && numero >= 75) return this.getColoresEtiquetasxId(75).etiqueta
    else if (numero < 75  && numero >= 70) return this.getColoresEtiquetasxId(70).etiqueta
    else if (numero < 70  && numero >= 60) return this.getColoresEtiquetasxId(60).etiqueta
    else if (numero < 60  && numero >= 50) return this.getColoresEtiquetasxId(50).etiqueta
    else if (numero < 50  && numero >= 40) return this.getColoresEtiquetasxId(40).etiqueta
    else if (numero < 40  && numero >= 30) return this.getColoresEtiquetasxId(30).etiqueta
    else if (numero < 30  && numero >= 20) return this.getColoresEtiquetasxId(20).etiqueta
    else if (numero < 20  && numero >= 10) return this.getColoresEtiquetasxId(10).etiqueta
    else if (numero < 10  && numero >  0 ) return this.getColoresEtiquetasxId(0).etiqueta
    else if (numero === 0) return this.getColoresEtiquetasxId(0).etiqueta
  }

  getAsignacionColorEtiqueta_Letra(valor:number){

    const numero = Math.trunc(valor)

    if(numero === 100)                     return this.getColoresEtiquetasxId(100).letra
    else if (numero < 100 && numero >=95)  return this.getColoresEtiquetasxId(95).letra
    else if (numero < 95  && numero >= 90) return this.getColoresEtiquetasxId(90).letra
    else if (numero < 90  && numero >= 85) return this.getColoresEtiquetasxId(85).letra
    else if (numero < 85  && numero >= 80) return this.getColoresEtiquetasxId(80).letra
    else if (numero < 80  && numero >= 75) return this.getColoresEtiquetasxId(75).letra
    else if (numero < 75  && numero >= 70) return this.getColoresEtiquetasxId(70).letra
    else if (numero < 70  && numero >= 60) return this.getColoresEtiquetasxId(60).letra
    else if (numero < 60  && numero >= 50) return this.getColoresEtiquetasxId(50).letra
    else if (numero < 50  && numero >= 40) return this.getColoresEtiquetasxId(40).letra
    else if (numero < 40  && numero >= 30) return this.getColoresEtiquetasxId(30).letra
    else if (numero < 30  && numero >= 20) return this.getColoresEtiquetasxId(20).letra
    else if (numero < 20  && numero >= 10) return this.getColoresEtiquetasxId(10).letra
    else if (numero < 10  && numero >  0 ) return this.getColoresEtiquetasxId(0).letra
    else if (numero === 0) return this.getColoresEtiquetasxId(0).letra
  }

}

const ctrlColores = new CtrlColores()
export default ctrlColores