import React, { Component } from 'react'
import {Document,Text,Page, View, StyleSheet, Image} from '@react-pdf/renderer'
import "../../styles/adminlte.css"
import { IAnexo03 } from '../../models/Seguimiento/anexo03.model'
import { ObtenerFecha, ObtenerHora, Obtener_LcNrc, Obtener_NrcLc } from '../../lib/source'
import ImagenLogo from '../../Image/LogoPre.jpeg'


interface IProps {
  anexo: IAnexo03
}

interface IState  {};

export default class DocumentPDFPre extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.handleRpta = this.handleRpta.bind(this);
    this.handleResultado = this.handleResultado.bind(this);
    this.handleCalificacion = this.handleCalificacion.bind(this);
    this.handleMaximoPuntaje = this.handleMaximoPuntaje.bind(this);
    this.handleValor = this.handleValor.bind(this)
    this.handleMaximoPuntaje = this.handleMaximoPuntaje.bind(this)
  }

  handleRpta(rpt: string) {
    let siCumple: number = 0;

    siCumple = this.props.anexo.pregunta1 === rpt ? 1 : 0;
    siCumple += this.props.anexo.pregunta2 === rpt ? 1 : 0;
    siCumple += this.props.anexo.pregunta3 === rpt ? 1 : 0;
    siCumple += this.props.anexo.pregunta4 === rpt ? 1 : 0;
    siCumple += this.props.anexo.pregunta5 === rpt ? 1 : 0;
    siCumple += this.props.anexo.pregunta6 === rpt ? 1 : 0;
    siCumple += this.props.anexo.pregunta7 === rpt ? 1 : 0;
    siCumple += this.props.anexo.pregunta8 === rpt ? 1 : 0;
    siCumple += this.props.anexo.pregunta9 === rpt ? 1 : 0;
    siCumple += this.props.anexo.pregunta10 === rpt ? 1 : 0;
    siCumple += this.props.anexo.pregunta11 === rpt ? 1 : 0;
    siCumple += this.props.anexo.pregunta12 === rpt ? 1 : 0;
    siCumple += this.props.anexo.pregunta13 === rpt ? 1 : 0;
    siCumple += this.props.anexo.pregunta14 === rpt ? 1 : 0;
    siCumple += this.props.anexo.pregunta15 === rpt ? 1 : 0;
    siCumple += this.props.anexo.pregunta16 === rpt ? 1 : 0;
    return siCumple;
  }

  handleResultado() {
    const rst =
    /*  ((this.handleRpta("1") * 2 +
        this.handleRpta("2") * 1 +
        this.handleRpta("3") * 0) *
        100) /
      ((15 - this.handleRpta("4")*2) )*/

      this.handleValor()*100 / this.handleMaximoPuntaje()

    return rst.toFixed(0);
  }

  handleValor(){

    const rst =
      (this.handleRpta("1") * 2) +
      (this.handleRpta("2") * 1) +
      (this.handleRpta("3") * 0)

    return rst

  }

  handleMaximoPuntaje() {

    const rst = (16 - this.handleRpta("4"))*2
    return rst
  }

  handleCalificacion() {
    /*const rst =
      (((this.handleRpta("1") * 2) +
        (this.handleRpta("2") * 1) +
        (this.handleRpta("3") * 0)) *
        100) / this.handleMaximoPuntaje()
     // ((30 - this.handleRpta("4")) );*/

     let rst = 0

    if(this.handleMaximoPuntaje() !== 0)
      rst = this.handleValor() *100 / this.handleMaximoPuntaje()


    if (rst >= 85.5) return "EXCELENTE";
    else if (rst < 85.5 && rst >= 71.5) return "BUENO";
    else if (rst < 71.5 && rst >= 56.5) return "ACEPTABLE";
    else if (rst < 56.5 && rst >= 41.5) return "INSUFICIENTE";
    else if (rst < 41.5 && rst >= 0) return "DEFICIENTE";
  }

  render() {
    const style = StyleSheet.create({
      textcenter:{
        textAlign: "center",
        justifyContent: "center",
        alignContent: "center",
        padding: "2px",
      },
      container: { width: "90%", marginLeft: "5%", marginTop: "5%" },
      borde: {
        border: "1px solid #333",
      },
      text8: {
        fontSize: "8px",
      },
      cajaflex: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
      col_3X: {
        position: "relative",
        width: "30%",
        padding: "2px",
      },
      ubicacionFinal: {
        marginTop: "20px",
      },
      row: {
        marginLeft: "-7.5px",
        marginRight: "-7.5px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        //   marginTop: "3rem",
        width: "100%",
        //  margin: "auto",
      },
      col_4: {
        position: "relative",
        width: "40%",
        padding: "2px",
        /*paddingLeft: "7.5px",*/
        border: "1px solid #333",
      },
      col_4_5: {
        position: "relative",
        width: "45%",
        padding: "2px",
        //paddingLeft: "7.5px",
        border: "1px solid #333",
        textAlign: "justify",
        justifyContent: "center",
        alignContent: "center",
      },
      col_5_5: {
        position: "relative",
        width: "55%",
        padding: "2px",
        //paddingLeft: "7.5px",
        border: "1px solid #333",
        textAlign: "justify",
        justifyContent: "center",
        alignContent: "center",
      },
      col_6: {
        position: "relative",
        width: "60%",
        padding: "2px",
        //paddingLeft: "7.5px",
        border: "1px solid #333",
      },
      col_2: {
        position: "relative",
        width: "20%",
        padding: "2px",
        /*paddingLeft: "7.5px",*/
        border: "1px solid #333",
      },
      col_8: {
        position: "relative",
        width: "80%",
        padding: "2px",
        //paddingLeft: "7.5px",
        border: "1px solid #333",
      },
      col_3: {
        position: "relative",
        width: "30%",
        padding: "2px",
        /*paddingLeft: "7.5px",*/
        border: "1px solid #333",
      },
      col_7: {
        position: "relative",
        width: "70%",
        padding: "2px",
        //paddingLeft: "7.5px",
        border: "1px solid #333",
      },
      col_1: {
        position: "relative",
        width: "10%",
        padding: "2px",
        //paddingLeft: "7.5px",
        border: "1px solid #333",
        textAlign: "center",
        justifyContent: "center",
        alignContent: "center",
      },
      col_1_5: {
        position: "relative",
        width: "5%",
        padding: "2px",
        //paddingLeft: "7.5px",
        border: "1px solid #333",
        textAlign: "center",
        justifyContent: "center",
        alignContent: "center",
      },
      rowgrap: {
        flexWrap: "wrap",
      },
      small: {
        fontSize: "8px",
      },
    });
    
    
    const fecha = ObtenerFecha(this.props.anexo.inicio);
    const hora = ObtenerHora(this.props.anexo.inicio);

    return (
      <Document
        title={this.props.anexo.cod_curso}
        author="UTDA"
        creator="UTDA-PDF"
      >
        <Page size="A4">
          <View style={style.container}>
            <View
              style={{
                width: "100%",
                height: "30px",
                marginTop: "5px",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Image src={ImagenLogo} />
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "15px",
              }}
            >
              <View style={[style.small, { width: "50%" }]}>
                <View style={[style.row, style.small]}>
                  <View style={style.col_4}>
                    <Text>ID INSTRUCTOR: </Text>
                  </View>
                  <View style={[style.col_6, { textAlign: "right" }]}>
                    <Text>{this.props.anexo.id_inst}</Text>
                  </View>
                </View>
                <View style={style.row}>
                  <View style={style.col_4}>
                    <Text>INSTRUCTOR: </Text>
                  </View>
                  <View style={[style.col_6, { textAlign: "right" }]}>
                    <Text>{this.props.anexo.instructor}</Text>
                  </View>
                </View>
                <View style={style.row}>
                  <View style={style.col_4}>
                    <Text>CFP/UPC/ESCUELA: </Text>
                  </View>
                  <View style={[style.col_6, { textAlign: "right" }]}>
                    <Text>{this.props.anexo.campus}</Text>
                  </View>
                </View>
                <View style={style.row}>
                  <View style={style.col_4}>
                    <Text>CURSO: </Text>
                  </View>
                  <View style={[style.col_6, { textAlign: "right" }]}>
                    <Text>{this.props.anexo.curso}</Text>
                  </View>
                </View>
                <View style={style.row}>
                  <View style={style.col_4}>
                    <Text>CÓDIGO CURSO(BB): </Text>
                  </View>
                  <View style={[style.col_6, { textAlign: "right" }]}>
                    <Text>{this.props.anexo.cod_curso}</Text>
                  </View>
                </View>
                <View style={style.row}>
                  <View style={style.col_4}>
                    <Text>PERIODO: </Text>
                  </View>
                  <View style={[style.col_6, { textAlign: "right" }]}>
                    <Text>{this.props.anexo.periodo}</Text>
                  </View>
                </View>
              </View>
              <View style={[style.small, { width: "45%" }]}>
                <View style={[style.row, style.small]}>
                  <View style={style.col_4}>
                    <Text>FECHA: </Text>
                  </View>
                  <View style={[style.col_6, { textAlign: "right" }]}>
                    <Text>{fecha}</Text>
                  </View>
                </View>
                <View style={[style.row, style.small]}>
                  <View style={style.col_4}>
                    <Text>HORA: </Text>
                  </View>
                  <View style={[style.col_6, { textAlign: "right" }]}>
                    <Text>{hora}</Text>
                  </View>
                </View>
                <View style={[style.row, style.small]}>
                  <View style={style.col_4}>
                    <Text>TIPO: </Text>
                  </View>
                  <View style={[style.col_6, { textAlign: "right" }]}>
                    <Text>{this.props.anexo.tipo}</Text>
                  </View>
                </View>
                <View style={[style.row, style.small]}>
                  <View style={style.col_4}>
                    <Text>NRC: </Text>
                  </View>
                  <View style={[style.col_6, { textAlign: "right" }]}>
                    <Text>
                      {Obtener_LcNrc(this.props.anexo.cod_curso) +
                        "_" +
                        Obtener_NrcLc(this.props.anexo.cod_curso)}
                    </Text>
                  </View>
                </View>
                <View style={[style.row, style.small]}>
                  <View style={style.col_4}>
                    <Text>MATRICULADOS: </Text>
                  </View>
                  <View style={[style.col_6, { textAlign: "right" }]}>
                    <Text>{this.props.anexo.alumnosbb}</Text>
                  </View>
                </View>
                <View style={[style.row, style.small]}>
                  <View style={style.col_4}>
                    <Text>ASISTENTES: </Text>
                  </View>
                  <View style={[style.col_6, { textAlign: "right" }]}>
                    <Text>{this.props.anexo.alumnos_asiste}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                marginTop: "15px",
              }}
            >
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={[style.row, style.small]}>
                  <View style={style.col_1_5}>
                    <Text style={style.textcenter}>
                      Item
                    </Text>
                  </View>
                  <View style={style.col_5_5}>
                    <Text style={style.textcenter}>
                      ACTIVIDAD
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>Cumple</Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      Cumple parcialmente
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>No cumple</Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>No aplica</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={[style.row, style.small]}>
                  <View
                    style={{
                      width: "100%",
                      textAlign: "center",
                      padding: "5px",
                    }}
                  >
                    <Text>Observación directa</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={[style.row, style.small]}>
                  <View style={style.col_1_5}>
                    <Text>
                      01
                    </Text>
                  </View>
                  <View style={style.col_5_5}>
                    <Text>
                      Plantea el objetivo de la sesión de clase y los temas o
                      tareas a desarrollar
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta1 === "1" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta1 === "2" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta1 === "3" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta1 === "4" ? "X" : ""}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={[style.row, style.small]}>
                  <View style={style.col_1_5}>
                    <Text>
                      02
                    </Text>
                  </View>
                  <View style={style.col_5_5}>
                    <Text>
                      Aplica técnicas o estrategias para mantener el interés y
                      motivación de los aprendices
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta2 === "1" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta2 === "2" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta2 === "3" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta2 === "4" ? "X" : ""}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={[style.row, style.small]}>
                  <View style={style.col_1_5}>
                    <Text>
                      03
                    </Text>
                  </View>
                  <View style={style.col_5_5}>
                    <Text>
                      Fomenta el trabajo colaborativo y/o el pensamiento
                      reflexivo, crítico y creativo de los estudiantes.
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta3 === "1" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta3 === "2" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta3 === "3" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta3 === "4" ? "X" : ""}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={[style.row, style.small]}>
                  <View style={style.col_1_5}>
                    <Text>
                      04
                    </Text>
                  </View>
                  <View style={style.col_5_5}>
                    <Text>
                      Aplica diversos recursos para presentar la clase
                      (diapositiva, pizarra, videos, diagramas, otros)
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta4 === "1" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta4 === "2" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta4 === "3" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta4 === "4" ? "X" : ""}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={[style.row, style.small]}>
                  <View style={style.col_1_5}>
                    <Text>
                      05
                    </Text>
                  </View>
                  <View style={style.col_5_5}>
                    <Text>
                      Usa adecuadamente los medios didácticos disponibles (videos, láminas, maquetas, módulos didácticos y otros)
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta5 === "1" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta5 === "2" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta5 === "3" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta5 === "4" ? "X" : ""}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={[style.row, style.small]}>
                  <View style={style.col_1_5}>
                    <Text>
                      06
                    </Text>
                  </View>
                  <View style={style.col_5_5}>
                    <Text>
                      Presenta ejemplos, casos prácticos o ejercicios de aplicación que están relacionados al tema.
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta6 === "1" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta6 === "2" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta6 === "3" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta6 === "4" ? "X" : ""}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={[style.row, style.small]}>
                  <View style={style.col_1_5}>
                    <Text>
                      07
                    </Text>
                  </View>
                  <View style={style.col_5_5}>
                    <Text>
                      Fomenta el diálogo, la participación y debate constructivo de ideas con y entre los estudiantes
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta7 === "1" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta7 === "2" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta7 === "3" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta7 === "4" ? "X" : ""}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={[style.row, style.small]}>
                  <View style={style.col_1_5}>
                    <Text>
                      08
                    </Text>
                  </View>
                  <View style={style.col_5_5}>
                    <Text>
                      Aplica la retroalimentación durante el proceso de enseñanza-aprendizaje.
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta8 === "1" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta8 === "2" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta8 === "3" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta8 === "4" ? "X" : ""}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={[style.row, style.small]}>
                  <View style={style.col_1_5}>
                    <Text>
                      09
                    </Text>
                  </View>
                  <View style={style.col_5_5}>
                    <Text>
                      Mantiene una adecuada conducción del grupo de estudiantes en la clase.
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta9 === "1" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta9 === "2" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta9 === "3" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta9 === "4" ? "X" : ""}
                    </Text>
                  </View>
                </View>
              </View>
               <View style={[style.row, style.small]}>
                   <View style={style.col_1_5}>
                    <Text>
                      10
                    </Text>
                  </View>
                  <View style={style.col_5_5}>
                    <Text>Uso adecuado y racional de materiales o insumos para las prácticas de taller/laboratorio</Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta10 === "1" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta10 === "2" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta10 === "3" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta10 === "4" ? "X" : ""}
                    </Text>
                  </View>
                </View>
                <View style={[style.row, style.small]}>
                  <View style={style.col_1_5}>
                    <Text>
                      11
                    </Text>
                  </View>
                  <View style={style.col_5_5}>
                    <Text>
                      Muestra cordialidad en el trato con los estudiantes y presentación personal
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta11 === "1" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta11 === "2" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta11 === "3" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta11 === "4" ? "X" : ""}
                    </Text>
                  </View>
                </View>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={[style.row, style.small]}>
                  <View
                    style={{
                      width: "100%",
                      textAlign: "center",
                      padding: "5px",
                    }}
                  >
                    <Text>Información de otras fuentes/momentos</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                
              </View>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                
              </View>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={[style.row, style.small]}>
                  <View style={style.col_1_5}>
                    <Text>
                      12
                    </Text>
                  </View>
                  <View style={style.col_5_5}>
                    <Text>
                      Registra asistencia de los aprendices
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta12 === "1" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta12 === "2" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta12 === "3" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta12 === "4" ? "X" : ""}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={[style.row, style.small]}>
                  <View style={style.col_1_5}>
                    <Text>
                      13
                    </Text>
                  </View>
                  <View style={style.col_5_5}>
                    <Text>
                      Realiza y mantiene actualizado el registro de charlas de 5 minutos (solo en taller)
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta13 === "1" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta13 === "2" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta13 === "3" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta13 === "4" ? "X" : ""}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={[style.row, style.small]}>
                  <View style={style.col_1_5}>
                    <Text>
                      14
                    </Text>
                  </View>
                  <View style={style.col_5_5}>
                    <Text>
                      Elabora los instrumentos de evaluación de acuerdo a los Procedimientos establecido
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta14 === "1" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta14 === "2" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta14 === "3" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta14 === "4" ? "X" : ""}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={[style.row, style.small]}>
                  <View style={style.col_1_5}>
                    <Text>
                      15
                    </Text>
                  </View>
                  <View style={style.col_5_5}>
                    <Text>
                      Revisa y califica las tareas o actividades programadas, dentro de los plazos establecidos.
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta15 === "1" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta15 === "2" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta15 === "3" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta15 === "4" ? "X" : ""}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={[style.row, style.small]}>
                  <View style={style.col_1_5}>
                    <Text>
                      16
                    </Text>
                  </View>
                  <View style={style.col_5_5}>
                    <Text>
                      Califica oportunamente y de acuerdo a los Procedimientos el cuaderno de informes semanal
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta16 === "1" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta16 === "2" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta16 === "3" ? "X" : ""}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.props.anexo.pregunta16 === "4" ? "X" : ""}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={[style.row, style.small]}>
                  <View
                    style={{
                      width: "100%",
                      textAlign: "center",
                      padding: "5px",
                    }}
                  >
                    <Text> </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={[style.row, style.small]}>
                  <View style={[style.col_6, style.textcenter]}>
                    <Text> Total </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.handleRpta("1")}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.handleRpta("2")}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.handleRpta("3")}
                    </Text>
                  </View>
                  <View style={[style.col_1, { textAlign: "right" }]}>
                    <Text style={{ textAlign: "center" }}>
                      {this.handleRpta("4")}
                    </Text>
                  </View>
                </View>
              </View>
              {/***********/}
              <View
                style={{
                  width: "100%",
                  textAlign:'center',
                  fontSize:'8px'
                }}
              >
                <Text>
                  Si en la ficha aparece algún ítem marcado en la columna "NO APLICA", se debe interpretar que el Especialista
                  consideró que dicho ítem no correspondía calificarse en dicha sesión.
                </Text>
              </View>
            </View>
            {/********************************** */}
            <View style={[style.cajaflex, { marginTop: "13px" }]}>
              <View style={[style.borde, style.col_4_5, style.text8]}>
                <View>
                  <Text>Aspectos positivos</Text>
                </View>
                <View style={{ minHeight: "30px" }}>
                  <Text>{this.props.anexo.description1}</Text>
                </View>
              </View>
              <View style={[style.borde, style.col_4_5, style.text8]}>
                <View>
                  <Text>Oportunidades de mejora</Text>
                </View>
                <View style={{ minHeight: "30px" }}>
                  <Text>{this.props.anexo.description2}</Text>
                </View>
              </View>
            </View>
            {/********************************** */}
            <View style={[style.cajaflex, { marginTop: "3px" }]} >
              <View
                style={{
                  width: "40%",
                  margin: "auto",
                  marginTop: "30px",
                }}
              >
                <View style={[style.small, style.row]}>
                  <View style={style.col_6}>
                    <Text>MAXIMO PUNTAJE</Text>
                  </View>
                  <View style={style.col_4}>
                    <Text style={{ textAlign: "right" }}>
                      { this.handleMaximoPuntaje() + ' puntos'}
                    </Text>
                  </View>
                </View>
                <View style={[style.small, style.row]}>
                  <View style={style.col_6}>
                    <Text>RESULTADO</Text>
                  </View>
                  <View style={style.col_4}>
                    <Text style={{ textAlign: "right" }}>
                      { this.handleValor() + ' puntos (' + this.handleResultado() + "%)"}
                    </Text>
                  </View>
                </View>
                <View style={[style.small, style.row]}>
                  <View style={style.col_6}>
                    <Text>CALIFICADO</Text>
                  </View>
                  <View style={style.col_4}>
                    <Text style={{ textAlign: "right" }}>
                      {this.handleCalificacion()}
                    </Text>
                  </View>
                </View>
              </View>
              {/** */}
              <View
                style={{
                  width: "40%",
                  marginTop: "10px",
                }}
              >
                <View style={[style.small, style.row]}>
                  <View style={style.col_6}>
                    <Text>EXCELENTE</Text>
                  </View>
                  <View style={style.col_4}>
                    <Text style={{ textAlign: "right" }}>
                      86 - 100%
                    </Text>
                  </View>
                </View>
                <View style={[style.small, style.row]}>
                  <View style={style.col_6}>
                    <Text>BUENO</Text>
                  </View>
                  <View style={style.col_4}>
                    <Text style={{ textAlign: "right" }}>
                      71 - 85%
                    </Text>
                  </View>
                </View>
                <View style={[style.small, style.row]}>
                  <View style={style.col_6}>
                    <Text>ACEPTABLE</Text>
                  </View>
                  <View style={style.col_4}>
                    <Text style={{ textAlign: "right" }}>
                      56 -  70%
                    </Text>
                  </View>
                </View>
                <View style={[style.small, style.row]}>
                  <View style={style.col_6}>
                    <Text>INSUFICIENTE</Text>
                  </View>
                  <View style={style.col_4}>
                    <Text style={{ textAlign: "right" }}>
                      41 -  55%
                    </Text>
                  </View>
                </View>
                <View style={[style.small, style.row]}>
                  <View style={style.col_6}>
                    <Text>DEFICIENTE</Text>
                  </View>
                  <View style={style.col_4}>
                    <Text style={{ textAlign: "right" }}>
                      0 - 40%
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            {/*************************************/}
            {
              <View
                style={[
                  style.cajaflex,
                  style.ubicacionFinal,
                  { justifyContent: "space-around", marginTop: "7px" },
                ]}
              >
                <View style={[style.col_3X, style.text8]}>
                  <View>
                    <Text style={{ minHeight: "40px" }}></Text>
                  </View>
                  <View
                    style={{
                      borderTop: "1px solid #333",
                      textAlign: "center",
                      padding: "3px",
                    }}
                  >
                    <Text>{this.props.anexo.id_inst}</Text>
                    <Text>{this.props.anexo.instructor}</Text>
                  </View>
                </View>
                <View style={[style.col_3X, style.text8]}>
                  <View>
                    <Text style={{ minHeight: "40px" }}></Text>
                  </View>
                  <View
                    style={{
                      borderTop: "1px solid #333",
                      textAlign: "center",
                      padding: "3px",
                    }}
                  >
                    <Text>{this.props.anexo.supervisorId}</Text>
                    <Text>{this.props.anexo.supervisor}</Text>
                  </View>
                </View>
              </View>
            }
          </View>
        </Page>
      </Document>
    );
  }
}
