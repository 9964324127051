import React, { Component } from 'react'
import AnexoSchema from '../../context/AnexoContext';
import { HoraActual, Obtener_LcNrc, Obtener_NrcLc } from '../../lib/source';
import { IAnexo03Schema } from '../../models/mongo/anexo.schema';
import { ICursoSeguimiento } from '../../models/Seguimiento/cursoseguimiento.model';
import InputText from '../InputText';


interface IProps {
  controlAlumno:ICursoSeguimiento
  Asistencia:Function
  tipo:string
}

interface IState {
  horaFinal: string;
  horaInicial: string;
  name: string;
  value: string;
}

export default class TablaDatosCurso extends Component<IProps, IState> {

  static contextType?: React.Context<any> | undefined = AnexoSchema

  constructor(props:IProps){
    super(props)

    this.state = {
      horaFinal: "",
      horaInicial: HoraActual().toString(),
      name: "",
      value: "",

    }

    this.handleInputTexto = this.handleInputTexto.bind(this)
  }

  componentDidUpdate(prevProps:IProps,prevState:IState){

    if (this.state.value !== prevState.value) {
      //this.props.Asistencia({'asistencia': this.state.value},{'horaInical': this.state.horaInicial})

      const { setUpdate } = this.context


      const valueDefault:IAnexo03Schema = {
          _id:'',
          asistencia: this.state.value,
          cod_curso:'',
          curso_horario:'',
          curso_cerrado:'',
          id_instructor:'',
          instructor:'', 
          id_seguimiento:'',
          nota:'',
          nrc:'',
          periodo:'',
          semana:'',
          tipo:'',
          zonal:'',
          campus:'',
          curso:'',
          alumnosbb:0,
          statusInst:false, 
          porcentaje:''
      } 

      setUpdate(valueDefault)

    }
  }

  handleInputTexto(name:string,value:string){

    this.setState({
      name,
      value
    })
   
  }

  render() {

  setInterval(() => {
    this.setState({
      horaFinal: HoraActual().toString(),
    });
  }, 100);

    return (
      <>
          <div className="row justify-content-md-center mt-5">
            <div className="col-lg-10">
              <div className="row justify-content-md-around">
                <div className="col-lg-4">
                  <table className="table table-bordered table-striped text-sm">
                    <tbody>
                      <tr>
                        <td width={"35%"}>ID</td>
                        <td align="right">
                          {this.props.controlAlumno.id_inst}
                        </td>
                      </tr>
                      <tr>
                        <td align="left">INSTRUCTOR</td>
                        <td align="right">
                          {this.props.controlAlumno.instructor}
                        </td>
                      </tr>
                      <tr>
                        <td align="left">CFP/UPC/ESCUELA</td>
                        <td align="right">
                          {this.props.controlAlumno.campus}
                        </td>
                      </tr>
                      <tr>
                        <td align="left">CURSO CERRADO</td>
                        <td align="right">
                          {this.props.controlAlumno.curso_cerrado}
                        </td>
                      </tr>
                      <tr>
                        <td align="left">CURSO</td>
                        <td align="right">
                          {this.props.controlAlumno.curso}
                        </td>
                      </tr>
                      <tr>
                        <td align="left">CÓDIGO CURSO(BB)</td>
                        <td align="right">
                          {this.props.controlAlumno.cod_curso}
                        </td>
                      </tr>
                      <tr>
                        <td align="left">PERIODO</td>
                        <td align="right">
                          {this.props.controlAlumno.periodo}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-3">
                  <table className="table table-bordered table-striped text-sm">
                    <tbody>
                      <tr>
                        <td width={"70%"}>CURSO HORARIO</td>
                        <td width={"30%"} align="right">
                          {this.props.controlAlumno.curso_horario.replace('-',' - ')}
                        </td>
                      </tr>
                      <tr>
                        <td>HORA DE INICIO SEGUIMIENTO</td>
                        <td align="right">{this.state.horaInicial}</td>
                      </tr>
                      <tr>
                        <td>TIPO</td>
                        <td align="right">{this.props.tipo}</td>
                      </tr>
                      <tr>
                        <td>NRC PRINCIPAL</td>
                        <td align="right">
                          {Obtener_LcNrc(this.props.controlAlumno.cod_curso) +  "_" + Obtener_NrcLc(this.props.controlAlumno.cod_curso)}
                        </td>
                      </tr>
                      <tr>
                        <td>MATRICULADOS</td>
                        <td align="right">
                          {this.props.controlAlumno.inscritosbb}
                        </td>
                      </tr>
                      <tr>
                        <td>ASISTENTES</td>
                        <td align="right">
                          <InputText
                            name="asistencia"
                            className=" form-control input-form "
                            placeHolder="asistencia"
                            required={true}
                            Value={this.handleInputTexto}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
      </>
    );
  }
}
