import {createContext} from 'react'

import { IAnexo03Schema } from "../models/mongo/anexo.schema";

interface IAnexoSchema extends IAnexo03Schema {
    
    setUpdate?:Function
}

const valueDefault:IAnexoSchema = {
_id:'',
asistencia:'',
cod_curso:'',
curso_horario:'',
id_instructor:'',
instructor:'',
id_seguimiento:'',
curso_cerrado:'',
nota:'',
nrc:'',
semana:'',
periodo:'',
statusInst:false,
tipo:'',
alumnosbb:0,
campus:'',
curso:'',
zonal:'',
porcentaje:''
}

const AnexoSchema = createContext<Partial<IAnexoSchema>>(valueDefault)


export default AnexoSchema