

export function batchUID(idalumno: string) {

        let temp = ''

        idalumno = idalumno.replace(' ', '')


        const cant = idalumno.length
        const cantidadCeros = 9 - cant

        for (var i = 0; i < cantidadCeros ; i++) {
            temp += '0'
        }

       return temp + idalumno
}

export function parseIdAlumno(idalumnos:string):string{

    let values:string[] = []
    let newIdAlumnos = idalumnos.split(',')

    var setvs = (cadena: string[]) => cadena.map((c) => values.push(batchUID(c)))
    setvs(newIdAlumnos)

    return values.join()
}

export function parseNrc(nrc:string):string{

    let values:string[] = []
    let newNrc = nrc.split(',')

    var setvs = (cadena:string[]) =>  cadena.map((c) => values.push(c.trim()))
    setvs(newNrc)

    return values.join()
}

export function removeDuplicados(data: any, parametro:string) {

    let hash: any = {};

    data = data.filter((o:any) => hash[o[parametro]] ? false : hash[o[parametro]] = true);//hash[o.cursoid]

    return data
}

export function Ordenar(data: any, parametro: string) {

    data = data.sort((a:any,b:any)=>{
        if(a[parametro] > b[parametro])
            return 1

        if(a[parametro] < b[parametro])
            return -1

        return 0
    })

    return data
}
export function OrdenarReverse(data: any, parametro: string) {

    data = data.sort((a:any,b:any)=>{
        if(a[parametro] > b[parametro])
            return 1

        if(a[parametro] < b[parametro])
            return -1

        return 0
    })

    return data.reverse()
}


export function Obtener_NrcLc(courseId:string){
    if (courseId === undefined || !courseId) return ''
    return courseId.replaceAll('-','_').split('_').reverse()[0]
}

export function Obtener_Periodo(courseId:string):string{
    return courseId.replaceAll('-','_').split('_')[0]
}

export function Obtener_LcNrc(courseId:string){

    if (courseId === undefined || !courseId) return ''
    const LcNrc:string = (courseId.split('_')[0]).split('-').reverse()[0]

    return LcNrc
}

export function DetectarLcNrc(courseId:string):string{



    let ascii = courseId.toUpperCase().charCodeAt(0)
	return (ascii > 64 && ascii < 91)? 'LC' : 'NRC'
}

export function FechaActual(){
    var f = new Date()
 
    const mes = (f.getMonth() +1)


    return FormatoDate_Ceros(f.getDay(),2) +'/' + FormatoDate_Ceros(mes,2) +'/'+ f.getFullYear()

}

export function FechaActual_Mes(date:string|Date, horas:number=0){
    var fecha = new Date(date) 

    var numberOfMlSeconds = fecha.getTime()
    var addMlSeconds = 1000 * 60 * 60 * horas // 1seg * min  * hora * cantHoras
    var newDateObj = new Date(numberOfMlSeconds + addMlSeconds)


    const mes = newDateObj.getMonth().toString()
    const dia = newDateObj.getDate()
    //const anno = newDateObj.getFullYear()

    var pMatricula:string =''


    switch (mes) {
        case '0': pMatricula ='enero' 
                break
        case '1': pMatricula ='febrero' 
                break
        case '2': pMatricula ='marzo' 
                break
        case '3': pMatricula ='abril' 
                break
        case '4': pMatricula ='mayo' 
                break
        case '5': pMatricula ='junio' 
                break
        case '6': pMatricula ='julio' 
                break
        case '7': pMatricula ='agosto' 
                break
        case '8': pMatricula ='setiembre' 
                break
        case '9': pMatricula ='octubre' 
                break
        case '10': pMatricula ='noviembre' 
                break
        case '11': pMatricula ='diciembre' 
                break
    }


    return FormatoDate_Ceros(dia,2) + " " + pMatricula
}

export function HoraActual(){
    var f = new Date()

    return FormatoDate_Ceros(f.getHours(),2) + ":" +  FormatoDate_Ceros(f.getMinutes(),2)
}

export function ObtenerFecha(fecha:Date){

    if(fecha===undefined || !fecha) return ''

    const mes = (fecha.getMonth() +1)
    const dia = fecha.getDate()
    const anno =  fecha.getFullYear()

    return FormatoDate_Ceros(dia,2) + "/" + FormatoDate_Ceros(mes,2) + "/" + anno

}

export function addDaysToDate(res:Date, days:number){
    //var res = new Date(date);
    res.setDate(res.getDate() + days);

    
    return res;
}

export function addHoursToDays(res:Date, hours:number){
    //var res = new Date(date);
    var numberOfMlSeconds = res.getTime();
    var addMlSeconds = (hours * 60) * 60000;
    var newDateObj = new Date(numberOfMlSeconds + addMlSeconds)
    return newDateObj;
}

export function Fecha(fecha:Date){

    if(fecha===undefined || !fecha) return ''

    const mes = (fecha.getMonth() +1)
    const dia = fecha.getDate()
    const anno =  fecha.getFullYear()

    return    anno + "-" + FormatoDate_Ceros(mes,2) + '-' + FormatoDate_Ceros(dia,2) 

}

export function ObtenerHora(fecha:Date){
    if(fecha===undefined || !fecha) return ''

    return FormatoDate_Ceros(fecha.getHours(),2) + ":" +  FormatoDate_Ceros(fecha.getMinutes(),2)

}

export function ObtenerSemana(){

    const currentdate: any = new Date()

    const anno = currentdate.getFullYear()

    var oneJan: any = new Date(currentdate.getFullYear(), 0, 1)
    var numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000))
    return anno + "-" +  FormatoDate_Ceros(Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7).toString(),2)

}

export function ObtenerHoraZonaHoraria(date: string|Date,horas:number=0) {
    if (date === undefined || !date) return ''

    var fecha = new Date(date);


    var numberOfMlSeconds = fecha.getTime()
    var addMlSeconds = 1000 * 60 * 60 * horas // 1seg * min  * hora * cantHoras
    var newDateObj = new Date(numberOfMlSeconds + addMlSeconds)


    const mes = (newDateObj.getMonth() + 1)
    const dia = newDateObj.getDate()
    const anno = newDateObj.getFullYear()

    return FormatoDate_Ceros(dia, 2) + "/" + FormatoDate_Ceros(mes, 2) + "/" + anno + " " + FormatoDate_Ceros(newDateObj.getHours(), 2) + ":" + FormatoDate_Ceros(newDateObj.getMinutes(), 2)

}

function FormatoDate_Ceros(texto:string|number, cantidadDigito:number){
    let temp:string =''
    let newTexto:string = texto.toString()

    const cant:number = newTexto.length
    const cantidadCeros = cantidadDigito - cant

        for (var i = 0; i < cantidadCeros ; i++) {
            temp += '0'
        }

    return temp + newTexto
}

export function ConvertirFecha(fecha:string , hora:string){
    const newfecha = fecha.split('/')
    const dia  = parseInt(newfecha[0])
    const mes  = parseInt(newfecha[1])
    const anno = parseInt(newfecha[2])

    const newHora = hora.split(':')
    const hours = parseInt(newHora[0])
    const minus = parseInt(newHora[1])

    return new Date(anno,mes,dia,hours,minus)



}


//regreso las modificaciones sobre la arreglo original
export const pipe = (...fns:any)=>
                               (x:any) => fns.reduce((v:any,f:Function)=> f(v),x)

/***
 * houses = [ {nombre:'casaplaya', cuartos:5, precio:'10'},
*             {nombre:'casa sjl', cuartos:5, precio:'10'},
              {nombre:'casa papa', cuartos:5, precio:'10'},
 *               ]

      function OrdenarxPrecio(casas:any){
          return casas.sort((a:any, b:any)=> a.precio - b.precio)
      }

      function NombreMayuscula(casas:any){
          return casas.map((e:any)=>{
              return {
                  nombre: e.name.toUpperCase(),
                  cuartos: e.cuartos
                  precio: e.precio
              }
          })
      }

      function aplicarImpuesto(casas:any){
          return casas.map((e:any)=>{
              return {
                  nombre: e.name,
                  cuartos: e.cuartos
                  precio: parseFloat(e.precio * 0.18).toFixed(2)
              }
          })
      }

   const myHouses = aplicarImpuesto(NombreMayuscula((OrdenarxPrecio(houses))))
 *
 * const myHouse = pípe(aplicarImpuesto,NombreMayuscula,OrdenarxPrecio)(houses)
 *
 */