
import React ,{  Component } from 'react'
import AuthSeguimientoContext from './SeguimientoContext'


interface IProps{}

interface IState{
    courseId:string
    instructorId:string
    especialistaId:string
    semana:string
    tipo:string
    addSeguimiento?:Function 
}

//export const AuthSeguimientoContext = createContext<Partial<IState>>({});

export default class AuthSeguimientoProvider extends Component<IProps, IState> {
    
    constructor(props:IProps){
        super(props)

        this.state ={
            courseId:'text',
            instructorId:'',
            especialistaId:'',
            semana:'',
            tipo:''
        }

        this.handleSeguimiento = this.handleSeguimiento.bind(this)
    }
    

    handleSeguimiento(courseId:string,instructorId:string,especialistaId:string,semana:string,tipo:string) {
        this.setState({
            courseId,
            instructorId,
            especialistaId,
            semana,
            tipo
        })
        
    }


    render(){
        return (
            <AuthSeguimientoContext.Provider value={
                {
                    ...this.state, 
                    addSeguimiento:this.handleSeguimiento
                }
            } > 
                {this.props.children}
            </AuthSeguimientoContext.Provider>
        )
    }
}

