import React, { Component,Fragment } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import ModalSaveConInstructor from '../../component/ModalSaveConInstructor'
import Links from '../../component/Seguimiento/Links'

import TablaDatosCurso from '../../component/Seguimiento/TablaDatosCurso'
import TablaPreguntas from '../../component/Seguimiento/TablaPreguntas'
import TablaPreguntasPre from '../../component/Seguimiento/TablaPreguntasPre'
import AuthSeguimientoContext from '../../context/SeguimientoContext'
import ctrlControlAnexo03 from '../../controller/Seguimiento/ctrlControlAnexo03.ctrl'
import crtrlJsonReadAnexo03 from '../../controller/Seguimiento/CtrlJsonReadAnexo03.ctrl'
import { ICursoSeguimiento } from '../../models/Seguimiento/cursoseguimiento.model'
import { ISegAnswer, ISegQuestion } from '../../models/Seguimiento/question.model'


interface IProps extends RouteComponentProps{ }

interface IState {
  curso:ICursoSeguimiento,
  especialistaId:string,
  answers:ISegAnswer[]
  questions:ISegQuestion[]
  semana:string
  tipo:string
  [texto:string]:any
}

export default class Ficha extends Component<IProps,IState> {

  static contextType?: React.Context<any> | undefined = AuthSeguimientoContext

  constructor(props:IProps){
    super(props)

    this.state= {
      curso:{
        periodo : '',
        zonal : '',
        campus : '',
        pk1_curso : 0,
        cod_curso : '',
        nrc : '',
        inicio : '',
        fin : '',
        curso : '',
        curso_cerrado : '',
        pk1_usuario : 0,
        id_inst : '',
        instructor : '',
        inscritosbb : 0,
        url_teams : '',
        curso_horario : '',
        
      },
      especialistaId:'',
      semana:'',
      tipo:'',
      answers:[],
      questions:[],
      detalle:[]
    }

    this.handleAsistencia = this.handleAsistencia.bind(this)
    this.handlePreguntas = this.handlePreguntas.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handelModalClose = this.handelModalClose.bind(this)
  }

  async componentDidMount(): Promise<void> {

    const { courseId , especialistaId, semana, tipo } = this.context


    if(courseId !== 'text'){
      this.setState({
        curso: await crtrlJsonReadAnexo03.getSeguimientoxCurso(courseId) ,
        especialistaId,
        tipo,
        semana,
        questions :  await ctrlControlAnexo03.getQuestion(),
        answers   :  await ctrlControlAnexo03.getAnswer(),
      })
    }
    else
    {
      this.props.history.push('/NoValidate')
    }
  }

  handleAsistencia(){

  }


  handlePreguntas(item:string , dato:string){
    this.setState({
      [item]:dato
    })
  
  }

  async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    this.setState({ modal: true })

  }

  handelModalClose(){
    this.setState({ modal: false})
  }



  render() {
    return (
      <Fragment >
        <ModalSaveConInstructor
              {...this.props}
              {...this.state}
              especialistaId={this.state.especialistaId}
              tipo={this.state.tipo}
              open={this.state.modal}
              closed={this.handelModalClose}
              
        />
        <Links  {...this.props } 
                controlAlumno={this.state.curso} 
                especialistaId={this.state.especialistaId}
                semana={this.state.semana}
                tipo={this.state.tipo}
        />
        <form onSubmit={this.handleSubmit}>
          <TablaDatosCurso
                        controlAlumno={this.state.curso}
                        tipo={this.state.tipo}
                        Asistencia={this.handleAsistencia}
          /> 
          <div className="row justify-content-md-center mt-5">
            <div className="col-lg-10">
              <div className="row justify-content-md-around">
                {/**around between*/}
                <p className="text-center">
                  <div className="badge bg-danger text-wrap" style={{width: "8rem"}} role='alert'>
                    NOTA
                  </div> Si en la ficha aparece algún ítem marcado en la
                  columna "NO APLICA", se debe interpretar que el Especialista consideró que dicho
                  ítem no correspondía calificarse en dicha sesión.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-lg-10">
              {
                this.state.tipo==='VIR'?
                  <TablaPreguntas
                      questions={this.state.questions}
                      answers={this.state.answers}
                      Anexo03={this.handlePreguntas}
                  />
                :
                  <TablaPreguntasPre
                      questions={this.state.questions}
                      answers={this.state.answers}
                      Anexo03={this.handlePreguntas}
                  />
              }
            </div>
          </div>
          <div className="row justify-content-md-end">
            <div className="col-lg-2">
              <button
                type="submit"
                className="btn btn-app btn-outline-primary"
              >
                <i className="far fa-save"></i> Registrar
              </button>
            </div>
          </div>
        </form>
      </Fragment>
    )
  }
}
