import React, { Component, Fragment } from 'react'
import { ISegAnswer } from '../../models/Seguimiento/question.model'

interface IProps {
  answers: ISegAnswer[]
  pregunta: number
  respuesta: string
  Selector:Function
}
interface IState {
  respuesta: string
  pregunta: string
  option:string

}

export default class BotonesRespuesta extends Component<IProps, IState> {

  constructor(props:IProps){
    super(props)

    this.state ={
      respuesta:'',
      pregunta: '',
      option:''
    }

    this.handleOnChange = this.handleOnChange.bind(this)
  }

  componentDidUpdate(prevProps:IProps,prevState:IState){

    if(this.state.option !== prevState.option){
      this.setState({
        respuesta: this.state.option,
      });

      this.props.Selector(this.state.pregunta, this.state.option)
    }

  }


  handleOnChange({target}: React.ChangeEvent<HTMLInputElement>){

    this.setState({
      pregunta: target.name,
      respuesta: target.value,
      option:target.value
    });

  }


  render() {

    const anwers = this.props.answers.map((a: ISegAnswer, i: number) => (
      <Fragment key={"rpt-" + this.props.pregunta + "-answer-" + i.toString()}>
        <label  className={ (this.state.option === a.value)? 'btn btn-outline-secondary m-1 active': 'btn btn-outline-secondary m-1'}
                htmlFor={this.props.pregunta.toString() + a.value}>
          <input
            type="radio"
            name= {'pregunta' + this.props.pregunta.toString()}
            id={this.props.pregunta.toString() + a.value}
            value={a.value}
            onChange={this.handleOnChange}
            checked={ (this.state.option === a.value) }
            required={true}
          />
          {a.answer} 
        </label>
      </Fragment>
    ));

    return (
      <div
        className="btn-group btn-group-toggle d-lg-flex d-md-block d-block">
          { anwers }
      </div>
    );
  }
}
