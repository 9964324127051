import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import ContenidoBody from '../component/ContenidoBody'

interface IProps extends RouteComponentProps {

}

interface IState {

}

export default class Logout extends Component<IProps, IState> {

  constructor(props:IProps){
    super(props)

    this.handleLogout = this.handleLogout.bind(this)

  }

  handleLogout(){
    const auth = this.context

    auth.Logout()
    auth.isLogged() && this.props.history.push('/')
  }


  render() {
    return (
      <ContenidoBody title='Logout' page='Logout' link='logout' >
          <div className="card">
            <div className="card-">
              <button className='btn btn-outline-secondary' onClick={this.handleLogout} >
                logout
              </button>
            </div>
          </div>
      </ContenidoBody>
    )
  }
}
