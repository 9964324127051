import React, { Component } from 'react'
import { IAnexo03Schema } from '../models/mongo/anexo.schema'
import AnexoSchema from './AnexoContext'

interface IProps{}

interface IState extends IAnexo03Schema{
    setUpdate?:Function
}

export default class AnexoProvide extends Component<IProps,IState> {

    constructor(props:IProps){
        super(props)

        this.state = {
                _id:'',
                asistencia:'',
                cod_curso:'',
                curso_horario:'',
                curso_cerrado:'', 
                id_instructor:'',
                instructor:'',
                id_seguimiento:'',
                nota:'',
                nrc:'',
                semana:'',
                periodo:'',
                statusInst:false,
                tipo:'',
                alumnosbb:0,
                campus:'',
                curso:'',
                zonal:'',
                porcentaje:''

        }

        this.handleUpdate = this.handleUpdate.bind(this)
    }

    handleUpdate(anexo:IAnexo03Schema){

        this.setState(anexo)
        
    }
    

    render() {
        return (
        <AnexoSchema.Provider value={{
            ...this.state,
            setUpdate:this.handleUpdate
        }}>
            { this.props.children}
        </AnexoSchema.Provider>
        )
    }
}
