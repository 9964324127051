import React, { Component, Fragment } from 'react'
import { RouteComponentProps } from 'react-router'
import ContenidoBody from '../../component/ContenidoBody'
import { BbTermContext } from '../../context/PeriodoProvider'
import bbListaAlumnos from '../../controller/BbListaAlumnos'
import BbZonales from '../../controller/BbZonales.ctrl'
import { IBbTerm } from '../../models/bb/BbTerms.model'
import { IListaMatriculado } from '../../models/oracle/listaMatriculados.model'
import { IOraZonales } from '../../models/oracle/OraZonales.model'

interface IProps extends RouteComponentProps{

}

interface IState {
  periodos    : IBbTerm[]
  periodo     : string
  activar     : boolean
  zonales     : IOraZonales[]
  zonal       : string
  reportes    : IListaMatriculado[]
  dato        : string
  auth        : Boolean
  tempPeriodo : string
  tempZonal   : string
  tempReportes: IListaMatriculado[]
  select      : string
  [data: string]: any
}


export default class ListaMatriculados extends Component<IProps, IState> {

  static contextType = BbTermContext
  private Datos: any = {}

  constructor(props:IProps){
    super(props)

    this.state = {
      activar: false,
      zonales: [],
      zonal: '0',
      periodos: [],
      periodo: '0',
      reportes: [],
      tempReportes: [],
      dato: '',
      auth: false,
      tempPeriodo: '',
      tempZonal: '',
      select: ''
    }

    this.handleInput  = this.handleInput.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleNrc    = this.handleNrc.bind(this)
  }

  async componentDidMount(){

    const BbContextTerm = this.context

    if (!BbContextTerm.BbAll() || BbContextTerm.BbAll().filter((term: IBbTerm) => term.option).length === 0) {
      this.props.history.push('/dashboard')
    }

    this.setState({
      activar: true
    })


    const { zonales, auth } = await BbZonales.All()

    if (!auth) {
      this.props.history.push('/dashboard')
    }


    this.setState({
      periodos: await BbContextTerm.BbAll().filter((term: IBbTerm) => term.option),
      zonales,
      auth,
      activar: false
    })

  }

  handleInput({ target }: React.ChangeEvent<HTMLInputElement>) {

    this.setState({
      [target.name]: target.value.trim().toUpperCase()
    })

  }

  async handleSelect({ target }: React.ChangeEvent<HTMLSelectElement>) {

    if(target.name === 'zonal' && this.state.periodo === '0'){
      alert('Seleccion un Periodo')
      return
    }


    this.setState({
      activar: true,
      [target.name]: target.value
    })

    if (target.name === 'periodo')
      await this.memorization(target.value)

    this.setState({
      activar: false
    })
  }

  async memorization(arg: string) {

    if (!this.Datos[arg])
      this.Datos[arg] = await bbListaAlumnos.FindxPeriodo(arg)

  }


  async handleNrc(){

    if (this.state.periodo === '0' || this.state.dato === '') return

    this.handleActivar()

    this.setState({
      reportes: await this.Datos[this.state.periodo].reportes.filter((rep: IListaMatriculado) => rep.NRC === this.state.dato),
      dato: '',
      select: 'nrc',

    })

    console.log(this.state.reportes);

    this.handleDesactivar()

  }


  handleActivar(){
    this.setState({
      activar: true
    })
  }

  handleDesactivar(){
    this.setState({
      activar: false
    })
  }

  render() {

    const listPeriodos = this.state.periodos.map((periodo: IBbTerm, i: number) =>
      <option value={periodo.periodo} key={periodo.periodo + '-' + i.toString()}>{periodo.name}</option>
    )

    const listZonales = this.state.zonales.map((zonal: IOraZonales, i: number) =>
      <option value={zonal.ZONAL} key={zonal.FILTRO + '-' + i.toString()}> {zonal.ZONAL}</option>
    )

    return (
      <ContenidoBody title={'REPORTE LISTA ALUMNOS'} link={'Lista'} page={'Alumnos'}>
        <div className="row justify-content-md-center">
          <div className="col-md-9">
            <div className="card direct-chat direct-chat-warning">
              <div className="card-header">
                <h3 className="card-title">
                  <span>
                    {this.state.activar
                      ? <i className="fas fa-spinner fa-spin"></i>
                      : <i className="fas fa-journal-whills"></i>}
                  </span> Consulta - REPORTE CONTROL ALUMNOS
                </h3>
                <div className="card-tools">
                  {/*Registros:*/}
                  <span data-toggle="tooltip"
                    title="3 New Messages"
                    className="badge badge-warning">
                  </span>
                  <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus" />
                  </button>
                  <button type="button" className="btn btn-tool" data-toggle="tooltip" title="Contacts" data-widget="chat-pane-toggle">
                    <i className="fas fa-comments" />
                  </button>
                </div>
              </div>
              <div className="card-body" style={{ margin: "10px 0px " }}>
                <button className="btn btn-app"
                  onClick={this.handleNrc}
                  disabled={this.state.activar}
                >
                  <span className="badge bg-orange"></span>
                  <i className="fa fa-book"></i> NRC
                </button>
                <button className="btn btn-app"

                  disabled={this.state.activar}
                >
                  <span className="badge bg-orange"></span>
                  <i className="far fa-books"></i> LISTA CRUZADA
                </button>
                <button className="btn btn-app"
                  disabled={this.state.activar}

                >
                  <span className="badge bg-orange"></span>
                  <i className="fas fa-chalkboard-teacher"></i> INSTRUCTOR
                </button>
              </div>
              <div className="card-footer d-flex flex-row">
                <div className="input-group mb-3 mr-3">
                  <select className='form-control'
                    name='periodo'
                    onChange={this.handleSelect}
                    value={this.state.periodo}
                    disabled={this.state.activar}
                  >
                    <option value="0">---PERIODO---</option>
                    {listPeriodos}
                  </select>
                </div>
                <div className="input-group mb-3 mr-3">
                  <select className='form-control'
                    name='zonal'
                    onChange={this.handleSelect}
                    value={this.state.zonal}
                    disabled={this.state.activar}
                  >
                    <option value="0">---Zonal---</option>
                    {listZonales}
                  </select>
                </div>
                <div className="input-group mb-3">
                  <input type="text"
                    name='dato'
                    minLength={2}
                    className="form-control"
                    placeholder=" NRC / LC "
                    aria-label=""
                    aria-describedby="basic-addon1"
                    value={this.state.dato}
                    onChange={this.handleInput}
                    disabled={this.state.activar}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-md-11">
            <div className="card direct-chat direct-chat-warning">
              <div className="card-header">
                <h3 className="card-title">
                  <span>
                    {this.state.activar
                      ? <i className="fas fa-spinner fa-spin"></i>
                      : <i className="fas fa-journal-whills"></i>}
                  </span> RESULTADO - REPORTE REPF01
                </h3>
              </div>
              <div className='card-body'>
                {
                  this.state.select === 'nrc' ?
                    <Fragment>

                    </Fragment>
                    :
                    <></>
                }

              </div>
            </div>
          </div>
        </div>
      </ContenidoBody>
    )
  }
}
