import React, { Component } from 'react'
import Chart from 'react-google-charts'
import ctrlData from '../../controller/Graficas/CtrlData.ctrl'
import { Ordenar } from '../../lib/source'
import { IMatricula } from '../../models/Grafica/Matriculas.model'
import { IGZonal } from '../../models/Grafica/zonal.model'


interface IProps {

  reportes: IMatricula[]
  zonal: string
  tperiodo:string
  pperiodo:string

}
interface IState {

}

export default class GraficaCharProgramas extends Component<IProps, IState> {

  render() {

    let  TipoPeriodo: IMatricula[] = []
    let datos: any = []

    let titulo:string ='Porcentaje de matriculas por programa'

    datos = [
        ['TipoPeriodo', 'Matriculados', { role: 'style' }, { role: 'annotation' }],
      ]



    TipoPeriodo = Ordenar(this.props.reportes.filter((rpt: IMatricula) => rpt.PartePeriodo === 'TODOS')
                                             .filter((rpt: IMatricula) => rpt.TipoPeriodo !== 'TODOS')
                                             .filter((rpt: IMatricula) => rpt.Zonal === this.props.zonal),'Porc_Mat').reverse()


      titulo += '\n'
      titulo +=  ctrlData.getZonalxId_Name(this.props.zonal).map((z:IGZonal)=>
                     z.name_abrev
                  )


      if(TipoPeriodo.length !== 0){

        TipoPeriodo.map((rpt: IMatricula, i: number) =>
          datos.push([
            rpt.TipoPeriodo.replace('programas','').replace('Prog.','').toLowerCase(),
            rpt.Porc_Mat ,
            rpt.Color_Mat,
            rpt.Porc_Mat + '%'
          ])
        )
      }
      else
          datos.push(['Empty',0,'#fff','0%'])

return (

	  <Chart
        width={'100%'}
        height={'38vh'}/**38 vh */
        chartType="BarChart"
        loader={<div>Loading Chart</div>}
        data={datos}
        options={{
		            title: titulo,
                titlePosition: 'out',
                titleTextStyle:{   bold: "True", fontSize:"12"},
                hAxis: {
                      title: '% matriculas',
                      minValue: 0,
                      maxValue:100,
                    },
                legend: { position: "none" },
                bar: { groupWidth: "45%" , background:'#333'},
                chartArea: { /*left: 200,*/ width: "40%", height: "auto" },
		            gridlines: {color: 'transparent' },

        }}


      />
    )
  }
}

