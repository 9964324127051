import React, { Component } from 'react'

interface IProps  {
  name:string
  placeHolder?:string
  className?:string
  required?:Boolean
  Value:Function

}
interface IState {
  name:string
  value:string

}

export default class InputText extends Component<IProps, IState> {

  constructor(props:IProps){
    super(props)

    this.state ={
      name:'',
      value:''
    }

    this.handleOnChange = this.handleOnChange.bind(this)
  }

  componentDidUpdate(prevProps:IProps, prevState:IState){

    if(prevState.value !== this.state.value){

      this.props.Value(this.state.name, this.state.value)
    }
  }

  handleOnChange(event:React.ChangeEvent<HTMLInputElement>){

    event.preventDefault()

    this.setState({
      name: event.target.name,
      value: event.target.value,
    });

  }

  render() {
    return (
      <input
        name={this.props.name}
        type="text"
        maxLength={2}
        placeholder={!!this.props.placeHolder ? this.props.placeHolder : ""}
        className={!!this.props.className ? this.props.className : ""}
        onChange={this.handleOnChange}
        value={this.state.value.replace(/[^0-9.]/g, "")} //.replace(/[^0-9.]/g, "")
        required={!!this.props.required}
      />
    );
  }
}
