import React, { Component } from 'react'
import { ISegAnswer, ISegQuestion } from '../../models/Seguimiento/question.model'
import TextArea from '../TextArea';
import BotonesRespuesta from './BotonesRespuesta'

interface IProps {
  answers: ISegAnswer[];
  questions: ISegQuestion[];
  Anexo03?:Function
}
interface IState {

  [data:string]:string

}

export default class TablaPreguntas extends Component<IProps, IState> {
  constructor(props:IProps){
    super(props)

    this.state = {
      pregunta:'',
      respuesta:''
    };

    this.handleSelector = this.handleSelector.bind(this)

  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {

    if (this.state.pregunta !== prevState.pregunta) {
      this.props.Anexo03!(this.state.pregunta, this.state.respuesta)
     
    }

    if (this.state.respuesta !== prevState.respuesta) {
      this.props.Anexo03!(this.state.pregunta, this.state.respuesta);
     
    }
  }

   handleSelector(pregunta:string, respuesta:string){

    this.setState({
      pregunta: pregunta,
      respuesta:respuesta
    });

  }

  render() {
 
    const tbody1 = this.props.questions.filter((q: ISegQuestion) => q.type === "Sesión Síncrona")
                                       .map((q: ISegQuestion, i: number) => (
                                          <tr key={"tbl-anexo03-" + i.toString()}>
                                            <td width={"5%"} align="center" className="align-middle">{q.number}</td>
                                            <td align="justify" className="text-md align-middle w-85">{q.question}</td>
                                            <td align="center" className="align-middle w-10">
                                              <BotonesRespuesta   answers={this.props.answers}
                                                                  pregunta={q.number}
                                                                  respuesta={this.state['pregunta'+this.state.pregunta]}
                                                                  Selector={this.handleSelector}/>
                                            </td>
                                          </tr>
                                        ))

    const tbody2 = this.props.questions.filter((q: ISegQuestion) => q.type === "Sesión Asíncrona")
                                        .map((q: ISegQuestion, i: number) => (
                                          <tr key={"tbl-anexo03-" + i.toString()}>
                                            <td width={"5%"} align="center" className="align-middle">{q.number}</td>
                                            <td align="justify" className="text-md align-middle w-85">{q.question}</td>
                                            <td align="center" className="align-middle w-10">
                                              <BotonesRespuesta   answers={this.props.answers}
                                                                  pregunta={q.number}
                                                                  respuesta={this.state['pregunta'+this.state.pregunta]}
                                                                  Selector={this.handleSelector}/>
                                            </td>
                                          </tr>
                                        ))


    return (
      <>
        <table className="table table-striped table-bordered text-sm">
          <thead>
            <tr className="bg-secondary text-dark">
              <td align="center">Item</td>
              <td align="center">Pregunta</td>
              <td align="center">Respuesta</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={3} align="center" className="text-lg">
                Sesión Síncrona
              </td>
            </tr>
            {tbody1}
            <tr>
              <td colSpan={3} align="center" className="text-lg">
                Sesión Asíncrona
              </td>
            </tr>
            {tbody2}
          </tbody>
        </table>
        <table className="table table-bordered">
          <thead>
          <tr>
            <td width={"5%"}></td>
            <td width={"w-95"}>
              <div className="row">
                <div className="col-4">
                  <TextArea
                    name="description1"
                    className="form-control"
                    title="Aspectos positivos"
                    Texto={this.handleSelector}
                    required={false}
                  />
                </div>
                <div className="col-4">
                  <TextArea
                    name="description2"
                    className="form-control"
                    title="Oportunidades de mejora"
                    Texto={this.handleSelector}
                    required={false}
                  />
                </div>
                <div className="col-4">
                  <TextArea
                    name="description3"
                    className="form-control"
                    title="Observaciones, incidentes"
                    Texto={this.handleSelector}
                    required={false}
                  />
                </div>
              </div>
            </td>
          </tr>
          </thead>
        </table>
      </>
    );
  }
}
