import React, { Component } from 'react'
import Chart from 'react-google-charts'
import ctrlData from '../../controller/Graficas/CtrlData.ctrl'
import { IMatricula } from '../../models/Grafica/Matriculas.model'
import { IGZonal } from '../../models/Grafica/zonal.model'

interface IProps {
  reportes:IMatricula[]
  pperiodo:string
  tperiodo:string
  zonal:string

}
interface IState {

}

export default class GraficaBubblePendientes extends Component<IProps, IState> {

  render() {

    let titulo = 'Porcentaje de programa pendientes vs días sin migrar.\n'

    ctrlData.getZonalxId_Name(this.props.zonal).map((z:IGZonal)=>
      titulo += z.name_abrev + '\n'
    )



    let data:any = [['Parte periodo', 'Días sin migrar','% Pendiente', 'Zonal', 'Matriculas pendientes']]

    let rst = this.props.reportes.filter((rpt:IMatricula)=> rpt.Zonal ===this.props.zonal)
                                 .filter((rpt:IMatricula)=> rpt.PartePeriodo !== 'TODOS')
                                 .filter((rpt:IMatricula)=> rpt.Campus === 'TODOS')

    if(this.props.tperiodo ==='TODOS'){
      rst = rst.filter((rpt:IMatricula)=> rpt.TipoPeriodo !== 'TODOS')
      titulo += this.props.tperiodo.replace('TODOS','Programa todos')
    }
    else{
      rst = rst.filter((rpt:IMatricula)=> rpt.TipoPeriodo === this.props.tperiodo)
      titulo += this.props.tperiodo//.replace('Prog.','').replace('programas','')
    }



    rst.map((r:IMatricula,i:number)=>
      data.push([
        r.PartePeriodo,
        r.DiasSinMigrar,
        r.Porc_Pend,
        ctrlData.getZonalxId_Name(r.Zonal)[0].name_abrev,
        r.Mat_Pendientes])
    )



    return (
            <Chart
                width={'100%'}
                height={'70vh'}
                chartType="BubbleChart"
                loader={<div>Loading Chart</div>}
                data={data}
                options={{
                  title:titulo,
                  titleTextStyle:{
                    fontSize:'small',
                  },
                  colorAxis: {colors: ['yellow', 'red']},
                  vAxis: { title: '% pendientes'},
                  hAxis: { title: 'dias sin migrar'},
                  bubble: { textStyle: { fontSize: 'small' } },
                  legend:{
                    position:'none'
                  }
                }}
            />
    )
  }
}
