import React, { Component, Fragment } from 'react'
import AnexoSchema from '../context/AnexoContext'

import  '../styles/modal.css'

interface IProps  {
  open:boolean
  status:boolean
  Close:Function
  save?:Function
  
}

interface IState  {

  status:Boolean
  description:string

}

export default class ModalSaveSinIntructor extends Component<IProps, IState> {

  static contextType?: React.Context<any> | undefined = AnexoSchema

  constructor(props: IProps) {
    super(props);

    this.handleClose = this.handleClose.bind(this)
    this.handleSave = this.handleSave.bind(this)

    this.state = {
      status: false,
      description:''
    };
  }
/*
  componentDidMount() {
    this.setState({
      status: this.props.open,
    });
  }*/

  handleClose() {
    this.props.Close()
  }
/*c
  handleOpen() {
    this.setState({ status: true });
  }

  omponentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        status: !this.state.status,
      })

    }
  }*/

  handleSave(){
    
    this.props.save!(this.state.description)
    this.setState({ status:false})
  }

 
  render() {
    return (
      <Fragment>
        {this.props.open && (
          <div className="newmodal" tabIndex={-1}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className= 'modal-header text-dark bg-danger' >
                  <h5 className="modal-title" style={{fontSize:'25px'}}>
                    <i className="fa-solid fa-brake-warning"></i>
                    {' Advertencia'}
                  </h5>
                  <button
                    type="button"
                    className="btn-light text-black"
                    aria-label="Close"
                    onClick={this.handleClose}
                  >
                    X
                  </button>
                </div>
                <div className="modal-body">
                  <i className="fas fa-exclamation-circle text-warning"></i>{" "}
                  {
                    this.props.status?
                      'El instructor ya se ha incorporado a su sesión de clases.'
                    :
                      'El curso no tiene el instructor presente, se va proceder a cerrar la ficha de seguimiento sin calificar.'
                  }
                  <div className="d-grid justify-content-center gap-2 text-center mx-auto" >
                    <div className="form-floating">
                      <textarea   className="form-control"
                                  placeholder="Puede incluir algun comentario"
                                  id="floatingTextarea2"
                                  style={{height: "100px"}}
                                  name= 'description'
                                  onChange={({target})=>{
                                              this.setState({
                                                description:target.value
                                              })
                                          }}
                      >
                      </textarea>
                    </div>
                </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-primary" onClick={this.handleSave}>
                    <i className='fa fa-save'></i>{'  '}Guardar
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger" 
                    data-bs-dismiss="modal"
                    onClick={this.handleClose}
                  >
                    <i className="fas fa-times"></i> Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}
