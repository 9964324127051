
import React, { Component, createContext } from "react";
import { IBbTerm } from "../models/bb/BbTerms.model";



interface IProps{}

interface IState{
  terms:        IBbTerm[]|null
  BbTerm?:      Function
  BbTermClear?: Function
  BbAll?:       Function
  BbSize?:      Function
}

export const BbTermContext = createContext<Partial<IState>>({})

export default class PeriodoProvider extends Component<IProps,IState> {

  constructor(props:IProps){
    super(props)

    this.state = {
      terms:[]

    }


    this.handleBbTerm = this.handleBbTerm.bind(this)
    this.handleBbTermClear = this.handleBbTermClear.bind(this)
    this.handleAll = this.handleAll.bind(this)
    this.handleSize = this.handleSize.bind(this)
  }


   handleBbTerm(periodos:IBbTerm[]) {

    this.setState({
          terms: periodos
    })
  }

  handleBbTermClear() {
    this.setState({
      terms:[]
    })
  }

  handleAll(){
    return this.state.terms
  }

  handleSize(){
    return this.state.terms?.length ? this.state.terms?.length : 0
  }


  render() {

    return (
      <BbTermContext.Provider value={{  BbTerm: this.handleBbTerm,
                                        BbTermClear:this.handleBbTermClear,
                                        BbAll:this.handleAll,
                                        BbSize: this.handleSize
                                        }} >
        {this.props.children}
      </BbTermContext.Provider>
    );
  }
}
