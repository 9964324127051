import React, {Component, Fragment} from "react";
import { Chart } from "react-google-charts";
import ctrlDataMatriculas from "../../controller/Graficas/CtrlDataMatriculas.ctrl"
import { IMatricula } from "../../models/Grafica/Matriculas.model"

import '../../styles/graficaTarjeta.css'

interface IProps{
  reportes:IMatricula[]
  zonal:string
  title:string
}

interface IState{}

export default class GraficaApexComboChart extends Component<IProps,IState> {

  analizo(reporte:IMatricula[], zonal:string, tPeriodo:string){
    return reporte.filter((rep:IMatricula) => rep.TipoPeriodo === tPeriodo)
                              .filter((rep:IMatricula) => rep.Zonal === zonal )
                              .filter((rep:IMatricula) => rep.PartePeriodo === 'TODOS' )
                              .filter((rep:IMatricula) => rep.Campus === 'TODOS' )


  }


  render() {
    let data: any = []
 //   let pperiodos:IMatricula[] = []


    data = [
      [
        "Programa","Todos","Iniciados","Inician en 15 días o menos"
      ]
    ]

const pperiodos:IMatricula[] = ctrlDataMatriculas.getMatriculasxZonalId_Tperiodo(this.props.reportes,this.props.zonal)



    pperiodos.map((pperiodo:IMatricula, i:number) =>
        data.push([
          pperiodo.TipoPeriodo,
            this.analizo(this.props.reportes, this.props.zonal, pperiodo.TipoPeriodo)
                               .filter((rep:IMatricula) => rep.calendarizacion === 'TODOS' ).length > 0
            ?
            this.analizo(this.props.reportes, this.props.zonal, pperiodo.TipoPeriodo)
                               .filter((rep:IMatricula) => rep.calendarizacion === 'TODOS' )
                               [0].Porc_Mat
            :
            0
            ,
            this.analizo(this.props.reportes, this.props.zonal, pperiodo.TipoPeriodo)
                               .filter((rep:IMatricula) => rep.calendarizacion === '1. Curso iniciado' ).length > 0
            ?
            this.analizo(this.props.reportes, this.props.zonal, pperiodo.TipoPeriodo)
                               .filter((rep:IMatricula) => rep.calendarizacion === '1. Curso iniciado' )
                               [0].Porc_Mat
            :
            0
            ,
            this.analizo(this.props.reportes, this.props.zonal, pperiodo.TipoPeriodo)
                               .filter((rep:IMatricula) => rep.calendarizacion === '2. Inicia en 15 días o menos' ).length > 0
            ?
            this.analizo(this.props.reportes, this.props.zonal, pperiodo.TipoPeriodo)
                               .filter((rep:IMatricula) => rep.calendarizacion === '2. Inicia en 15 días o menos' )
                               [0].Porc_Mat
            :
            0
          ]

        )
    )


    const options = {
      title: "",
      chartArea: { width: "50%" ,height: "75%"},
     // vAxis: { title: "Programas" },
      hAxis: {
                title: "% Matrículas",
              //  position:'none',
                minValue:0,
                maxValue:100,
            //    format:'###,#S%'
              },
      colors:['#13248C','#223CEA','#3ce4f1'],
      Animation:{
        duration:3
      },
      legend:{
        position: 'right',
      },
      animation: {
        duration: 1000,
        easing: "out",
        startup: true,
      }

    }



    return (
      <Fragment>
        <div className='card w-100 card-alto' >
          <div className="card-header bg-info">
              <h4 className="card-title text-center w-100">
                { 'Matrículas: ' +this.props.title}
              </h4>
          </div>
          <div className="card-body hiddenScrollY "  style={{background: '#F4F4F4'}}> {/** border-secondary border-top border-left tarjeta_altura*/}
            <Chart
              chartType="BarChart"
              width="100%"
              height={"310px"}
              data={data}
              options={options}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}


