import React, { Component, Fragment } from 'react'
import graficaMapa from '../controller/Graficas/CtrlData.ctrl'
import { IMatricula } from '../models/Grafica/Matriculas.model'
import { IGZonal } from '../models/Grafica/zonal.model'

interface IProps {
  tperiodo:string
  reportes:IMatricula[]
  zonal:string
  pperiodo:string
}
interface IState {

}

export default class Tarjeta extends Component<IProps, IState> {


  render() {

    let Reporte:IMatricula[] = []


    Reporte = this.props.reportes.filter((rpt: IMatricula) => rpt.Zonal === this.props.zonal)
                                 .filter((rpt: IMatricula) => rpt.PartePeriodo === this.props.pperiodo)
                                 .filter((rpt: IMatricula) => rpt.TipoPeriodo === this.props.tperiodo)

    const dato:IMatricula = Reporte[0]

    return (
      <Fragment>
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 blockquote display-5">
          <div className="info-box bg-gradient-info">
            {" "}
            {/**bg-gradient-info */}
            <div className="info-box-content" style={{ fontSize: "normal" }}>
              <span className="info-box-number text-center text-lg">
                {this.props.zonal === "TODOS"
                  ? "NACIONAL"
                  : Reporte.map((tp: IMatricula) =>
                      graficaMapa
                        .getZonalxId_Name(tp.Zonal)
                        .map((zonal: IGZonal) => zonal.departamento)
                    )}
              </span>
              <span className="info-box-number text-center text-md">
                {
                  Reporte.map(
                    (tp: IMatricula) =>
                      tp.TipoPeriodo.replace("TODOS", "Programa todos")
                                    .toUpperCase()
                  )}
              </span>
              <span className="info-box-number text-center text-md">
                {"PERIODO" +
                  Reporte.map((tp: IMatricula) => " " + tp.PartePeriodo)}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-12">
          <div
            className="info-box text-white"
            style={{ backgroundColor: dato.Color_Curso }}
          >
            {/**bg-gradient-info */}
            <span className="info-box-icon text-white">
              <i
                className="fas fa-user-graduate"
                style={{ color: dato.Color_Letra }}
              ></i>
            </span>
            <div
              className="info-box-content"
              style={{
                width: Reporte.map((tp: IMatricula) => tp.Porc_Mat) + "%",
              }}
            >
              <span
                className="info-box-number text-lg"
                style={{ color: dato.Color_Letra }}
              >
                {dato.Porc_Mat + "%"}
              </span>
              <div className="progress">
                <div className="progress-bar"></div>
              </div>
              <span
                className="progress-description text-sm"
                style={{ color: dato.Color_Letra }}
              >
                Avance de Matricula
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-12 ">
          <div className="info-box bg-gradient-info">
            <span className="info-box-icon">
              <i className="fas fa-user-graduate"></i>
            </span>
            <div className="info-box-content">
              <span className="info-box-number text-lg">
                {Reporte.map((tp: IMatricula) => tp.Matriculas_24h)}
              </span>
              <div className="progress">
                <div className="progress-bar" style={{ width: "100%" }}></div>
              </div>
              <span className="progress-description text-sm">
                Últimas 24 horas
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-12 ">
          <div className="info-box bg-gradient-danger">
            <span className="info-box-icon">
              <i className="fal fa-users-slash"></i>
            </span>
            <div className="info-box-content">
              <span className="info-box-number text-lg">
                {dato.Mat_Pendientes_Etiqueta}
              </span>
              <div className="progress">
                <div
                  className="progress-bar"
                  style={{ width: `${dato.Porc_Mat}%` }}
                ></div>
              </div>
              <span className="progress-description text-sm">
                Matriculas Pendientes
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-12 ">
          <div className="info-box bg-gradient-danger text-white">
            <span className="info-box-icon">
              <i className="fad fa-calendar-alt"></i>
            </span>
            <div className="info-box-content">
              <span className="info-box-number text-lg">
                {dato.DiasSinMigrar}
              </span>
              <div className="progress">
                <div
                  className="progress-bar"
                  style={{ width: `${100 - dato.Porc_Cursos}%` }}
                ></div>
              </div>
              <span className="progress-description text-sm">
                Dias sin migrar
              </span>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
