import React, { Component } from 'react'

import { IPkiHora } from '../../models/Grafica/PkiHora.model'
import '../../styles/graficaTarjeta.css'
import ctrlZonales from '../../controller/Graficas/CtrlZonales.ctrl'
import GraficaMapsPkiSvgAsignacionDirecta from './GraficaMapsPkiSvgAsignacionDirecta'
import EtiquetaKpiResultadoDeHasta from './EtiquetaKpiResultadoDeHasta'




interface IProps{
    reportes:IPkiHora[]
    zonal:string
    title:string
    formula:string
    denominadorTitulo:string
    divisorTitulo:string
}

interface IState{
    visible:boolean
    zonalId:string
    inst_asig:number
    inst:number
    result:string
    resultInt:any
    semana:string
    colorBgTitle:string
    colorTextTitle:string
}

export default class GraficaKpiSvgAsignacionDirecta extends Component<IProps,IState> {

constructor(props:IProps){
    super(props)

    this.state={
        visible:false,
        zonalId:'TODOS',
        inst_asig: 0,
        inst: 0,
        result:'',
        resultInt:0,
        semana:'',
        colorBgTitle:'#FFF',
        colorTextTitle:'#000'
    }

    this.handleVisible = this.handleVisible.bind(this)
    this.handleZonalId = this.handleZonalId.bind(this)
}

componentDidMount(){
    const dato:IPkiHora[] = this.props.reportes.filter((d:IPkiHora)=> d.zonal === 'TODOS')

    this.setState({
        result    : dato.length > 0 ? ((dato[0].sobreasignados * 100)/ dato[0].instructores).toFixed(1).toString() : '',
        resultInt : dato.length > 0 ? (dato[0].sobreasignados * 100)/ dato[0].instructores : 0,
        colorBgTitle: dato.length > 0 ? dato[0].color_sobreasignados : '#FFF',
        colorTextTitle:dato.length > 0 ? dato[0].color_letra : '#FFF',
        inst_asig   : dato.length > 0 ? dato[0].sobreasignados   : 0,
        inst        : dato.length > 0 ? dato[0].instructores     : 0,
        semana: dato.length > 0 ? dato[0].semana     : '',
    
    })
}


handleVisible(visible:boolean){

    this.setState({
        visible
    })
}

handleZonalId(zonalId:string ){

   
    const dato:IPkiHora[] = this.props.reportes.filter((r:IPkiHora)=> r.zonal === zonalId)

    this.setState({
        zonalId,
        inst_asig   : dato.length > 0 ? dato[0].sobreasignados   : 0,
        inst        : dato.length > 0 ? dato[0].instructores     : 0,
        result      : dato.length > 0 ? ((dato[0].sobreasignados * 100)/ dato[0].instructores).toFixed(1).toString() : '',
        resultInt   : dato.length > 0 ?  (dato[0].sobreasignados * 100)/ dato[0].instructores : 0,
        colorBgTitle: dato.length > 0 ? dato[0].color_sobreasignados : '#FFF',
        colorTextTitle:dato.length > 0 ? dato[0].color_letra : '#FFF',
    
    })
   
}

render() {

    return (
        <>
            <div className='card w-100 card-alto ' >
                <div className="card-header text-sm"
                    style={{
                        background : this.state.colorBgTitle,
                        color: this.state.colorTextTitle,
                       
                    }}
                >
                    <h4 className="card-title text-center w-100">
                    { 'KPI: ' + this.props.title } <br />
                    {this.state.semana}
                    </h4>
                </div>
                <div className="card-body  "  style={{background: '#F4F4F4'}}> {/** border-secondary border-top border-left tarjeta_altura*/}
                    <div className="row">
                        <div className="col-5" >
                            <div className= { this.state.zonalId ==='TODOS' ? 'invisible': 'sobreimg_2 w-100'} >
                                <div className="card text-sm w-100"
                                    style={{ background:'#fff'}}
                                >
                                    <div className="card-header ">
                                        <h4 className='card-title text-sm'>
                                            Z. { ctrlZonales.getZonalxId_nameZonal(this.state.zonalId) }
                                        </h4>
                                    </div>
                                    <div className='card-body p-2'>
                                        <h4 className='card-title w-100 h-100 text-sm '>
                                            <ul>
                                                <li>Instructores JC sobreasignados: <b>{this.state.inst_asig}</b></li>
                                                <li>Instructores JC: <b>{this.state.inst} </b></li>
                                            </ul>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className='sobreimg_1 w-100'>{/*'sobreimg_2 w-100'*/}
                                <EtiquetaKpiResultadoDeHasta
                                    de= { this.state.inst_asig }
                                    hasta={ this.state.inst }
                                    meta={'0'}
                                    title={ this.props.formula}
                                    etiqueta={ this.state.colorBgTitle }
                                    color={ this.state.colorTextTitle}
                                    funcionVisible={this.handleVisible}
                                /> 
                            </div>
                        </div>
                        <div className="col-1"></div>
                        <div className="col-6">
                            {/*<div className={ this.state.visible? "formula w-100" : 'formula invisible'} >
                                <div className="container ">
                                    <div className="w-100">
                                        <EtiquetaKpi
                                                denominadorTitulo={this.props.denominadorTitulo}
                                                divisorTitulo={this.props.divisorTitulo}
                                                title={ this.props.formula}
                                        />
                                        <EtiquetaKpi
                                                denominadorTitulo={'num'}
                                                divisorTitulo={'den'}
                                                title={ this.props.formula}
                                        />
                                    </div>
                                </div>
                            </div>*/}
                            <GraficaMapsPkiSvgAsignacionDirecta
                                                reportes={this.props.reportes}
                                                zonal={this.props.zonal}
                                                ZonalId={this.handleZonalId}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
  }
}
