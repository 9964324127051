//import Questions from '../../data/JSON_Seguimiento_Question.json'
import Answers from '../../data/JSON_Seguimiento_Answer.json'
import { ISegAnswer  } from '../../models/Seguimiento/question.model'
import ApiJson from '../../ApiJson.json'
import axios from 'axios'
import { IDetalleSchema } from '../../models/mongo/detail.schema'


class CtrlControlAnexo03 {

  async getQuestion() {

    //const questions: ISegQuestion[] = Questions
    //return questions.filter((a: ISegQuestion) => a.active === true)

    const { data } = await axios(`${ApiJson.Api}/api/anexo03/questions`, { 
                                            method:'GET',
                                            headers:{
                                              'Content-Type': 'Application/json',
                                              'token': '',   //localStorage.getItem('token') || '',
                                              'cursoId': ''
                                            },
                                           
                                          })
      
    return  data.data 
  }

  getAnswer() {

    const answers: ISegAnswer[] = Answers

    return answers.filter((a: ISegAnswer) => a.active === true)
  }

  getAnswerxId(id: string) {
    const Id = parseInt(id)

    const rpta = this.getAnswer()

    return (rpta.filter((r: ISegAnswer) => r.item === Id).length > 0) ?
      rpta.filter((r: ISegAnswer) => r.item === Id)[0].answer
      :
      ''

  }

  getAnswerxAnswer_item(answer: string) {

    const rpta = this.getAnswer()
    answer = answer.replace('SI CUMPLE', 'CUMPLE')

    const r =  (rpta.filter((r: ISegAnswer) => r.answer === answer).length > 0) ?
      rpta.filter((r: ISegAnswer) => r.answer === answer)[0].item.toString()
      :
      ''
      return r

  }

  getAnexo03(anexo03: any) {

    return ({
      periodo: anexo03.controlAlumno[0].periodo,
      tipo_nrc: anexo03.controlAlumno[0].tipo_nrc,
      curso: anexo03.controlAlumno[0].curso,
      id_inst: anexo03.controlAlumno[0].id_inst || "",
      instructor: anexo03.controlAlumno[0].instructor || "",
      zonal: anexo03.controlAlumno[0].zonal,
      campus: anexo03.controlAlumno[0].campus,
      cod_curso: anexo03.controlAlumno[0].cod_curso,
      supervisorId: anexo03.supervisorId,
      supervisor: anexo03.supervisor,
      alumnosbb: parseInt(anexo03.controlAlumno[0].inscritosbb),
      alumnos_asiste: parseInt(anexo03.asistencia),
      inicio: anexo03.horaInical,
      fin: new Date(),
      curso_cerrado: anexo03.controlAlumno[0].curso_cerrado,
      pregunta1: anexo03.pregunta1 ?? "NO APLICA",
      pregunta2: anexo03.pregunta2?? "NO APLICA",
      pregunta3: anexo03.pregunta3?? "NO APLICA",
      pregunta4: anexo03.pregunta4?? "NO APLICA",
      pregunta5: anexo03.pregunta5?? "NO APLICA",
      pregunta6: anexo03.pregunta6?? "NO APLICA",
      pregunta7: anexo03.pregunta7?? "NO APLICA",
      pregunta8: anexo03.pregunta8?? "NO APLICA",
      pregunta9: anexo03.pregunta9?? "NO APLICA",
      pregunta10: anexo03.pregunta10?? "NO APLICA",
      pregunta11: anexo03.pregunta11?? "NO APLICA",
      pregunta12: anexo03.pregunta12?? "NO APLICA",
      pregunta13: anexo03.pregunta13?? "NO APLICA",
      pregunta14: anexo03.pregunta14?? "NO APLICA",
      pregunta15: anexo03.pregunta15 ?? "NO APLICA",
      description1: anexo03.description1?? "",
      description2: anexo03.description2?? "",
      description3: anexo03.description3?? "",
      description: anexo03.description ?? "",
      statusInst: anexo03.statusInst
    })

  }

  async CreateFile(anexo03: any, semana: string) {

    let anexo = null

    await fetch(`${ApiJson.Server}/api/seguimiento/anexo03/create`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          'token': semana, // localStorage.getItem('token') || '',
          'cursoId': anexo03.cod_curso
        },
        body: JSON.stringify(anexo03)
      })
      .then((db) => db.json())
      .then((data) => {
        anexo = data.data

      })

    return { anexo03: anexo }

  }

  async ListaCursos() {

    const { data } = await axios(`${ApiJson.Api}/api/anexo03/getAnexo03`, { 
                                            method:'GET',
                                            headers:{
                                              'Content-Type': 'Application/json',
                                              'token': '',   //localStorage.getItem('token') || '',
                                              'cursoId': 'courseId'
                                            },
                                          })
   
    return { auth:data.auth, cursos:data.data }

  }

  async ListaCursosDetails() {

    const { data } = await axios(`${ApiJson.Api}/api/anexo03/details`, { 
                                            method:'GET',
                                            headers:{
                                              'Content-Type': 'Application/json',
                                              'token': '',   //localStorage.getItem('token') || '',
                                              'cursoId': 'courseId'
                                            },
                                          })
   
    return { auth:data.auth, cursos:data.data }

  }

  async ValidarFile(courseId: string, semana: string) {

    let datos: any = { semana , courseId}
   
  
    const { data } = await axios(`${ApiJson.Api}/api/anexo03`, { 
                                            method:'GET',
                                            headers:{
                                              'Content-Type': 'Application/json',
                                              'token': semana,   //localStorage.getItem('token') || '',
                                              'cursoId': courseId
                                            },
                                            data: JSON.stringify(datos),
                                           
                                          })
  

   
    return { auth: data.auth, anexo03: data.data }

  }

    async Create(anexo03: any, semana: string='') {

    let anexo = null

    await fetch(`${ApiJson.Api}/api/anexo03/create`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          'token':  localStorage.getItem('token') || '',
          'cursoId': anexo03.cod_curso
        },
        body: JSON.stringify(anexo03)
      })
      .then((db) => db.json())
      .then((data) => {
        anexo = data.data

      })

    return { anexo03: anexo }

  }

  async Details(detalle: IDetalleSchema[]) {

    const { data } = await axios(`${ApiJson.Api}/api/anexo03/details`,
                    {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'Application/json',
                        'token':  localStorage.getItem('token') || '',
                        'cursoId': ''
                      },
                      data: JSON.stringify(detalle)
                    })
                    

    return { detalle: data }

  }

  async getDetails(_id:string) {

      
  
    const { data } = await axios(`${ApiJson.Api}/api/anexo03/details/${_id}`, { 
                                            method:'GET',
                                            headers:{
                                              'Content-Type': 'Application/json',
                                              'token': localStorage.getItem('token') || '',
                                              'cursoId': ''
                                            },
                                          })
  

   
    return { auth: data.auth, details: data.data }

  }


}

const ctrlControlAnexo03 = new CtrlControlAnexo03()
export default ctrlControlAnexo03