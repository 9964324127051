import React, { Component, ComponentType } from "react";
import { Redirect,  Route } from "react-router-dom";
//import {AuthContext } from "./AuthProvider";
import AuthContext from './AuthContext'

interface IProps {
  path: string
  component: ComponentType<any>
}


export default class PrivateRoute extends Component<IProps & Readonly<any>,{}> {
  static contextType = AuthContext;

  render(){

    //const auth = this.context
    const { isLogged } = this.context

    return (
      <Route  exact
              path={this.props.path}
              render={
                (props)=>
                  isLogged()?
                    React.createElement(this.props.component,{...props})
                  :
                    <Redirect to={
                      {
                        pathname:'/',
                        state: {
                          from:props.location
                        }
                      }
                    } />
              }
      />
    )
  }
}

/*
    return (

      <Route exact path={this.props.path}>
        {
          auth.isLogged()?
            React.createElement(this.props.component,{...this.props})
            :
            <Redirect to={'/login'} />
        }
      </Route>

    )
  }
}*/
