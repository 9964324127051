import React, { Component } from 'react'
import { Link } from 'react-router-dom'

interface IProps {
  Vista:Function
}
interface IState {

  select:string

}

export default class Pestana extends Component<IProps, IState> {

  constructor(props:IProps){
    super(props)

    this.handleGrafica = this.handleGrafica.bind(this)

    this.state = {
      select : "tbldashboard",
    };
  }

  handleGrafica(vista:string){

    this.setState({ select :vista})

    this.props.Vista(vista)
  }

  render() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <ul className="nav nav-tabs">
          <li className="nav-item">
              <Link
                className={
                  this.state.select === "tbldashboard"
                    ? "nav-link active font-weight-bold"
                    : "nav-link"
                }
                to="#"
                onClick={() => this.handleGrafica("tbldashboard")}
                style={
                  this.state.select === "tbldashboard"
                    ? { color: "#333" }
                    : { color: "#9E9699" }
                }
              >
                KPI
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  this.state.select === "grafica"
                    ? "nav-link active font-weight-bold"
                    : "nav-link"
                }
                to="#"
                onClick={() => this.handleGrafica("grafica")}
                style={
                  this.state.select === "grafica"
                    ? { color: "#333" }
                    : { color: "#9E9699" }
                }
              >
                Zonal y Periodo
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  this.state.select === "tblPendientes"
                    ? "nav-link active font-weight-bold"
                    : "nav-link disable"
                }
                to="#"
                onClick={() => this.handleGrafica("tblPendientes")}
                style={
                  this.state.select === "tblPendientes"
                    ? { color: "#333" }
                    : { color: "#9E9699" }
                }
              >
                Impedimentos de matrículas
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  this.state.select === "tblAntiguedades"
                    ? "nav-link active font-weight-bold"
                    : "nav-link"
                }
                to="#"
                onClick={() => this.handleGrafica("tblAntiguedades")}
                style={
                  this.state.select === "tblAntiguedades"
                    ? { color: "#333" }
                    : { color: "#9E9699" }
                }
              >
                Tiempo sin migrar
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  this.state.select === "tblDatos"
                    ? "nav-link active font-weight-bold"
                    : "nav-link"
                }
                to="#"
                onClick={() => this.handleGrafica("tblDatos")}
                style={
                  this.state.select === "tblDatos"
                    ? { color: "#333" }
                    : { color: "#9E9699" }
                }
              >
                Datos
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
