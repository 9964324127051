import React, { Component, Fragment } from 'react'

import {  OrdenarReverse } from '../lib/source'

import { IPendienteMatricula } from '../models/Grafica/PendienteMatricula.model'


import Etiqueta from './Etiqueta'

interface IProps {
  tperiodo:string
  reportes:IPendienteMatricula[]
  zonal:string
  pperiodo:string
  calendarizacion:string
}
interface IState {

}

export default class TarjetaMotivos extends Component<IProps, IState> {


  render() {

    let Reporte:IPendienteMatricula[] = []


    Reporte = this.props.reportes.filter((rpt: IPendienteMatricula) => rpt.Zonal === this.props.zonal)
                                 .filter((rpt: IPendienteMatricula) => rpt.PartePeriodo === this.props.pperiodo)
                                 .filter((rpt: IPendienteMatricula) => rpt.TipoPeriodo === this.props.tperiodo)
                                 .filter((rpt: IPendienteMatricula) => rpt.Calendarizacion === this.props.calendarizacion)
                                 .filter((rpt: IPendienteMatricula) => rpt.Campus === 'TODOS')


    let analizo:any = [
//        {motivo:'En proceso ', valor: Reporte[0].Antig_En_proceso , data: Reporte[0].En_proceso},
        {motivo:'Exceso horas ', valor: Reporte[0].Antig_Exceso_Horas , data: Reporte[0].Exceso_Horas},
        {motivo:'Falta Pago ', valor: Reporte[0].Antig_Falta_pago , data: Reporte[0].Falta_de_pago},
        {motivo:'Sin frecuencia ', valor: Reporte[0].Antig_Frecuencia , data: Reporte[0].Sin_Frecuencia},
        {motivo:'Fuera de rango ', valor: Reporte[0].Antig_Fuera_de_rango , data: Reporte[0].Fuera_de_rango},
        {motivo:'Incumple diseño ', valor: Reporte[0].Antig_Incumple_diseno , data: Reporte[0].Incumple_Hrs_Diseno},
        {motivo:'Sin Contrato ', valor: Reporte[0].Antig_Sin_Contrato , data: Reporte[0].Sin_Contrato},
        {motivo:'Sin instructor ', valor: Reporte[0].Antig_Sin_Instructor , data: Reporte[0].Sin_Instructor},
        {motivo:'Fuera de curricula ', valor: Reporte[0].Antig_Sin_patron , data: Reporte[0].Sin_patron},
        {motivo:'Sin usuario ', valor: Reporte[0].Antig_Sin_Usuario , data: Reporte[0].Sin_usuario},
        {motivo:'Solo sin usuario ', valor: Reporte[0].Antig_Solo_Sin_Usuario , data: Reporte[0].Solo_sin_Usuario},
    ]

    analizo = OrdenarReverse(analizo,'valor')



    let etiqueta = analizo.slice(0, 4).map((a:any , i:number)=>{



        return(
            <div className="col-lg-12 col-md-6 col-12" key={'etiqueta-impe'+ i.toString()}>
                <Etiqueta icon="fad fa-calendar-alt"
                          mostrar={a.valor}
                          text={a.motivo}
                          equivalente={' dias'}
                          background='#1ca4fc'
                          linea2={'('+ a.data+' matriculas pendientes)'}
                />
            </div>
        )
    })


    /*
<div className="col-12">
                <Etiqueta icon="fad fa-calendar-alt" mostrar={'test'} text='Dias promedio sin migrar' background='#1ca4fc'/>
            </div>
    */

    return (
      <Fragment>
        <div className="row justify-content-md-center">
            {etiqueta}
        </div>
      </Fragment>
    );
  }
}

