import ApiJson from '../ApiJson.json'
import { IListaMatriculado } from '../models/oracle/listaMatriculados.model'


class BbListaAlumnos {

  private static getBbRpt: BbListaAlumnos
  private auth: Boolean = false
  private listaMatriculados: IListaMatriculado[] = []

  private constructor() {

  }

  static getBbReporte() {
    if (!BbListaAlumnos.getBbRpt) {
      BbListaAlumnos.getBbRpt = new BbListaAlumnos()
    }

    return BbListaAlumnos.getBbRpt
  }

  async FindxPeriodo(periodo: string) {

    await fetch(`${ApiJson.Server}/api/oracle/listaalumnos/${periodo}/periodo`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          'token': sessionStorage.getItem('token') || ''
        },
      })
      .then((db) => db.json())
      .then((data) => {

        this.listaMatriculados = (!!data.auth) ? data.data : []
        this.auth = !!data.auth ? true : false

        sessionStorage.setItem('token', data.auth)
      })


    return { reportes: this.listaMatriculados, auth: this.auth }
  }

  async FindxZonal(filtro: string) {

    await fetch(`${ApiJson.Server}/api/oracle/listaalumnos/zonal/${filtro}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          'token': sessionStorage.getItem('token') || ''
        },
      })
      .then((db) => db.json())
      .then((data) => {

        this.listaMatriculados = (!!data.auth) ? data.data : []
        this.auth = data.auth

        sessionStorage.setItem('token', data.token)
      })


    return { reportes: this.listaMatriculados, auth: this.auth }
  }

}

const bbListaAlumnos = BbListaAlumnos.getBbReporte()
export default bbListaAlumnos