import { IPkiHora } from "../../models/Grafica/PkiHora.model";

class CtrlDataPkiHoras{

  async getDataPki(){

    let datos: IPkiHora[] = []

    await fetch('/data/JSON_KPI_HORAS.json')
      .then((db) => db.json())
      .then((data) => {
        datos = data

      })
 
    return datos
  }

  async getPkiHoras(){
    return await this.getDataPki() as IPkiHora[]
  }

  findZonalId_pki(dataPki: IPkiHora[], zonalId:string){

    const data: IPkiHora[] = dataPki.filter((pki: IPkiHora)=> pki.zonal === zonalId)

    return data.length>0 ? data[0] : {}
  }

}

const ctrlDataPkiHoras = new CtrlDataPkiHoras()
export default ctrlDataPkiHoras