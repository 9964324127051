import React, { Component, Fragment } from 'react'
import ctrlColores from '../../controller/Graficas/CtrlColores.ctrl'
import ctrlZonales from '../../controller/Graficas/CtrlZonales.ctrl'
import { IPkiNota } from '../../models/Grafica/PkiNota.model'
import { IGZonal } from '../../models/Grafica/zonal.model'

import '../../styles/mapa.css'

interface IProps  {
  reportes:IPkiNota[]
  zonal:string
  ZonalId:Function
}

interface IState  {
  zonales:IGZonal[]
}


export default class GraficaMapsPkiSvgNotas extends Component<IProps, IState> {

  private linea:string ='#767474'

  colores:any={
    zpt:"#333",
    zcasm:'#333',
    zlo:'#333',
    zla:'#333',
    zll:'#333',
    za:'#333',
    zlc:'#333',
    zjph:'#333',
    zuh:'#333',
    zia:'#333',
    zcamd:'#333',
    zap:'#333',
    zmt:'#333'
  }

  constructor(props:IProps){
    super(props)

    this.state ={
      zonales:[]
    }

    this.handleColor = this.handleColor.bind(this)
    this.handleMessage = this.handleMessage.bind(this)
  }

  async componentDidMount(){

    this.setState({
      zonales: await ctrlZonales.getZonales()
    })

  }

  handleColor(){

        const color ='silver'

        const mat:IPkiNota[] =this.props.reportes//.filter((rpt: IMatricula) => rpt.Zonal === zonal.zpt.name)
                                    .filter((rpt: IPkiNota) => rpt.zonal !== 'TODOS')




        const zonales = this.state.zonales.filter((z:IGZonal)=> z.name.includes('ZONAL'))

        zonales.map((z:IGZonal,i:number)=>
            this.colores[z.id]  = mat.filter((m:IPkiNota)=> m.zonal === z.id).length !== 0 ?
              ctrlColores.getAsignacionColorEtiqueta_Fondo(mat.filter((m:IPkiNota)=> m.zonal === z.id)[0].PORC_CUMPLIMIENTO*100)
            :
              color
        )


  }

  handleMessage(zonalId:string){
    this.props.ZonalId(zonalId)
  }


  render() {

    this.handleColor()

    return (
      <Fragment>
        <svg version="1.1"
          id="senati_map"
          className='mapa_alto'
          //height={'270px'}
          width={'200px'}
          focusable="true"
        >
          <g >
            <path id="za"
                  stroke={this.linea}
                  stroke-width="1px"
                  className='border'
                  onMouseOver={()=>this.handleMessage('za')}
                  onMouseOut={()=>this.handleMessage('TODOS')}
                  d="M57.1,117.8l0.6,1.1l1-0.2l-0.1,0.8v1.2l1,0.6l0,1.8l0.1,0.2l0.8,1.3l0.4,0.5l1.6,0.7l1.4,1
                    l1.8,1l1.1,0.7l-0.1,1.2l-0.4,0.5l-0.7,0.1l-1,0.1l-0.1,0.1l-0.6,1.2l-0.8,1.9l-0.7,0.1l-0.1,0.7l-0.2,1.2l0.4-0.1L63,136l0.9,0.5
                    l0.7,1.1l0.2,1l0.1,1.7l-0.5,0.7l-0.6,0.6l0.3,1l-0.2,0.9l-0.2,0.5l-0.4,0.3v0.1l-0.6,0.2l-2.2,0.9l-0.3,0.2l-0.2,0.4l0,0.5
                    l-0.1,0.4l-0.2,0.3l-0.3,0.3l-0.6,0.7l-2.6,2.2l-1.1,0.4l-1.2,0l-0.2-1.5l0.2-0.6l-0.1-0.3l-1-0.6l-0.2-0.9l0.1-0.5l0.4-0.5
                    l0.7-0.4l0.1-1l-0.7-0.3l-0.9,0.1l-0.4,0.5l-0.6,0.5l0,0l-0.1,0l-0.1,0.8l-0.1,1.3l-0.2,0.6l-1.1,0.8l0-0.1l-0.3-0.7l-0.4-0.5
                    l-0.9-1.4l-0.9-1.5l-1.5-3.3l-0.9-2.2l-0.2-0.8l0.2-0.7l0-0.4l-1.3-1.6l-0.5-0.9l-0.3-1.4l-0.1-1.4l-0.5-0.9l-0.6-0.5l-0.4-1.3
                    l-0.1-0.9l-0.8-0.8l-0.9-0.7l-0.3-1.5h0.1l0.5-0.1l0.4-0.8l-0.1-0.5l0.1-0.5L40,123l0.3-0.3l0.3-0.1l2-0.6l1.9-0.7l0.4-0.8l0-0.3
                    l1-2.8l0.5-1.1l0.9-0.8l0.2-0.2l0.5-0.3l0.3-0.1l0.5-0.3l1.1-1.6l2.7-0.5l0.3,0l0.1,0.4l0.3,0.9l0.1,0.3l0.3,0.3l0.7,0.7l0.5,0.3
                    l0.3,0.1L57.1,117.8z"
                  fill={this.colores['za']}
                    />
            <path
                  id='zia'
                  stroke={this.linea}
                  stroke-width="1px"
                  className='border'
                  onMouseOver={()=>this.handleMessage('zia')}
                  onMouseOut={()=>this.handleMessage('TODOS')}
                  d="M89,198l-0.3-0.1h-1l-0.6,0.3l-0.1-0.1H86l-1.2-0.7l-0.3-0.5l-0.1,0l-1.4-0.7l-0.4-0.8l-0.1-1.4l0.5-0.9
                    l-0.3-1.7L83,191l0.3-0.2l0.2-0.2l0.2-0.5l0-0.3l-0.1,0l0.2-0.6l-0.1-0.7l-0.4-0.4l-0.9,0.1l-0.7,0.6l-0.5,0.4l-0.1,0l-0.6,0
                    l-0.5,0l-0.3,0l-0.3-0.1l-0.1-0.2l-0.5,0l-0.4-0.6l0.6-0.8l0.6-1l0.2-1.3v-0.9l0.1-0.7l-0.1,0l-0.1-0.4h-1.4l-0.8-0.3l-0.9,0.4
                    l-0.7,0.7l-1,0.7l-1,0.6l-0.6,1.3l-0.5,0.4l0,0.7l0.1,0.1l0.1,0.1l0.7,1.2l0.2,0.6l-0.2,1.8l-0.1,0.8l-0.4,1.4l-0.1,0.4l-0.5,1.3
                    l-0.4-0.6l-0.4-0.2l-0.6,0.2l-0.2,0.7l0.6,0.2l0.6,0.4l-0.1,3.2l1.1,0.5l0.4,0.2l0.9,0.8l0.3,1.2l0.5,0.9l0.2,0.2l1.2,1.2l0.6,1.5
                    l0.1,0.6l0,0.5l1.3,1.1l1.8,1l1.5,0.9l0.5,0.3l0.3,0.2l0.4,0.3l0.3,0.3l1.5,2.3l1.4,1l0.5,0.5l0.2,0.3l0.2,0.2l1,1.5l0.7,1.2
                    l0.1,0.1l0.3-0.8l0.4-1.3l1.1-1.4l1.3-0.7l0.6-0.3l1.3-0.2l0,0.1l0.1,0v0l0.1,0.4l0.3,0.3l0.6,0.2l0.4,0.1l1.1,0.3l1.2,0.3
                    l0.4,1.3l0.4,1.2l1.6,1.6l0.4,0.3l0.5,0.1l0.4,0l0.3-0.1l0.4-0.3l0.3-0.3l0.3-0.1l0.2,0.9l0.1,1.1l0.1,0.4l0.5,0l0.5-0.3l0.1-1.2
                    l0.6-1.3l0.4-0.8l1.3-0.7l0.8-0.4l-0.1,0.8l0.4,1l0.6,0.3l1.9-0.2l0.8-0.4l0.3-0.2l0.6-0.3l0.3,0l0.6,0.3l0.7-0.3l0.4-0.7l0.6-0.7
                    l1.3-0.3l0.7-1l0.9-1.2l0.4-1l-0.1-1l-0.1-1.1l0.3-1l0.4-0.7l0.5-0.2l0.8-0.2l0.2-0.9l-0.7-0.1l-1-0.6L118,207l-0.3-0.2l-1,0.1
                    l-1.5,0.6l-1.3,0.1l-1.6,0.7l-0.6,0.7l-0.8,0.4l-0.1-0.1l-0.1-0.3l-0.2-1l-0.3-0.5l0.1-1.3l-0.2-1.3l-0.6-1.1l0.6-1l0.4-0.6
                    l0.4-0.8v-0.7l-0.2-0.3l-0.6-0.9l-0.2-0.6l-0.2-0.5l-0.8-2.4l-0.8-1.6l0.1-0.3l0.5-0.1l0.2-0.2l-0.7-1.2l-0.4-0.2l-0.3-0.1
                    l-0.2-0.5l-0.1-0.4l-0.3-2.3l0-0.3l0-0.4l0.3-1.3l0.4-0.8l0.3-0.1l0.4,0.2l0.3,0.5l3.6,2.6l0.1,0l0.9-0.1l1.3-0.4l0.2-0.8l0,0
                    l0.1-0.3l-0.7-1.1l-1-1.3l-0.4-1.5l-0.7-1.5l-0.9-1.6l-1-0.7l-0.1-0.4l-0.5-1l-0.2-0.2l-0.8-0.7L108,178l-0.7-0.8l-1.9-3.7
                    l-0.3-0.6l0.1,0l0-0.7l-0.4-0.1h-1l-0.6,0.4l-0.9,0.1l-1.2-0.2l-1.1-0.8l-0.6,0.1l0,0l-0.4,0.4l-0.7,0.5l-0.9-0.1l-0.1,0.1
                    l-0.4,1.2l0.3,0.3l0.6,0.7l0.2,0.5l0.3,0l0.2,0.3l0.4,1.4l0.4,1.6l0.4,1.5l0.3,1.4l-0.1,0.4l0.3,0.5l-0.7,0.5l-1.1,0.4l-0.7,1.3
                    l-1,0.7l-0.9,0.4l-0.7,0.9l-0.9,1l-0.5,0.2l0-0.1l-0.4-0.2l-1.5,0.4l-0.5,1.6l0.3,0.3l0.3,0.2l0.2,0.2l0.7,0.9l0.4,0.5l-0.1,0.5
                    l0,0.5l0.1,0.5l0,0.1l0,1l-0.1,1.5l-0.5,1.5l-0.3,0.6l-1.5,0.4l-1.3-0.4L89,198L89,198L89,198"
                  fill={this.colores['zia']}
            />
            <path id="zll"
                  stroke={this.linea}
                  stroke-width="1px"
                  className='border'
                  onMouseOver={()=>this.handleMessage('zll')}
                  onMouseOut={()=>this.handleMessage('TODOS')}
                  d="M54.9,106.4l0.1,1.3l0.1,1.5l0.6,0.1l0,0.1l0.5,0l0.5,0.4l0.2,1.4l0.2,1.1h0.2l1.5-0.6l1.1-0.1
                    l0.9,0.6l1.5,0.2l0.7,1.1l0.5,0.7l0.4,1.4l-0.3,0.2l0.1,0.5l0,0.3l-0.4,0.1l-1.4,0.2l-1.1,0.4l-0.6,0.6l-1.1,0.7l-1.5,0.3
                    l-0.6-1.1l-1.7-2.6l-0.3-0.1l-0.5-0.3l-0.7-0.7l-0.3-0.3l-0.1-0.3l-0.3-0.9l-0.1-0.4l-0.3,0l-2.7,0.5l-1.1,1.6l-0.5,0.3l-0.3,0.1
                    l-0.5,0.3l-0.2,0.2l-0.9,0.8l-0.5,1.1l-1,2.8l0,0.3l-0.4,0.8l-1.9,0.7l-2,0.6l-0.3,0.1L40,123l-0.4,0.6l-0.1,0.5l0.1,0.5l-0.4,0.8
                    l-0.5,0.1h-0.1l0-0.1l0-0.5l-0.9-1.4l-0.4-1.5l0.1-0.3l0-0.7l-0.1-0.6l-0.1-0.3l-1-1.1l-1.2-1.3l0.1-0.4l0-0.3l-1.1-2.2l-2.3-2.5
                    l-0.3-0.2l-0.3-0.2l-0.3-0.1l-1.1-0.9l-0.3-0.3l-0.3-0.3l-0.5-0.7l-0.8-1.4l-0.2-0.9l-0.2-0.5l-0.6-1.1l-0.5-0.5l-0.2-0.3
                    l-0.5-0.8l-0.1-0.5l0.1-0.4l-0.3-0.9l-0.1-0.3l-0.4-0.8l-0.2-0.2l-0.8-0.8l0.4-0.4l2-2l0.6-0.5l0.3-0.2l1.4,0.3l0.5,0.4l0,0.4
                    l0.1,2.4l0,0l0.3,0.4v0.9l0.4,1.1l0.2,0.1l0.9,0.2l0.3,0.1l1.3,1.1l0.6,1.2l0.1,0.3l0,0.1l0.8,0l0.9-0.4l0.6-0.5l0.2-0.5l0.7-0.7
                    l0.9-0.5l0.7-0.4l1,0.6l0.2,1.1l0.9,0.2l1.6-0.4h0.6l0.7,0.5v1l0.1,1l0.6,0.4l0.1,0.3l0.1,0.4l0.9-0.5l1-0.4l1-0.9l0.9,0.2
                    l0.5,0.5l0.7-0.2l0.2-0.5l0.5-0.2l0.8-0.8l0.6-0.7l0.5-0.7l-0.2-1.3l-0.6-1.5l-0.7-1.3l-0.7-1.3L49,98l0-0.2l0.9-0.2l0.9-0.3
                    l0.4-0.1l0.1,0.2l0.2,0.3l0.2,0.1l0.6,1l1.3,1.5l0.2,0.4l-0.1,0.4l-0.4,0.7l0.1,0.7l0.4,1L54,104l0.5,0.3l0.1,0.1l0.1,1.3
                    L54.9,106.4z"
                    fill= {this.colores['zll']}
            />
            <path id="zla"
                  stroke={this.linea}
                  stroke-width="1px"
                  className='border'
                  onMouseOver={()=>this.handleMessage('zla')}
                  onMouseOut={()=>this.handleMessage('TODOS')}
                  d="M25.6,82.3l0.4,0.5l0.1,0l0.4-0.1l1,0.4l0.2,0.4l0.3-0.2l1.1-0.9l0.3,0.7l0.2,0.2l0.1,0l1.3,0.6
                    l0.1,0.3l-0.5,2.3l-0.2,0.6L30,87.7h-0.4l-0.5-0.1l-0.6,0.3l-0.4,0.2l-0.3,0.3l-0.2,0.4l-0.1,0.3l0.2,1.9l1.9,2.3l1.1,0.2l0.9,0.2
                    l0.2,0.3l-0.1,0.5l-1.2,1.2l-0.9,0.4l-0.1,0.8l0.1,0.4l-0.1,0.5l-0.3,0.1l-0.5-0.4l-1.4-0.3L27,97.4l-0.6,0.5l-2,2l-0.4,0.4l0-0.4
                    L24,99.4l-0.2-0.4l-1.2-1.5l-0.3-0.2L21.7,97l-0.8-0.8L20.7,96l-0.2-0.8l-0.2-0.6l-1.9-1.7l-0.4-0.2l-0.7-0.3l-1.8-0.8l-1.9-1.1
                    l-1.1-0.7l-0.4-0.5L11.7,89l-0.4-0.2l0.1-0.1l0.4-0.5l0.7-1.1l0.5-0.8l0.1-0.5l0.3-0.7l0.8-1l0.6-0.7l0.3-0.3l0.3-0.2l0.5-0.2
                    l1.3-0.4l0.3-0.2l0.8-0.6l0.7-0.5l0.9-1.1l0.3-0.5l0.1-1.1l0.2-0.4l0-0.3l0.2-0.3l0.3,0.2l0.3,0.2l1.6,1.3l0.7,1.3l0.3,0.8
                    l0.6,0.3l0.5,0.2l0.4,0.3L25.6,82.3z"
                  fill={this.colores['zla']}
            />
            <path
                  id='zlc'
                  stroke={this.linea}
                  stroke-width="1px"
                  className='border'
                  onMouseOver={()=>this.handleMessage('zlc')}
                  onMouseOut={()=>this.handleMessage('TODOS')}
                  d="M82.3,178.4l0.3-0.6l-0.2-0.2l-0.1-0.4l0-0.1l-0.3-0.6l-0.1-1.8l0.3-1.5l-0.1-0.1l-0.8-1.5l0-0.3l0.2-0.3
                    l-0.1-0.4l-0.3-0.2l0-1.4h-2.1l-0.4,0.1l-0.5-0.2l-0.4-0.1l-0.3,0.1l-0.5-0.3l-1.3-0.4l-0.1-0.3l-1.1-1.4l-0.9-0.8l-0.1-0.3
                    l-0.1-1l0-0.9l-0.1-0.5l-0.3-0.2l-0.3,0l-0.5,0l-0.6-0.3l-0.5-0.8l-1.4-2.8l-0.2-1.8l0-0.4l0.1-0.7l0-0.3l-0.2-0.5l-0.6-0.4
                    l-0.2-0.2l-0.2-0.4l0.1-0.4l0-0.6l0-0.4l0-0.5l-0.1-0.4l-0.6-0.6l-0.2-0.3l-0.1-0.4l0-0.4l-0.1-0.5l-0.1-0.3l-0.4-0.5l-0.2-0.4
                    l0-0.5l-0.1-0.5l-0.2-0.6l-0.2-0.3l-0.2,0l-0.4-0.1l-0.3-0.2l-0.1-0.2l-0.1-0.7l-0.3-0.2l-0.6,0.1l-0.4-0.2l-0.2-0.4l0-0.2
                    l-0.6,0.2l-2.2,0.9l-0.3,0.2l-0.2,0.4l0,0.5l-0.1,0.4l-0.2,0.3l-0.3,0.3l-0.6,0.7l-2.6,2.2l-1.1,0.4l-1.2,0l-0.2-1.5l0.2-0.6
                    l-0.1-0.3l-1-0.6l-0.2-0.9l0.1-0.5l0.4-0.5l0.7-0.4l0.1-1l-0.7-0.3l-0.9,0.1l-0.4,0.5l-0.6,0.5l0,0l-0.1,0l-0.1,0.8l-0.1,1.3
                    l-0.2,0.6l-1.1,0.8l0.9,1.2l0.6,0.7l0.9,1.4l0.6,1.2l1,3.5l-0.1,0.5l-0.5,0l-0.2,0.5l0.3,0.6l0.7,0.5l0.5,0.1l0.3,0.1l0.9,0.5
                    L57,161l1,0.9l0.9,1.4l0,0l0.5,0.8l0.2,0.6v0l0.2,0.4l-0.2,0.9l0.5,1.3l0.1,0.8l0,0.7l-0.5,1l0.7,0.1l0.5,0.2l0.3,0.2l0.2,0.3
                    l0.3,0.3l1.6,1.7l0.8,0.7l0.5,0.6l0.3,0.4l0.1,0.7l0,0.1v0l0.5,1.3l1.6,3.1l0.5,0.6l1.2,1.7l0.1,0.9l0.1,0.5l0.2,0.7l0.2,0.3
                    l0.2,0.2l0.5,0.4l0.4,0.4l0.4,0.4l1.1,1.4l0.6,0l0,0l0.5-0.4l0.6-1.3l1-0.6l1-0.7l0.7-0.7l0.9-0.4l0.8,0.3h1.4l0.1,0.4l0.1,0
                    l0-0.4l0.9-0.4l0.4-0.8l0.4-0.5l0.4-0.8l0.5-0.5l-0.2-0.7L82.3,178.4z"
                  fill={this.colores['zlc']}
            />
            <path id="zlo"
                  stroke={this.linea}
                  stroke-width="1px"
                  className='border'
                  onMouseOver={()=>this.handleMessage('zlo')}
                  onMouseOut={()=>this.handleMessage('TODOS')}
                  d="M88.5,0.1l1.6,1.1L91,1.9l0.3,0.4l1.1,0.5l0.3-0.1l0.4-0.3l0.3-0.1l0.3,0.1L94,2.8l0.2,0.4
                    l0.2,0.4l0.1,0.3l0,0.3l0.1,0.4l1.1,0.6L96,4.9l0.7,0.5L99,7.1l0.3,0.3l0.1,0.1l0.2,0.8l0.3,1l0.3,1.1l0.4,0.4l0.6,0.6l-0.2,0.6
                    l-0.3,0.4l0.4,0.8l0.2,0.4l0.4,0.3l1.9,0.5l3,1.9l0.4,0.8l1.3,0.3l0.6-0.1l0.8,0.1l1.4,1.3l0.1,0.9L111,20l0.3,0.4l0.6,0.8
                    l0.4,0.8l-0.4,0.4l-0.1,0.2l-0.3,1.3l0.2,0.3l1,0.7l1.7,0.8l0.8-0.4l0.6-0.5l0.9,0.6l0.6,1l0.5,3.1l-0.3,0.8l-0.3,0.4l-0.4,0.2
                    l0.2,1.2l0.1,0.3l0.4,0.3l1,0.5l0.4-0.1l0.6,0l0.5,0.8l0.3,0.7l0.9-0.2l1.9-0.7l1.3-0.5l2,0.5l1.7,0.6l0.4,0.1l1,0.1l2.2-1.3
                    l1.2-0.5l0.7,0.1l0.6,0l0.3-0.8l0.9-1.3l1.2-1l0.4-0.2l0.1,0.3l0,0.4l0.1,0.4l1.4,0.2l0.8,0l1.5,0.7l0.7,0.9l2.4,0.2l1.2-0.7
                    l1.8-0.5l1.7-0.7l2.1,1.4h1.3l1.3,1.1l1.4,0.6l0.8,0.8l0.1-0.1l0.3-0.1l0.6,0.1l1.1,1L158,36l2.5-0.5v0.8l-0.5,1.8l-4.9,7.6
                    l-0.5,0.8L153,49l-0.9,1.3l-0.6,1l-0.7,1.2l1.6,0.7l1.1,0.4l0.4,0l0.3-0.1L155,53l0.2-0.2l0.6-0.2l0.3,0l0.3,0.1l1.2,1l0.7,0.9
                    l0.3,0.5l0.1,0.4l0.1,0.4l0.1,0.3l0.2,0.3l1.2,1l0.3,0.2l0.5,0.3l0.5,0.5l0,0.4l0.1,0.4l-0.1,0.3l-0.3,0.4l-0.5,0.4l-2.6-0.1
                    l-0.8-0.6l-0.6-0.6l-0.1-0.3l0.1-0.4l0-0.3l-0.3-0.5l-1.5-0.1l-0.5,0.3l-0.5,0.4l-1.2,0.2l-0.6-0.5l-1.9-0.2l-0.7,0.3l-0.3,0.2
                    l-0.5,0.9l-0.1,0.4l-1.2,1.4l-0.5,0l-0.8,0.7h-1.6l-1.4,0.8l-2.1,0.3l-2.1,0.8l-2.1-0.2h-2.1l-1.3,1.1l-1.4,1.3l-1.6,1.1l-2.1,0.8
                    l-2.2,1.4l-2.2,2.4l-2.1,0.8l-1.6,2.5l-0.5,2.4l-0.5,3l-1.9,1.6l-1.7,2.8l1.4,3.5l0.6,2.2l-2.1,2.1l-2.2,1.1l-3.5,1.6l-1.4,0.8
                    l-0.6,3.3l-0.7,0.2l0,0.1l0.3,0.2l0.8,1.2l0.2,1.2l-0.5,0.3l-2.7,0.4l0,1.2l-0.4,1.2l-0.3,0l-0.1-0.2l-0.3-0.2l0,0l0-0.4l-1-0.7
                    l-1-0.8l-1.4-0.1l-1-0.1l-0.5-0.6l-0.7-0.1l-0.5,0.1l-0.3,0.5l0,0.1l0,0l0,0l-0.6,0.8l-0.5,0.5l-0.3,0.5l0.1,0.6l0.1,0.6l0.2,2.8
                    l-0.1,1.2l-0.1,0.4l-0.3,0.4l-0.4,0.3l-0.2,0.1l-0.4,0.8l-0.8,0.4l-1.8,0.1l-1.5-0.1l-0.1-0.2l-0.2,0.1l-0.4-0.3l-1.6-0.1
                    l-0.7,0.9l-0.7,0.6l-0.4,0.4l-0.5-0.1l-0.9-0.2l-0.9,0.7l-0.3,0.6v1.3l-0.1,0.9v0.9l-0.5,0.4l-0.8,0.2l0,0l-0.3-0.3l-0.3-0.3
                    l-0.5-0.3h-0.6l-1.9,1.6l-0.9,0.9l-0.3,0.3l-0.3,0.2l-0.5,0.2l-0.3-0.9l-0.1-0.7l0-0.2l0.1-0.4l0.1-0.6l0.1-0.9l0.1-0.3l0-0.1
                    l-0.6-0.9l0.2-1.9l0.6-1.1l0.8-0.7l-0.2-0.3l-0.3-0.7h-1l-0.2-0.8l-0.9-1.1l-0.4-1.3l-0.3-2.3l-1.1-1.1l-0.4-1.2V102l0.1-0.9
                    l0.9-0.3l0-0.2l0.1-0.3l1.4-1V98l-0.4-3l0.7-0.7l0.3-0.1l0.6,0l0.4,0.1l0.4,0.1l0.4,0.2l0.4,0.3l0.4,0.3l0.1,0.2l1.6-0.1l1.2-0.2
                    l0.5-0.2l0,0l0.1-0.4l0.2-1.1l0-0.6l0-0.5l0.1-0.8l0.1-1l0.1-0.7l0.1-0.7l0-0.4l0-0.4L83.7,88l0.6-0.7l0.1-1.2l-0.7-1l-1.2-0.6
                    l-1.7,0.4l-0.9,0.7l-1.3-0.3l-0.4-0.1l-1,0l-0.6,0.1l-0.8,0.6l-1.4,0.9l-1-0.5l-1-1.2l-1.4-1.2l-1.3-0.1l-0.3,0.2l-0.5,0.3
                    l-0.5,0.2l-0.8,0.1l-0.7-0.3l-0.7-0.5l-0.3-0.4l-0.3-0.6l-0.1-0.5l-0.3-0.8L65,80.9l-0.2-0.4l-1.4-1l-0.7-0.2l-0.4,0l-0.5,0
                    l-0.7,0l-1.2-0.1l-0.4-0.1L58.9,79l-0.4-0.2L58,78.1l-0.3-0.3l-0.3-0.5L57,77l-0.7-0.3l-0.9-0.3l-0.8-0.3l-0.4-0.3l-0.8-0.6
                    L53.2,75l-0.1-0.2L52.2,73l-0.1-0.4l-0.1-0.8l-0.1,0l-1.4-1.1v-1l0.9-1L51.3,68l0-0.4l0.1-0.8l0.2-0.7l0.2-0.6L52,65l0.3-0.3
                    l0.3-0.3l0.3-0.2l0.3-0.5l0.9-1.9l0.1-0.4l0.1-0.8l-0.1-0.5l-0.1-0.3l0-0.7l0.2-2.5l0.1-0.3l-0.1-2.1l-0.1-0.3l-0.1-0.3L54,53.4
                    l-0.5-0.7l-0.2-0.4L53.2,52l0-0.4l0-0.6l0.1-0.6l-0.6-2.7l-0.1-0.4l-0.5-1l-0.6-0.9l-0.1-0.4l0-0.4l-0.1-1.4l-0.5-2.3L51,41l5-1.7
                    l3-1l2.5-0.9l2-0.7l3.4-1.2l3.6-2.7l4.6-3.5l1.4-1.6l2.8-3.3l0.9-1l1.3-1.6l0.6-0.6l0.3-0.2l0.1-0.4l1-3.8l0.3-1.2l0.2-1l0.2-0.7
                    l0.4-1.6l0.4,0.4l0.2,0.2l0.4,0.2l1.6-0.1l-0.7-2.2l-0.4-1.2l0.1-1l0.1-0.1l0.1-0.1l0.3-0.2l0.1-1l-0.3-0.5L85.7,6l-0.3,0L85,5.7
                    l-0.3-0.4l-0.3-0.5l-0.2-0.5L84,3.6l-0.1-0.3l-0.2-0.6L82.4,2l-0.8-0.1L81.8,1l1.4,0.1l0.6,0.4l0.8,0.3l1.1-0.2l0.6-0.2l0.3-0.2
                    l0.4,0l0.5-0.4l0.1-0.4L87.6,0L88.5,0.1z"
                  fill={this.colores['zlo']}
            />
            <path
                  id='zcamd'
                  stroke={this.linea}
                  stroke-width="1px"
                  className='border'
                  onMouseOver={()=>this.handleMessage('zcamd')}
                  onMouseOut={()=>this.handleMessage('TODOS')}
                  d="M179.6,175.9l-0.3-0.6l-0.5-1.1l-0.2-0.4l-1.2-2.5l-0.6-1.3l-0.6-1.3l-0.5-0.8l-0.4-0.7L175,167l-0.5-0.9
                    l-0.4-0.7l-1.6-2.6l-0.5-0.8l-0.5-0.8L169,157l-1-1.6l-1-1.6l0,0l-0.3,0l-1.1,0l-0.3,0.1l-0.7,0l-0.6-0.5l-2.1-0.2l-0.3,0.1
                    l-0.3,0.1l-0.4,0.3l-0.2,0.1l-1.1,0.1l-1.8,0.3l-1.8,0.3l-1.5,0.1l-0.1-0.1l-0.2-0.3l-0.4-0.4l-0.4-0.3l-0.8,0.5l-0.7,0.5l0-0.5
                    l0-0.9l0-1.9l0-2.5l0-3.8l0-2.2l0-1.7l0-1.5l-2.2,3.5l-0.8,0.3v0l-1.1,1l-1.7,1.7l-1.5,0.6l-0.9,0.9l-0.5,0.6l-0.5,1.4l0,0.7
                    l-0.1,0l-0.1,1.3l-0.3,0.8l-0.9,0.8l-0.6,0.6l-3.8,0.1l-1.5-0.1l-0.1,0l-0.4,0.1l-1,0.1h-0.4h-0.4l-0.5-0.1l-0.1-0.1l-0.9,0.1
                    l-1-0.2l0,0l-0.7,0.1l-0.1,0.7l0.5,0.5l-0.2,0.8l-0.3,1.2l-0.6,0.9l-0.4,0.4l-0.2,0v0l-0.2,0l-1.6,0.6l-0.8,0.7l-1,0.6l-0.6,0.2
                    l-0.7-0.2l-1-0.4l-2-0.2l-1.1-0.6l-0.4-0.3l-0.4-1l-1.1,0.2l-0.3,0.2l-0.4,0.1l-0.5,0.1l-0.5,0.1l-1,0.2l-0.3,0l-2.2,0.3l-0.3-0.1
                    l-0.1,0.4l-0.2,0.4l-0.5,1.1l-0.2,0.4l-0.2,0.2L111,162l-0.1,0.1l-0.3,1l-0.3,1l0.1,0l-0.2,0.8l0.2,1.2l0.4,0.3l0.7,0.2l0.4,0.6
                    l0.1,0.5l0.6,0.9l0.6,1.4l-0.7,0.9l-1.1,0.9l-1,0.8l-1,0.7l-0.4-0.4l-0.3-0.7l0,0l-0.4-0.3l-0.7-0.1l-1,0.1l-1.5,0.4l0,0.6l-0.1,0
                    l0.3,0.6l1.9,3.7l0.7,0.8l0.2-0.2l0.8,0.7l0.2,0.2l0.5,1l0.1,0.4l1,0.7l0.9,1.6l0.7,1.5l0.4,1.5l1,1.3l0.7,1.1l-0.1,0.3l0,0
                    l-0.2,0.8l-1.3,0.4l-0.9,0.1l-0.1,0l-3.6-2.6l-0.3-0.5l-0.4-0.2l-0.3,0.1l-0.4,0.8l-0.3,1.3l0,0.4l0,0.3l0.3,2.3l0.1,0.4l0.2,0.5
                    l0.3,0.1l0.4,0.2l0.7,1.2l-0.2,0.2l-0.5,0.1l-0.1,0.3l0.8,1.6l0.8,2.4l0.2,0.5l0.2,0.6l0.6,0.9l0.2,0.3v0.7l-0.4,0.8l-0.4,0.6
                    l-0.6,1l0.6,1.1l0.2,1.3l-0.1,1.3l0.3,0.5l0.2,1l0.1,0.3l0.1,0.1l0.8-0.4l0.6-0.7l1.6-0.7l1.3-0.1l1.5-0.6l1-0.1l0.3,0.2l0.2-0.3
                    l1,0.6l1.1,0.1l0.8,0.2l0.4-0.4l0.3,0l0.4,0.2l0.5,0.3l1.2,0.1l1-0.2l0.8-0.2l-0.1,0.6l0.2,0.4l0.5,0.7l0.3,0.4l0.2,0.2l0.8,0.2
                    l0.4,0l1.5-0.3l1.4,0.6l0.2,0.3l0.4,0l0.4-0.5l0.2-0.8l0-0.6l-0.2-0.3l-0.1-0.3l0.2-0.4l0.4,0l0.3,0.2l1.1,1.4l0.8,1.1l-0.1,2.2
                    l-0.2,0.3l0.1,0.3l0.4,0.3l0.3,0.1l2,0.5l1.4-0.6l0.8-0.5l0-0.3l1.5,0.4l0.5,0.4l0.4,0.5l0.3,0.2l0.3-0.1l0.3-0.4l0.2-0.2l0.3-0.1
                    l0.2,0.1l0.1,1l-0.1,2.6l0.9,1l0.9,0.2l0.9-0.1l0-0.4l-0.1-0.7l0.7-0.4l0.1-1.2l0-0.3l0-0.4l-0.1-0.3l-0.3-0.4l-0.1-0.3l-0.1-1.6
                    l0.1-0.3l0.3-0.4l0.2-0.4l-0.2-1.5l-0.7-1l-0.4-0.1l-0.3-0.4l-0.4-0.7l0-0.1l-0.3-0.3l0.4-0.6l1-0.7l0.4-1l0.2-1.3l0.8-1.3
                    l0.4-1.1l0.2-1.3l0.2-1.4l-0.3-1.3l0.1-0.9l0.1-1l1.2-0.5l1-1.2l0.4-0.8l1-1.3l1.2-1.3l1.4-1.5l0.1-1.5l0.1-2.1h0l0,0.1l0.9-0.2
                    l2.1,0.2l1.8,0.8l1.6,0.9l2.8,0.5l0.3,0.1l1.6-0.1l1.6-0.4l0.8-0.9l0.8-0.4l1.5-0.6l1.1-0.3l1.2-0.6l1.4-0.5l1.1-0.7l1.2-0.9
                    l0.3-1.2l-0.1-1.1l0.9-0.4l0.7-1.3l0.3-0.9L179.6,175.9L179.6,175.9z"
                  fill={this.colores['zcamd']}
            />
            <path
                  id='zjph'
                  stroke={this.linea}
                  stroke-width="1px"
                  className='border'
                  onMouseOver={()=>this.handleMessage('zjph')}
                  onMouseOut={()=>this.handleMessage('TODOS')}
                  d="M112.8,168.6l-0.6-0.9l-0.1-0.5l-0.4-0.6l-0.7-0.2l-0.4-0.3l-0.2-1.2l0.2-0.8l-0.1,0l0.3-1l0.3-1l0.1-0.1
                    l0.3-0.3l0.2-0.2l0.2-0.4l0.5-1.1l0.2-0.4l0.1-0.4l0,0l0.1-1.3l-0.3-2.1l-0.6-0.5l0,0l-0.8-0.2l-0.1-1.3l0-1l-0.1-0.1l-0.5-0.7
                    l-0.5-0.4l-1.8-0.5l-0.3,0.6l-0.3,0.6l-0.1,0.2l0,0.6v0.7l-0.1,1.3l-0.3,1l-0.7,0.4l-1.4-0.3l-0.4-1.1l-0.2-0.9l-1.2,0.2l-0.5,0.1
                    l-0.3-0.1l-2,0.1h-1.5l-1-0.4l-0.1-0.2l-0.1-0.6l-0.2-0.9l0.6-0.3l1-0.8l0.2-0.2l0.4-0.5l1-1l0.8-0.4l0.4-0.1l0.5-0.2l0.1-0.3
                    l0-0.1l0.4-0.2l0.1-0.7l-0.2-0.6l-0.4-0.2l-0.7-0.7l-0.4-1l0.1-1l0.4-0.8l0.1-1.1l-1-0.1l-0.7-0.5l-0.9-1.2l-0.1-1.3l-0.3-1.1
                    l-0.5-0.3l-0.3-0.8l-0.1-0.2l0.1-1.2l-0.4-0.9l-0.3-1l-0.7-0.5l-0.6-0.8l-0.1-0.6l-0.3,0.3l-0.8,1l-0.7,0.8l-0.3,1l-0.5,0.6
                    l-0.4,0.4l-0.9,0.1l-0.7,0.5l-0.4,0.2l-0.2,0.4l-0.3,0l-0.3,0.4l-0.7,0.5l-0.7-0.5l-0.3-0.5l-0.1,0l-0.4-0.3l-0.9-0.1l-0.7,0.6
                    l-0.9,0.7l-0.8,0.7l-0.3,0l-0.2,0l-1.1,1l-1,0.8l-0.7,0.5l-0.7,1l-0.7,1.1l-0.5,0.3l-0.3,0l-0.4,0.6l-0.7,0.3l-0.2,1.1l-0.2,0.7
                    l-0.1,0l-0.1,0.2l-1-0.1l-1.3-0.4l-0.7-0.2l-1.4-0.1l-0.7-0.3l-0.7-0.7l-1-0.1l-1.1,0.4l-1.1,0.7l-1,0.7l-1.3,0.3l0.2,0.2l0.2,0.6
                    l0.1,0.5l0,0.5l0.2,0.4l0.4,0.5l0.1,0.3l0.1,0.5l0,0.4l0.1,0.4l0.2,0.3l0.6,0.6l0.1,0.4l0,0.5l0,0.4l0,0.6l-0.1,0.4l0.2,0.4
                    l0.2,0.2l0.6,0.4l0.1,0.2l0,0l0.1,0.3l0,0.3l-0.1,0.7l0,0.4l0.2,1.8l1.4,2.8l0.5,0.8l0.6,0.3l0.5,0l0.3,0l0.3,0.2l0.1,0.5l0,0.9
                    l0.1,1l0.1,0.3l0.9,0.8l1.1,1.4l0.1,0.3l1.3,0.4l0.5,0.3l0.3-0.1l0.4,0.1l0.5,0.2l0.4-0.1h2.1l0,1.4l0.3,0.2l0.1,0.4l-0.2,0.3
                    l0,0.3l0.8,1.5l0.1,0.1l-0.3,1.5l0.1,1.8l0.3,0.6l0,0.1l0.1,0.3l0,0l0,0.1l0.2,0.2l-0.3,0.6l-0.1,1l0.2,0.7l-0.5,0.5l-0.4,0.8
                    l-0.4,0.5l-0.4,0.8l-0.9,0.4l0,0.4l0,0l-0.1,0.7v0.9l-0.2,1.3l-0.6,1l-0.6,0.8l0.4,0.6l0.5,0l0.1,0.2l0.3,0.1l0.3,0l0.5,0l0.6,0
                    l0.1,0l0.5-0.4l0.7-0.6l0.9-0.1l0.4,0.4l0.1,0.7l-0.2,0.6l0.1,0l0,0.3l-0.2,0.5l-0.2,0.2L83,191l-0.4,0.4l0.3,1.7l-0.5,0.9
                    l0.1,1.4l0.4,0.8l1.4,0.7l0.1,0l0.3,0.5l1.2,0.7h0.9l0.1,0.1l0.6-0.3h1l0.4,0.1l0-0.1h0.6l1.3,0.4l1.5-0.4l0.3-0.6l0.5-1.5
                    l0.1-1.5l0-1l0-0.1l-0.1-0.5l0-0.5l0.1-0.5l-0.4-0.5l-0.7-0.9l-0.2-0.2l-0.3-0.2l-0.3-0.3l0.5-1.6l1.5-0.4l0.4,0.2l0,0.1l0.5-0.2
                    l0.9-1l0.7-0.9l0.9-0.4l1-0.7l0.7-1.3l1.1-0.4l0.7-0.5l-0.3-0.5l0.1-0.4l-0.3-1.4l-0.4-1.5l-0.4-1.6l-0.4-1.4l-0.2-0.3l-0.3,0
                    l-0.2-0.5l-0.6-0.7l-0.3-0.3l0.4-1.2l0.1-0.1l0.9,0.1l0.7-0.5l0.4-0.4l0,0l0.6-0.1l1.1,0.8l1.2,0.2l0.9-0.1l0.6-0.4h1l0.4,0.1
                    l0,0.1l1.5-0.4l1-0.1l0.7,0.1l0.4,0.3l0,0l0.3,0.7l0.4,0.4l1-0.7l1-0.8l1.1-0.9l0.7-0.9L112.8,168.6z"
                  fill={this.colores['zjph']}
            />
            <path
                  id='zcasm'
                  stroke={this.linea}
                  stroke-width="1px"
                  className='border'
                  onMouseOver={()=>this.handleMessage('zcasm')}
                  onMouseOut={()=>this.handleMessage('TODOS')}
                  d="M84.4,86l-0.7-1l-1.2-0.6l-1.7,0.4l-0.9,0.7l-1.3-0.3l-0.4-0.1l-1,0l-0.6,0.1l-0.8,0.6l-1.4,0.9l-1-0.5
                    l-1-1.2l-1.4-1.2l-1.3-0.1l-0.3,0.2l-0.5,0.3l-0.5,0.2l-0.8,0.1l-0.7-0.3l-0.7-0.5l-0.3-0.4l-0.3-0.6l-0.1-0.5l-0.3-0.8L65,80.9
                    l-0.2-0.4l-1.4-1l-0.7-0.2l-0.4,0l-0.5,0l-0.7,0l-1.2-0.1l-0.4-0.1L58.9,79l-0.4-0.2L58,78.1l-0.3-0.3l-0.3-0.5L57,77l-0.7-0.3
                    l-0.9-0.3l-0.8-0.3l-0.4-0.3l-0.8-0.6L53.2,75l-0.1-0.2L52.2,73l-0.1-0.4l-0.1-0.8l-0.1,0l-1.4-1.1v-1l0.9-1L51.3,68l0-0.4
                    l0.1-0.8l0.2-0.7l0.2-0.6L52,65l0.3-0.3l0.3-0.3l0.3-0.2l0.3-0.5l0.9-1.9l0.1-0.4l0.1-0.8l-0.1-0.5l-0.1-0.3l0-0.7l0.2-2.5
                    l0.1-0.3l-0.1-2.1l-0.1-0.3l-0.1-0.3L54,53.4l-0.5-0.7l-0.2-0.4L53.2,52l0-0.4l0-0.6l0.1-0.6l-0.6-2.7l-0.1-0.4l-0.5-1l-0.6-0.9
                    l-0.1-0.4l0-0.4l-0.1-1.4l-0.5-2.3l-0.7,0.2l-0.9,0.8l-1.5,1.6l-0.5,0.8l-0.7,0.9l-0.4,0.3l-0.5,0.3l-0.3,0.3l-0.2,0.4l0.2,0.4
                    l0.3,0.3l0.1,0.5l-0.2,0.3l-0.9,0.7l-0.1,0l0-0.9l-0.1-0.4l-0.3-0.2l-0.3,0l-0.4,0.1l-0.2,0.2l-0.1,0.3L43,48.8l0,0.3l0.1,0.5
                    l-0.1,0.9l-0.2,1.1L42.5,52l-0.2,0.4l-0.1,0.1l-0.6,0.7l-0.9,1.1l-0.1,0.3l-0.2,0.7l0,0.4l0,0.5l0,0.4l-0.1,0.3l-1.1,2.6l-0.3,0.3
                    l-0.2,0.1l0,0.5l0,0.3l0.2,0.7l0,0.8l-0.2,1.1l-0.2,0.3l-0.4,0.1v0l-0.2,0.1L37.3,64L37,64.2l-0.5,0.3L36,64.8l-0.2,0.2l-0.4,0.6
                    L35.2,66l0,0.6L35,67.8l-1.2,1.5l-0.4,0.3l-0.4,0.1l-0.5-0.4l-1.2-0.2l-0.3,0l-0.4,0l-0.3,0.3l-0.8,0.9L28.5,72l0.5,1.7l0.5,0.7
                    l0.1,2.3l-0.3,0l-0.9,1l-0.3,2.3l0.3,1.2l0.2,0.4l0.2,0.2L29,82l0.1,0.4l-0.1,0l0.3,0.7l0.2,0.2l0.1,0l1.3,0.6l0.1,0.3l-0.5,2.3
                    l-0.2,0.6L30,87.7h-0.4l-0.5-0.1l-0.6,0.3l-0.4,0.2l-0.3,0.3l-0.2,0.4l-0.1,0.3l0.2,1.9l1.9,2.3l1.1,0.2l0.9,0.2l0.2,0.3l-0.1,0.5
                    l-1.2,1.2l-0.9,0.4l-0.1,0.8l0.1,0.4l-0.1,0.5l-0.3,0.1l0,0.4l0.1,2.4l0,0l0.3,0.4v0.9l0.4,1.1l0.2,0.1l0.9,0.2l0.3,0.1l1.3,1.1
                    l0.6,1.2l0.1,0.3l0,0.1l0.8,0l0.9-0.4l0.6-0.5l0.2-0.5l0.7-0.7l0.9-0.5l0.7-0.4l1,0.6l0.2,1.1l0.9,0.2l1.6-0.4h0.6l0.7,0.5v1
                    l0.1,1l0.6,0.4l0.1,0.3l0.1,0.4l0.9-0.5l1-0.4l1-0.9l0.9,0.2l0.5,0.5l0.7-0.2l0.2-0.5l0.5-0.2l0.8-0.8l0.6-0.7l0.5-0.7l-0.2-1.3
                    l-0.6-1.5l-0.7-1.3l-0.7-1.3L49,98l0-0.2l0,0l0.9-0.2l0.9-0.3l0.4-0.1l0.1,0.2l0.2,0.3l0.2,0.1l0.6,1l1.3,1.5l0.2,0.4l-0.1,0.4
                    l-0.4,0.7l0.1,0.7l0.4,1L54,104l0.5,0.3l0.1,0.1l0.1,1.3l0.2,0.8l0.1,1.3l0.1,1.5l0.6,0.1l0,0.1l0.5,0l0.5,0.4l0.2,1.4l0.2,1.1
                    h0.2l1.5-0.6l1.1-0.1l0.9,0.6l1.5,0.2l0.7,1.1l0.5,0.7l0.4,1.4l-0.3,0.2l0.1,0.5l0,0.3l-0.4,0.1l-1.4,0.2l-1.1,0.4l-0.6,0.6
                    L61,119l0.2-0.1l0.4,0l0.3,0.2l0.1,0.3h0.6h0.9H64l-0.1-0.3l0.5-0.1l1.2-0.2l1,0.2l1.4-0.5l1,0.4l0.6,0.4l0.5-1l0.5-0.6l0.6,0.3
                    l0-0.2l0.6,0.5l0.4,0.2l0.5,0.9l0.9,2.3l0.4,0.5l0.7,0.1l0.2-0.2l0.2-0.4l-0.3-1.1l-0.4-3.1l1.2-1.2l1.3-0.5l-0.1-0.1l0.2-1.9
                    l0.6-1.1l0.8-0.7l-0.2-0.3l-0.3-0.7h-1l-0.2-0.8l-0.9-1.1l-0.4-1.3l-0.3-2.3l-1.1-1.1l-0.4-1.2V102l0.1-0.9l0.9-0.3l0-0.2l0.1-0.3
                    l1.4-1V98l-0.4-3l0.7-0.7l0.3-0.1l0.6,0l0.4,0.1l0.4,0.1l0.4,0.2l0.4,0.3l0.4,0.3l0.1,0.2l1.6-0.1l1.2-0.2l0.5-0.2l0,0l0.1-0.4
                    l0.2-1.1l0-0.6l0-0.5l0.1-0.8l0.1-1l0.1-0.7l0.1-0.7l0-0.4l0-0.4L83.7,88l0.6-0.7L84.4,86z"
                  fill={this.colores['zcasm']}
            />
            <polygon
                  id='zmt'
                  stroke={this.linea}
                  stroke-width="1px"
                  className='border'
                  onMouseOver={()=>this.handleMessage('zmt')}
                  onMouseOut={()=>this.handleMessage('TODOS')}
                  points="168,247.5 168,246.8 167.5,246.3 166.7,245.5 165.8,245.4 165.5,245.5 165,245.6 164.7,245.6
                    163.2,244.9 162,244.2 160.7,243.3 160.5,243 159.1,240.6 159.1,240.6 159.4,240 159.5,238.9 159.6,237.8 160.7,236.6
                    160.3,236.2 159.5,236.1 159.1,235.9 158.1,235 156.6,233.4 156.5,233 156,231.5 156,231.1 156.1,230.8 155.9,229.5 155.3,227.7
                    155,227.3 152.9,226.7 152.8,226.8 152.6,226.5 151.7,226.5 150.6,226.5 149.8,226.7 149.7,226.6 149.3,226.4 149,226.4
                    148.9,227.1 148.9,227.8 148.9,228.5 148.9,229.1 148.8,229.5 148.7,230.2 148.5,230.6 148.2,231 147.8,231.3 147.5,231.6
                    147.4,231.9 147.4,232.3 147.5,232.7 147,232.9 146,232.6 144.9,231.8 144.3,231.5 143.6,231.5 143.3,232 143.6,232.4 144.2,233
                    144.5,233.2 144.7,233.5 143.8,233.9 142.5,235.8 142.6,236.6 142.5,237.4 142.1,237.6 141.7,237.7 140.7,238.5 140.6,238.7
                    140.5,239.1 140.5,239.4 140.6,239.9 141.1,240.9 141.4,241.2 142,242.1 141.6,243.6 140.9,243.9 140,244.6 138.6,245
                    139.2,245.2 139.9,245.6 139.9,245.9 140,246.1 140.9,246.9 141.2,247.1 141.6,248.5 141.7,249.2 141.8,250.3 142.6,251.7
                    143.9,252.4 144.2,252.7 144.3,252.9 144.5,253.1 144.5,253.1 144.5,253.1 145.5,253.8 146.6,254.2 147.4,254.7 147.9,255.1
                    148.3,255.6 150.7,257.8 151.8,258.8 152,258.9 152.6,259 153,259.2 153.6,259.6 155.1,260.9 155.2,261 155.4,260.9 155.5,261.3
                    155.9,261.6 157.4,261.3 159.7,261.2 161.9,260.7 163.3,259.4 164.1,258.1 164.7,256.8 164.6,255.3 164.2,253.7 163.9,252.9
                    163.8,251.9 164.2,251.4 165.1,251.2 165.3,250.9 165.4,251 166.2,250.3 168,248.6 		"
                  fill={this.colores['zmt']}
              />
            <path
                  id='zpt'
                  stroke={this.linea}
                  stroke-width="1px"
                  className='border'
                  onMouseOver={()=>this.handleMessage('zpt')}
                  onMouseOut={()=>this.handleMessage('TODOS')}
                  d="M30,69.1L28.9,68l-1.1-1.2l-0.1-0.4l-0.9-2.1l-0.1-0.6l-0.3-0.8l-0.3-0.3l-0.8-0.2l-1.5,0l-0.5-0.2L23,62.3
                    l-0.4,0l0,0l-0.4,0.3l-0.7-0.6l-1.2-0.4L18.9,61l-0.7-0.5l-0.8-0.2l-0.4,0l-0.3,0.3l-0.5,0.5l-1,0.8l-0.3,0.2l-0.5,0.2l-0.3-0.1
                    l-0.8-0.4l-0.2-0.4l0-0.4l0.5-0.8l-0.1-0.6l-0.3-0.8l-0.8-1l-0.3-0.4L12,57.1l0.5-0.4l0.3-0.2l0.1-0.1l0-0.1l0.5-0.9l0.6-0.1
                    l0.4,0l0.5,0.2l0.6-0.4l0.6-0.6l0.3-0.8l0.2-1l-0.1-1.4l-0.3-0.9L16,49.4l-0.2-0.9l-0.5-0.2l-0.7-0.2l0,0l-0.3,0.2L14,48.5l-0.3,0
                    l-0.8,0l-0.4,0.2l-0.2,0.4l-0.1,0.3l-0.6,0.8l-0.7,0.4l-1,0.5L8.4,52l-0.2,0.3L8,52.7l-0.1,0.4l-0.1,0.6L7.5,54l-0.8,0.5L6,55
                    l-0.4,0.6L5,56.7L4.6,57l-0.4,0.3l-2,1.8l-0.3,0.4L1.8,60l-0.5,0.7l-0.4,1.8l-0.4,2.3l-0.1,1.3L2,67.9L3.4,69L4,70l0.5,0.5
                    l-0.3,0.1l-0.5-0.1l-0.4,0.2l-0.2,1l-0.4,0.2l-0.5,0.8l-0.1,0.8l1.2,1L4.5,75l1,0.6l1,1.7l0.6,1.3l0.1,1.4l-0.4,1.2l-0.4,0.3
                    l-0.1,0.3l-0.2,0l0,0l-0.3,0.5l-0.7,0.2l-0.9-0.1l-0.6,0.7l-0.2,0.9L3.8,85l0.2,0.4l0.8,0.5l1.3,0.5l0.2,0.5l0.7,0.6l1.3,1
                    l1.2,0.8l1.1-0.5l0.6-0.1l0.1,0.1l0.1-0.1l0.4-0.5l0.7-1.1l0.5-0.8l0.1-0.5l0.3-0.7l0.8-1l0.6-0.7l0.3-0.3l0.3-0.2l0.5-0.2
                    l1.3-0.4l0.3-0.2l0.8-0.6l0.7-0.5l0.9-1.1l0.3-0.5l0.1-1.1l0.2-0.4l0-0.3l0.2-0.3l0.3,0.2l0.3,0.2l1.6,1.3l0.7,1.3l0.3,0.8
                    l0.6,0.3l0.5,0.2l0.4,0.3l0.3,0.6l0.4,0.5l0.1,0l0.4-0.1l1,0.4l0.2,0.4l0.3-0.2l1.1-0.9L29,82l-0.1-0.2l-0.2-0.2l-0.2-0.4
                    l-0.3-1.2l0.3-2.3l0.9-1l0.3,0l-0.1-2.3L29,73.7L28.5,72l1.2-1.7l0.8-0.9l0.3-0.3L30,69.1z"
                  fill= {this.colores['zpt']}
            />
            <path
                  id='zuh'
                  stroke={this.linea}
                  stroke-width="1px"
                  className='border'
                  onMouseOver={()=>this.handleMessage('zuh')}
                  onMouseOut={()=>this.handleMessage('TODOS')}
                  d="M152.9,132.2l-0.3,0.1l-0.8,0.7l-1.9,1.6l-0.9,0.6l-0.5,0.3l-1.2,1.3l-0.2,0.5l-0.4,0.3l-1.8,0.6l-0.3,0.4
                    l-0.3,0.6l-1.6,0.9l-1,0.1l-1.3,0l-2.6,0h-4.1H133h-0.4l-0.6,0h-1.3l-0.6,0l0.2-0.7l0.2-1l-0.2-0.7l-0.2-0.4l-0.7-0.4l-0.5-0.3
                    l-0.1-0.4l0.2-0.5l0.1-0.5l-0.7-1.8l-1-0.6l-0.4-0.1l-2.9-0.5l-1.7-0.5l-1.6,0l-0.3,0l-1.7,0l-3.1-0.1l0.3-0.5l0.4-0.3l0.5-0.4
                    l1.4-1.3l0.8-1.1l0.2-0.3l0.1-0.4l0-0.3l0-0.3l-0.2-0.8l-0.6-0.8l-0.7-0.4l-0.9-1.3l-0.2-0.2l-0.1-0.4l-0.1-0.4l-0.2-0.3l-0.2-0.2
                    l-0.4-0.2l-0.4-0.1l-0.5-0.1l-1-1l-0.8-2.2l-1-0.7l-0.8-0.7l-0.1-0.7l-0.2-0.8l-0.6-1.8l-0.8-1.5l-0.8-0.5l-0.7-0.2l-0.2-0.3
                    l-0.1-1.1l0.7,0.1l0.4-0.5l0.1-0.6l-0.2-0.4l-0.2-0.2l-0.4-0.1l-1-0.4l-0.3-0.2l-0.1-0.3l-0.2-0.3l-0.9-0.9l-0.6-0.5l-0.4-0.3
                    l0-0.3l0.2,0l-0.1-0.2l-0.3-0.2l0,0l0-0.4l-1-0.7l-1-0.8l-1.4-0.1l-1-0.1l-0.5-0.6l-0.7-0.1l-0.5,0.1l-0.3,0.5l0,0.1l0,0l0,0
                    l-0.6,0.8l-0.5,0.5l-0.3,0.5l0.1,0.6l0.1,0.6l0.2,2.8l-0.1,1.2l-0.1,0.4l-0.3,0.4l-0.4,0.3l-0.2,0.1l-0.4,0.8l-0.8,0.4l-1.8,0.1
                    l-1.5-0.1l-0.1-0.2l-0.2,0.1l-0.4-0.3l-1.6-0.1l-0.7,0.9l-0.7,0.6l-0.4,0.4l-0.5-0.1l-0.9-0.2l-0.9,0.7l-0.3,0.6v1.3l-0.1,0.9v0.9
                    l-0.5,0.4l-0.8,0.2l0,0l-0.3-0.3l-0.3-0.3l-0.5-0.3h-0.6l-1.9,1.6l-0.9,0.9l-0.3,0.3l-0.3,0.2l-0.5,0.2l0,0l0,0l-0.3-0.9l-0.1-0.7
                    l0-0.2l0.1-0.4l0.1-0.6l0.1-0.9l0.1-0.3l0-0.1l-0.5-0.8l-1.3,0.5l-1.2,1.2l0.4,3.1l0.3,1.1l-0.2,0.4l-0.2,0.2l-0.7-0.1l-0.4-0.5
                    l-0.9-2.3l-0.5-0.9l-0.4-0.2l-0.6-0.5l0,0.2l-0.6-0.3l-0.5,0.6l-0.5,1l-0.6-0.4l-1-0.4l-1.4,0.5l-1-0.2l-1.2,0.2l-0.5,0.1l0.1,0.3
                    h-0.6h-0.9H62l-0.1-0.3l-0.3-0.2l-0.4,0L61,119l-0.7-1.1l-1.1,0.7l-0.6,0.1l-0.1,0.8v1.2l1,0.6l0,1.8l0.1,0.2l0.8,1.3l0.4,0.5
                    l1.6,0.7l1.4,1l1.8,1l1.1,0.7l-0.1,1.2l-0.4,0.5l-0.7,0.1l-1,0.1l-0.1,0.1l-0.6,1.2l-0.8,1.9l-0.7,0.1l-0.1,0.7l-0.2,1.2l0.4-0.1
                    L63,136l0.9,0.5l0.7,1.1l0.2,1l0.1,1.7l-0.5,0.7l-0.6,0.6l0.3,1l-0.2,0.9l-0.2,0.5l-0.4,0.3v0.1l0,0.2l0.2,0.4l0.4,0.2l0.6-0.1
                    l0.3,0.2l0.1,0.7l0.1,0.2l0.3,0.2l0.4,0.1l0.2,0l0,0l1.3-0.3l1-0.7l1.1-0.7l1.1-0.4l1,0.1l0.7,0.7l0.7,0.3l1.4,0.1l0.7,0.2
                    l1.3,0.4l1,0.1l0.1-0.2l0.1,0l0.2-0.7l0.2-1.1l0.7-0.3l0.4-0.6l0.3,0l0.5-0.3l0.7-1.1l0.7-1l0.7-0.5l1-0.8l1.1-1l0.2,0l0.3,0
                    l0.8-0.7l0.9-0.7l0.7-0.6l0.9,0.1l0.4,0.3l0.1,0l0.3,0.5l0.7,0.5l0.7-0.5l0.3-0.4l0.3,0l0.2-0.4l0.4-0.2l0.7-0.5l0.9-0.1l0.4-0.4
                    l0.5-0.6l0.3-1l0.7-0.8l0.8-1l0.3-0.3l0.1,0.6l0.6,0.8l0.7,0.5l0.3,1l0.4,0.9l-0.1,1.2l0.1,0.2l0.3,0.8l0.5,0.3l0.3,1.1l0.1,1.3
                    l0.9,1.2l0.7,0.5l1,0.1l-0.1,1.1l-0.4,0.8l-0.1,1l0.4,1l0.7,0.7l0.4,0.2l0.2,0.6l-0.1,0.7l-0.4,0.2l0,0.1l-0.1,0.3l-0.5,0.2
                    l-0.4,0.1l-0.8,0.4l-1,1l-0.4,0.5l-0.2,0.2l-1,0.8l-0.6,0.3l0.2,0.9l0.1,0.6l0.1,0.2l1,0.4h1.5l2-0.1l0.3,0.1l0.5-0.1l1.2-0.2
                    l0.2,0.9l0.4,1.1l1.4,0.3l0.7-0.4l0.3-1l0.1-1.3V153l0-0.6l0.1-0.2l0.3-0.6l0.3-0.6l1.8,0.5l0.5,0.4l0.5,0.7l0.1,0.1l0,1l0.1,1.3
                    l0.8,0.2l0,0l0.6,0.5l0.3,2.1l-0.1,1.3l0,0l0.3,0.1l2.2-0.3l0.3,0l1-0.2l0.5-0.1l0.5-0.1l0.4-0.1l0.3-0.2l1.1-0.2l0.4,1l0.4,0.3
                    l1.1,0.6l2,0.2l1,0.4l0.7,0.2l0.6-0.2l1-0.6l0.8-0.7l1.6-0.6l0.3,0l0.4-0.4l0.6-0.9l0.3-1.2l0.2-0.8l-0.5-0.5l0.1-0.7l0.7-0.1l0,0
                    l1,0.2l0.9-0.1l0.1,0.1l0.5,0.1h0.4h0.4l1-0.1l0.4-0.1l0.1,0l1.5,0.1l3.8-0.1l0.6-0.6l0.9-0.8l0.3-0.8l0.1-1.3l0.1,0l0-0.7
                    l0.5-1.4l0.5-0.6l0.9-0.9l1.5-0.6l1.7-1.7l1.1-1v0l0.8-0.3l2.2-3.5l0-0.2l0-0.7l0-1l0.2-0.4l0.2-0.2l0.1-2.1l0.2-1.3l0-0.7
                    L152.9,132.2z"
                  fill={this.colores['zuh']}
            />
            <path
                  id='zap'
                  stroke={this.linea}
                  stroke-width="1px"
                  className='border'
                  onMouseOver={()=>this.handleMessage('zap')}
                  onMouseOut={()=>this.handleMessage('TODOS')}
                  d="M176.2,197.8l-0.3-0.2l-0.3-0.3l-0.2-0.3l-0.4-1.8l-0.2-0.7l-0.7-1.2l0.6-0.9l0.6-1.1l0.3-0.9l0-0.8l0-0.9
                    l0.7-1.2l0.4-2l-0.3-0.6l-0.1-1.5l0.2-1.4l-1.2,0.9l-1.1,0.7l-1.4,0.5l-1.2,0.6l-1.1,0.3l-1.5,0.6l-0.8,0.4l-0.8,0.9l-1.6,0.4
                    l-1.6,0.1l-0.3-0.1l-2.8-0.5l-1.6-0.9l-1.8-0.8l-2.1-0.2l-0.9,0.2l-0.1,2l-0.1,1.5l-1.4,1.5l-1.2,1.3l-1,1.3l-0.4,0.8l-1,1.2
                    l-1.2,0.5l-0.1,1l-0.1,0.9l0.3,1.3l-0.2,1.4l-0.2,1.3l-0.4,1.1l-0.8,1.3l-0.2,1.3l-0.4,1l-1,0.7l-0.4,0.6l0.3,0.3l0,0.1l0.4,0.7
                    l0.3,0.4l0.4,0.1l0.7,1l0.2,1.5l-0.2,0.4l-0.3,0.4l-0.1,0.3l0.1,1.6l0.1,0.3l0.3,0.4l0.1,0.3l0,0.4l0,0.3l-0.1,1.2l-0.7,0.4
                    l0.1,0.7l0,0.4l-0.9,0.1l-0.9-0.2l-0.9-1l0.1-2.6l-0.1-1l-0.2-0.1l-0.3,0.1l-0.2,0.2l-0.3,0.4l-0.3,0.1l-0.3-0.2l-0.4-0.5
                    l-0.5-0.4l-1.5-0.4l0,0.3l-0.8,0.5l-1.4,0.6l-2-0.5l-0.3-0.1l-0.4-0.3l-0.1-0.3l0.2-0.3l0.1-2.2l-0.8-1.1l-1.1-1.4l-0.3-0.2
                    l-0.4,0l-0.2,0.4l0.1,0.3l0.2,0.3l0,0.6l-0.2,0.8l-0.4,0.5l-0.4,0l-0.2-0.3l-1.4-0.6l-1.5,0.3l-0.4,0l-0.8-0.2l-0.2-0.2l-0.3-0.4
                    l-0.5-0.7l-0.2-0.4l0.1-0.6l-0.8,0.2l-1,0.2l-1.2-0.1l-0.5-0.3l-0.4-0.2l-0.3,0l-0.4,0.4l-0.8-0.2l-0.4,0l-0.2,0.9l-0.8,0.2
                    l-0.5,0.2l-0.4,0.7l-0.3,1l0.1,1.1l0.1,1l-0.4,1l-0.9,1.2l-0.7,1l-1.3,0.3l-0.6,0.7l-0.4,0.7l-0.7,0.3l-0.6-0.3l-0.3,0l-0.6,0.3
                    l-0.3,0.2l-0.8,0.4l-1.9,0.2l-0.6-0.3l-0.4-1l0.1-0.8l-0.8,0.4l-1.3,0.7l-0.4,0.8l-0.6,1.3l-0.1,1.2l-0.5,0.3l-0.5,0l-0.1-0.4
                    l-0.1-1.1l-0.2-0.9l-0.3,0.1l-0.3,0.3l-0.4,0.3l-0.3,0.1l-0.4,0l-0.5-0.1l-0.4-0.3l-1.6-1.6l-0.4-1.2l-0.4-1.3l-1.2-0.3l-1.1-0.3
                    l-0.4-0.1l-0.6-0.2l-0.3-0.3l-0.1-0.4l-0.1,0l0-0.1l-1.3,0.2l-0.6,0.3l-1.3,0.7l-1.1,1.4l-0.4,1.3l-0.3,0.8l0.3,0.3l0.4,0.4l1,0.4
                    l1.6,0.7l0.8,0.4l1.5,0.8l0.9,0.4l1.7,0.7l0.5,0.3l0.4,0.6l0.1,0.4l0.3,0.3l0.3,0.2l0.7,0.3l1.2,0.3l1.2,0.4l1.8,1l0.7,0.9
                    l0.5,0.4l1.8,1.5l2.8,1l0.5,0.1l2.1,0.7l0.7,0.3l1.3,0.7l1.1,1l3,1.3l1.2,0.5l0.4,0.1l0.3,0.2l0.5,0.3l0.5,0.5l0.3,0.3l0.9,0.3
                    l0.4,0l0.5,0l0.3,0l0.5,0l0.5,0.1l1.2,0.4l0.5,0.2l1.3,0.8l0.3,0.3l0.6,0.9l0.9,0.9l1.5,1.1l1.1,0.7l0.4,0.2l0.6,0.3l0.7,0.5
                    l0.2,0.3l0.3,0.6l0.8,0.6l0.4,0.2l0.8,0.2l0.9,0.2l0.3,0.1l1.1,0.5l1.4-0.4l0.9-0.6l0.7-0.3l0.4-1.5l-0.7-0.9l-0.3-0.3l-0.5-1
                    l-0.1-0.5l0-0.4l0.1-0.3l0.2-0.2l0.9-0.8l0.5-0.2l0.3-0.2l0.1-0.8l-0.1-0.9l1.4-1.9l0.8-0.4l-0.2-0.3l-0.4-0.2l-0.5-0.5l-0.3-0.4
                    l0.3-0.5h0.7l0.6,0.3l1.1,0.8l1,0.3l0.5-0.2l-0.1-0.4v-0.4l0.2-0.3l0.3-0.3l0.3-0.3l0.4-0.4l0.2-0.4l0.1-0.7l0-0.4l0-0.6l0-0.7
                    l0-0.7l0.1-0.6l0.4-0.1l0.4,0.2l0.1,0.1l0.8-0.1l1.1-0.1l0.9,0.1l0.2,0.2l0.1,0l2.1,0.6l0.3,0.4l0.6,1.8l0.1,1.3l-0.1,0.3l0,0.4
                    l0.5,1.5l0.1,0.4l1.5,1.6l1,0.9l0.4,0.2l0.8,0.1l0.4,0.4l-1.2,1.1l-0.1,1.1l-0.1,1.2l-0.4,0.5l1.4,2.5l0.2,0.3l1.3,1l1.2,0.6
                    l1.5,0.8l0.3,0l0.5-0.1l0.3-0.1l0.5-1.1l0.4-0.4l0.9-0.8l0.9-0.5l0.8-0.4l0.3-0.3l0.9-1.9l1.8-2.2l1.2-1l0.8-0.4l0.5-0.4l0-0.4
                    l-0.2-0.3l-0.1-0.9l0.1-1.1l0.6-0.8l0,0l0.1-0.5v-1.3l-0.4-0.8l-0.2-0.4l-0.2,0l-0.5-0.6l-0.1-0.1l-0.7,0.4v0l0.7-0.4l-1-0.7
                    l-1.1-0.7l-0.5-0.3l-1.1-3l-1.1-3.1l1.2-2.5l0,0l0.1-0.2l0.9-1.6l1-0.9l0.4,0l0.4-0.1l0.1-0.4l-0.5-0.6l-0.4-0.4l-1.4-1.1
                    l-1.2-1.7l0.2-2.4l0.1-0.1l0-0.5l0.6-1.3l0.9-1.3l1.1-1.3l0.9-1l0.9-1.1l0.3-1.7l0.7-0.5l1-0.4l-0.3-1L176.2,197.8z"
                  fill={this.colores['zap']}
            />
          </g>
        </svg>
      </Fragment>
    )
  }
}