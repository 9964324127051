import React, { Component, Fragment } from 'react'
import '../../styles/graficaTarjeta.css'

interface IProps {
    de:string|number
    hasta:string|number
    meta:string
    title:string
    etiqueta?:string
    color?:string
    funcionVisible:Function
}

interface IState {
    aparece:boolean
}

export default class EtiquetaKpiResultadoDeHasta extends Component<IProps,IState> {
    constructor(props:IProps){
        super(props)

        this.state={
            aparece:false
        }

        this.handleEvent = this.handleEvent.bind(this)
    }

    handleEvent(aparece:boolean){

        this.props.funcionVisible(aparece)

    }


    render() {
        return (
            <Fragment>
                <div className=' w-100'>
                    <div className="card w-100 text-sm hober"
                        style={{ background:this.props.etiqueta?? '' , color:this.props.color??''}}
                        onMouseOver={()=>this.handleEvent(true)}
                        onMouseOut ={()=>this.handleEvent(false) }

                    >
                        <div className='card-body  ' >
                            <h4 className='w-100 text-center p-2' style={{fontSize:'30px'}}>
                                <b>  { this.props.de + ' de ' + this.props.hasta}</b>
                            </h4>
                        </div>
                    </div>
                    <div className="card w-100 text-sm "
                        style={{ background:'#424242', color:'#fff'}}
                    >
                        <div className='card-body  ' >
                            <h4 className='w-100 text-md text-center pt-2 ' >
                                Valor esperado <b>{this.props.meta}</b>
                            </h4>
                        </div>
                    </div>
                </div>
            </Fragment>
    )
  }
}

