import React, { Component, Fragment } from 'react'

import { Switch  } from 'react-router-dom'
import NoValidate from './component/NoValidate'
//import ContenidoBody from './component/ContenidoBody'
import SystemOracle from './component/system/SystemOracle'
import GeneralRoute from './context/GeneralRoute'
import PrivateRoute from './context/PrivateRoute'
import PublicRoute from './context/PublicRoute'
import Analitica from './pages/Analitica/Analitica'
import Dashboard from './pages/Dashboard'
import Herramientas from './pages/Herramientas/Herramientas'


import Home from './pages/Home'
import Login from './pages/Login'
import Logout from './pages/Logout'
import NoFound from './pages/NoFound'
import ControlAlumnos from './pages/Oracle/ControlAlumnos'
import ListaMatriculados from './pages/Oracle/ListaMatriculados'
import RptF02 from './pages/Oracle/RptF02'
//import Anexo03 from './pages/Seguimiento/Anexo03'
import Ficha from './pages/Seguimiento/Ficha'
import ReporteAnexo from './pages/Seguimiento/ReporteAnexo'
import ReporteAnexoView from './pages/Seguimiento/ReporteAnexoView'
import Seguimiento from './pages/Seguimiento/Seguimiento'
import SinDatos from './pages/Seguimiento/SinDatos'
import Supervision from './pages/Seguimiento/Supervision'
//import TestRealizado from './pages/Seguimiento/TestRealizado'


interface IProps {

}
interface IState {

}

export default class App extends Component<IProps, IState> {

  render() {

    return (
      <Fragment>
        {/*    <div className="wrapper">
          <Navbar/>
          <Aside />
          <div className="content-wrapper">*/}
        {/* <ContentHeader /> */}

      {/*  <ContenidoBody title={'ANALITICA ACADEMICA'} link={'Login'} page={''}>
      
      y
     https://www.gcabana.com/#/seguimiento/anexo03?courseId=202220-NSID-512-TEC-NRC_26431&instructorId=001481519&especialistaId=000794385&semana=2022-33&codigo=f60f25e409cade74ac7c9944dba36558
      */}

          <Switch>
            <PrivateRoute exact path="/home" component={Home} />
            <PrivateRoute  exact path="/dashboard" component={Dashboard} />
            <GeneralRoute  exact path="/analitica" component={Analitica} />
           


            <PrivateRoute exact  path="/reporte/controlalumnos" component={ControlAlumnos}/>
            <PrivateRoute exact path="/reporte/listaMatriculados" component={ListaMatriculados} />
            <PrivateRoute exact path="/reporte/rptf02" component={RptF02} />

            <GeneralRoute exact path="/seguimiento/anexo03" component={Seguimiento}/>
            <GeneralRoute exact path="/seguimiento/ficha" component={Ficha}/>
            <GeneralRoute exact path="/seguimiento/reporte" component={ReporteAnexo}/>
            <GeneralRoute exact path="/seguimiento/reporteview" component={ReporteAnexoView}/>
            <GeneralRoute exact path="/seguimiento/supervision" component={Supervision}/> 

            {/**
             *  <GeneralRoute exact path="/seguimiento/anexo03/ficha" component={Anexo03}/>
             * <GeneralRoute exact path="/seguimiento/reporte" component={TestRealizado}/>
             */}
            
            {/*####### Enlace Teams ######## */}
            <GeneralRoute exact path="/herramientas" component={Herramientas}/>

            {/****************** */}

            <PrivateRoute exact path="/logout" component={Logout} />

            <PrivateRoute exact path="/configuracion/oracleBbdd" component={SystemOracle} />

            <GeneralRoute exact path='/' component={Login} />

            {/*<PublicRoute exact path='/dashboard' component={Dashboard} />*/}

           {/* <PublicRoute exact path="/" component={Login} />*/}
            <GeneralRoute exact path="/sindatos" component={SinDatos} />
            <GeneralRoute exact path="/NoValidate" component={NoValidate} />
            <PublicRoute path="*" component={NoFound} />


          </Switch>
     { /*  </ContenidoBody> */}
        {/*         </div>
           </section>
        </div>
        *  </div>
        </div> */}
      </Fragment>
    );
  }
}
