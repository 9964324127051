import ApiJson from '../../ApiJson.json'


class CpBlackboardApex {

  async copySystem(option:string) {

     await fetch(`${ApiJson.Server}/api/system/copiar/${option}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          'token': sessionStorage.getItem('token') || ''
        },
      })
      .then((db) => db.json())
      .then((data) => {

        console.log(data)
      })
  }

}

const cpBlackboardApex = new CpBlackboardApex()
export default cpBlackboardApex