import React, { Component } from 'react'

interface IProps {
  name:string
  className:string
  title:string
  placeHolder?:string
  required:Boolean
  Texto:Function
}
interface IState {
  [texto:string]:string
}

export default class TextArea extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      name: "",
      texto: ""
    }

    this.handleOnChange = this.handleOnChange.bind(this)
  }

  componentDidUpdate(prevProps:IProps, prevState:IState){

    if(this.state.texto !== prevState.texto){
      this.props.Texto(this.state.name, this.state.texto)

    }
  }

  handleOnChange({ target }: React.ChangeEvent<HTMLTextAreaElement>) {

    this.setState({
      name:target.name,
      texto:target.value.replaceAll('"',"'")
    })

  }

  render() {
    return (
      <div className="input-group">
        <span className="input-group-text">{(!!this.props.title)? this.props.title : ''}</span>
        <textarea
          className={!this.props.className ? "" : this.props.className}
          cols={150}
          rows={5}
          name={this.props.name}
          placeholder={this.props.placeHolder}
          onChange={this.handleOnChange}
          value={this.state.texto}
          required={!!this.props.required}
        />
      </div>
    );
  }
}
