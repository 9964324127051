import React, { Component, createContext } from "react"
import { IAnexo03 } from "../models/Seguimiento/anexo03.model"


interface IProps {}

interface IState {

  periodo: string
  nrc: string
  lc: string | null
  tipo_nrc: string
  curso: string
  id_inst: string | null
  instructor: string | null
  zonal: string
  campus: string
  cod_curso: string
 // id_supervisor: string
  supervisorId:string
  supervisor: string
  alumnosbb: number
  alumnos_asiste: number
  inicio: string
  fin: Date
  curso_cerrado: string
  pregunta1: string
  pregunta2: string
  pregunta3: string
  pregunta4: string
  pregunta5: string
  pregunta6: string
  pregunta7: string
  pregunta8: string
  pregunta9: string
  pregunta10: string
  pregunta11: string
  pregunta12: string
  pregunta13: string
  pregunta14: string
  pregunta15: string
  description1: string
  description2: string
  description3: string
  description: string
  anexo:IAnexo03|null
  statusInst:boolean
  setAnexo?: Function
  Clear?: Function
}

export const Anexo03Context = createContext<Partial<IState>>({});

export default class Anexo03Provider extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.handleSetAnexo = this.handleSetAnexo.bind(this)
    this.handleClearAnexo = this.handleClearAnexo.bind(this)
  }

  handleSetAnexo(anexo03: any) {

     this.setState({
      periodo: anexo03.controlAlumno[0].periodo,
      tipo_nrc: anexo03.controlAlumno[0].tipo_nrc,
      curso: anexo03.controlAlumno[0].curso,
      id_inst: anexo03.controlAlumno[0].id_inst ?? "",
      instructor: anexo03.controlAlumno[0].instructor ?? "",
      zonal: anexo03.controlAlumno[0].zonal,
      campus: anexo03.controlAlumno[0].campus,
      cod_curso: anexo03.controlAlumno[0].cod_curso,
      supervisorId: anexo03.supervisorId?? '000000000',
      supervisor: anexo03.supervisor,
      alumnosbb: parseInt(anexo03.controlAlumno[0].inscritosbb),
      alumnos_asiste: parseInt(anexo03.asistencia),
      inicio: anexo03.horaInical,
      fin: anexo03.horaFinal?? new Date(),
      curso_cerrado: anexo03.controlAlumno[0].curso_cerrado,
      pregunta1: anexo03.pregunta1?? "NO APLICA",
      pregunta2: anexo03.pregunta2?? "NO APLICA",
      pregunta3: anexo03.pregunta3?? "NO APLICA",
      pregunta4: anexo03.pregunta4?? "NO APLICA",
      pregunta5: anexo03.pregunta5?? "NO APLICA",
      pregunta6: anexo03.pregunta6?? "NO APLICA",
      pregunta7: anexo03.pregunta7?? "NO APLICA",
      pregunta8: anexo03.pregunta8?? "NO APLICA",
      pregunta9: anexo03.pregunta9?? "NO APLICA",
      pregunta10: anexo03.pregunta10?? "NO APLICA",
      pregunta11: anexo03.pregunta11?? "NO APLICA",
      pregunta12: anexo03.pregunta12?? "NO APLICA",
      pregunta13: anexo03.pregunta13?? "NO APLICA",
      pregunta14: anexo03.pregunta14?? "NO APLICA",
      pregunta15: anexo03.pregunta15?? "NO APLICA",
      description1: anexo03.description1??'',
      description2: anexo03.description2??'',
      description3: anexo03.description3??'',
      description: anexo03.description?? '',
      statusInst: anexo03.statusInst
    })

  }

//202210-PIAD-623-LC_T6
  handleClearAnexo() {
    this.setState({
      anexo: null,
    })

    sessionStorage.clear()
  }



  render() {
    return (
      <Anexo03Context.Provider
        value={{
          ...this.state,
          setAnexo: this.handleSetAnexo,
          Clear: this.handleClearAnexo,
        }}
      >
        {this.props.children}
      </Anexo03Context.Provider>
    );
  }
}
