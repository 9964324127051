import { IRepF02 } from "../models/oracle/repf02.model"
import ApiJson from '../ApiJson.json'

class BbRptf02 {

  private static getBbRptf02: BbRptf02
  private auth: Boolean = false
  private RptF02: IRepF02[] = []

  private constructor() {

  }

  static getBbRptF02() {
    if (!BbRptf02.getBbRptf02) {
      BbRptf02.getBbRptf02 = new BbRptf02()
    }

    return BbRptf02.getBbRptf02
  }

  async FindxPeriodoxZonal(periodo: string, zonal: string) {

    await fetch(`${ApiJson.Server}/api/oracle/rpf02/findxPeriodoxZonal/${periodo}/periodo/${zonal}/zonal`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          'token': sessionStorage.getItem('token') || ''
        },
      })
      .then((db) => db.json())
      .then((data) => {

        this.RptF02 = (data.auth) ? data.data : []
        this.auth = data.auth
      })


    return { reportes: this.RptF02, auth: this.auth }
  }

  async FindxPeriodoxNrc(periodo: string, nrc: string) {

    await fetch(`${ApiJson.Server}/api/oracle/rpf02/${periodo}/periodo/${nrc}/nrc`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          'token': sessionStorage.getItem('token') || ''
        },
      })
      .then((db) => db.json())
      .then((data) => {

        this.RptF02 = (data.auth) ? data.data : []
        this.auth = data.auth
      })


    return { reportes: this.RptF02, auth: this.auth }
  }

  async FindxPeriodo(periodo: string) {

    await fetch(`${ApiJson.Server}/api/oracle/rpf02/findxPeriodo/${periodo}/periodo`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          'token': sessionStorage.getItem('token') || ''
        },
      })
      .then((db) => db.json())
      .then((data) => {

        this.RptF02 = (data.auth) ? data.data : []
        this.auth = data.auth
      })


    return { reportes: this.RptF02, auth: this.auth }
  }

  async FindxPeriodoxLc(periodo: string, lc: string) {

    await fetch(`${ApiJson.Server}/api/oracle/rpf02/${periodo}/periodo/${lc}/lc`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          'token': sessionStorage.getItem('token') || ''
        },
      })
      .then((db) => db.json())
      .then((data) => {

        this.RptF02 = (data.auth) ? data.data : []
        this.auth = data.auth
      })


    return { reportes: this.RptF02, auth: this.auth }
  }

}

const BBRptf02 = BbRptf02.getBbRptF02()
export default BBRptf02