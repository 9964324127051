//import { useMsal } from '@azure/msal-react';
import React from 'react'
import { Link } from 'react-router-dom';
//import SignOutButton from '../component/Authentication/SignOutButton'


import imagen from '../../Image/fondo2.jpg'

import '../../styles/fondo.css'


interface IProps {}

interface IState {
    cont: number
}
export default class SinDatos extends React.Component<IProps,IState>{

  private intervalId:any

  constructor(props:IProps){
      super(props)

      this.state={
        cont: 0
      }

     
      
      this.logout = this.logout.bind(this)
  }

  logout(){
        window.close()
    }

    componentDidMount(){
      this.intervalId = setInterval(()=>{
          return (
              this.setState({
                  cont: this.state.cont - 1 
              })
          )
      }, 1000)
  
  }

  componentDidUpdate(prevState:IState){

      if(prevState.cont !== this.state.cont){
        //  if(this.state.cont === 0 ) this.logout()
      }
  }

  componentWillUnmount(){
      clearInterval(this.intervalId)
  }


  render(){
    const fondo = {
        backgroundImage:`url(${imagen})`,
        backgroundSize:'cover',
        backgroundRepeat:'no-repeat',
        backgroundPosition:'center',

        border:'1px solid blue',
        with:'100%',
        height:'100vh'
    }

  return(
    <>
        <div style={ fondo } >
          <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
              <Link className="navbar-brand" to="#">
              ANALÍTICA ACADÉMICA
              </Link>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
              </button>
          </nav>
          <div className="card card-primary margen mx-auto" style={{width:'30%'}}>
            <div className="card-header ">
                <div className='logo'>
                    <img src="/images/logo.svg" className='center' alt="" width={'100px'}/>
                </div>
            </div>
            <div className="card-body">
                <div className="form-check">
                    <label className="form-check-label" htmlFor="exampleCheck1">
                        <section className='text-lg text-center text-primary'>
                            <b>No encontramos información para esta ficha de seguimiento</b>
                          <p>
                            por favor informar para implementar la ficha
                          </p>
                        </section>
                    </label>
                </div>
            </div>
            
          </div>
        </div>
    </>
  )
  }
}
