import React, { Component, Fragment} from 'react'
import { BbTermContext } from '../../context/PeriodoProvider'
import { IBbTerm } from '../../models/bb/BbTerms.model'
import { RouteComponentProps } from 'react-router'
import { IRepF02 } from '../../models/oracle/repf02.model'
import BBRptf02 from '../../controller/BbRptF02.ctrl'
import { IOraZonales } from '../../models/oracle/OraZonales.model'
import TableOracleRepF02a from '../../component/Oracle/TableOracleRepF02a'
import TableOracleRepF02b from '../../component/Oracle/TableOracleRepF02b'
import ContenidoBody from '../../component/ContenidoBody'


interface IProps extends RouteComponentProps {

}

interface IState{
  periodos  : IBbTerm[]
  periodo   : string
  activar   : boolean
  zonales   : IOraZonales[]
  zonal     : string
  reportes  : IRepF02[]
  dato      : string
  auth      : boolean
  tempPeriodo:string
  tempZonal : string
  tempReportes: IRepF02[]
  select    : string
  [data: string]: any

}

export class RptF02 extends Component<IProps,IState> {

  static contextType = BbTermContext

  constructor(props:IProps){
    super(props)

    this.state = {
      activar: false,
      zonales:[],
      zonal: '',
      periodos:[],
      periodo:'0',
      reportes: [],
      tempReportes:[],
      dato:'',
      auth:false,
      tempPeriodo:'',
      tempZonal:'',
      select:''
    }

    this.handleInput = this.handleInput.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleNrc = this.handleNrc.bind(this)
    this.handleLc = this.handleLc.bind(this)
    this.handleAlumno = this.handleAlumno.bind(this)
  }

  async componentDidMount(){

    const BbTerm = this.context


   if(BbTerm.terms.filter((term:IBbTerm)=> term.option).length=== 0) {
     this.props.history.push('/dashboard')
   }

    this.setState({
      activar:true
    })
/*
    const {zonales , auth} = await BbZonales.Zonales()

    if(!auth){
      sessionStorage.clear()
      this.props.history.push('/login')
    }

*/

    this.setState({
      periodos: BbTerm.terms.filter((term: IBbTerm) => term.option),
  //    zonales,
      auth:true,
      activar:false
    })

  }

  handleInput({ target }: React.ChangeEvent<HTMLInputElement>){

    this.setState({
      [target.name] : target.value.trim().toUpperCase()
    })

  }

  handleSelect({ target }: React.ChangeEvent<HTMLSelectElement>){
    this.setState({
      [target.name]: target.value
    })
  }


  async handleNrc(){


    if( this.state.periodo === '0') return
    if(this.state.dato==='') return

    this.setState({
      activar:true
    })

    if(this.state.zonal==='0'){
      const { reportes } = await BBRptf02.FindxPeriodoxNrc(this.state.periodo, this.state.dato)

      this.setState({
        reportes,
        tempPeriodo: this.state.periodo,
        tempZonal: this.state.zonal,
        tempReportes:reportes
      })

    }
    else if(this.state.tempPeriodo === this.state.periodo && this.state.tempZonal=== this.state.zonal ){

      this.setState({
        reportes: this.state.tempReportes.filter((rpt: IRepF02) => rpt.NRC === this.state.dato ),
        tempPeriodo: this.state.periodo,
        tempZonal: this.state.zonal,
      })

    }else{

      const { reportes } = await BBRptf02.FindxPeriodoxZonal(this.state.periodo, this.state.zonal)

      this.setState({
        reportes: reportes.filter((rpt: IRepF02) => rpt.NRC === this.state.dato ),
        tempPeriodo: this.state.periodo,
        tempZonal:this.state.zonal,
        tempReportes:reportes
      })

    }

    this.setState({
      select:'nrc',
      activar: false,
      dato: '',
    })

  }

  async handleLc() {

    if (this.state.periodo === '0') return
    if(this.state.zonal==='0')
    if (this.state.dato === '') return

    this.setState({
      activar: true
    })

    if (this.state.tempPeriodo === this.state.periodo && this.state.tempZonal === this.state.zonal) {

      this.setState({
        reportes: this.state.tempReportes.filter((rpt: IRepF02) => rpt.NRC === this.state.dato),
      })

    } else {

    const { reportes } = await BBRptf02.FindxPeriodoxZonal(this.state.periodo, this.state.zonal)

      this.setState({
        reportes: reportes.filter((rpt: IRepF02) => rpt.LC === this.state.dato),
        tempPeriodo: this.state.periodo,
        tempZonal: this.state.zonal,
        tempReportes: reportes
      })
    }

    this.setState({
      select: 'lc',
      activar: false,
      dato: '',
    })
  }

  async handleAlumno(){

    if(!this.state.periodo) return
    if(!this.state.dato) return

    this.setState({
      activar: true
    })

    const { reportes , auth} = await BBRptf02.FindxPeriodo(this.state.periodo)

    if(!auth){
      sessionStorage.clear()
      this.props.history.push('/login')
    }


    this.setState({
      reportes: reportes.filter((rpt:IRepF02)=> rpt.NRC === this.state.dato),
      activar:false
    })

  }

  render() {

    const listPeriodos = this.state.periodos.map((periodo:IBbTerm,i:number)=>
      <option value={periodo.periodo} key={periodo.periodo }>{periodo.name}</option>
    )

    const listZonales = this.state.zonales.map((zonal:IOraZonales, i:number)=>
      <option value={zonal.FILTRO} key={zonal.FILTRO}> {zonal.ZONAL}</option>
    )

    return (
    <ContenidoBody title={'REPORTE REPF02'} link={'Reporte'} page={'REPF02'}>
        <div className="row justify-content-md-center">
          <div className="col-md-9">
            <div className="card direct-chat direct-chat-warning">
              <div className="card-header">
                <h3 className="card-title">
                  <span>
                    {this.state.activar
                      ? <i className="fas fa-spinner fa-spin"></i>
                      : <i className="fas fa-journal-whills"></i>}
                  </span> Consulta - REPORTE REPF02
                </h3>
                <div className="card-tools">
                  {/*Registros:*/}
                  <span data-toggle="tooltip"
                    title="3 New Messages"
                    className="badge badge-warning">
                  </span>
                  <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus" />
                  </button>
                  <button type="button" className="btn btn-tool" data-toggle="tooltip" title="Contacts" data-widget="chat-pane-toggle">
                    <i className="fas fa-comments" />
                  </button>
                </div>
              </div>
              <div className="card-body" style={{ margin: "10px 0px " }}>
                <button className="btn btn-app" onClick={this.handleNrc} >
                  <span className="badge bg-orange"></span>
                  <i className="fa fa-book"></i> NRC
                </button>
                <button className="btn btn-app" onClick={this.handleLc} >
                  <span className="badge bg-orange"></span>
                  <i className="far fa-books"></i> LISTA CRUZADA
                </button>
                <button className="btn btn-app" onClick={this.handleAlumno} >
                  <span className="badge bg-orange"></span>
                  <i className="fas fa-chalkboard-teacher"></i> ALUMNO
                </button>
              </div>
              <div className="card-footer d-flex flex-row">
                <div className="input-group mb-3 mr-3">
                      <select className='form-control'
                              name='periodo'
                              onChange={this.handleSelect}
                              value={this.state.periodo}
                      >
                        <option value="0">---PERIODO---</option>
                        { listPeriodos }
                      </select>
                </div>
                <div className="input-group mb-3 mr-3">
                  <select className='form-control'
                    name='zonal'
                    onChange={this.handleSelect}
                    value={this.state.zonal}
                  >
                    <option value="0">---Zonal---</option>
                    {listZonales}
                  </select>
                </div>
                <div className="input-group mb-3">
                  <input type="text"
                    name='dato'
                    minLength={2}
                    className="form-control"
                    placeholder=" NRC / LC "
                    aria-label=""
                    aria-describedby="basic-addon1"
                    value={this.state.dato}
                    onChange={this.handleInput}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-md-11">
            <div className="card direct-chat direct-chat-warning">
              <div className="card-header">
                <h3 className="card-title">
                  <span>
                    {this.state.activar
                      ? <i className="fas fa-spinner fa-spin"></i>
                      : <i className="fas fa-journal-whills"></i>}
                  </span> RESULTADO - REPORTE REPF01
                </h3>
              </div>
              <div className='card-body'>
                {
                  (this.state.select==='nrc' && this.state.reportes.length !==0)?
                  <Fragment>
                    <TableOracleRepF02a reportes={this.state.reportes} />
                    <br />
                    <TableOracleRepF02b reportes={this.state.reportes} />
                  </Fragment>
                  :
                  <></>
                }
              </div>
            </div>
          </div>
        </div>
    </ContenidoBody>
    )
  }
}

export default RptF02

