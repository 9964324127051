import {  Ordenar, OrdenarReverse, removeDuplicados } from "../../lib/source"
import { IMatricula } from "../../models/Grafica/Matriculas.model"

class CtrlDataMatriculas{

    async getDataMaticula(){

        let matriculas:IMatricula[] = []

        await fetch('/data/JSON_Matricula.json')
            .then((db) => db.json())
            .then((data) => {
            matriculas = data
        })

        return matriculas

    }

    async getMatricula(){
        return await this.getDataMaticula()
    }

    async getMatriculas_Zonales(){

        const data:IMatricula[] = await this.getMatricula()

        let zonales:IMatricula[] =  removeDuplicados(data,'Zonal')

        return zonales.length > 0 ? zonales : []

    }

    getMatriculasxZonalId_Tperiodo(data:IMatricula[],zonalId:string ){

        let matr:IMatricula[] = data.filter((tperiodo:IMatricula) => tperiodo.Zonal === zonalId)
                                             .filter((tperiodo:IMatricula)=> tperiodo.PartePeriodo === 'TODOS')
                                             .filter((tperiodo:IMatricula)=> tperiodo.TipoPeriodo !== 'TODOS')
                                             .filter((tperiodo:IMatricula)=> tperiodo.calendarizacion === 'TODOS')
                                             .filter((zonal:IMatricula)=> zonal.Campus === 'TODOS')

        return matr.length > 0 ? OrdenarReverse(matr,'TipoPeriodo') : []
    }

    getMatriculasxZonalId_Tperiodo_tp(data: IMatricula[], zonalId: string, tperiodo:string) {

        let matr: IMatricula[] = data.filter((tperiodo: IMatricula) => tperiodo.Zonal === zonalId)
            .filter((tperiodo: IMatricula) => tperiodo.PartePeriodo === 'TODOS')
            .filter((tperiodo: IMatricula) => tperiodo.TipoPeriodo !== 'TODOS')
            .filter((tperiodo: IMatricula) => tperiodo.calendarizacion === 'TODOS')
            .filter((zonal: IMatricula) => zonal.Campus === 'TODOS')

        const tp = matr.filter((m:IMatricula) => m.TipoPeriodo === tperiodo)

        return tp.length > 0 ? tperiodo : 'TODOS'
    }

    getMatriculasxZonalId_Pperiodos(data: IMatricula[], zonalId: string) {

        let matr: IMatricula[] = data.filter((tperiodo: IMatricula) => tperiodo.Zonal === zonalId)
            .filter((tperiodo: IMatricula) => tperiodo.PartePeriodo === 'TODOS')
            .filter((tperiodo: IMatricula) => tperiodo.TipoPeriodo !== 'TODOS')
            .filter((tperiodo: IMatricula) => tperiodo.calendarizacion === 'TODOS')
            .filter((zonal: IMatricula) => zonal.Campus === 'TODOS')

        return matr.length > 0 ? Ordenar(matr, 'PartePeriodo') : []
    }

    getMatriculasxZonalIdxTperiodo_Pperiodo(data:IMatricula[],zonalId:string,tperiodo:string, pperiodo:string){


        let matr:IMatricula[] = data.filter((tperiodo:IMatricula) => tperiodo.Zonal === zonalId)
                                    .filter((pp:IMatricula)=> pp.TipoPeriodo === tperiodo)
                                    .filter((pp:IMatricula)=> pp.PartePeriodo !== 'TODOS')
                                    .filter((zonal:IMatricula)=> zonal.Campus === 'TODOS')
                                    .filter((tperiodo:IMatricula)=> tperiodo.calendarizacion === 'TODOS')


        return matr.length > 0 ?   OrdenarReverse(matr,'PartePeriodo')  : []
    }

    getMatriculasxZonalIdxTperiodo_Pperiodo_pp(data: IMatricula[], zonalId: string, tperiodo: string, pperiodo: string) {


        let matr: IMatricula[] = data.filter((tperiodo: IMatricula) => tperiodo.Zonal === zonalId)
            .filter((pp: IMatricula) => pp.TipoPeriodo === tperiodo)
            .filter((pp: IMatricula) => pp.PartePeriodo !== 'TODOS')
            .filter((zonal: IMatricula) => zonal.Campus === 'TODOS')
            .filter((tperiodo: IMatricula) => tperiodo.calendarizacion === 'TODOS')

        const pp = matr.filter((m: IMatricula) => m.PartePeriodo === pperiodo)


        return pp.length > 0 ? pperiodo :  'TODOS'
    }

    getMatriculasxTperiodoxPperiodoxCalendarizacion_ZonalId(data:IMatricula[],tperiodo:string, pperiodo:string, calendarizacion:string){


        let matr:IMatricula[] = data.filter((zonal:IMatricula)=> zonal.TipoPeriodo === tperiodo)
                                    .filter((zonal:IMatricula)=> zonal.PartePeriodo === pperiodo)
                                    .filter((zonal:IMatricula)=> zonal.calendarizacion === calendarizacion)
                                    .filter((zonal:IMatricula)=> zonal.Campus === 'TODOS')
                                    .filter((zonal:IMatricula)=> zonal.Zonal !== 'TODOS')
                                    .filter((zonal:IMatricula)=> zonal.Zonal !== 'ep')

        return matr.length > 0 ? Ordenar(matr,'Zonal') : []
    }


}


const  ctrlDataMatriculas = new CtrlDataMatriculas()
export default ctrlDataMatriculas