import { createContext } from 'react'
import { IUser } from '../models/user.model'


interface IAuthContext  {
    user:             IUser|string|null
    authentication:   Boolean
    /*Login:          any
    /Logout:          any
    isLogget:         any*/
    isLogged?:        ()=>Boolean
   }

   const defaultState={
     user:            null,
     authentication:  false

   }

const AuthContext = createContext<Partial<IAuthContext>>(defaultState)
// const AuthContext = React.createContext<IAuthContext>(defaultState)
  export default AuthContext

   /*

const AuthContext = createContext<Partial<IAuthContext>>({})

 export default AuthContext

 */

