import { IKpiSinInstructores } from "../../models/Grafica/PkiSinInstructor.model"

class CtrlDataPkiSinInstructor {

  async getDataPkiIniciados() {

    let datos: IKpiSinInstructores[] = []

    await fetch('/data/JSON_KPI_SinInstructor_iniciados.json')
      .then((db) => db.json())
      .then((data) => {
        datos = data

      })

    return datos
  }

  async getDataPkiProximos() {

    let datos: IKpiSinInstructores[] = []

    await fetch('/data/JSON_KPI_SinInstructor_proximos.json')
      .then((db) => db.json())
      .then((data) => {
        datos = data

      })

    return datos
  }

  async getPkiSinInstructorIniciados() {
    return await this.getDataPkiIniciados() as IKpiSinInstructores[]
  }

  async getPkiSinInstructorProximos() {
    return await this.getDataPkiProximos() as IKpiSinInstructores[]
  }

}

const ctrlDataPkiSinInstructor = new CtrlDataPkiSinInstructor()
export default ctrlDataPkiSinInstructor