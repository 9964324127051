
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import AuthSeguimientoContext from '../../context/SeguimientoContext'
import ctrlControlAnexo03 from '../../controller/Seguimiento/ctrlControlAnexo03.ctrl'
import ctrlSeguimientoCursos from '../../controller/Seguimiento/ctrlCursoSeguimiento.ctrl'
import { ICursoSeguimiento } from '../../models/Seguimiento/cursoseguimiento.model'



interface IProps extends RouteComponentProps{

}

interface IState {
    courseId:string
    instructorId:string
    especialistaId:string
    semana:string
    codigo: string
    controlAlumno?:ICursoSeguimiento
    tipo:string
    data:any
}

/*
PRECESENCIAL
http://localhost:3000/#/seguimiento/anexo03?courseId=202220-NSID-512-TEC-NRC_26431&tipo=PRE&instructorId=001481519&especialistaId=000794385&semana=2022-33&codigo=f60f25e409cade74ac7c9944dba36558

VIRTUAL
http://localhost:4000/#/seguimiento/anexo03?courseId=202220-NSID-512-TEC-NRC_26431&tipo=VIR&instructorId=001481519&especialistaId=000794385&semana=2022-33&codigo=f60f25e409cade74ac7c9944dba36558

https://www.gcabana.com/#/seguimiento/anexo03?courseId=202220-NSID-512-TEC-NRC_26431&tipo=PRE&instructorId=001481519&especialistaId=000794385&semana=2022-33&codigo=f60f25e409cade74ac7c9944dba36558
/*

codigo=5ee6b1f968380a11a8d03465e703cc78


"pk1_curso":240730,
"cod_curso":"202220-NSID-512-TEC-NRC_26431",
"nrc":"26431",
"inicio":"2022-08-11T00:00:00",
especialistaId=000794385
"fin":"2022-09-13T00:00:00",
"curso":"INSPECCIONES DE SEGURIDAD",
"curso_cerrado":"N",
"pk1_usuario":364096,
"id_inst":"001481519",
"instructor":"MANRIQUE TRUJILLO, DIEGO FABRIZIO",
"inscritosbb":20,
"url_teams":"https://teams.microsoft.com/l/meetup-join/19%3ameeting_MmYyMTViMWYtOTE3YS00ZTIzLTk2MDQtOGYxODJhOTM2YTBi%40thread.v2/0?context=%7b%22Tid%22%3a%22b4a40545-7779-4b38-aff7-1f1738f80840%22%2c%22Oid%22%3a%223415902e-ec86-4c13-b55f-6fc11eeae0ca%22%7d",
"curso_horario":"17:00-19:15"},
*/
export default class Seguimiento extends Component<IProps,IState> {

    static contextType = AuthSeguimientoContext
   
    constructor(props:IProps){
        super(props)

        const { search } = this.props.location
        const query = new URLSearchParams(search)

        this.state ={
            courseId        :query.get('courseId') ?? '',
            instructorId    :query.get('instructorId') ?? '',
            especialistaId  :query.get('especialistaId') ?? '',
            semana          :query.get('semana') ?? '',
            codigo          :query.get("codigo") || '',
            tipo            :query.get("tipo") || "VIR",
            data:false
        }


       
    }

    async componentDidMount(){

      if(  this.state.courseId === '' || 
            this.state.instructorId === '' ||
            this.state.especialistaId === '' ||
            this.state.semana === '' || 
            this.state.codigo === ''
           
          ){
            
          this.props.history.push('/NoValidate')
      }
      else{   
        this.setState({
            controlAlumno: await ctrlSeguimientoCursos.getValidar(this.state.courseId,this.state.instructorId,this.state.codigo),
           
        })

        
        if (this.state.controlAlumno?.cod_curso){
            const { anexo03 } = await ctrlControlAnexo03.ValidarFile(this.state.courseId,this.state.semana)
            
            const {addSeguimiento}= this.context
            addSeguimiento!(this.state.courseId,this.state.instructorId,this.state.especialistaId,this.state.semana,this.state.tipo)
            
            /**Registro ya realizado
              * anexo03 existe que muestre la ficha
            */  
            
            if(anexo03.length > 0){ 
              this.props.history.push('/seguimiento/reporte')
            }
            else{
              this.props.history.push('/seguimiento/ficha')
            }
           

        }else{
            this.props.history.push("/sindatos") 
        }
      }
    }  
 
  render() {
    return (
      <div>
        { 
          
        }
      </div> 
    )
  }
}

