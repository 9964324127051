import React, { Component, Fragment } from 'react'
import ctrlMaxTime from '../controller/Graficas/CtrlMaxTime.ctrl'

import { FechaActual_Mes } from '../lib/source'
import { IMatricula } from '../models/Grafica/Matriculas.model'


import Etiqueta from './Etiqueta'

interface IProps {
  tperiodo:string
  reportes:IMatricula[]
  zonal:string
  pperiodo:string
  calendarizacion:string
}
interface IState {
  fecha:Date

}

export default class TarjetaH extends Component<IProps, IState> {

  constructor(props:IProps){
    super(props)

    this.state = {
      fecha: new Date()
    }
  }

  async componentDidMount(){
    this.setState({
      fecha: await ctrlMaxTime.getFechaHoraMaxTime(),

    })
  }


  render() {
   
    let Reporte:IMatricula[] = []


    Reporte = this.props.reportes.filter((rpt: IMatricula) => rpt.Zonal === this.props.zonal)
                                 .filter((rpt: IMatricula) => rpt.PartePeriodo === this.props.pperiodo)
                                 .filter((rpt: IMatricula) => rpt.TipoPeriodo === this.props.tperiodo)
                                 .filter((rpt: IMatricula) => rpt.calendarizacion === this.props.calendarizacion)
                                 .filter((rpt: IMatricula) => rpt.Campus === 'TODOS')

    const dato:IMatricula = Reporte[0]
   
    return (
      <Fragment>
        <div className="row">
            <div className="col-lg-12 col-md-6 col-12">
                <Etiqueta   icon="fas fa-user-graduate"
                            mostrar={dato? dato.Porc_Mat : 0}
                            text='Porcentaje de matrículas en Blackboard'
                            background={dato? dato.Color_Mat : null}
                            letra={dato? dato.Color_Letra : null}
                            equivalente={  '%'}
                />
            </div>
            <div className="col-lg-12 col-md-6 col-12">
                <Etiqueta   icon="fas fa-users"
                            mostrar={dato? dato.matricula_sinfo : 0}
                            text={'Matrículas en SINFO'}
                            background='#1ca4fc'
                />
            </div>
            <div className="col-lg-12 col-md-6 col-12">
                <Etiqueta   icon="far fa-users-slash"
                            mostrar={dato? dato.Mat_Pendientes_Etiqueta : 0}
                            text={'Matrículas pendientes en Blackboard' }
                            background='#1ca4fc'
                />
            </div>
            <div className="col-lg-12 col-md-6 col-12">
                <Etiqueta   icon="fad fa-calendar-alt"
                            mostrar={dato? dato.DiasSinMigrar : 0}
                            text='Promedio de días sin migrar'
                            background='#1ca4fc'
                />
            </div>
            <div className="col-lg-12 col-md-6 col-12">
                <Etiqueta   icon="fas fa-users"
                            mostrar={dato? dato.Matriculas_24h : 0}
                            text={'Matrículas en Blackboard el ' + FechaActual_Mes(this.state.fecha)}
                            background='#1ca4fc'
                />
            </div>
        </div>
      </Fragment>
    );
  }
}

