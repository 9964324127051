import React, { Component, Fragment } from 'react'
import { removeDuplicados } from '../../lib/source'
import { IRepF02 } from '../../models/oracle/repf02.model'

interface IProps {
  reportes:IRepF02[]
}
interface IState {

}

export default class TableOracleRepF02a extends Component<IProps, IState> {

  render() {

    const tbody = removeDuplicados(this.props.reportes, 'NRC').map((rpt:IRepF02,i:number)=>
    <Fragment key={rpt.PERIODO + '-' + i.toString() }>
            <tr>
              <td align='center' > {rpt.PERIODO} </td>
              <td align='center' > {rpt.ZONAL} </td>
              <td align='center'  colSpan={2}> {rpt.COD_CURSO} </td>
              <td align='center' > {rpt.REGISTRADOS_PADRE_SINFO} </td>
              <td align='center' > {rpt.APTOS_EN_SINFO} </td>
              <td align='justify' rowSpan={2}>
                {rpt.IND_OBSERVACIONES? rpt.IND_OBSERVACIONES : <></>}
                {rpt.FRECUENCIA       ? ' '+rpt.FRECUENCIA : <></>}
                {rpt.RANGO_ALUMNOS    ? ' '+rpt.RANGO_ALUMNOS : <></>}
              </td>
            </tr>
            <tr>
              <td align='center' > {rpt.PARTE_PERIODO} </td>
              <td align='center' > {rpt.CAMPUS} </td>
              <td align='center' > {rpt.NRC} </td>
              <td align='center' > {rpt.LC} </td>
              <td align='center' colSpan={2}> {rpt.CANTIDAD_ALUMNOS_REGISTRADOS}</td>
            </tr>
    </Fragment>

    )

    const tbodyInst = removeDuplicados(this.props.reportes, 'ID_INST').map((rpt: IRepF02, i: number) =>
              <tr key={'periodo-inst'+i.toString()}>
                <td align='center'>{ rpt.ID_INST }</td>
                <td align='center'>{ rpt.CORREO_INSTRUCTOR }</td>
                <td align='justify'>{ rpt.INSTRUCTOR }</td>
                <td align='center'>
                  { rpt.INSTRUCTOR_HABILITADO ? rpt.INSTRUCTOR_HABILITADO :<></> }
                  { rpt.TIENE_INSTRUCTOR ? rpt.TIENE_INSTRUCTOR : <></> }
                  { rpt.EXCESO_HORAS_ASIGNADAS ? rpt.EXCESO_HORAS_ASIGNADAS : <></> }
                </td>
              </tr>
    )

    return (
      <Fragment>
        <table className='table table-sm table-bordered  w-100'>
          <thead className='bg-primary text-white'>
            <tr>
              <td align='center' width='10%' >PERIODO/ PP</td>
              <td align='center' width='25%'>ZONAL CAMPUS</td>
              <td align='center' width='15%' colSpan={2} >COD CURSO</td>
              <td align='center' width='10%'>INSCRITOS</td>
              <td align='center' width='10%'>APTOS</td>
              <td align='center' >OBSERVACIONES</td>
            </tr>
          </thead>
          <tbody>
            { tbody }
          </tbody>
        </table>
        <br />
        <table className='table table-sm table-bordered w-100'>
          <thead className='bg-purple text-white'>
            <tr>
              <td align='center' width='15%'>COD INSTRUCTOR</td>
              <td align='center' width='20%'>EMAIL</td>
              <td align='center' width='%'>INSTRUCTOR</td>
              <td align='center' width='30%'>OBSERVACIONES</td>
            </tr>
          </thead>
          <tbody>
            { tbodyInst}
          </tbody>
        </table>
      </Fragment>
    )
  }
}
