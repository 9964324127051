//import {  FC, useEffect, useMemo, useState  } from 'react'

import React, { Component } from 'react'
import AuthLtiContext from './AuthLtiContext'

interface IProps{}

interface IState{
    ltik:       string
    user:       string
    courseId:   string
    setUpdate?: Function
}

export default class AuthLtiProvider extends Component<IProps,IState> {

    constructor(props:IProps){
        super(props)

        this.state ={ 
            ltik:     '',
            user:     '',
            courseId: '',
            
        }

        this.handleUpdate = this.handleUpdate.bind(this)
        
    }

    

    handleUpdate(ltik:string,user:string, courseId:string){

        this.setState({
            ltik,
            user,
            courseId
        })
        
       
    }
  

    render() {
        return (
        <AuthLtiContext.Provider value={
            {
                ...this.state,
                setUpdate: this.handleUpdate
            }
        }>
            {this.props.children}
        </AuthLtiContext.Provider>
        )
    }
}


/*
const AuthLtiProvider:FC= (props)=> {

    const [user, setUser] = useState<string>('')
    const [ltik, setLtik] = useState<any>()
    const [courseId, setCourseId ]  = useState<string>('')

    useEffect(()=>{
        const frm = ()=>{
            //############
            console.log('eff') 
            setLtik(ltik);
        }

        frm()
    },[ltik])
 

    const isLogged = ()=> (!!ltik)? true : false

    const setUpdate = (data:any, data1:string, data2:string)=>{
        console.log('provider');
       
        setLtik(data)

        console.log(data)
        console.log(ltik);
    }



    const value = useMemo(()=>{
        return({
            user,
            ltik,
            courseId,
            isLogged ,
            
        })
    },[user,ltik, courseId, isLogged ])


  return (
    <AuthLtiContext.Provider
        value={value}
    >
        {props.children}
    </AuthLtiContext.Provider>
  )
}

export default AuthLtiProvider
*/