import {   PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import React, { Component, Fragment } from 'react'
import {  RouteComponentProps } from 'react-router-dom';
import DocumentPDF from '../../component/Seguimiento/DocumentPDF';
import DocumentPDFInst from '../../component/Seguimiento/DocumentPDFInst';
import DocumentPDFPre from '../../component/Seguimiento/DocumentPDFPre';
import AnexoSchema from '../../context/AnexoContext';
import ctrlControlAnexo03 from '../../controller/Seguimiento/ctrlControlAnexo03.ctrl';
import ctrlSeguimientoCursos from '../../controller/Seguimiento/ctrlCursoSeguimiento.ctrl';
import { ObtenerFecha } from '../../lib/source';
import { IAnexo03 } from '../../models/Seguimiento/anexo03.model';

import '../../styles/style.css'

interface IProps extends RouteComponentProps {

}

interface IState {

  anexo:IAnexo03
  active:boolean
}

export default class ReporteAnexoView extends Component<IProps, IState> {
   static contextType = AnexoSchema

  constructor(props:IProps){
    super(props)

    this.state = {
   
       anexo:{
              periodo:'', 
              nrc:'',
              lc:'',
              tipo:'',
              curso:'',
              id_inst:'',
              instructor:'',
              zonal:'',
              campus:'',
              cod_curso:'',
              supervisorId:'',
              supervisor:'',
              alumnosbb:0,
              alumnos_asiste: 0,
              inicio:new Date(),
              fin:new Date(),
              curso_cerrado:'',
              pregunta1:'',
              pregunta2:'',
              pregunta3:'',
              pregunta4:'',
              pregunta5:'',
              pregunta6:'',
              pregunta7:'',
              pregunta8:'',
              pregunta9:'',
              pregunta10:'',
              pregunta11:'',
              pregunta12:'',
              pregunta13:'',
              pregunta14:'',
              pregunta15:'',
              pregunta16:'',
              description1:'',
              description2:'',
              description3:'',
              description:'',
              statusInst:false
            
       },
       active:true
    }


 //   window.location.hash = "no-back-button"
 //   window.location.hash = "Again-No-back-button"

  }

  async componentDidMount(){

    this.setState({active:true})

    const anexoSchema = this.context

     const supervisor:string = await ctrlSeguimientoCursos.getEspecialistaSeguimientoxId_Name(anexoSchema.id_seguimiento)

    
    if(anexoSchema.statusInst){

      const {details} = await ctrlControlAnexo03.getDetails(anexoSchema._id)

      const _anexo: IAnexo03 ={
                        periodo:anexoSchema.periodo,
                        nrc:anexoSchema.nrc,
                        lc:anexoSchema.nrc,
                        curso:anexoSchema.cod_curso,
                        tipo:anexoSchema.tipo,
                        id_inst:anexoSchema.id_instructor,
                        instructor: anexoSchema.instructor,
                        zonal:anexoSchema.zonal,
                        campus:anexoSchema.campus,
                        cod_curso:anexoSchema.cod_curso,
                        supervisorId:anexoSchema.id_seguimiento,
                        supervisor,
                        alumnosbb:anexoSchema.alumnosbb,
                        alumnos_asiste: parseInt(anexoSchema.asistencia),
                        inicio:new Date(anexoSchema.createdAt) ?? new Date(),
                        fin:new Date(anexoSchema.createdAt) ?? new Date(),
                        curso_cerrado:anexoSchema.curso_cerrado,
                        pregunta1:details.find((d:any) => d.question ==='1').answer?? '4',
                        pregunta2:details.find((d:any) => d.question ==='2').answer?? '4',
                        pregunta3:details.find((d:any) => d.question ==='3').answer?? '4',
                        pregunta4:details.find((d:any) => d.question ==='4').answer?? '4',
                        pregunta5:details.find((d:any) => d.question ==='5').answer?? '4',
                        pregunta6:details.find((d:any) => d.question ==='6').answer?? '4',
                        pregunta7:details.find((d:any) => d.question ==='7').answer?? '4',
                        pregunta8:details.find((d:any) => d.question ==='8').answer?? '4',
                        pregunta9:details.find((d:any) => d.question ==='9').answer?? '4',
                        pregunta10:details.find((d:any) => d.question ==='10').answer?? '4',
                        pregunta11:details.find((d:any) => d.question ==='11').answer?? '4',
                        pregunta12:details.find((d:any) => d.question ==='12').answer?? '4',
                        pregunta13:details.find((d:any) => d.question ==='13').answer?? '4',
                        pregunta14:details.find((d:any) => d.question ==='14').answer?? '4',
                        pregunta15:details.find((d:any) => d.question ==='15').answer?? '4',
                        pregunta16:details.find((d:any) => d.question ==='16')? details.find((d:any) => d.question ==='16').answer : '4',
                        description1:details.find((d:any) => d.question ==='description1').answer?? '4',
                        description2:details.find((d:any) => d.question ==='description2').answer?? '4',
                        description3:details.find((d:any) => d.question ==='description3').answer?? '4',
                        description:anexoSchema.descripcion,
                        statusInst:anexoSchema.statusInst
                      }

  
      this.setState({
        anexo:_anexo,
      })
      
    }
    else{
      
      const _anexo: IAnexo03 ={
                        periodo:anexoSchema.periodo,
                        nrc:anexoSchema.nrc,
                        lc:anexoSchema.nrc,
                        curso:anexoSchema.cod_curso,
                        tipo:anexoSchema.tipo,
                        id_inst:anexoSchema.id_instructor,
                        instructor: anexoSchema.instructor,
                        zonal:anexoSchema.zonal,
                        campus:anexoSchema.campus,
                        cod_curso:anexoSchema.cod_curso,
                        supervisorId:anexoSchema.id_seguimiento,
                        supervisor,
                        alumnosbb:anexoSchema.inscritosbb,
                        alumnos_asiste: parseInt(anexoSchema.asistencia),
                        inicio:new Date(anexoSchema.createdAt) ?? new Date(),
                        fin:new Date(anexoSchema.createdAt) ?? new Date(),
                        curso_cerrado:anexoSchema.curso_cerrado,
                        pregunta1: '4',
                        pregunta2: '4',
                        pregunta3: '4',
                        pregunta4: '4',
                        pregunta5: '4',
                        pregunta6:'4',
                        pregunta7:'4',
                        pregunta8:'4',
                        pregunta9:'4',
                        pregunta10: '4',
                        pregunta11: '4',
                        pregunta12: '4',
                        pregunta13: '4',
                        pregunta14: '4',
                        pregunta15: '4',
                        pregunta16: '4',
                        description1: anexoSchema.descripcion,
                        description2: '',
                        description3: '',
                        description:anexoSchema.descripcion,
                        statusInst:anexoSchema.statusInst
                      }

  
      this.setState({
        anexo:_anexo,
      })
    }


  this.setState({
    active:false
  })
} 

handleRpta(rpt: string) {
    let siCumple: number = 0;

    siCumple = this.state.anexo.pregunta1 === rpt ? 1 : 0;
    siCumple += this.state.anexo.pregunta2 === rpt ? 1 : 0;
    siCumple += this.state.anexo.pregunta3 === rpt ? 1 : 0;
    siCumple += this.state.anexo.pregunta4 === rpt ? 1 : 0;
    siCumple += this.state.anexo.pregunta5 === rpt ? 1 : 0;
    siCumple += this.state.anexo.pregunta6 === rpt ? 1 : 0;
    siCumple += this.state.anexo.pregunta7 === rpt ? 1 : 0;
    siCumple += this.state.anexo.pregunta8 === rpt ? 1 : 0;
    siCumple += this.state.anexo.pregunta9 === rpt ? 1 : 0;
    siCumple += this.state.anexo.pregunta10 === rpt ? 1 : 0;
    siCumple += this.state.anexo.pregunta11 === rpt ? 1 : 0;
    siCumple += this.state.anexo.pregunta12 === rpt ? 1 : 0;
    siCumple += this.state.anexo.pregunta13 === rpt ? 1 : 0;
    siCumple += this.state.anexo.pregunta14 === rpt ? 1 : 0;
    siCumple += this.state.anexo.pregunta15 === rpt ? 1 : 0;
    siCumple += this.state.anexo.pregunta16 === rpt ? 1 : 0;
    return siCumple;
  }

  handleResultado() {
    const rst =
    /*  ((this.handleRpta("1") * 2 +
        this.handleRpta("2") * 1 +
        this.handleRpta("3") * 0) *
        100) /
      ((15 - this.handleRpta("4")*2) )*/

      this.handleValor()*100 / this.handleMaximoPuntaje()

    return rst.toFixed(0);
  }

    handleValor(){

    const rst =
      (this.handleRpta("1") * 2) +
      (this.handleRpta("2") * 1) +
      (this.handleRpta("3") * 0)

    return rst

  }

  handleMaximoPuntaje() {

    const rst = (16 - this.handleRpta("4"))*2
    return rst
  }


  render() {
    return (
      <Fragment>
        {
          !this.state.active?
            <div style={{ width: "80%", marginLeft: "10%"}}>
                  <div className="error-content w-100">
                    <div className="row justify-content-center mt-2">
                      <div className="col-lg-6 col-12">
                        <div className="card">
                          <div className="card-body">
                            <button className="btn btn-app" 
                                onClick={()=>{
                                  this.props.history.push('/seguimiento/supervision')
                                }}
                            >
                              <i className="fa-solid fa-backward"></i>{" "} Retornar
                            </button>
                            {
                            !this.state.active ? 
                              this.state.anexo.statusInst ?
                                this.state.anexo.tipo ==='VIR'?
                                  <PDFDownloadLink
                                    document={<DocumentPDF anexo={this.state.anexo} />}
                                    fileName={
                                      this.state.anexo.cod_curso +
                                      "_" +
                                      ObtenerFecha(new Date())
                                    }
                                  >
                                    <button className="btn btn-app">
                                      <i className="far fa-file-pdf font-weight-bold"></i>{" "} DESCARGAR
                                    </button>
                                  </PDFDownloadLink>
                                :
                                  <PDFDownloadLink
                                      document={<DocumentPDFPre anexo={this.state.anexo} />}
                                      fileName={
                                        this.state.anexo.cod_curso +
                                        "_" +
                                        ObtenerFecha(new Date())
                                      }
                                    >
                                      <button className="btn btn-app">
                                        <i className="far fa-file-pdf font-weight-bold"></i>{" "} DESCARGAR
                                      </button>
                                    </PDFDownloadLink>

                              :
                              
                                <PDFDownloadLink
                                    document={<DocumentPDFInst anexo={this.state.anexo} />}
                                    fileName={
                                      this.state.anexo.cod_curso +
                                      "_" +
                                      ObtenerFecha(new Date())
                                    }
                                  >
                                    <button className="btn btn-app">
                                      {
                                        this.state.active?
                                        <>
                                          <i className="fa-solid fa-spinner fa-spin"></i> GENERANDO PDF...
                                        </>
                                        :
                                        <>
                                          <i className="far fa-file-pdf font-weight-bold"></i>{" "} DESCARGAR
                                        </>
                                      }
                                      
                                    </button>
                                </PDFDownloadLink>
                            :
                                <button className='btn bbtn btn-app'>
                                  <i className="fa-solid fa-spinner fa-spin"></i> GENERANDO PDF...
                                </button>

                          }
                          </div>
                        </div>
                      </div>
                    </div>  
                  </div>
                  <PDFViewer 
                            className='PDFView'
                            showToolbar={false}
                  >
                    {
                      this.state.anexo.statusInst ?
                        this.state.anexo.tipo ==='VIR'?
                          <DocumentPDF anexo={this.state.anexo} />
                        :
                          <DocumentPDFPre anexo={this.state.anexo} />
                      :
                        <DocumentPDFInst anexo={this.state.anexo} />
                    }
                  </PDFViewer>
            </div>          
          :
            <div>

            </div>
        }
      </Fragment>
    );
  }
}
