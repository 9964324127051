import React, { Component, Fragment } from 'react'
import { RouteComponentProps } from 'react-router'

import { IMatricula } from '../models/Grafica/Matriculas.model'
import { IGZonal } from '../models/Grafica/zonal.model'

import '../styles/style.css'

import ctrlData from '../controller/Graficas/CtrlData.ctrl'
import { Ordenar, removeDuplicados } from '../lib/source'

import Navbar from '../component/Navbar'
import Tarjeta from '../component/Tarjeta'


import GraficaPendienteMatricula from '../component/Graficos/GraficaBarCharMotivoMatricula'
import { IPendienteMatricula } from '../models/Grafica/PendienteMatricula.model'
import GraficaBubblePendientes from '../component/Graficos/GraficaBubblePendiente'
import GraficaCharProgramas from '../component/Graficos/GraficaBarCharPrograma'
import { ITipoPeriodo } from '../models/Grafica/TipoPeriodo.model'
import Pestana from '../component/Pestana'
import GraficaApexRadarAntgMatricula from '../component/Graficos/GraficaApexRadarAntgMatricula'
import GraficoTablaResumenAntiguedad from '../component/Graficos/GraficoTablaResumenAntiguedad'

interface IProps extends RouteComponentProps{

}

interface IState {
  activar: boolean
  auth: Boolean
  zonal:string
  tperiodos:string
  pperiodos:string
  reportes:IMatricula[]
  pendienteMaticula:IPendienteMatricula[]
  TempReportes: IMatricula[]
  GraficaZonal: IMatricula[]
  GraficaCand: IMatricula[]
  Tarjeta1: IMatricula[]
  Zonales:IMatricula[]
  TPeriodos:IMatricula[]
  PPeriodos:IMatricula[]
  select :string
  vista:string
}

export default class Dashboard extends Component<IProps, IState> {

//  static contextType = AuthContext

  constructor(props: IProps) {
    super(props)

  //  const { search } = this.props.location
 //   const query = new URLSearchParams(search)

    this.state = {
          activar: false,
          auth: true,
          zonal: 'TODOS',
          pperiodos:'TODOS',
          //reportes: [],
          //TempReportes: [],
          //pendienteMaticula:[],
          reportes:[], //ctrlData.getMatriculas(),
          TempReportes: [], //ctrlData.getMatriculas(),
          pendienteMaticula: ctrlData.getPendienteMatricula(),
          GraficaCand: [],
          GraficaZonal: [],
          Tarjeta1:[],
          Zonales:[],
          TPeriodos:[],
          PPeriodos:[],
          tperiodos:'TODOS',
          select:'TODOS',
          vista:'grafica'

        }

    this.handleZonal = this.handleZonal.bind(this)
    this.handleTipoPrograma = this.handleTipoPrograma.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.handleGrafica = this.handleGrafica.bind(this)
    this.handleSelect = this.handleSelect.bind(this)

  }

  async componentDidMount(){

    this.setState({
      pendienteMaticula: await ctrlData.getPendienteMatriculaFile(),
      reportes: await ctrlData.getMatriculasFile(),
      TempReportes: await ctrlData.getMatriculasFile(),
    })

    this.setState({
      GraficaZonal: this.state.TempReportes,
      Zonales:removeDuplicados(this.state.TempReportes,'Zonal'),
      TPeriodos: removeDuplicados(this.state.TempReportes,'TipoPeriodo'),

    })

  }

  componentDidUpdate(prevProps:IProps,prevState:IState){
    if (prevState.zonal !== this.state.zonal ){

      this.setState({
 //       tperiodos: 'TODOS',
 //       pperiodos:'TODOS',
        GraficaZonal: this.state.TempReportes
      })
    }

    if (prevState.tperiodos !== this.state.tperiodos) {

      this.setState({
        select: this.state.tperiodos,
  //      pperiodos:'TODOS',
        GraficaZonal: this.state.TempReportes
      })
    }

    if (prevState.pperiodos !== this.state.pperiodos ){

      const tperiodos = (this.state.pperiodos !== 'TODOS')?
                            removeDuplicados(this.state.TempReportes
                                          .filter((t:IMatricula) => t.PartePeriodo === this.state.pperiodos)
                                          .filter((t:IMatricula) => t.TipoPeriodo !== 'TODOS')
                                          ,'TipoPeriodo')[0].TipoPeriodo


                          :
                              'TODOS'


      this.setState({
        GraficaZonal: this.state.TempReportes,
        tperiodos
      })
    }

  }

  handleZonal(zonal:string){

    this.setState({
      zonal,
//      pperiodos:'TODOS',
//      tperiodos:'TODOS'
    })
  }

  handleTipoPrograma(tperiodos: string){

    this.setState({
      tperiodos,
    })

  }

  handleReset(reset:Boolean){

    this.setState({
      zonal: 'TODOS',
      reportes: [],//ctrlData.getMatriculas(),
      GraficaCand: [],
      GraficaZonal: this.state.TempReportes,
      Tarjeta1: [],
      PPeriodos:[],
      tperiodos: 'TODOS',
      pperiodos:'TODOS',
      select: 'TODOS',
    })
  }

  handleGrafica(vista:string){

    this.setState({
      vista,
    })

 //   this.handleReset(true)
  }

  handleSelect({ target }: React.ChangeEvent<HTMLSelectElement>) {

    if(target.name === 'zonal'){
      this.setState({
        zonal:target.value,
   //     pperiodos:'TODOS',
   //     tperiodos:'TODOS'
      })
    }
    else if (target.name ==='tperiodos')
      this.setState({
        tperiodos:target.value,
        pperiodos:'TODOS',
      })
    else if (target.name ==='pperiodos'){
      this.setState({
        pperiodos:target.value
      })
    }

  }

 render() {

  const listZonales = Ordenar(this.state.TempReportes.filter((zonal:IMatricula)=> zonal.TipoPeriodo === this.state.tperiodos)
                                                      .filter((zonal:IMatricula)=> zonal.PartePeriodo === this.state.pperiodos)
                                                      .filter((zonal:IMatricula)=> zonal.Zonal !== 'TODOS'),'Zonal')
                                                      .map((zonal:IMatricula,i :number)=>
                                                        ctrlData.getZonalxId_Name(zonal.Zonal).map((z:IGZonal,j:number)=>
                                                          <option value={zonal.Zonal}
                                                                  //selected={(zonal.Zonal==='TODOS')? true : false}
                                                                  key={'zonal-'+i.toString()}>
                                                            { z.departamento.toUpperCase() }
                                                          </option>
                                                        )
                                                      )

  const listTPeriodos = ctrlData.getPeriodos_TipoPeriodoxZonal(this.state.TempReportes,this.state.zonal)
                                .map((tperiodos:ITipoPeriodo,i :number)=>
                                                            <option value={tperiodos.TipoPeriodo}
                                                                    key={'tperiodo-'+i.toString()}
                                                            >
                                                              {tperiodos.tperiodo.toUpperCase()}
                                                            </option>
                                                          )

  const listPPeriodos = this.state.TempReportes.filter((pp:IMatricula)=> pp.TipoPeriodo === this.state.tperiodos)
                                               .filter((pp:IMatricula)=> pp.Zonal === this.state.zonal)
                                               .filter((pp:IMatricula)=> pp.PartePeriodo !== 'TODOS')
                                               .map((pp:IMatricula,i:number)=>
                                                <option value={pp.PartePeriodo}
                                                        //selected={(pp.PartePeriodo==='TODOS')? true : false}
                                                        key={'TipoPeriodo-'+i.toString()}
                                                >
                                                  {pp.PartePeriodo.toUpperCase()}
                                                </option>
                                                )

   return (
     <Fragment>
       <Navbar zonal={this.state.zonal} />
       <div className="row justify-content-md-center w-100">
         <div className="col-lg-12">
           <div
             className="card direct-chat direct-chat-warning"
             style={{ background: "rgba(238, 216, 209,.3)" }}
           >
             <div className="row mt-5">
               <div className="col-lg-9 col-md-12 col-sm-12 col-12">
                 <Pestana Vista={this.handleGrafica} />
                 <div className="row">
                   <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                     <div className="input-group mb-3">
                       <div className="input-group-prepend">
                         <label
                           className="input-group-text"
                           htmlFor="selectZonal"
                         >
                           Zonal
                         </label>
                       </div>
                       <select
                         className="custom-select"
                         id="selectZonal"
                         name="zonal"
                         onChange={this.handleSelect}
                         value={this.state.zonal}
                       >
                         <option value="TODOS">TODOS</option>
                         {listZonales}
                       </select>
                     </div>
                   </div>
                   <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                     <div className="input-group mb-3">
                       <div className="input-group-prepend">
                         <label
                           className="input-group-text"
                           htmlFor="selectZonal"
                         >
                           Programa
                         </label>
                       </div>
                       <select
                         className="custom-select"
                         id="selectTPeriodo"
                         name="tperiodos"
                         onChange={this.handleSelect}
                         value={this.state.tperiodos}
                       >
                         <option value="TODOS">TODOS</option>
                         {listTPeriodos}
                       </select>
                     </div>
                   </div>
                   <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                     <div className="input-group mb-3">
                       <div className="input-group-prepend">
                         <label
                           className="input-group-text"
                           htmlFor="selectZonal"
                         >
                           Periodo
                         </label>
                       </div>
                       <select
                         className="custom-select"
                         id="selectTPeriodo"
                         name="pperiodos"
                         onChange={this.handleSelect}
                         value={this.state.pperiodos}
                       >
                         <option value="TODOS">TODOS</option>
                         {listPPeriodos}
                       </select>
                     </div>
                   </div>
                 </div>
                 {this.state.vista === "grafica" ? (
                   <>
                     <div className="row">
                       <div className="col-lg-4">
                         <GraficaCharProgramas
                           reportes={this.state.GraficaZonal}
                           zonal={this.state.zonal}
                           tperiodo={this.state.tperiodos}
                           pperiodo={this.state.pperiodos}
                         />
                       </div>
                       <div className="col-lg-4">
                        {/* <GraficaCharPeriodos
                           reportes={this.state.GraficaZonal}
                           zonal={this.state.zonal}
                           tperiodo={this.state.tperiodos}
                           pperiodo={this.state.pperiodos}
                 />*/}
                       </div>
                       <div className="col-lg-4">
                         <GraficaPendienteMatricula
                           reportes={this.state.pendienteMaticula}
                           zonal={this.state.zonal}
                           tperiodo={this.state.tperiodos}
                           pperiodo={this.state.pperiodos}
                         />
                       </div>
                     </div>
                     <div className="row mt-2">
                       <div className="col-lg-6">
                         <GraficaBubblePendientes
                           reportes={this.state.reportes}
                           tperiodo={this.state.tperiodos}
                           pperiodo={this.state.pperiodos}
                           zonal={this.state.zonal}
                         />
                       </div>
                       <div className="col-lg-6">
                        {/* <GraficaBubbleMotivos
                           reportes={this.state.pendienteMaticula}
                           tperiodo={this.state.tperiodos}
                           pperiodo={this.state.pperiodos}
                           zonal={this.state.zonal}
                /> */}
                       </div>
                     </div>
                   </>
                 ) : (
                   <></>
                 )}
                 {this.state.vista === "tblPendientes" ? (
                   <>
                     <div className="row">
                       <div className="col-lg-9 col-md-12">
                         <div className="r">
                           <div className="col-12">
                             <h3 className="text-center">
                               Impedimentos de matrícula
                             </h3>
                           </div>
                         </div>
                         <div className="row">
                           <div className="col-12">
                         {/*    <GraficoTablaResumen
                               reportes={this.state.pendienteMaticula}
                               tperiodo={this.state.tperiodos}
                               pperiodo={this.state.pperiodos}
                               zonal = {this.state.zonal}
                               Zonal={this.handleZonal}
                             />*/}
                           </div>
                         </div>
                       </div>
                       <div className="col-lg-3 col-md-12">
                        {/* <GraficaApexRadarPendMatricula
                           reportes={this.state.pendienteMaticula}
                           zonal={this.state.zonal}
                           tperiodo={this.state.tperiodos}
                           pperiodo={this.state.pperiodos}
                 /> */}
                       </div>
                     </div>
                   </>
                 ) : (
                   <></>
                 )}
                 {this.state.vista === "tblAntiguedades" ? (
                   <>
                     <div className="row">
                       <div className="col-lg-9 col-md-12">
                         <div className="row">
                           <div className="col-12">
                             <h3 className="text-center">
                               Promedio de tiempo sin migrar (en días)
                             </h3>
                           </div>
                         </div>
                         <div className="row">
                           <div className="col-12">
                             <GraficoTablaResumenAntiguedad
                               reportes={this.state.pendienteMaticula}
                               zonal = {this.state.zonal}
                               tperiodo={this.state.tperiodos}
                               pperiodo={this.state.pperiodos}
                               Zonal={this.handleZonal}
                             />
                           </div>
                         </div>
                       </div>
                       <div className="col-lg-3 col-md-12">
                         <GraficaApexRadarAntgMatricula
                           reportes={this.state.pendienteMaticula}
                           zonal={this.state.zonal}
                           tperiodo={this.state.tperiodos}
                           pperiodo={this.state.pperiodos}
                         />
                       </div>
                     </div>
                   </>
                 ) : (
                   <></>
                 )}
               </div>
               <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                 <div className="row">
                   <div className="col-lg-12">
                     <div className="row">
                       <Tarjeta
                         tperiodo={this.state.tperiodos}
                         reportes={this.state.TempReportes}
                         zonal={this.state.zonal}
                         pperiodo={this.state.pperiodos}
                       />
                     </div>
                   </div>
                   <div className="col-lg-12">
                   {/*  <GraficaMaps
                       reportes={this.state.reportes}
                       tperiodo={this.state.tperiodos}
                       pperiodo={this.state.pperiodos}
                       zonal={this.state.zonal}
                       Zonal={this.handleZonal}
                 /> */}
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </Fragment>
   );
  }
}
