import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { /*BrowserRouter as Router,*/HashRouter } from 'react-router-dom'
//import AuthProvider from "./context/AuthProvider"
import reportWebVitals from './reportWebVitals'
//import PeriodoProvider from './context/PeriodoProvider'
import Anexo03Provider from './context/Anexo03Provider'
import AuthLtiProvider from './context/AuthLtiProvider';
import AuthSeguimientoProvider from './context/SeguimientoProvider';
import AnexoProvide from './context/AnexoProvide';

ReactDOM.render(
  <HashRouter >
      <AuthLtiProvider>
        <AuthSeguimientoProvider>
     {/*     <AuthProvider>
            <PeriodoProvider> */}
            <AnexoProvide>
              <Anexo03Provider>
                <App />
              </Anexo03Provider>
            </AnexoProvide>
        {/*    </PeriodoProvider>
          </AuthProvider>*/}
        </AuthSeguimientoProvider>
      </AuthLtiProvider>
  </HashRouter>

,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
