import React, { Component } from "react"
// import { AuthContext } from "../context/AuthProvider"

//import ctrlReporte from "../controller/Graficas/CtrlReportes.ctrl"
import { IFile } from "../models/Grafica/file.model"

import {Navbar as Navbarx, Nav} from "react-bootstrap"
import "react-bootstrap-submenu/dist/index.css"

import { ObtenerHoraZonaHoraria } from "../lib/source"
import { NavLink } from "react-router-dom"
import ctrlMaxTime from "../controller/Graficas/CtrlMaxTime.ctrl"
import ctrlColores from "../controller/Graficas/CtrlColores.ctrl"
import ctrlZonales from "../controller/Graficas/CtrlZonales.ctrl"
import AuthLtiContext from "../context/AuthLtiContext"



//import { CtrlJsonReadAnexo03 } from "../controller/Seguimiento/ctrlJsonReadAnexo03.ctrl"

interface IProps {
  zonal: string
  
}

interface IState {
  listaReporte: IFile[]
  listaReportes :IFile[]
  fecha: Date
  user:string
}



export default class Navbar extends Component<IProps, IState> {
  static contextType = AuthLtiContext

  constructor(props:IProps){
    super(props)

    this.state = {
      listaReportes:[],
      listaReporte:[],
      user:'',

      fecha:new Date()
    }
  }

  async componentDidMount(){

    const { user } = this.context


    this.setState({

      fecha: await ctrlMaxTime.getFechaHoraMaxTime(),
      listaReporte: this.state.listaReportes.filter((rpt:IFile) => rpt.report !== 'UPDATE'),
      user

    })

      
  }

  render() {

    const linkDescarga:string = 'https://senatipe.sharepoint.com/sites/tecnologiasdigitales/Shared%20Documents/Reportes/' + ctrlZonales.getZonalxId_sharePoint(this.props.zonal)

 /*
    const archivosNacional =  this.state.listaReporte.filter((rpt:IFile) => rpt.level===1 )
                                                     .filter((rpt:IFile) => rpt.file!=='Borrar' )
                                                     .map((rpt:IFile,i:number)=>
                                                          <NavDropdown.Item href={"/rpt_excel/"+ rpt.file}
                                                            download
                                                            target='_blank'
                                                            key={"level-1-"+ i.toString()}
                                                          >
                                                            <i className="fas fa-file-excel mr-2 text-success"></i> {rpt.rptName}
                                                          </NavDropdown.Item>

                                                      )

    const carpetaZonales = this.state.listaReporte.filter((rpt1:IFile) => rpt1.level===1 && rpt1.isDirectory)
                                                   .filter((rpt1:IFile) => rpt1.file !== 'Zonal_No_declarada')
                                                   .filter((rpt1:IFile) => rpt1.file !== 'Borrar')
                                                   .map((rpt1:IFile, i:number)=>
                                                      <>
                                                        <DropdownSubmenu href="#"
                                                                         title={"📁" + rpt1.file.split('/')[(rpt1.file.split('/').length-1)] }
                                                                         key={"level-1-d-"+ i.toString()}
                                                        >
                                                          {
                                                            this.state.listaReporte.filter((rpt2:IFile)=> rpt2.level === 2 && rpt2.isDirectory)
                                                             .filter((rpt2:IFile)=>
                                                              rpt2.path.split('/')[rpt2.path.split('/').length-2] === rpt1.path.split('/')[(rpt1.path.split('/').length-1)]
                                                              )
                                                             .map((rpt2:IFile, j:number)=>
                                                                <DropdownSubmenu
                                                                          href="#"
                                                                          title={"📁"+ rpt2.path.split('/')[rpt2.path.split('/').length-1]}
                                                                          key={"level-2-" + j.toString()}
                                                                          >
                                                                  {
                                                                    this.state.listaReporte.filter((rpt3:IFile) => rpt3.level === 3 && !rpt3.isDirectory)
                                                                                           .filter((rpt3:IFile) =>
                                                                                              rpt3.path.split('/')[rpt3.path.split('/').length-2] === rpt2.path.split('/')[rpt2.path.split('/').length-1]
                                                                                           )
                                                                                           .map((rpt3:IFile, k:number) =>
                                                                                            <NavDropdown.Item href={rpt3.path.replace('C:/inetpub/wwwroot/','')}
                                                                                                              download
                                                                                                              target='_blank'
                                                                                                              key={"lvl-3-" + k.toString()}
                                                                                            >
                                                                                              <i className="fas fa-file-excel mr-2 text-success"></i>
                                                                                              { rpt3.rptName }
                                                                                            </NavDropdown.Item>
                                                                                            )
                                                                  }
                                                                </DropdownSubmenu>
                                                             )

                                                          }
                                                          {
                                                            this.state.listaReporte.filter((rpt2:IFile)=> rpt2.level === 2 && !rpt2.isDirectory)
                                                                                   .filter((rpt2:IFile)=>
                                                                                    rpt2.path.split('/')[rpt2.path.split('/').length -2] === rpt1.file.split('/')[rpt1.file.split('/').length-1]
                                                                                    )
                                                                                   .map((rpt2:IFile,l:number)=>
                                                                                    <NavDropdown.Item href={rpt2.path.replace('C:/inetpub/wwwroot','')}
                                                                                                      download
                                                                                                      target='_blank'
                                                                                                      key={"lvl-2-d-" + l.toString()}
                                                                                    >
                                                                                      <i className="fas fa-file-excel mr-2 text-success"></i>
                                                                                      {rpt2.rptName}
                                                                                    </NavDropdown.Item>
                                                                                   )

                                                          }
                                                          </DropdownSubmenu>
                                                      </>
                                                      )

                                                      */

    return (


        <Navbarx collapseOnSelect expand="lg"  variant="dark" style={{background:ctrlColores.getColoresInstitucionalxId(4).etiqueta}}>
          <Navbarx.Brand href="#">🖳 ANALÍTICA ACADÉMICA</Navbarx.Brand>
          <Navbarx.Toggle aria-controls="responsive-navbar-nav" />
          <Navbarx.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
            <Navbarx.Text>
                <a className='nav-link' target={'_blank'} href={linkDescarga} rel={'noreferrer'}>
                  🗀 Descarga
                </a>
              </Navbarx.Text>
              {/*<NavDropdownMenu title="🗀 Descarga" id="collasible-nav-dropdown">
                <DropdownSubmenu href="#" title="📁 zonales">
                  {this.props.zonal}
                </DropdownSubmenu>
                  {/*archivosNacional 
              </NavDropdownMenu>*/}
            </Nav>
            <Nav className='ml-auto text-white'>
              <Navbarx.Text>
                <NavLink className='nav-link' to='#'>
                    &#9200; actualizado: { ObtenerHoraZonaHoraria(this.state.fecha,5) }
                </NavLink>
              </Navbarx.Text>
              <Navbarx.Text>
                <NavLink to='#' className="nav-link fw-bold">{" "} &#9993;{ this.state.user }</NavLink>
              </Navbarx.Text>
            </Nav>
          </Navbarx.Collapse>
        </Navbarx>
    )


  }
}
