import { createContext } from 'react'



interface IAuthSegmientoContext  {
    courseId:string
    instructorId:string
    especialistaId:string
    semana:string
    tipo:string
    addSeguimiento?:Function
   }

const defaultState={
    courseId:'',
    instructorId:'',
    especialistaId:'',
    semana:'',
    tipo:''
   }

const AuthSeguimientoContext = createContext<Partial<IAuthSegmientoContext>>(defaultState)
// const AuthContext = React.createContext<IAuthContext>(defaultState)
  export default AuthSeguimientoContext