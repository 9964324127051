import React, { Component } from 'react'
import EtiquetaKpiResultado from './EtiquetaKpiResultado'


import ctrlColores from '../../controller/Graficas/CtrlColores.ctrl'
import '../../styles/graficaTarjeta.css'
import { IPkiNota } from '../../models/Grafica/PkiNota.model'
import GraficaMapsPkiSvgNotas from './GraficaMapsPkiSvgNotas'
import ctrlZonales from '../../controller/Graficas/CtrlZonales.ctrl'
import EtiquetaKpiTotal from './EtiquetaKpiTotal'


interface IProps{
    reportes:IPkiNota[]
    zonal:string
    title:string
    formula:string
    denominadorTitulo:string
    divisorTitulo:string
}

interface IState{
    visible:boolean
    zonalId:string
    cur_pend:string
    cur:string
    result:string
    resultInt:any
    rango:string
}

export default class GraficaKpiSvgNotas extends Component<IProps,IState> {

constructor(props:IProps){
    super(props)

    this.state={
        visible:false,
        zonalId:'TODOS',
        cur_pend: '',
        cur: '',
        result:'',
        resultInt:0,
        rango:''
    }

    this.handleVisible = this.handleVisible.bind(this)
    this.handleZonalId = this.handleZonalId.bind(this)
}

componentDidMount(){

    const dato:IPkiNota[] = this.props.reportes.filter((r:IPkiNota)=> r.zonal === 'TODOS')

    this.setState({
        result: dato.length >    0 ? (dato[0].PORC_CUMPLIMIENTO*100).toFixed(1).toString() : '',
        resultInt:dato.length > 0 ? dato[0].PORC_CUMPLIMIENTO*100 : 0,
        rango: dato.length > 0 ? dato[0].RANGO : ''
    })
}

handleVisible(visible:boolean){

    this.setState({
        visible
    })
}

handleZonalId(zonalId:string ){
    const dato:IPkiNota[] = this.props.reportes.filter((r:IPkiNota)=> r.zonal === zonalId)

    this.setState({
        zonalId,
        cur_pend: dato.length >0 ? (dato[0].CURSOS - dato[0].CURSOS_CON_NOTAS_PENDIENTES).toString() : '',
        cur: dato.length >0 ?      dato[0].CURSOS.toString() : '',
        result: dato.length >    0 ? (dato[0].PORC_CUMPLIMIENTO*100).toFixed(1).toString() : '',
        resultInt:dato.length > 0 ? dato[0].PORC_CUMPLIMIENTO*100 : 0
    })

}

  render() {

    const dato:IPkiNota[] = this.props.reportes.filter((d:IPkiNota)=> d.zonal === this.props.zonal)

    const num = dato.length > 0 ? dato[0].CURSOS - dato[0].CURSOS_CON_NOTAS_PENDIENTES : 0
    const den = dato.length > 0 ? dato[0].CURSOS : 0


    //const result = dato.length >    0 ? (dato[0].PORC_CUMPLIMIENTO*100).toFixed(1) : 0
    //const resultInt = dato.length > 0 ? dato[0].PORC_CUMPLIMIENTO*100 : 0



    return (
        <>
            <div className='card w-100 card-alto' >
                <div className="card-header"
                    style={{
                        background :dato.length > 0 ? ctrlColores.getAsignacionColorEtiqueta_Fondo(this.state.resultInt) : '#333',
                        color: dato.length      > 0 ? ctrlColores.getAsignacionColorEtiqueta_Letra(this.state.resultInt) : '#FFF'
                    }}
                >
                    <h4 className="card-title text-center w-100">
                    { 'KPI: ' + this.props.title}
                    <div>{this.state.rango}</div>
                    </h4>
                </div>
                <div className="card-body  "  style={{background: '#F4F4F4'}}> {/** border-secondary border-top border-left tarjeta_altura*/}
                    <div className="row">
                        <div className="col-5" >
                            <div className={ this.state.zonalId ==='TODOS' ? 'invisible': 'sobreimg_2 w-100'}>
                                <div className="card text-sm w-100"
                                    style={{ background:'#fff'}}
                                >
                                    <div className="card-header">
                                        <h4 className='card-title text-sm'>
                                            Z. { ctrlZonales.getZonalxId_nameZonal(this.state.zonalId)}
                                        </h4>
                                    </div>
                                    <div className='card-body p-2'>
                                        <h4 className='card-title w-100 h-100 text-sm '>
                                            <div className="d-flex justify-content-between mb-3">
                                                <div>Cursos pendientes</div>
                                                <div><b>{this.state.cur_pend}</b></div>
                                            </div>
                                            <div className="d-flex justify-content-between" >
                                                <div>Total de cursos:</div>
                                                <div><b>{this.state.cur}</b></div>
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className='sobreimg_1 w-100'>
                                <EtiquetaKpiResultado
                                    resultado={ this.state.result }
                                    meta={'100%'}
                                    title={ this.props.formula}
                                    etiqueta={ ctrlColores.getAsignacionColorEtiqueta_Fondo(this.state.resultInt)}
                                    color={ ctrlColores.getAsignacionColorEtiqueta_Letra(this.state.resultInt)}
                                    funcionVisible={this.handleVisible}
                                />
                            </div>
                        </div>
                        <div className="col-1"></div>
                        <div className="col-6">
                            <div className={ this.state.visible? "formula w-100" : 'formula invisible'} >
                                <div className="container ">
                                    <div className="w-100">
                                        <EtiquetaKpiTotal
                                                denominadorTitulo={this.props.denominadorTitulo}
                                                divisorTitulo={this.props.divisorTitulo}
                                                title={ this.props.formula}
                                        />
                                        <EtiquetaKpiTotal
                                                denominadorTitulo={num}
                                                divisorTitulo={den}
                                                title={ this.props.formula}
                                        />
                                    </div>
                                </div>
                            </div>
                            <GraficaMapsPkiSvgNotas  reportes={this.props.reportes}
                                                  zonal={this.props.zonal}
                                                  ZonalId={this.handleZonalId}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
  }
}




