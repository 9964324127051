import React, { Component, Fragment } from 'react'


import { OrdenarReverse } from '../lib/source'

import { IPendienteMatricula } from '../models/Grafica/PendienteMatricula.model'


import Etiqueta from './Etiqueta'

interface IProps {
  tperiodo:string
  reportes:IPendienteMatricula[]
  zonal:string
  pperiodo:string
  calendarizacion:string
}

interface IState {

}

export default class TarjetaImpedimento extends Component<IProps, IState> {


  render() {

    let Reporte:IPendienteMatricula[] = []


    Reporte = this.props.reportes.filter((rpt: IPendienteMatricula) => rpt.Zonal === this.props.zonal)
                                 .filter((rpt: IPendienteMatricula) => rpt.PartePeriodo === this.props.pperiodo)
                                 .filter((rpt: IPendienteMatricula) => rpt.TipoPeriodo === this.props.tperiodo)
                                 .filter((rpt: IPendienteMatricula) => rpt.Calendarizacion === this.props.calendarizacion)
                                 .filter((rpt: IPendienteMatricula) => rpt.Campus === 'TODOS')

    let analizo:any = [
        {motivo:'En proceso ',          valor: Reporte.length > 0 ? Reporte[0].Porc_En_Proceso       :0  , data: Reporte.length > 0 ? Reporte[0].En_proceso          : 0 },
        {motivo:'Exceso horas ',        valor: Reporte.length > 0 ? Reporte[0].Porc_Exceso_Horas     :0  , data: Reporte.length > 0 ? Reporte[0].Exceso_Horas        : 0 },
        {motivo:'Falta Pago ',          valor: Reporte.length > 0 ? Reporte[0].Porc_Falta_Pago       :0  , data: Reporte.length > 0 ? Reporte[0].Falta_de_pago       : 0 },
        {motivo:'Sin frecuencia ',      valor: Reporte.length > 0 ? Reporte[0].Porc_Frecuencia       :0  , data: Reporte.length > 0 ? Reporte[0].Sin_Frecuencia      : 0 },
        {motivo:'Fuera de rango ',      valor: Reporte.length > 0 ? Reporte[0].Porc_Fuera_De_Rango   :0  , data: Reporte.length > 0 ? Reporte[0].Fuera_de_rango      : 0 },
        {motivo:'Incumple diseño ',     valor: Reporte.length > 0 ? Reporte[0].Porc_Incumple_diseno  :0  , data: Reporte.length > 0 ? Reporte[0].Incumple_Hrs_Diseno : 0 },
        {motivo:'Sin Contrato ',        valor: Reporte.length > 0 ? Reporte[0].Porc_Sin_Contrato     :0  , data: Reporte.length > 0 ? Reporte[0].Sin_Contrato        : 0 },
        {motivo:'Sin instructor ',      valor: Reporte.length > 0 ? Reporte[0].Porc_Sin_Instructor   :0  , data: Reporte.length > 0 ? Reporte[0].Sin_Instructor      : 0 },
        {motivo:'Fuera de curricula ',  valor: Reporte.length > 0 ? Reporte[0].Porc_Sin_Patron       :0  , data: Reporte.length > 0 ? Reporte[0].Sin_patron          : 0 },
        {motivo:'Sin usuario ',         valor: Reporte.length > 0 ? Reporte[0].Porc_Sin_Usuario      :0  , data: Reporte.length > 0 ? Reporte[0].Sin_usuario         : 0 },
        {motivo:'Solo sin usuario ',    valor: Reporte.length > 0 ? Reporte[0].Porc_Solo_Sin_Usuario :0  , data: Reporte.length > 0 ? Reporte[0].Solo_sin_Usuario    : 0 },
    ]

    analizo = OrdenarReverse(analizo,'valor')



    let etiqueta = analizo.slice(0, 4).map((a:any , i:number)=>{
       return(
            <div className="col-lg-12 col-md-6 col-12" key={'etiqueta-impe'+ i.toString()}>
                <Etiqueta icon="far fa-users-slash"
                          mostrar={a.valor}
                          text={a.motivo}
                          equivalente={'%'}
                          linea2={'('+ a.data +' matriculas pendientes)'}
                          background='#1ca4fc'
                          key={'etiqueta-impe-item'+ i.toString()}/>
            </div>
          
        )
    })


    /*
<div className="col-12">
                <Etiqueta icon="fad fa-calendar-alt" mostrar={'test'} text='Dias promedio sin migrar' background='#1ca4fc'/>
            </div>
    */

    return (
      <Fragment>
        <div className="row justify-content-center">
          {etiqueta}
        </div>
      </Fragment>
    );
  }
}

