import React, { Component } from 'react'
import ctrlZonales from '../../controller/Graficas/CtrlZonales.ctrl'
import { IPendienteMatricula } from '../../models/Grafica/PendienteMatricula.model'
import { Chart } from "react-google-charts"
import { OrdenarReverse } from '../../lib/source'
import  '../../styles/graficaTabla.css'

interface IProps {
  reportes:IPendienteMatricula[]
  pperiodo:string
  tperiodo:string
  zonal:string
  Zonal:Function

}
interface IState {
  zonal: string
  index: number
}

export default class GraficoTablaResumenAntiguedad extends Component<IProps, IState> {

  private datos: any = []

  constructor(props:IProps){
    super(props)

    this.state ={
      zonal:'',
      index:100
    }
  }

  componentDidUpdate(prevProps:IProps, prevState:IState){

    if (prevState.index !== this.state.index) {
      this.props.Zonal(
        ctrlZonales.getZonalxDepartamento(this.datos[this.state.index + 1][0]).id
      );
    }

  }

  render() {

    //let datos: any = []

    this.datos = [
      [
        " ",
        "% Avance matricula",
        "Matriculas sinfo",
        "Matriculas pendientes",
        "Cursos Finalizados",
        "Sin instructor",
        "Sin contrato",
        "Fuera de rango",
        "Exceso horas",
        "Sin frecuencia",
        "Sin pago",
        "Fuera de curricula",
        "Sin usuario",
        "Solo sin usuario",
        "Incumple hrs diseño",
      ],
    ];

    let rst: IPendienteMatricula[] = [];
    let totales: IPendienteMatricula[] = []


    if(this.props.zonal ==='TODOS'){
      rst = OrdenarReverse(
                    this.props.reportes
                      .filter((rpt: IPendienteMatricula) => rpt.Zonal !== "TODOS")
                      .filter((rpt: IPendienteMatricula) => rpt.Campus === "TODOS")
                      .filter((rpt: IPendienteMatricula) => rpt.Zonal !== "nd")
                      .filter(
                        (rpt: IPendienteMatricula) =>
                          rpt.PartePeriodo === this.props.pperiodo
                      )
                      .filter(
                        (rpt: IPendienteMatricula) =>
                          rpt.TipoPeriodo === this.props.tperiodo
                      ),
                    "Porc_Matriculas_BB"
                  );

      totales = this.props.reportes.filter((rpt: IPendienteMatricula) => rpt.Zonal === "TODOS")
                                   .filter((rpt: IPendienteMatricula) => rpt.Campus === "TODOS")
                                   .filter((rpt: IPendienteMatricula) => rpt.PartePeriodo === this.props.pperiodo)
                                   .filter((rpt: IPendienteMatricula) => rpt.TipoPeriodo === this.props.tperiodo)

    }else{
      rst = OrdenarReverse(
        this.props.reportes.filter((rpt: IPendienteMatricula) => rpt.Zonal === this.props.zonal)
                           .filter((rpt: IPendienteMatricula) => rpt.Campus !== "TODOS")
                           .filter((rpt: IPendienteMatricula) => rpt.PartePeriodo === this.props.pperiodo)
                           .filter((rpt: IPendienteMatricula) => rpt.TipoPeriodo === this.props.tperiodo),
                      "Porc_Matriculas_BB"
      );

       totales = this.props.reportes.filter((rpt: IPendienteMatricula) => rpt.Zonal === this.props.zonal)
                                    .filter((rpt: IPendienteMatricula) => rpt.Campus === "TODOS")
                                    .filter((rpt: IPendienteMatricula) => rpt.PartePeriodo === this.props.pperiodo)
                                    .filter((rpt: IPendienteMatricula) => rpt.TipoPeriodo === this.props.tperiodo)
    }

    rst.map((rpt: IPendienteMatricula) =>
      this.datos.push([
        this.props.zonal === "TODOS"
          ? ctrlZonales.getZonalxId(rpt.Zonal).departamento
          : rpt.Campus.toLowerCase()
                      .replaceAll("-", " - ")
                      .replace("cfp", "CFP")
                      .replace("ucp", "UCP")
                      .replace("ind", "IND")
                      .replace("ufp", "UFP"),
        rpt.Porc_Matriculas_BB,
        rpt.Matriculas_SINFO,
        rpt.Mat_Pendientes,
        rpt.Curso_Finalizado,
        rpt.Antig_Sin_Instructor,
        rpt.Antig_Sin_Contrato,
        rpt.Antig_Fuera_de_rango,
        rpt.Antig_Exceso_Horas,
        rpt.Antig_Frecuencia,
        rpt.Antig_Falta_pago,
        rpt.Antig_Sin_patron,
        rpt.Antig_Sin_Usuario,
        rpt.Antig_Solo_Sin_Usuario,
        rpt.Antig_Incumple_diseno,
      ])
    )

    totales.map((rpt: IPendienteMatricula) =>
      this.datos.push([
        this.props.zonal==='TODOS'? 'Nacional' :  'Zonal',
        rpt.Porc_Matriculas_BB,
        rpt.Matriculas_SINFO,
        rpt.Mat_Pendientes,
        rpt.Curso_Finalizado,
        rpt.Antig_Sin_Instructor,
        rpt.Antig_Sin_Contrato,
        rpt.Antig_Fuera_de_rango,
        rpt.Antig_Exceso_Horas,
        rpt.Antig_Frecuencia,
        rpt.Antig_Falta_pago,
        rpt.Antig_Sin_patron,
        rpt.Antig_Sin_Usuario,
        rpt.Antig_Solo_Sin_Usuario,
        rpt.Antig_Incumple_diseno,
      ])
    )

    return (
      <Chart
        chartType="Table"
        width="100%"
        // height="400px"
        data={this.datos}
        options={{
          title: "Detalle de pendientes por zonales",
          curveType: "function",
          legend: { position: "top" },
          pageSize: 25,
          frozenColumns: 1,
          cssClassNames: {
            headerCell: "TableHeaderCell",
           // tableRow:'TableRow',
            tableCell:'TableCell'
          },
          firstRowNumber: 2,
          sort: "disable",
          /** */
        }}
        chartEvents={[
          {
            eventName: "select", // "ready",
            callback: ({ chartWrapper, google }) => {
              const chart: any = chartWrapper.getChart();
              let row;

              google.visualization.events.addListener(
                chart,
                "select",
                async (e) => {
                  row = chart.getSelection()[0];

                  if (row !== undefined) {
                    this.setState({
                      index: chart.getSelection()[0].row,
                    });
                  }
                }
              );
            },
          },
        ]}
      />
    );
  }
}