import React, { Component, ComponentType } from "react";
import { Redirect,  Route } from "react-router-dom";
import authLogin from "../controller/Auth/AuthLogin.ctrl";
import AuthContext  from "./AuthContext";

interface IUserLogin{
  user:string
}

interface IProps {
  path: string
  component: ComponentType<any>
}

interface IState {
  authUser: Boolean
  userLogin:IUserLogin[]
  user:string
}

export default class PublicRoute extends Component<IProps & Readonly<any>,IState> {
  static contextType = AuthContext

  constructor(props:IProps){
    super(props)

    this.state ={
      userLogin:[],
      authUser:false,
      user:''
    }
  }


  render(){

    const { user, isLogged } = this.context

    const authUser = authLogin.getValidarUserLogin(user)

    return (
      <Route  exact
              path={this.props.path}
              render={
                (props)=>
                  !isLogged() ?
                    React.createElement(this.props.component,{...props})
                  :
                      authUser ?
                        <Redirect to='/analitica' />
                      :
                        <Redirect to='/NoValidate' />
                     //   <Redirect to='/analitica' />
              }

      />
    )
  }
}

/*
    return (

      <Route exact path={this.props.path}>
        {
          auth.isLogged()?
            React.createElement(this.props.component,{...this.props})
            :
            <Redirect to={'/login'} />
        }
      </Route>

    )
  }
}*/
