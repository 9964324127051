import { Ordenar } from "../../lib/source"
import { IMatricula } from "../../models/Grafica/Matriculas.model"


class CtrlDataCalendarizacion{

    getCalendarizacionxZonalxTPeriodoxPPeriodo(datos:IMatricula[] ,zonal:string, tperiodo:string, pperiodo:string){

        const dato:IMatricula[] = datos.filter((dat:IMatricula)=> dat.Zonal === zonal)
                                                .filter((dat:IMatricula)=> dat.TipoPeriodo === tperiodo)
                                                .filter((dat:IMatricula)=> dat.PartePeriodo === pperiodo)
                                                .filter((dat:IMatricula)=> dat.Campus === 'TODOS')
                                                .filter((dat:IMatricula)=> dat.calendarizacion !== 'TODOS')

        return dato.length > 0 ? Ordenar(dato,'calendarizacion') : []

    }

}

const ctrlDataCalendarizacion = new CtrlDataCalendarizacion()
export default ctrlDataCalendarizacion