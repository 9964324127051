import React, { Component } from 'react'
import { IPendienteMatricula } from '../../models/Grafica/PendienteMatricula.model'
import Chart  from 'react-apexcharts'

interface IProps {
  reportes:IPendienteMatricula[]
  pperiodo:string
  tperiodo:string
  zonal:string
}
interface IState {

}

export default class GraficaApexRadarAntgMatricula extends Component<IProps, IState> {

  render() {

    let data:any = null
    
    const categories = [
        "Fuera de curricula",
        "Sin frecuencia",
        "Sin instructor",
        "Sin contrato",
        "Incumple horas diseño",
        "Solo sin usuario",
        "Sin usuario",
        "Falta pago",
        "Exceso horas",
        "Fuera de rango"
      ];

    this.props.reportes
      .filter((rpt: IPendienteMatricula) => rpt.Zonal === this.props.zonal)
      .filter((rpt: IPendienteMatricula) => rpt.Campus === "TODOS")
      .filter(
        (rpt: IPendienteMatricula) => rpt.TipoPeriodo === this.props.tperiodo
      )
      .filter(
        (rpt: IPendienteMatricula) => rpt.PartePeriodo === this.props.pperiodo
      )
      .map(
        (rpt: IPendienteMatricula) =>
          (data = [
            rpt.Antig_Sin_patron,
            rpt.Antig_Frecuencia,
            rpt.Antig_Sin_Instructor,
            rpt.Antig_Sin_Contrato,
            rpt.Antig_Incumple_diseno,
            rpt.Antig_Solo_Sin_Usuario,
            rpt.Antig_Sin_Usuario,
            rpt.Antig_Falta_pago,
            rpt.Antig_Exceso_Horas,
            rpt.Antig_Fuera_de_rango,
          ])
      );

  return (
      <Chart  options=  {{
                          chart: {
                            height: '100%',
                            width:'100%',
                            type: 'radar',
                          },
                          title: {
                      //      text: 'Tiempo sin migrar (en días)',
                          },
                          subtitle:{
                       //     text: subtitle
                          },
                          markers: {
                            size: 5,
                          },
                          xaxis: {
                            categories,
                            max:100,
                            min:0,
                            labels:{
                              style:{
                                fontSize:'16px'
                              }
                            }
                          },
                          /*muestra las etiqueda
                          dataLabels: {
                            enabled: true
                          },*/
                          plotOptions: {
                            radar: {
                              //size: 140,
                              polygons: {
                                strokeColors: '#e9e9e9',
                                fill: {
                                  colors: ['#f8f8f8', '#fff']
                                }
                              }
                            }
                          },
                        }}
              series={[
                      {
                        name: 'Días sin migrar: ',
                        data:data
                      }
                    ]}
              type="radar"
              height={'600px'}
    />
  )}
}