import { ICursoSeguimiento } from "../../models/Seguimiento/cursoseguimiento.model"
//import ControlCursos_Supervision from '../../data/JSON_ControlCursos_Supervision.json'
import md5 from 'md5'
import crtrlJsonReadAnexo03 from "./CtrlJsonReadAnexo03.ctrl"
import { IEspecialistaSeguimiento } from "../../models/Seguimiento/especialistaSeguimiento.model"


class CtrlSeguimientoCursos{

    async getCursos(){
      const controlcursos = await crtrlJsonReadAnexo03.getSeguimiento() as ICursoSeguimiento[] //await ControlCursos_Supervision as ICursoSeguimiento[]

      return  controlcursos
    }

    async getCursosHabilitados(){
      const cursos:ICursoSeguimiento[] = await this.getCursos()

      return cursos.filter((c:ICursoSeguimiento) => c.curso_cerrado === 'N')
    }

    async getCursosCerrado(){
      const cursos:ICursoSeguimiento[] = await this.getCursos()

      return cursos.filter((c:ICursoSeguimiento) => c.curso_cerrado === 'Y')
    }

    async getCursosAptos(){
      const cursos:ICursoSeguimiento[] = await this.getCursos()

      return cursos.filter((c:ICursoSeguimiento) => c.curso_cerrado === 'N')
                   // .filter((c:ICursoSeguimiento) => c.curso_cerrado === 'N')
    }

    async getValidar(courseId:string,instructorId:string,codigo:string){

      const cursox:ICursoSeguimiento[] = await this.getCursosAptos()
      

      let curso = cursox.find((c:ICursoSeguimiento) => c.cod_curso === courseId && c.id_inst === instructorId ) as ICursoSeguimiento
                      //   .filter((c:ICursoSeguimiento) => c.id_inst === instructorId)

/*
     console.log(cursox)
      console.log(`courseId: ${courseId}`)
      console.log(`instructorId ${instructorId}`)
      console.log(`codigo ${codigo}`)
      console.log(curso)
      console.log(`md5 ${md5((curso.pk1_curso + curso.pk1_usuario).toString())}`);
      debugger
*/

      if(!!curso){

       // console.log(md5((curso.pk1_curso + curso.pk1_usuario).toString()))
       // console.log(codigo);
        if (codigo === md5((curso.pk1_curso + curso.pk1_usuario).toString())) {
          return curso
        }
      }

      return {
              periodo : '',
              zonal : '',
              campus : '',
              pk1_curso : 0,
              cod_curso : '',
              nrc : '',
              inicio : '',
              fin : '',
              curso : '',
              curso_cerrado : '',
              pk1_usuario : 0,
              id_inst : '',
              instructor : '',
              inscritosbb : 0,
              url_teams : '',
              curso_horario : '',
                  
            }

    }

   async getCursoSeguimiento(courseId:string,instructorId:string){

      const cursox:ICursoSeguimiento[] = await this.getCursosAptos()
      

      let curso:any = cursox.find((c:ICursoSeguimiento) => c.cod_curso === courseId && c.id_inst === instructorId  )
     
      return curso

    }  

  async getEspecialistaSeguimientoxId_Name(supervisorId: string) {


    supervisorId = supervisorId?? '000000000'

    const especialistaSeguimiento: IEspecialistaSeguimiento[] = await crtrlJsonReadAnexo03.getEspecialistaSeguimiento()

    return (especialistaSeguimiento
                        .filter((es:IEspecialistaSeguimiento) => es.Especialista_ID === supervisorId)).length>0?
              especialistaSeguimiento
                        .filter((es: IEspecialistaSeguimiento) => es.Especialista_ID === supervisorId)[0].Especialista_Nombre
            :
              'No Definido'

  }

}

const ctrlSeguimientoCursos = new CtrlSeguimientoCursos()
export default ctrlSeguimientoCursos