import React, { Component, Fragment } from 'react'
import '../../styles/graficaTarjeta.css'

interface IProps {
    denominadorTitulo:string|number
    divisorTitulo:string|number
    title:string
}

interface IState {

}

export default class EtiquetaKpi extends Component<IProps,IState> {
  render() {
    return (
      <Fragment>
            <div className="card w-100 p-2" >
                <div className='d-flex justify-content-around ' >
                    <div className='align-self-center text-center t-fontSize' style={{width:'40%'}}>
                        {this.props.title }
                    </div>
                    <div className="justify-content-center align-self-center"  style={{ width:'5%'}}>
                        <div className='h-100'>=</div>
                    </div>
                    <div className='justify-content-center align-self-center t-fontSize'  style={{ width:'50%'}}>
                        <div className="d-flex flex-column">
                            <div className='border-bottom border-dark pb-1  text-center '>
                                {this.props.denominadorTitulo}
                            </div>
                            <div className='pt-1 text-center '>
                                {this.props.divisorTitulo}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </Fragment>
    )
  }
}

