import React, { Component, Fragment } from 'react'

interface IProps {}

interface IState {}

export default class Procesando extends Component<IProps,IState> {
  
    render(){
        return(
            <Fragment>
                <div className=" w-100 d-flex mt-5 justify-content-center" style={{height:'70vh'}}>
                    <div className="align-content-center" style={{height:'60%'}} >
                        <img src="/images/tony.gif" className='rounded mx-auto d-block' alt="Tony" />
                    </div>
                </div>
            </Fragment>
        )
    }

}
