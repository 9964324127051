import { IPersonalAuth } from "../../models/Grafica/personalAuth.model"

export interface IUserLogin{
    user:string
}

class AuthLogin {

    private static getConnection:AuthLogin
    datos: IUserLogin[] = []

    private constructor(){
        this.getData()
    }

    static getUserLoginConnection(){
        if(!AuthLogin.getConnection){
            AuthLogin.getConnection = new AuthLogin()
        }

        return AuthLogin.getConnection
    }

    private async getData() {

        //let datos: IUserLogin[] = []
        this.datos= []

        await fetch('/Auth/JSON_PersonalAuth.json')
          .then((db) => db.json())
          .then((data) => {
            this.datos = data

          })

        return this.datos
      }

      async getDataUser() {
        return this.datos as IPersonalAuth[]
      }

      

       getValidarUserLogin(userName:string) {

        const rstFind:IUserLogin[] = this.datos.filter((u:IUserLogin) => u.user === userName/*.split('@')[0] */)

        return rstFind.length > 0 ? true : false
      }




}

const authLogin = AuthLogin.getUserLoginConnection()
export default authLogin