import React, { Component } from 'react'

import '../styles/graficaTarjeta.css'

interface IProps  {
   icon:string
   text:string|number
   mostrar:number|string
   background?:string|null
   letra?:string|null
   equivalente?:string
   linea2?:string
}

interface IState  {}

export default class Etiqueta extends Component<IProps, IState> {

  render() {
    return (

        <>
            <div className="info-box"
                 style={{ backgroundColor: this.props.background?  this.props.background : 'rgba(53, 53, 53, 0.6)'}}
            >
                <div className="d-flex align-items-center w-100">
                    <div className='w-25'>
                        <span className="info-box-icon text-white text-center">
                            <i className={this.props.icon} style={{ color: this.props.letra? this.props.letra : '#FFF' }}></i>
                        </span>
                    </div>
                    <div className="w-75">
                        <span
                            className="info-box-number text-md text-left"
                            style={{ color: this.props.letra? this.props.letra  : '#FFF' }}
                        >
                            {this.props.mostrar + (this.props.equivalente?? '')}

                            { this.props.linea2 ?
                                <>
                                   <p className='text-sm'>{this.props.linea2}</p>
                                </>

                            :
                                <></>
                            }
                        </span>
                        <div className="progress">
                            <div className="progress-bar"></div>
                        </div>
                        <span   className="info-box-number text-sm"
                                style={{ color: this.props.letra?  this.props.letra : '#FFF' }}
                        >
                            {this.props.text}
                        </span>
                    </div>
                </div>
            </div>
            {/** /}
            <div    className=  "info-box text-white border_rojo"
                    style={{ backgroundColor: this.props.background?  this.props.background : 'rgba(53, 53, 53, 0.6)'}}
            >
                {/**bg-gradient-info
                <span className="info-box-icon text-white border_azul">
                    <i className={this.props.icon} style={{ color: this.props.letra? this.props.letra : '#FFF' }}></i>
                </span>/}
                <div className="info-box-content border_verde">
                    <span
                        className="info-box-number text-md text-justify"
                        style={{ color: this.props.letra? this.props.letra  : '#FFF' }}
                    >
                        {this.props.mostrar + (this.props.equivalente?? '')}

                        { this.props.linea2 ?
                            <>
                               <p className='text-sm'>{this.props.linea2}</p>
                            </>

                        :
                            <></>
                        }
                    </span>
                    <div className="progress">
                        <div className="progress-bar"></div>
                    </div>
                    <span   className="progress-description text-sm"
                            style={{ color: this.props.letra?  this.props.letra : '#FFF' }}
                    >
                        {this.props.text}
                    </span>
                </div>
                    </div>*/}
        </>
    )
  }
}