import React, { Component } from 'react'
import { IMatricula } from '../../models/Grafica/Matriculas.model'
import EtiquetaKpi from './EtiquetaKpi'
import EtiquetaKpiResultado from './EtiquetaKpiResultado'

import '../../styles/graficaTarjeta.css'
import GraficaSvgMapa from './GraficaSvgMapa'
import ctrlZonales from '../../controller/Graficas/CtrlZonales.ctrl'

interface IProps{
    reportes:IMatricula[]
    pperiodo:string
    tperiodo:string
    zonal:string
    calendarizacion:string
    title:string
    formula:string
    denominadorTitulo:string
    divisorTitulo:string
   // Zonal:Function
}

interface IState{
    visible:boolean
    zonalId:string
    Porc_Mat:string
    mat_pend:string
    mat_sinfo:string
    mat_bb:string
    ins_sinfo:string
    result:string
    resultInt:any
    colorBg:string
    colorText:string
}

export default class GraficaKpiSvgCalendarizacion extends Component<IProps,IState> {

    constructor(props:IProps){
        super(props)

        this.state={
            visible:false,
            zonalId:'TODOS',
            Porc_Mat:'',
            mat_pend:'',
            mat_sinfo:'',
            mat_bb:'',
            ins_sinfo:'',
            result:'',
            resultInt:0,
            colorBg:'',
            colorText:''
        }

        this.handleVisible = this.handleVisible.bind(this)
        this.handleZonalId = this.handleZonalId.bind(this)
    }

    handleVisible(visible:boolean){

        this.setState({
            visible
        })
    }

    componentDidMount(){
        const dato:IMatricula[] = this.props.reportes.filter((d:IMatricula)=> d.Zonal === 'TODOS')
                                                     .filter((r:IMatricula)=> r.PartePeriodo === this.props.pperiodo)
                                                     .filter((r:IMatricula)=> r.TipoPeriodo === this.props.tperiodo)
                                                     .filter((r:IMatricula)=> r.calendarizacion === this.props.calendarizacion)
                                                     .filter((r:IMatricula)=> r.Campus === 'TODOS')

                
        this.setState({
            result    : dato.length > 0 ? dato[0].Porc_Mat.toString() : '',
            resultInt : dato.length > 0 ? dato[0].Porc_Mat            : 0,
            colorBg   : dato.length > 0 ? dato[0].Color_Mat : '',
            colorText : dato.length > 0 ? dato[0].Color_Letra : '',
            mat_bb :    dato.length > 0 ? dato[0].matricula_bb                  :'',
            ins_sinfo:  dato.length > 0 ? dato[0].inscripciones_sinfo.toString(): '',

        })
    }

    handleZonalId(zonalId:string ){
        const dato:IMatricula[] = this.props.reportes.filter((r:IMatricula)=> r.Zonal === zonalId)
                                                    .filter((r:IMatricula)=> r.PartePeriodo === this.props.pperiodo)
                                                    .filter((r:IMatricula)=> r.TipoPeriodo === this.props.tperiodo)
                                                    .filter((r:IMatricula)=> r.calendarizacion === this.props.calendarizacion)
                                                    .filter((r:IMatricula)=> r.Campus === 'TODOS')

        this.setState({
            zonalId,
            Porc_Mat:   dato.length > 0 ? dato[0].Porc_Mat.toString()           : '',
            mat_pend:   dato.length > 0 ? dato[0].Mat_Pendientes.toString()     : '',
            mat_sinfo:  dato.length > 0 ? dato[0].matricula_sinfo.toString()    : '',
            mat_bb :    dato.length > 0 ? dato[0].matricula_bb                  :'',
            ins_sinfo:  dato.length > 0 ? dato[0].inscripciones_sinfo.toString(): '',
            result    : dato.length > 0 ? dato[0].Porc_Mat.toString()           : '',
            resultInt : dato.length > 0 ? dato[0].Porc_Mat                      : 0,
            colorBg   : dato.length > 0 ? dato[0].Color_Mat : '',
            colorText : dato.length > 0 ? dato[0].Color_Letra : '',
        })
    }

  render() {

    return (
        <>
            <div className='card w-100 card-alto' >
                <div className="card-header"
                     style={{
                            background : this.state.colorBg ,
                            color:this.state.colorText
                            }}
                >
                    <h4 className="card-title text-center w-100">
                       { 'KPI: Matrículas ' + this.props.title}
                       <div className='text-center'>{ this.props.pperiodo}</div>
                    </h4>
                </div>
                <div className="card-body"  style={{background: '#F4F4F4'}}> {/** border-secondary border-top border-left tarjeta_altura*/}
                    <div className="row">
                        <div className="col-5" >
                            <div className={ this.state.zonalId ==='TODOS' ? 'invisible': 'sobreimg_2 w-100'}>
                                <div className="card text-sm w-100"
                                    style={{ background:'#fff'}}
                                >
                                    <div className="card-header">
                                        <h4 className='card-title text-sm'>
                                            Z. { ctrlZonales.getZonalxId_nameZonal(this.state.zonalId)}
                                        </h4>
                                    </div>
                                    <div className='card-body p-2'>
                                        <h4 className='card-title w-100 h-100 text-sm '>
                                            <div className="d-flex-justify-content-between mb-2">
                                                <div>Inscripciones SINFO:</div>
                                                <div><b>{this.state.mat_sinfo}</b></div>
                                            </div>
                                            <div className="d-flex-justify-content-between">
                                                <div>Matrículas pendientes:</div>
                                                <div><b>{this.state.mat_pend}</b></div>
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className='sobreimg_1 w-100'>
                                <EtiquetaKpiResultado
                                    resultado={ this.state.result }
                                    meta={'100%'}
                                    title={ this.props.formula}
                                    etiqueta={ this.state.colorBg }
                                    color={ this.state.colorText}
                                    funcionVisible={this.handleVisible}
                                />
                            </div>
                        </div>
                        <div className="col-1"></div>
                        <div className="col-6">
                            <div className={ this.state.visible? "formula w-100" : 'formula invisible'} >
                                <div className="container ">
                                    <div className="w-100">
                                        <EtiquetaKpi
                                            denominadorTitulo={this.props.denominadorTitulo}
                                            divisorTitulo={this.props.divisorTitulo}
                                            title={ this.props.formula}
                                        />
                                        <EtiquetaKpi
                                            denominadorTitulo={this.state.mat_bb}
                                            divisorTitulo={this.state.ins_sinfo}
                                            title={ this.props.formula}
                                        />
                                    </div>
                                </div>
                            </div>
                            <GraficaSvgMapa
                                reportes={this.props.reportes}
                                tperiodo={this.props.tperiodo}
                                zonal={this.props.zonal}
                                pperiodo={this.props.pperiodo}
                                calendarizacion ={this.props.calendarizacion}
                                ZonalId={this.handleZonalId}
                            />
                        </div>
                    </div>
                </div>
            </div>


        </>

    )
  }
}
