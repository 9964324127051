//import { useMsal } from '@azure/msal-react';
import React from 'react'
import { Link } from 'react-router-dom';
//import SignOutButton from '../component/Authentication/SignOutButton'

import '../styles/fondo.css'
import imagen from '../Image/fondo2.jpg'

import { msalConfig }  from '../config/authConfig'
import { PublicClientApplication } from '@azure/msal-browser'

import '../styles/fondo.css'

//import { render } from '@testing-library/react';

/*
interface IProps {

}
interface IState {

}



function handleLogout(instance:any) {
  instance.logoutRedirect().catch((e:any) => {
      console.error(e);
  });
}
*/

export interface IUserLogin{
    user:string
}

interface IProps {}

interface IState {
    cont: number
}
export default class handleLogout extends React.Component<IProps,IState>{

  publicClientApplication:PublicClientApplication
  private intervalId:any

  constructor(props:IProps){
      super(props)

      this.state={
        cont: 0
      }

      this.publicClientApplication = new PublicClientApplication(msalConfig)

      
      this.logout = this.logout.bind(this)
  }

  logout(){
      this.publicClientApplication.logoutRedirect()
    }

    componentDidMount(){
      this.intervalId = setInterval(()=>{
          return (
              this.setState({
                  cont: this.state.cont - 1 
              })
          )
      }, 1000)
  
  }

  componentDidUpdate(prevState:IState){

      if(prevState.cont !== this.state.cont){
          if(this.state.cont === 0 ) this.logout()
      }
  }

  componentWillUnmount(){
      clearInterval(this.intervalId)
  }


  render(){
    const fondo = {
        backgroundImage:`url(${imagen})`,
        backgroundSize:'cover',
        backgroundRepeat:'no-repeat',
        backgroundPosition:'center',

        border:'1px solid blue',
        with:'100%',
        height:'100vh'
    }

  return(
    <>
        <div style={ fondo } >
          <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
              <Link className="navbar-brand" to="#">
              ANALÍTICA ACADÉMICA
              </Link>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
              </button>
          </nav>
          <div className="card card-primary margen mx-auto" style={{width:'30%'}}>
            <div className="card-header ">
                <div className='logo'>
                    <img src="/images/logo.svg" className='center' alt="" width={'100px'}/>
                </div>
            </div>
            <div className="card-body">
                <div className="form-check">
                    <label className="form-check-label" htmlFor="exampleCheck1">
                        <section className='text-lg text-center text-primary'>
                            Su sesión no se ha cerrado correctamente, o tiene su sesion abierta en otra aplicacion
                        </section>
                        <section>
                          <p>
                            Estamos reiniciando su sesion en {this.state.cont}
                          </p>
                        </section>
                    </label>
                </div>
            </div>
            <div className="card-footer text-right">
              <button className="btn btn-outline-sm-danger" data-toggle="dropdown"  onClick={() => this.logout()}>
                  <i className="fas fa-sign-out" /> salir
              </button>
            </div>
          </div>
        </div>
    </>
  )
  }
}

/*

export default class NoFound extends Component<IProps, IState> {
  render() {
    return (
      <Fragment>
                      <div className="card card-primary margen" style={{width:'30%'}}>
                        <div className="card-header ">
                            <div className='logo'>
                                <img src="/images/logo.svg" className='center' alt="" width={'100px'}/>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="form-check">
                                <label className="form-check-label" htmlFor="exampleCheck1">
                                    <span className='text-lg text-capitalize text-center text-primary'>
                                        Debe cerrar su session
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <SignOutButton />
                        </div>
                      </div>



        <div className="xcontend-wrapper">
          {/* Content Header (Page header) /}
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>500 Error Page</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="#">Seguimiento</Link>
                    </li>
                    <li className="breadcrumb-item active">500 Error Page</li>
                  </ol>
                </div>
              </div>
            </div>
            {/* /.container-fluid /}
          </section>
        </div>

        {/* Main content /}
        <section className="content">
          <div className="error-page">
            <h2 className="headline text-danger">
              <i className="fas fa-usb-drive"></i>
            </h2>
            <div className="error-content">
              <h3>
                <i className="fas fa-exclamation-triangle text-danger" />
                Oops! Error de proceso.
              </h3>
              <p>
                Los datos enviados no son validos, verificar la informacion enviada
                con soporte UTDA. Trabajaremos para solucionarlo de inmediato.
              </p>
            </div>
          </div>
          {/* /.error-page }
        </section>
        {/* /.content
      </Fragment>
    );
  }
}
*/