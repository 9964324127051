import React, { Component, Fragment } from 'react'
import { RouteComponentProps } from 'react-router'

import { IMatricula } from '../../models/Grafica/Matriculas.model'
import { IGZonal } from '../../models/Grafica/zonal.model'
import { IGCampus } from '../../models/Grafica/campus.model'
import { IPendienteMatricula } from '../../models/Grafica/PendienteMatricula.model'


import '../../styles/style.css'

import ctrlData from '../../controller/Graficas/CtrlData.ctrl'
import {  removeDuplicados } from '../../lib/source'

import Navbar from '../../component/Navbar'
import Pestana from '../../component/Pestana'
import GraficoTablaResumen from '../../component/Graficos/GraficoTablaResumen'
import GraficaApexRadarPendMatricula from '../../component/Graficos/GraficaApexRadarPendMatricula'
import TarjetaH from '../../component/TarjetaH'
import GraficaBarCharZonales from '../../component/Graficos/GraficaBarCharZonales'
import GraficaBarCharCampus from '../../component/Graficos/GraficaBarCharCampus'
import TarjetaImpedimento from '../../component/TarjetaImpedimento'
import GraficaBubbleMotivos from '../../component/Graficos/GraficaBubbleMotivo'
import TarjetaMotivos from '../../component/TarjetaMotivos'
import GraficaCharPeriodos from '../../component/Graficos/GraficaBarChartPeriodo'
import { ICursoEstado } from '../../models/Grafica/CursoEstado.model'
import ctrlDataMatriculas from '../../controller/Graficas/CtrlDataMatriculas.ctrl'
import ctrlDataCalendarizacion from '../../controller/Graficas/CtrlDataCalendarizacion.ctrl'
import { IValoresInicial } from '../../models/Grafica/ValoresIniciales.model'
import ctrlDataValoresIniciales from '../../controller/Graficas/CtrlDataValoresIniciales.ctrl'
import TarjetaImpedimentoCursos from '../../component/TarjetaImpedimentoCursos'
import Procesando from '../../component/Procesando'

import { IPkiHora } from '../../models/Grafica/PkiHora.model'

import GraficaApexComboChart from '../../component/Graficos/GraficaApexComboChart'
import { IPkiNota } from '../../models/Grafica/PkiNota.model'
import ctrlDataPkiNotas from '../../controller/Graficas/ctrlDataPkiNota.ctrl'
import ctrlDataPkiHoras from '../../controller/Graficas/CtrlDataPki.Horas.ctrl'


import '../../styles/graficaTarjeta.css'
import GraficaKpiSvgCalendarizacion from '../../component/Graficos/GraficaKpiSvgCalendarizacion'
import GraficaKpiSvgNotas from '../../component/Graficos/GraficaKpiSvgNotas'
import GraficaKpiSvgAsignacionDirecta from '../../component/Graficos/GraficakpiSvgAsignacionDirecta'
import GraficaKpiSvgInstructores from '../../component/Graficos/GraficakpiSvgInstructores'
import { IKpiSinInstructores } from '../../models/Grafica/PkiSinInstructor.model'
import ctrlDataPkiSinInstructor from '../../controller/Graficas/CtrlDataPkiSinInstructor.ctrl'
import { IKpiFueraRango } from '../../models/Grafica/PkiFueraRango.model'
import ctrlDataPkiFueraRango from '../../controller/Graficas/CtrlDataPkiFueraRango.ctrl'
import GraficakpiSvgFueraRango from '../../component/Graficos/GraficakpiSvgFueraRango'

//http://localhost:3000/#/analitica?user=gcabana@senati.pe

interface IProps extends RouteComponentProps{

}

interface IState {
  activar: boolean
  auth: Boolean
  zonal:string
  tperiodos:string
  pperiodos:string
  calendarizacion:string
  select :string
  vista:string

  ctrlDataMatriculas:IMatricula[]
  ctrlDataPendienteMatriculas: IPendienteMatricula[]
  campus:IGCampus[]
  cursosEstado: ICursoEstado[]

  ctrlMatriculas: IMatricula[]
  ctrlDataPkiNotas:IPkiNota[]
  ctrlDataPkiHoras:IPkiHora[]
  ctrlDataPkiSinInstructorIniciado:IKpiSinInstructores[]
  ctrlDataPkiSinInstructorProximo:IKpiSinInstructores[]
  ctrlDatFueraRangoIniciado:IKpiFueraRango[]
  ctrlDatFueraRangoProximo:IKpiFueraRango[]

  cursoEstado:string

  ctrlDataValoresIniciales:IValoresInicial

  process:boolean

  prevZonal:string
  prevPPeriodo:string 
  prevTPeriodo:string
  
}

export default class Analitica extends Component<IProps, IState> {

//  static contextType = AuthContext

  constructor(props: IProps) {
    super(props)
/*
    const { search } = this.props.location
    const query = new URLSearchParams(search)
*/
    this.state = {
          activar: false,
          auth: true,
          zonal: 'TODOS',
          pperiodos:'TODOS',
          tperiodos:'TODOS',
          calendarizacion:'TODOS',

          select:'TODOS',
          vista:'tbldashboard',

          campus:[],
          cursosEstado:[],
          cursoEstado:'',

          ctrlDataMatriculas:[],
          ctrlDataPendienteMatriculas:[],
          ctrlMatriculas:[],
          ctrlDataPkiHoras:[],
          ctrlDataPkiNotas:[],
          ctrlDataPkiSinInstructorIniciado:[],
          ctrlDataPkiSinInstructorProximo:[],
          ctrlDatFueraRangoIniciado:[],
          ctrlDatFueraRangoProximo:[],

          ctrlDataValoresIniciales:{calendarizacion:'',pperiodo:'',tperiodo:'',zonal:''},

          process: false,
          prevPPeriodo:'',
          prevTPeriodo:'',
          prevZonal:'',

        }

    this.handleZonal = this.handleZonal.bind(this)
    this.handlePPeriodo = this.handlePPeriodo.bind(this)
//    this.handleTipoPrograma = this.handleTipoPrograma.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.handleGrafica = this.handleGrafica.bind(this)
    this.handleSelect = this.handleSelect.bind(this)

  }

  async componentDidMount(){

    this.setState({ 
      process:true
    })

    const valoresIniciales:IValoresInicial = await ctrlDataValoresIniciales.getValorInicial()

    this.setState({
      cursosEstado: await ctrlData.getCursosEstado(),

      ctrlDataMatriculas : await ctrlData.getMatriculasFile(),
      ctrlDataPendienteMatriculas : await ctrlData.getPendienteMatriculaFile(),

      ctrlDataValoresIniciales: await ctrlDataValoresIniciales.getValorInicial(),
      ctrlDataPkiHoras: await ctrlDataPkiHoras.getPkiHoras(),
      ctrlDataPkiNotas: await ctrlDataPkiNotas.getPkiNotas(),
      ctrlDataPkiSinInstructorIniciado: await ctrlDataPkiSinInstructor.getPkiSinInstructorIniciados(),
      ctrlDataPkiSinInstructorProximo: await ctrlDataPkiSinInstructor.getPkiSinInstructorProximos(),
      ctrlDatFueraRangoIniciado: await ctrlDataPkiFueraRango.getPkiFueraRangoIniciados(),
      ctrlDatFueraRangoProximo: await ctrlDataPkiFueraRango.getPkiFueraRangoProximos(),

  //    ctrlMatriculas : await ctrlDataMatriculas.getMatricula()

      zonal: valoresIniciales.zonal,
      pperiodos: valoresIniciales.pperiodo,
      tperiodos: valoresIniciales.tperiodo,
      calendarizacion: valoresIniciales.calendarizacion,

      prevZonal: valoresIniciales.zonal,
      prevPPeriodo: valoresIniciales.pperiodo,
      prevTPeriodo: valoresIniciales.tperiodo

      
    })

    this.setState({
      process:false
    })
  }

  componentWillUnmount(){
    this.setState({
      cursosEstado: [],

      ctrlDataMatriculas : [],
      ctrlDataPendienteMatriculas : [],

      ctrlDataValoresIniciales: {calendarizacion:'',pperiodo:'',tperiodo:'',zonal:''},
      ctrlDataPkiHoras: [],
      ctrlDataPkiNotas: [],

  //    ctrlMatriculas : await ctrlDataMatriculas.getMatricula()

      pperiodos: '',
      tperiodos: ''
    })
  }

  componentDidUpdate(prevProps:IProps,prevState:IState){

/*
    if(prevState.zonal !== this.state.zonal){

      console.log('-----componentUpdate-Zonal----');
      console.log(`zonal: ${this.state.zonal}`)
      console.log(`tperiodos: ${this.state.tperiodos}`)
      console.log(`pperiodos: ${this.state.pperiodos}`)
      console.log(`calendarizacion: ${this.state.calendarizacion}`)

    }

    if(prevState.tperiodos !== this.state.tperiodos){

      console.log('-----componentUpdate-TipoPeriodo----');
      console.log(`zonal: ${this.state.zonal}`)
      console.log(`tperiodos: ${this.state.tperiodos}`)
      console.log(`pperiodos: ${this.state.pperiodos}`)
      console.log(`calendarizacion: ${this.state.calendarizacion}`)

    }
*/
    if(prevState.pperiodos !== this.state.pperiodos){
/*
      console.log('-----componentUpdate-PartePeriodo----');
      console.log(`zonal: ${this.state.zonal}`)
      console.log(`tperiodos: ${this.state.tperiodos}`)
      console.log(`pperiodos: ${this.state.pperiodos}`)
      console.log(`calendarizacion: ${this.state.calendarizacion}`)
*/
      if(this.state.tperiodos ==='TODOS' && this.state.pperiodos!=='TODOS'){
     
        const tperiodos = (this.state.pperiodos !== 'TODOS')?
                            removeDuplicados(this.state.ctrlDataMatriculas
                                          .filter((t:IMatricula) => t.PartePeriodo === this.state.pperiodos)
                                          .filter((t:IMatricula) => t.TipoPeriodo !== 'TODOS')
                                          .filter((t:IMatricula) => t.calendarizacion !== 'TODOS')
                                          .filter((zonal:IMatricula)=> zonal.Campus === 'TODOS')
                                          ,'TipoPeriodo')[0].TipoPeriodo

                          :
                              'TODOS'


      this.setState({
        zonal: this.state.zonal,
        tperiodos,
        pperiodos:this.state.pperiodos,
      })

      }

    }
/*
    if(prevState.calendarizacion !== this.state.calendarizacion){

      console.log('-----componentUpdate-Calendarizacion----');
      console.log(`zonal: ${this.state.zonal}`)
      console.log(`tperiodos: ${this.state.tperiodos}`)
      console.log(`pperiodos: ${this.state.pperiodos}`)
      console.log(`calendarizacion: ${this.state.calendarizacion}`)

    }

 */  

  }

  handleZonal(zonal:string){

    this.setState({
      zonal,
//      pperiodos:'TODOS',
//      tperiodos:'TODOS'
    })
  }

/*
  handleTipoPrograma(tperiodos: string){

    this.setState({
      tperiodos,
    })

  }
*/

handleReset(reset:Boolean){

    this.setState({
      zonal: 'TODOS',
      tperiodos: 'TODOS',
      pperiodos:'TODOS',
      calendarizacion:'TODOS',
      select: 'TODOS',
    })
  }

  handleGrafica(vista:string){

    this.setState({
      vista,
    })

  }

  handleSelect({ target }: React.ChangeEvent<HTMLSelectElement>) {

    if(target.name === 'zonal'){
      this.setState({
        zonal:target.value,
        pperiodos:'TODOS',
        tperiodos:'TODOS',
        calendarizacion:'TODOS' 
      })

    }
    else if (target.name ==='tperiodos'){
      this.setState({
        tperiodos:target.value,
        pperiodos:'TODOS',//this.state.tperiodos
        calendarizacion:'TODOS',
      })

    }
    else if (target.name ==='pperiodos'){
      this.setState({
        pperiodos:target.value,
        calendarizacion:'TODOS',
      })

    }
    else if (target.name === 'calendarizacion'){
      this.setState({
        calendarizacion: target.value
      })
    }

  }

  handlePPeriodo(pperiodos:string){
    this.setState({
      pperiodos
    })
  }

 render() {

  const listTPeriodos = ctrlDataMatriculas.getMatriculasxZonalId_Tperiodo(this.state.ctrlDataMatriculas,this.state.zonal)
                                          .map((tperiodos:IMatricula,i :number)=>
                                                                <option value={tperiodos.TipoPeriodo}
                                                                        key={'tperiodo-'+i.toString()}
                                                                >
                                                                  {tperiodos.TipoPeriodo}
                                                                </option>
                                                              )

  const listPPeriodos = ctrlDataMatriculas.getMatriculasxZonalIdxTperiodo_Pperiodo(this.state.ctrlDataMatriculas,
                                                                                   this.state.zonal,
                                                                                   this.state.tperiodos,
                                                                                   this.state.pperiodos
                                                                                   )
                                                                 .map((pp:IMatricula,i:number)=>
                                                                <option value={pp.PartePeriodo}
                                                                      key={'TipoPeriodo-'+i.toString()}
                                                                >
                                                                {pp.PartePeriodo}
                                                                </option>
                                                              )

  const listaCalendarizacion = ctrlDataCalendarizacion.getCalendarizacionxZonalxTPeriodoxPPeriodo(this.state.ctrlDataMatriculas, this.state.zonal,this.state.tperiodos,this.state.pperiodos)
                                          .map((ce:IMatricula,i:number)=>
                                               <option value={ce.calendarizacion}
                                                     key={'cursoEstado-'+i.toString()}
                                             >
                                               {ce.calendarizacion}
                                             </option>
                                           )

  const listZonales = ctrlDataMatriculas.getMatriculasxTperiodoxPperiodoxCalendarizacion_ZonalId(this.state.ctrlDataMatriculas,
                                                                                                 'TODOS',
                                                                                                 'TODOS',
                                                                                                 'TODOS')
    //, this.state.pperiodos,this.state.calendarizacion)
                                        .map((zonal:IMatricula,i :number)=>
                                          ctrlData.getZonalxId_Name(zonal.Zonal).map((z:IGZonal,j:number)=>
                                            <option value={zonal.Zonal}
                                                    key={'zonal-'+i.toString()}>
                                              { z.name_zonal }
                                            </option>
                                          )
                                        )



   return (
     <Fragment>
      <Navbar zonal={this.state.zonal} />
      {!this.state.process?
        <div className="row justify-content-md-center w-100">
         <div className="col-lg-12">
           <div
             className="card direct-chat direct-chat-warning"
             style={{ background: "rgba(255, 255, 255,1)" }}
           >
             <div className="row mt-1">
               <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                 <Pestana Vista={this.handleGrafica} />
                 <div className="row mb-lg-5 mb-md-3 mb-1">
                   {this.state.vista !== 'tbldashboard' && <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                     <div className="input-group mb-3">
                       <div className="input-group-prepend">
                         <label
                           className="input-group-text"
                           htmlFor="selectZonal"
                         >
                           Zonal
                         </label>
                       </div>
                       <select
                         className="custom-select"
                         id="selectZonal"
                         name="zonal"
                         onChange={this.handleSelect}
                         value={this.state.zonal}
                         defaultValue={this.state.prevZonal}
                       >
                         <option value="TODOS">Todos</option>
                         {listZonales}
                       </select>
                     </div>
                   </div>}
                  { this.state.vista !== 'tbldashboard' &&
                   <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                     <div className="input-group mb-3">
                       <div className="input-group-prepend">
                         <label
                           className="input-group-text"
                           htmlFor="selectZonal"
                         >
                           Programa
                         </label>
                       </div>
                       <select
                          className="custom-select"
                          id="selectTPeriodo"
                          name="tperiodos"
                          onChange={this.handleSelect}
                          value={this.state.tperiodos}
                          //defaultValue={this.state.prevTPeriodo}
                       >
                         <option value="TODOS">Todos</option>
                         {listTPeriodos}
                       </select>
                     </div>
                   </div>}
                  { this.state.vista !== 'tbldashboard' &&
                   <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                     <div className="input-group mb-3">
                       <div className="input-group-prepend">
                         <label
                           className="input-group-text"
                           htmlFor="selectZonal"
                         >
                           Periodo
                         </label>
                       </div>
                       <select
                          className="custom-select"
                          id="selectTPeriodo"
                          name="pperiodos"
                          onChange={this.handleSelect}
                          value={this.state.pperiodos}
                          defaultValue={this.state.prevPPeriodo}
                       >
                         <option value="TODOS">Todos</option>
                         {listPPeriodos}
                       </select>
                     </div>
                   </div>}
                   { this.state.vista !== 'tbldashboard' &&
                    <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <label
                            className="input-group-text"
                            htmlFor="selectCalendarizacion"
                          >
                            Calendarización
                          </label>
                        </div>
                        <select
                          className="custom-select"
                          id="selectCalendarizacion"
                          name="calendarizacion"
                          onChange={this.handleSelect}
                          value={this.state.calendarizacion}
                        >
                          <option value="TODOS">Todos</option>
                          {listaCalendarizacion}
                        </select>
                      </div>
                    </div>
                    }
                 </div>
                 {this.state.vista === "tbldashboard" && !this.state.process? (
                   <>
                    <div className="row justify-content-center">
                        <div className="col-12">
                          {/*<div className="row">
                            <div className="col-12">
                              <div className="h3 text-bold text-center mx-3">
                                 Indicadores claves de desempeño del proceso de matrículas (KPI) - {this.state.pperiodos}
                              </div>
                           </div>
                          </div>*/}
                          <div className="mx-auto" style={{ width:'96%',}}>
                            <div className='w-100 d-flex flex-wrap justify-content-around'>
                              <div className="t-ancho t-alto_2 mb-4" >
                                <GraficaKpiSvgCalendarizacion
                                        reportes={this.state.ctrlDataMatriculas}
                                        zonal={this.state.zonal}
                                        tperiodo={'TODOS'}
                                        pperiodo={'202220-3 y 4'}
                                        calendarizacion ={'TODOS'}
                                        title={ 'Blackboard vs SINFO ' }
                                        formula='Matriculas Blackboard vs SINFO'
                                        denominadorTitulo={'Matrículas en Blackboard'}
                                        divisorTitulo={'Inscripciones en SINFO'}

                                />
                              </div>
                              <div className="t-ancho t-alto_2 mb-4">
                                <GraficaKpiSvgAsignacionDirecta
                                          reportes = {this.state.ctrlDataPkiHoras}
                                          zonal = {'TODOS'}
                                          title={'Instructores JC sobreasignados'}
                                          formula={'Instructores JC sobreasignados'}
                                          denominadorTitulo={'Instructores JC con horarios'}
                                          divisorTitulo={'Instructores JC'}
                                />
                              </div>
                              <div className="t-ancho t-alto_2 mb-4"> 
                                <GraficaKpiSvgNotas
                                          reportes = {this.state.ctrlDataPkiNotas}
                                          zonal = {'TODOS'}
                                          title={'Cursos finalizados con notas completas'}
                                          formula={'Cursos finalizados con notas completas'}
                                          denominadorTitulo={'Cursos finalizados con notas completadas'}
                                          divisorTitulo={'Total de cursos finalizados'}
                                />
                              </div>
                              <div className="t-ancho t-alto_2 mb-4">
                                <GraficaApexComboChart
                                          reportes = {this.state.ctrlDataMatriculas}
                                          zonal = {'TODOS'}
                                          title={'vista por programa y calendarización '}
                                    />
                              </div>
                              <div className="t-ancho t-alto_2  mb-4"  >
                                <GraficaKpiSvgInstructores
                                          reportes = {this.state.ctrlDataPkiSinInstructorIniciado}
                                          zonal = {'TODOS'}
                                          title={'NRCs que debían haber iniciado pero carecen de instructor (Todos los periodos)'}
                                          texto1={'Debieron Iniciar'}
                                          formula={''}
                                          denominadorTitulo={'NRCs que carecen de instructor'}
                                          divisorTitulo={'NRCs que deben haber iniciado'}
                                />
                              </div>
                              <div className="t-ancho t-alto_2 mb-4" >
                                <GraficaKpiSvgInstructores
                                          reportes = {this.state.ctrlDataPkiSinInstructorProximo}
                                          zonal = {'TODOS'}
                                          title={'NRCs que iniciarán en menos de 15 días pero carecen de instructor (Todos los periodos)'}
                                          texto1={'Están por Iniciar'}
                                          formula={''}
                                          denominadorTitulo={'NRCs que carecen de instructor'}
                                          divisorTitulo={'NRCs que iniciaran en menos de 15 días'}
                                />
                              </div>
                              <div className="t-ancho t-alto_2 mb-4" >
                                <GraficakpiSvgFueraRango
                                          reportes = {this.state.ctrlDatFueraRangoIniciado}
                                          zonal = {'TODOS'}
                                          title={'NRCs que debían haber iniciado pero están fuera de rango (Todos los periodos)'}
                                          formula={'NRCs que debían haber iniciado'}
                                          nrcbajoRango={'Por poco alumnado'}
                                          nrcSobreRango={'Por exceder el limite'}
                                          nrcTotal={'Migrados con excepción'}
                                          denominador={'NRCs fuera de rango sin migrar que debian haber iniciado'}
                                          divisor={'NRCs fuera de rango que debian haber iniciado'}
                                />
                              </div>
                              <div className="t-ancho t-alto_2 mb-4" >
                                <GraficakpiSvgFueraRango
                                          reportes = {this.state.ctrlDatFueraRangoProximo}
                                          zonal = {'TODOS'}
                                          title={'NRCs que iniciarán en menos de 15 días pero están fuera de rango (Todos los periodos)'}
                                          formula={'NRCs que iniciarán'}
                                          nrcbajoRango={'Por poco alumnado'}
                                          nrcSobreRango={'Por exceder el limite'}
                                          nrcTotal={'Migrados con excepción'}
                                          denominador={'NRCs fuera de rango sin migrar que iniciaran en menos 15 días'}
                                          divisor={'NRCs fuera de rango que iniciaran en menos 15 días'}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                   </>
                 ) : (
                   <>
                   </>
                 )}
                 {this.state.vista === "grafica"  || this.state.process? (
                   <>
                      <div className="row">
                        <div className="col-xl-3 col-lg-4 col-12 ">
                          <div className="row mt-5 justify-content-center ">
                            <div className="col-10 ">
                              <TarjetaH
                                      reportes={this.state.ctrlDataMatriculas}
                                      tperiodo={this.state.tperiodos}
                                      zonal={this.state.zonal}
                                      pperiodo={this.state.pperiodos}
                                      calendarizacion ={this.state.calendarizacion}
                                  />
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-9 col-lg-8 col-12">
                          <div className="row">
                            <div className="col-12">
                              <div className='d-flex flex-row justify-content-between container align-items-start mt-2' >
                                <div className="h3 text-center text-lg text-md text-bold">
                                  Porcentaje de avance de matrículas en Blackbloard con relación a SINFO
                                </div>
                                <div className='w-30'  >
                                  <img src="./images/barraColores.jpg" width={'100%'} alt={"Leyenda de Colores"} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-lg-12 col-12">
                              <div className="row">
                                <div className="col-lg-6 col-12">
                                  <div className="text-center">
                                    Vista por zonal/Sede
                                  </div>
                                  {
                                    ( this.state.zonal === 'TODOS' )?
                                      <GraficaBarCharZonales
                                        reportes={this.state.ctrlDataMatriculas}
                                        zonal={this.state.zonal}
                                        tperiodo={this.state.tperiodos}
                                        pperiodo={this.state.pperiodos}
                                        calendarizacion ={this.state.calendarizacion}
                                      />
                                    :
                                      <></>
                                }
                                {
                                  ( this.state.zonal !== 'TODOS' )?
                                    <GraficaBarCharCampus
                                      reportes={this.state.ctrlDataMatriculas}
                                      zonal={this.state.zonal}
                                      tperiodo={this.state.tperiodos}
                                      pperiodo={this.state.pperiodos}
                                      calendarizacion ={this.state.calendarizacion}
                                    />
                                  :
                                    <></>
                                }
                                </div>
                                <div className="col-lg-6 col-12">
                                  <div className="text-center ">
                                    Vista por periodo
                                  </div>
                                  <GraficaCharPeriodos
                                    reportes={this.state.ctrlDataMatriculas}
                                    zonal={this.state.zonal}
                                    tperiodo={this.state.tperiodos}
                                    pperiodo={this.state.pperiodos}
                                    calendarizacion ={this.state.calendarizacion}
                                    />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                   </>
                 ) : (
                   <></>
                 )}
                 {this.state.vista === "tblPendientes"  && !this.state.process? (
                   <>
                     <div className="row justify-content-center">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-12">
                              <div className="h3 text-bold text-center">
                                Impedimentos de matrículas por porcentaje { this.state.zonal==='TODOS'?  '- Nacional' : '- Zonal '+ ctrlData.getZonalxId_Name(this.state.zonal)[0].departamento}
                              </div>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-xl-3 col-lg-4 col-12">
                              <div className="row justify-content-center">
                                <div className="col-10">
                                  <TarjetaImpedimento
                                      reportes={this.state.ctrlDataPendienteMatriculas}
                                      zonal={this.state.zonal}
                                      tperiodo={this.state.tperiodos}
                                      pperiodo={this.state.pperiodos}
                                      calendarizacion ={this.state.calendarizacion}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6  col-12">
                              <GraficaApexRadarPendMatricula
                                    reportes={this.state.ctrlDataPendienteMatriculas}
                                    zonal={this.state.zonal}
                                    tperiodo={this.state.tperiodos}
                                    pperiodo={this.state.pperiodos}
                                    calendarizacion ={this.state.calendarizacion}
                                />
                            </div>
                            <div className="col-xl-3 col-lg-2 col-12">
                              <TarjetaImpedimentoCursos
                                  reportes={this.state.ctrlDataPendienteMatriculas}
                                  zonal={this.state.zonal}
                                  tperiodo={this.state.tperiodos}
                                  pperiodo={this.state.pperiodos}
                                  calendarizacion ={this.state.calendarizacion}
                                />
                            </div>
                          </div>
                        </div>
                     </div>
                   </>
                 ) : (
                   <></>
                 )}
                 {this.state.vista === "tblAntiguedades"  && !this.state.process? (
                   <>
                     <div className="row justify-content-center">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-12">
                              <div className="h3 text-bold text-center">
                                Porcentajes de casos sin migrar por días  { this.state.zonal==='TODOS'?  'nivel nacional' : 'zonal '+ ctrlData.getZonalxId_Name(this.state.zonal)[0].departamento}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-lg-3 col-12">
                                <div className="row justify-content-center">
                                  <div className="col-10">
                                    <TarjetaMotivos
                                      reportes={this.state.ctrlDataPendienteMatriculas}
                                      zonal={this.state.zonal}
                                      tperiodo={this.state.tperiodos}
                                      pperiodo={this.state.pperiodos}
                                      calendarizacion ={this.state.calendarizacion}

                                    />
                                  </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-12">
                                <GraficaBubbleMotivos
                                  reportes={this.state.ctrlDataPendienteMatriculas}
                                  tperiodo={this.state.tperiodos}
                                  pperiodo={this.state.pperiodos}
                                  zonal={this.state.zonal}
                                  calendarizacion ={this.state.calendarizacion}
                                />
                            </div>
                          </div>
                        </div>
                     </div>
                   </>
                 ) : (
                   <></>
                 )}
                 {this.state.vista === "tblDatos"  && !this.state.process? (
                   <>
                     <div className="row">
                        <div className="col-12">
                          <div className="h3 text-bold text-center ">
                            Impedimentos de matrículas
                          </div>
                        </div>
                        <div className="col-12">
                          <GraficoTablaResumen
                            reportes={this.state.ctrlDataPendienteMatriculas}
                            zonal = {this.state.zonal}
                            tperiodo={this.state.tperiodos}
                            pperiodo={this.state.pperiodos}
                            calendarizacion ={this.state.calendarizacion}
                            Zonal={this.handleZonal}
                          />
                        </div>
                    </div>
                   </>
                 ) : (
                   <></>
                 )}
               </div>
             </div>
           </div>
         </div>
        </div>
      :
          <Procesando />
      }
     </Fragment>
   );
  }
}
