import React, { Component } from 'react'
import ctrlZonales from '../../controller/Graficas/CtrlZonales.ctrl'
import { IPendienteMatricula } from '../../models/Grafica/PendienteMatricula.model'
import { Chart } from "react-google-charts"
import {  OrdenarReverse } from '../../lib/source'
import ctrlData from '../../controller/Graficas/CtrlData.ctrl'

import '../../styles/graficaTabla.css'
interface IProps {
  reportes:IPendienteMatricula[]
  pperiodo:string
  tperiodo:string
  zonal: string
  Zonal: Function
  calendarizacion:string
}
interface IState {
  index:number
}

export default class GraficoTablaResumen extends Component<IProps, IState> {
  private datos: any = [];

  constructor(props: IProps) {
    super(props);

    this.state ={
      index:100
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevState.index !== this.state.index) {
      this.props.Zonal(
        ctrlZonales.getZonalxNameZonal(this.datos[this.state.index + 1][0]).id
      );
    }
  }

  render() {
    // let datos: any = []

    this.datos = [
      [
        " ",
        "% Avance matricula",
        "Matriculas sinfo",
        "Matriculas pendientes",
        "Cursos Finalizados",
        "Sin instructor",
        "Sin contrato",
        "Fuera de rango",
        "Exceso horas",
        "Sin frecuencia",
        "Sin pago",
        "Fuera de curricula",
        "Sin usuario",
        "Solo sin usuario",
        "Incumple hrs diseño",
      ],
    ]

  
   

    let rst: IPendienteMatricula[] = []
    let totales: IPendienteMatricula[] = []

    if(this.props.zonal ==='TODOS'){

      rst = OrdenarReverse(
        this.props.reportes
          .filter((rpt: IPendienteMatricula) => rpt.Zonal !== "TODOS")
          .filter((rpt: IPendienteMatricula) => rpt.Zonal !== "nd")
          .filter((rpt: IPendienteMatricula) => rpt.Campus === "TODOS")
          .filter((rpt: IPendienteMatricula) => rpt.PartePeriodo === this.props.pperiodo)
          .filter((rpt: IPendienteMatricula) => rpt.TipoPeriodo === this.props.tperiodo)
          .filter((rpt: IPendienteMatricula) => rpt.Calendarizacion === this.props.calendarizacion)
          ,"Porc_Matriculas_BB")


      totales = this.props.reportes
                              .filter((rpt: IPendienteMatricula) => rpt.Zonal  === "TODOS")
                              .filter((rpt: IPendienteMatricula) => rpt.Campus === "TODOS")
                              .filter((rpt: IPendienteMatricula) => rpt.PartePeriodo === this.props.pperiodo)
                              .filter((rpt: IPendienteMatricula) => rpt.TipoPeriodo === this.props.tperiodo)
                              .filter((rpt: IPendienteMatricula) => rpt.Calendarizacion === this.props.calendarizacion)

      }
    else {
       rst = OrdenarReverse(
         this.props.reportes
           .filter((rpt: IPendienteMatricula) => rpt.Zonal === this.props.zonal)
           .filter((rpt: IPendienteMatricula) => rpt.Campus !== "TODOS")
           .filter((rpt: IPendienteMatricula) => rpt.PartePeriodo === this.props.pperiodo)
           .filter((rpt: IPendienteMatricula) => rpt.TipoPeriodo === this.props.tperiodo)
           .filter((rpt: IPendienteMatricula) => rpt.Calendarizacion === this.props.calendarizacion)
           ,"Porc_Matriculas_BB")

       totales = this.props.reportes
         .filter((rpt: IPendienteMatricula) => rpt.Zonal === this.props.zonal)
         .filter((rpt: IPendienteMatricula) => rpt.Campus === "TODOS")
         .filter((rpt: IPendienteMatricula) => rpt.PartePeriodo === this.props.pperiodo)
         .filter((rpt: IPendienteMatricula) => rpt.TipoPeriodo === this.props.tperiodo)
         .filter((rpt: IPendienteMatricula) => rpt.Calendarizacion === this.props.calendarizacion)
    }

      rst.map((rpt: IPendienteMatricula) =>
        this.datos.push([
          this.props.zonal === "TODOS" ? ctrlZonales.getZonalxId_nameZonal(rpt.Zonal):ctrlData.getCampusxCode(rpt.Campus),
          rpt.Porc_Matriculas_BB,
          rpt.Matriculas_SINFO,
          rpt.Mat_Pendientes,
          rpt.Curso_Finalizado,
          rpt.Sin_Instructor,
          rpt.Sin_Contrato,
          rpt.Fuera_de_rango,
          rpt.Exceso_Horas,
          rpt.Sin_Frecuencia,
          rpt.Falta_de_pago,
          rpt.Sin_patron,
          rpt.Sin_usuario,
          rpt.Solo_sin_Usuario,
          rpt.Incumple_Hrs_Diseno,
        ])
      )



/*
      rst
        .filter((rpt: IPendienteMatricula) => rpt.Zonal === this.props.zonal)
        .filter((rpt: IPendienteMatricula) => rpt.Campus === "TODOS")
        .map((rpt: IPendienteMatricula) =>
          this.datos.push([
            "totales",
            rpt.Porc_Matriculas_BB,
            rpt.Mat_Pendientes,
            rpt.Matriculas_SINFO,
            rpt.Curso_Finalizado,
            rpt.Sin_Instructor,
            rpt.Sin_Contrato,
            rpt.Fuera_de_rango,
            rpt.Exceso_Horas,
            rpt.Sin_Frecuencia,
            rpt.Falta_de_pago,
            rpt.Sin_patron,
            rpt.Sin_usuario,
            rpt.Solo_sin_Usuario,
            rpt.Incumple_Hrs_Diseno,
          ])
        )
*/
        totales
          .map((rpt: IPendienteMatricula) =>
            this.datos.push([
              'Total ' + ctrlData.getZonalxId_Name(this.props.zonal)[0].name_zonal,
              rpt.Porc_Matriculas_BB,
              rpt.Matriculas_SINFO,
              rpt.Mat_Pendientes,
              rpt.Curso_Finalizado,
              rpt.Sin_Instructor,
              rpt.Sin_Contrato,
              rpt.Fuera_de_rango,
              rpt.Exceso_Horas,
              rpt.Sin_Frecuencia,
              rpt.Falta_de_pago,
              rpt.Sin_patron,
              rpt.Sin_usuario,
              rpt.Solo_sin_Usuario,
              rpt.Incumple_Hrs_Diseno,
            ])
          );


    return (
   
      <Chart
        chartType="Table"
        width="100%"
        height="100%"
        data={this.datos}
        options={{
          title: "Detalle de pendientes por zonales",
          curveType: "function",
          legend: { position: "bottom" },
          pageSize: 25,
          frozenColumns: 1,
          cssClassNames: {
            headerCell: "TableHeaderCell",
            tableCell :'TableCell'
          },
          firstRowNumber: 2,
          sort: "disable",

        }}
        chartEvents={[
          {
            eventName: "select", // "ready",
            callback: ({ chartWrapper, google }) => {
              const chart: any = chartWrapper.getChart();
              let row;

              google.visualization.events.addListener(
                chart,
                "select",
                async (e) => {
                  row = chart.getSelection()[0];

                  if (row !== undefined) {
                    this.setState({
                      index: chart.getSelection()[0].row,
                    });
                  }
                }
              );
            },
          },
        ]}
      />
      
    );
  }
}