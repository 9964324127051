import zonales from  '../../data/JSON_zonales.json'
import campus from '../../data/JSON_Campus.json'
import mapa from  '../../data/JSON_mapa.json'
import pendienteMatricula from '../../data/JSON_Pendiente_Matricula.json'
import cursosEstado from '../../data/JSON.CursosEstado.json'


import { IGZonal } from '../../models/Grafica/zonal.model'
import { IMatricula } from '../../models/Grafica/Matriculas.model'
import { IGMapa } from '../../models/Grafica/Mapa.model'
import { IPendienteMatricula } from '../../models/Grafica/PendienteMatricula.model'
import { ITipoPeriodo } from '../../models/Grafica/TipoPeriodo.model'
import { Ordenar, removeDuplicados } from '../../lib/source'
import { IGCampus } from '../../models/Grafica/campus.model'
import { ICursoEstado } from '../../models/Grafica/CursoEstado.model'
import ctrlColores from './CtrlColores.ctrl'





class CtrlData {



  getZonales(){
    return zonales.zonales as IGZonal[]
  }


  getCampus(){
    return campus.campus as IGCampus[]
  }

  getMapa(){

    return mapa.departamentos as IGMapa[]
  }

  /*
  getMatriculas(){
    return matriculas as IMatricula[]
  }
  */

  getCursosEstado(){
    return cursosEstado.estado as ICursoEstado[]
  }

  getCursoEstado_etiqueta(valor:string){

      const cursoEstados:ICursoEstado[] = this.getCursosEstado()

      return  cursoEstados.filter((ce:ICursoEstado)=> ce.valor === valor).length > 0 ? cursoEstados.filter((ce:ICursoEstado)=> ce.valor === valor)[0].etiqueta : 'No definido'

  }

  getCampusxCode(code:string){

    const campus = this.getCampus()
    let campu:string =''

    campu = campus.filter((c:IGCampus)=> c.stvcamp_code === code).length > 0 ? campus.filter((c:IGCampus)=> c.stvcamp_code === code)[0].stvcamp_desc : 'No declarada'

    return campu

  }

  async getMatriculasFile() {

    let matriculas:IMatricula[] = []
/*
    await fetch('/data/JSON_Matricula.json')
      .then((db) => db.json())
      .then((data) => {
        matriculas = data
    })

    console.log(matriculas)
*/
    const pendientesMatriculas:IPendienteMatricula[] = await this.getPendienteMatriculaFile()


    pendientesMatriculas.map((pmat:IPendienteMatricula)=>
      matriculas.push({
        PartePeriodo:             pmat.PartePeriodo,
        TipoPeriodo:              pmat.TipoPeriodo,
        Zonal:                    pmat.Zonal,
        Campus:                   pmat.Campus,
        calendarizacion:          pmat.Calendarizacion,
        inscripciones_sinfo:      pmat.Inscripciones_SINFO,
        matricula_sinfo:          pmat.Matriculas_SINFO,
        matricula_bb:             pmat.Matriculas_BB,
        Mat_Pendientes:           pmat.Mat_Pendientes,
        Mat_Pendientes_Etiqueta:  pmat.Mat_Pendientes_Etiqueta,
        Porc_Mat:                 pmat.Porc_Matriculas_BB,
        Porc_Pend:                pmat.Mat_Pendientes,
        Color_Mat:                ctrlColores.getAsignacionColorEtiqueta_Fondo(pmat.Porc_Matriculas_BB)?? '#333',
        Porc_Cursos:              0,
        Color_Curso:              '#222',
        Color_Letra:              ctrlColores.getAsignacionColorEtiqueta_Letra(pmat.Porc_Matriculas_BB)?? '#333',
        Matriculas_24h:           pmat.Ult_24_horas,
        DiasSinMigrar:            pmat.Antiguedad
      })

    )

    return matriculas as IMatricula[]

  }


  async getZonales_vigentes(){

    const data:IPendienteMatricula[] = await this.getPendienteMatricula()

    let zonales:IPendienteMatricula =  removeDuplicados(data,'Zonal')

    return zonales

  }


   getPeriodos_TipoPeriodoxZonal(Matriculas:IMatricula[], zonal:string){

    let matr:IMatricula[] =  []//this.getMatriculas()
    let newMatr:ITipoPeriodo[] = []

    matr = Matriculas.filter((tperiodo:IMatricula)=> tperiodo.Zonal === zonal)
               .filter((tperiodo:IMatricula)=> tperiodo.PartePeriodo === 'TODOS')
               .filter((tperiodo:IMatricula)=> tperiodo.TipoPeriodo !== 'TODOS')
               .filter((tperiodo:IMatricula)=> tperiodo.Campus === 'TODOS')

        matr.map((mat:IMatricula) =>
            newMatr.push({
                PartePeriodo:             mat.PartePeriodo ,
                TipoPeriodo:              mat.TipoPeriodo,
                tperiodo:                 mat.TipoPeriodo.replace('programas','').replace('Prog.',''),
                Zonal:                    mat.Zonal ,
                Campus:                   mat.Campus,
                calendarizacion:          mat.calendarizacion,
                inscripciones_sinfo:      mat.inscripciones_sinfo,
                matricula_sinfo:          mat.matricula_sinfo,
                matricula_bb:             mat.matricula_bb,
                Mat_Pendientes:           mat.Mat_Pendientes ,
                Mat_Pendientes_Etiqueta:  mat.Mat_Pendientes_Etiqueta,
                Porc_Mat:                 mat.Porc_Mat ,
                Porc_Pend:                mat.Porc_Pend ,
                Color_Mat:                mat.Color_Mat ,
                Porc_Cursos:              mat.Porc_Cursos ,
                Color_Curso:              mat.Color_Curso ,
                Color_Letra:              mat.Color_Letra,
                Matriculas_24h:           mat.Matriculas_24h ,
                DiasSinMigrar:            mat.DiasSinMigrar
            })
    )


    return Ordenar(newMatr,'tperiodo')
  }


  getPendienteMatricula(){

     return pendienteMatricula as IPendienteMatricula[]
  }

  async getPendienteMatriculaFile() {

    let pendienteMatr: IPendienteMatricula[] = []

    await fetch('/data/JSON_Pendiente_Matricula.json')
      .then((db) => db.json())
      .then((data) => {
        pendienteMatr = data

      })


    return pendienteMatr

  }
/*
  async dataActualizacionJson() {

    let datos: IFile[]= []

    await fetch('/data/JSON_ActualizacionJSon.json')
      .then((db) => db.json())
      .then((data) => {
         datos = data.data

      })

      console.log(datos)

    return datos

  }

  async getActualizacionJson() {

    let datos: IFile[]= await this.dataActualizacionJson()

    return datos

  }
*/

/*
  getMatriculasxZonal(zonal:string){
    return this.getMatriculasFile().filter((mat:IMatricula)=> mat.Zonal === zonal)
  }
  */

  getMapaxId(id:string){
    return this.getMapa().filter((map:IGMapa) => map.id === id)
  }

  getZonalxId_Name(id:string){
    return this.getZonales().filter((z:IGZonal)=> z.id === id)
  }

  getMapaxZonal(zonal:string){
    return this.getMapa().filter((map:IGMapa) => map.zonal === zonal)
  }

  getZonalxAbrev(abrev:string){
    return this.getZonales().filter((z:IGZonal)=> z.abrev === abrev).length > 0 ?
                          this.getZonales().filter((z:IGZonal)=> z.abrev === abrev)[0]
                          :
                            {
                              code: 'TODOS',
                              name: 'NACIONAL',
                              abrev: 'NACIONAL',
                              id: 'TODOS',
                              name_rrhh: 'Nivel Nacional',
                              name_abrev: 'NACIONAL',
                            }
  }


  getZonal_Folder(id:string){

    const zonal:string = this.getZonalxId_Name(id)[0].name_abrev

    return zonal.replaceAll(' ','_').replaceAll('-','_')
                .replace('Todas_las_zonales','Nacional')
                .replace('Madre_de_Dios','MadreDeDios')
                .replace('San_Martín','SanMartin')
  }



}

const ctrlData = new CtrlData()
export default ctrlData