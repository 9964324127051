import React, { Component } from 'react'
import Chart from 'react-google-charts'
import ctrlData from '../../controller/Graficas/CtrlData.ctrl'
import { Ordenar } from '../../lib/source'
import { IPendienteMatricula } from '../../models/Grafica/PendienteMatricula.model'
import { IGZonal } from '../../models/Grafica/zonal.model'


interface IProps {

  reportes: IPendienteMatricula[]
  zonal: string
  tperiodo:string
  pperiodo:string

}
interface IState {

}

export default class GraficaMotivoMatricula extends Component<IProps, IState> {

  render() {

    let datos: any = []
    let titulo:string ='Causas de falta de matricula'

    const pendienteMatricula: IPendienteMatricula[]  = Ordenar(this.props.reportes.filter((rpt: IPendienteMatricula) => rpt.PartePeriodo === this.props.pperiodo)
                                                                                  .filter((rpt:IPendienteMatricula) => rpt.Campus === 'TODOS')
                                                                                  .filter((rpt: IPendienteMatricula) => rpt.TipoPeriodo === this.props.tperiodo)
                                                                                  .filter((rpt: IPendienteMatricula) => rpt.Zonal === this.props.zonal),'Porc_Mat').reverse()

    titulo += '\n'
    titulo +=  ctrlData.getZonalxId_Name(this.props.zonal).map((z:IGZonal)=>
                    z.name_abrev +
                    '\n'+ this.props.tperiodo.replace('TODOS','Programa todos')+
                    '\nPeriodo ' + this.props.pperiodo.replace('TODOS','todos')

                )


    if(pendienteMatricula.length !== 0){

      datos = [

        ['En proceso',          pendienteMatricula[0].Porc_En_Proceso ,     '#FF7777', pendienteMatricula[0].Porc_En_Proceso+'%'],
        ['Exceso de horas',     pendienteMatricula[0].Porc_Exceso_Horas ,   '#FF7777', pendienteMatricula[0].Porc_Exceso_Horas+'%'],
        ['Falta de pago',       pendienteMatricula[0].Porc_Falta_Pago ,     '#FF7777', pendienteMatricula[0].Porc_Falta_Pago+'%'],
        ['Sin Frecuencia',      pendienteMatricula[0].Porc_Frecuencia ,     '#FF7777', pendienteMatricula[0].Porc_Frecuencia+'%'],
        ['Fuera de rango',      pendienteMatricula[0].Porc_Fuera_De_Rango , '#FF7777', pendienteMatricula[0].Porc_Fuera_De_Rango+'%'],
        ['Sin instructor',      pendienteMatricula[0].Porc_Sin_Instructor , '#FF7777', pendienteMatricula[0].Porc_Sin_Instructor+'%'],
        ['Fuera de Curricula',  pendienteMatricula[0].Porc_Sin_Patron ,     '#FF7777', pendienteMatricula[0].Porc_Sin_Patron+'%'],
        ['Sin usuario',         pendienteMatricula[0].Porc_Sin_Usuario ,    '#FF7777', pendienteMatricula[0].Porc_Sin_Usuario+'%'],
        ['Solo sin usuario',    pendienteMatricula[0].Porc_Solo_Sin_Usuario,'#FF7777', pendienteMatricula[0].Porc_Solo_Sin_Usuario+'%'],
        ['Incumple hrs diseño', pendienteMatricula[0].Porc_Incumple_diseno ,'#FF7777', pendienteMatricula[0].Porc_Incumple_diseno+'%'],

      ]

      datos.sort((a:any , b:any)=> a[1] -b[1]).reverse()

      datos.unshift(['Motivos', '% Pendientes', { role: 'style' }, { role: 'annotation' }])

    }
    else
        datos.push(['Empty',0,'#fff','0%'])


  return (

	  <Chart
        width={'100%'}
        height={'38vh'}
        chartType="BarChart"
        loader={<div>Loading Chart</div>}
        data={datos}
        options={{
		            title: titulo,
                titlePosition: 'out',
                titleTextStyle:{   bold: "True", fontSize:"12"},
                hAxis: {
                      title: '% casos',
                      minValue: 0,
                      maxValue:100,
                    },
                legend: { position: "none" },
                bar: { groupWidth: "30%" , background:'#333'},
                chartArea: { /*left: 200,*/ width: "50%", height: "auto" },
		            gridlines: {color: 'transparent' },

        }}


      />
    )
  }
}
