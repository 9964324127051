import React, { Component,Fragment } from 'react'

import {   RouteComponentProps } from 'react-router-dom'
import AuthLtiContext from '../context/AuthLtiContext'
import { IPersonalAuth } from '../models/Grafica/personalAuth.model'

/*import '../styles/fondo.css'
import imagen from '../Image/fondo2.jpg'
import ctrlColores from '../controller/Graficas/CtrlColores.ctrl'
*/
interface IProps extends RouteComponentProps {}

interface IState {
    ltik: string
    rol:string
    user:string
    person: IPersonalAuth[]
    courseId:string
    app:string
    instructorId:string
    especialistaId:string
    semana:string
}


export default class Login extends Component<IProps,IState>{

    static contextType = AuthLtiContext
    //http://localhost:3000/#/?courseId=202220-NCCU-211-TEC-NRC_38222&instructorId=001404360&especialistaId=000794385&semana=2022-33&codigo=5ee6b1f968380a11a8d03465e703cc78

constructor(props:IProps){
    super(props)

    const { search } = this.props.location
    const query = new URLSearchParams(search)

    

    this.state ={
        ltik            : query.get("ltik") || '',
        rol             : query.get("rol")  ?? '', 
        user            : query.get('user') ?? '',
        person          : [],
        courseId        : query.get('courseId') ?? '',
        app             : query.get('app') ?? '',
        instructorId    : query.get('instructorId') ?? '',
        especialistaId  : query.get('especialistaId') ?? '',
        semana          : query.get('semana') ?? '',
    }
    
}

componentDidMount() {

    const lti = this.context
    lti.setUpdate(this.state.ltik,this.state.user, this.state.courseId)

    //console.log(this.state)

    if((this.state.rol.includes('institution/person') && this.state.app.includes('Analitica') && this.state.user.includes('@senati') )) {
    //if((this.state.rol.includes('institution/person') && this.state.app==='Analitica' && this.state.user.includes('@senati') )) {    
        this.props.history.push('/analitica')
        
    }
    else if (this.state.app.includes('TestCurso') && this.state.user.includes('@senati')){
        this.props.history.push('herramientas')
    }
    else    
        this.props.history.push('/NoValidate')
        
}



render(){

  /*  const fondo = {
        backgroundImage:`url(${imagen})`,
        backgroundSize:'cover',
        backgroundRepeat:'no-repeat',
        backgroundPosition:'center',

        border:'1px solid blue',
        with:'100%',
        height:'100vh'
      }
*/
    


    return (
        <Fragment>
            Inicio {this.state.rol + ' - ' + this.state.user}
           {/* <AuthenticatedTemplate>
                <Redirect to='/analitica' />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div style={ fondo } className='hiddenScrollY hiddenScrollX' >
                    <nav className="navbar navbar-expand-lg navbar-dark " style={{background: ctrlColores.getColoresInstitucionalxId(4).etiqueta}}>
                        <Link className="navbar-brand" to="#">
                        ANALÍTICA ACADÉMICA
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                        </button>
                    </nav>
                
                    <div className="row justify-content-center">
                        <div className="col-xl-2 col-lg-3 col-md-4 col-10  mt-5 " >
                            <div className='w-100 d-flex justify-content-center align-items-center' style={{height:'70vh'}} >
                                <div className="card  w-100 " >
                                    <div className="card-header " style={{background: ctrlColores.getColoresInstitucionalxId(4).etiqueta}} > 
                                        <div className='logo'>
                                            <img src="/images/logo.svg" className='center' alt="" height={'20px'}/>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="text-center">
                                            <label className="form-check-label text-center" style={{color: ctrlColores.getColoresInstitucionalxId(4).etiqueta }} >
                                                    Analítica Académica
                                            </label> 
                                        </div>
                                        <div className='w-100 mt-3'>
                                            <SignInButton />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </UnauthenticatedTemplate>*/}
        </Fragment>
    )
  }
}
