import React, { Component, Fragment } from 'react'
import { RouteComponentProps } from 'react-router-dom';
import AnexoSchema from '../context/AnexoContext';
import ctrlControlAnexo03 from '../controller/Seguimiento/ctrlControlAnexo03.ctrl';
import { IAnexo03Schema } from '../models/mongo/anexo.schema';
import { IDetalleSchema } from '../models/mongo/detail.schema';
import { ICursoSeguimiento } from '../models/Seguimiento/cursoseguimiento.model';
import  '../styles/modal.css'

interface IProps extends RouteComponentProps {
  open:boolean
  closed:Function
  curso:ICursoSeguimiento
  especialistaId:string  
  semana:string
  tipo:string
  [item:string]:any
  
}

interface IState  {

  status:boolean,
  activo:boolean
  

}

export default class ModalSaveConInstructor extends Component<IProps, IState> {

    static contextType?: React.Context<any> | undefined = AnexoSchema
    
    constructor(props: IProps) {
        super(props);

        this.handleClose = this.handleClose.bind(this)
        this.handleSave  = this.handleSave.bind(this)

        this.state = {
            status: false,
            activo:false,
        }
    }

    componentDidMount() {
        this.setState({
        status: this.props.open,
        });
    }

    handleClose() {
        this.props.closed()
    }

    handleOpen() {
        this.setState({ status: true });
    }


    handleValor(){

        const rst =
        (this.handleRpta("1") * 2) +
        (this.handleRpta("2") * 1) +
        (this.handleRpta("3") * 0)

        return rst

    }

    handleRpta(rpt: string) {
        let siCumple: number = 0;

        siCumple  = this.props.pregunta1 === rpt ? 1 : 0;
        siCumple += this.props.pregunta2 === rpt ? 1 : 0;
        siCumple += this.props.pregunta3 === rpt ? 1 : 0;
        siCumple += this.props.pregunta4 === rpt ? 1 : 0;
        siCumple += this.props.pregunta5 === rpt ? 1 : 0;
        siCumple += this.props.pregunta6 === rpt ? 1 : 0;
        siCumple += this.props.pregunta7 === rpt ? 1 : 0;
        siCumple += this.props.pregunta8 === rpt ? 1 : 0;
        siCumple += this.props.pregunta9 === rpt ? 1 : 0;
        siCumple += this.props.pregunta10 === rpt ? 1 : 0;
        siCumple += this.props.pregunta11 === rpt ? 1 : 0;
        siCumple += this.props.pregunta12 === rpt ? 1 : 0;
        siCumple += this.props.pregunta13 === rpt ? 1 : 0;
        siCumple += this.props.pregunta14 === rpt ? 1 : 0;
        siCumple += this.props.pregunta15 === rpt ? 1 : 0;
        siCumple += this.props.pregunta16 === rpt ? 1 : 0;

        return siCumple;
    }

    handlePorcentaje(tipo:string){
      
      let rst:number = 0
      const maxPuntaje:number = (16 - this.handleRpta("4"))*2  

        if(maxPuntaje !== 0)
          rst = this.handleValor() *100 / maxPuntaje

        return rst.toFixed(0)
    }

    

    async handleSave(){

        this.setState({ activo:true})

        const { asistencia } = this.context

        const porcentaje = this.handlePorcentaje(this.props.tipo)

        const anexo:IAnexo03Schema = {
                            cod_curso: this.props.curso.cod_curso,
                            curso_horario: this.props.curso.curso_horario,
                            curso_cerrado:this.props.curso.curso_cerrado,
                            id_instructor: this.props.curso.id_inst,
                            instructor:this.props.curso.instructor,
                            id_seguimiento:this.props.especialistaId,
                            semana:this.props.semana, 
                            nota: this.handleValor().toString(),
                            nrc: this.props.curso.nrc,
                            periodo: this.props.curso.periodo,
                            asistencia,
                            statusInst:true,
                            tipo:this.props.tipo,
                            alumnosbb:this.props.curso.inscritosbb,
                            campus:this.props.curso.campus,
                            curso:this.props.curso.curso,
                            zonal:this.props.curso.zonal,
                            porcentaje
                            
                        }

        const {anexo03} = await ctrlControlAnexo03.Create(anexo) 
        const _anexo03:IAnexo03Schema = anexo03![0]

        const detalle:IDetalleSchema[] = [
            {anexo: _anexo03._id, question:'1', answer: this.props.pregunta1},
            {anexo: _anexo03._id, question:'2', answer: this.props.pregunta2},
            {anexo: _anexo03._id, question:'3', answer: this.props.pregunta3},
            {anexo: _anexo03._id, question:'4', answer: this.props.pregunta4},
            {anexo: _anexo03._id, question:'5', answer: this.props.pregunta5},
            {anexo: _anexo03._id, question:'6', answer: this.props.pregunta6},
            {anexo: _anexo03._id, question:'7', answer: this.props.pregunta7},
            {anexo: _anexo03._id, question:'8', answer: this.props.pregunta8},
            {anexo: _anexo03._id, question:'9', answer: this.props.pregunta9},
            {anexo: _anexo03._id, question:'10', answer: this.props.pregunta10},
            {anexo: _anexo03._id, question:'11', answer: this.props.pregunta11},
            {anexo: _anexo03._id, question:'12', answer: this.props.pregunta12},
            {anexo: _anexo03._id, question:'13', answer: this.props.pregunta13},
            {anexo: _anexo03._id, question:'14', answer: this.props.pregunta14},
            {anexo: _anexo03._id, question:'15', answer: this.props.pregunta15},
            {anexo: _anexo03._id, question:'16', answer: this.props.pregunta16 ?? '4'},
            {anexo: _anexo03._id, question:'description1', answer: this.props.description1 ?? ''},
            {anexo: _anexo03._id, question:'description2', answer: this.props.description2 ?? ''},
            {anexo: _anexo03._id, question:'description3', answer: this.props.description3 ?? ''},
        ]

        await ctrlControlAnexo03.Details(detalle)

        this.setState({ activo:false , })
        this.handleClose()

        this.props.history.push('/seguimiento/reporte')
      
    }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        status: !this.state.status,
      });
    }
  }

  render() {
    return (
      <Fragment>
        {this.state.status && (
          <div className="newmodal" tabIndex={-1}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header bg-primary text-dark">
                  <h5 className="modal-title">
                    <i className="far fa-save"></i> Enviar y Guardar
                  </h5>
                  <button
                    type="button"
                    className="btn-outline-danger"
                    aria-label="Close"
                    onClick={this.handleClose}
                  >
                    X
                  </button>
                </div>
                <div className="modal-body">
                  <i className="fas fa-exclamation-circle text-warning"></i>{" "}
                  La informacion debe ser verificada, antes de ser enviada
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    data-bs-dismiss="modal"
                    onClick={this.handleClose}
                  >
                    <i className="fas fa-times"></i> Cerrar
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={this.handleSave}
                  >
                    {
                        this.state.activo ? 
                            <>
                                <i className="fa-solid fa-spinner fa-spin"></i> Guardando...
                            </>
                            :
                            <>
                                <i className="far fa-save"></i> Guardar
                            </>
                        
                        } 
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}
