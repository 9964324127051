import { createContext } from 'react'

interface IAuthLtiContext  {
    ltik:       any
    user:       string
    courseId:   string
    setUpdate?: Function
  }

   const defaultState={
      ltik:     '',
      user:     '',
      courseId: '',

   }

const AuthLtiContext = createContext<Partial<IAuthLtiContext>>(defaultState)
// const AuthContext = React.createContext<IAuthContext>(defaultState)
  export default AuthLtiContext

   /*

const AuthContext = createContext<Partial<IAuthContext>>({})

 export default AuthContext

 */

