import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  authDev: {
    clientId: "a0794d5d-c66a-40ce-bca6-df3487ce8c9c",
    authority: "https://login.microsoftonline.com/b4a40545-7779-4b38-aff7-1f1738f80840",
    redirectUri: "https://www.gcabana.com",
   },
   auth: {
    clientId: "65852916-64f4-480a-936a-3d94aa94f32a",
    authority: "https://login.microsoftonline.com/b4a40545-7779-4b38-aff7-1f1738f80840",
    redirectUri:"https://senati-analitica.u-planner.com",
  },
   system: {
    loggerOptions: {
        loggerCallback: (level:any, message:any, containsPii:any) => {
            if (containsPii) {
                return;
            }

            switch (level) {
                case LogLevel.Error:
                    console.error(message);
                    return;
                case LogLevel.Info:
                    console.info(message);
                    return;
                case LogLevel.Verbose:
                    console.debug(message);
                    return;
                case LogLevel.Warning:
                    console.warn(message);
                    return;
            }
        }
    }
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read","Profile"],
  prompt:"select_account"
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};