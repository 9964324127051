import { IMaxTime } from "../../models/Grafica/MaxTime.model"

class CtrlMaxTime {

    async getMaxtime(){
        let datos:IMaxTime[] = []

        await fetch('/data/JSON_ActualizacionJSon.json')
          .then((db) => db.json())
          .then((data) => {
            datos = data
    
          })

        return datos
    }

    async getFechaHoraMaxTime(){
        const maxtime:IMaxTime[] = await this.getMaxtime()

        return maxtime.filter((mt:IMaxTime) => mt.tabla === 'MV_PS_ENROLAMIENTOS').length > 0 ?
                    maxtime.filter((mt:IMaxTime) => mt.tabla === 'MV_PS_ENROLAMIENTOS')[0].fecha_hora :
                    new Date('2022-01-01T00:00:00.000Z')


    }


}

const ctrlMaxTime = new CtrlMaxTime()
export default ctrlMaxTime