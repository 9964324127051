import React, { Component } from 'react'
import { removeDuplicados } from '../../lib/source'
import { IRepF02 } from '../../models/oracle/repf02.model'

interface IProps {
  reportes: IRepF02[]
}
interface IState {

}

export default class TableOracleRepF02b extends Component<IProps, IState> {

  render() {

    const tbody = removeDuplicados(this.props.reportes,'ID_ALUMNO').map((rpt:IRepF02,i:number)=>
      <tr key={'periodo-' + rpt.ID_ALUMNO + '-' + i.toString()} >
        <td align='center'>{rpt.NRC}</td>
        <td align='center'>{ rpt.ID_ALUMNO }</td>
        <td align='center'>{ rpt.CORREO_ALUMNO }</td>
        <td align='justify'>{ rpt.NOMBRE }</td>
        <td align='center'>{ rpt.ESTADO}</td>
        <td align='center'>{ rpt.PAGOS }</td>
      </tr>
    )


    return (
      <table className='table table-sm table-bordered'>
        <thead className='bg-warning text-white'>
          <tr>
            <td align='center' width='5%' >NRC</td>
            <td align='center' width='8%'>ID ALUMNO</td>
            <td align='center' width='17%'>CORREO</td>
            <td align='center' width=''   >ALUMNO - { this.props.reportes.length}</td>
            <td align='center' width='10%'>ESTADO</td>
            <td align='center' width='30%'>OBSERVACION</td>
          </tr>
        </thead>
        <tbody>
          { tbody }
        </tbody>
      </table>
    )
  }
}
