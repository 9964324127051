import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import ContenidoBody from '../component/ContenidoBody'
import AuthContext from '../context/AuthContext'

export default class Home extends Component {

    static contextType  = AuthContext

    render() {
      
        return (
            <Fragment>
                <ContenidoBody title={'SENATI - ANALITICA'} link={'Login'} page={''}>
                    <div className="row justify-content-center" >
                        <div className="col-12">

                            <div className="row justify-content-center">
                                <div className="col-lg-8 lg-md-12">
                                    <div className="card card-secondary text-dark">
                                        <div className="card-header">
                                            <h3 className="card-title"><i className="fas fa-desktop"></i> Aplicaciones</h3>
                                        </div>
                                        <div className="card-body">
                                            <Link className="btn btn-app" to='/dashboard' >
                                                <i className="fa fa-bar-chart" aria-hidden="true"></i> dashboard
                                            </Link>
                                        </div>
                                        <div className="card-body">
                                            <Link className="btn btn-app" to='/analitica' >
                                                <i className="fa fa-bar-chart" aria-hidden="true"></i> analitica
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                            <div className="row justify-content-center">
                                <div className="col-lg-8 col-md-12">
                                    <div className="card card-secondary text-dark mt-5">
                                        <div className="card-header">
                                            <h3 className="card-title"><i className="far fa-abacus"></i> Accesos</h3>
                                        </div>
                                        <div className="card-body">
                                            <a className="btn btn-app" href='https://blackboard.senati.com' target={'_blank'} rel={'noreferrer'} >
                                                <i className="fa-solid fa-chalkboard-user"></i> blackboard
                                            </a>
                                            <a className="btn btn-app" href='https://outlook.com/senati.pe' target={'_blank'} rel={'noreferrer'}>
                                                <i className="far fa-envelope"></i> correo
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContenidoBody>
            </Fragment>

        )
    }
}
