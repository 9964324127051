import { IValoresInicial } from "../../models/Grafica/ValoresIniciales.model"

class CtrlDataValoresIniciales{

    async getData(){
        
        let datos:IValoresInicial[] = []

        await fetch('/data/JSON_Combos.json')
            .then((db) => db.json())
            .then((data) => {
            datos = data
    
            })

        return datos
        
    }

    async getValorInicial(){

        const datos:IValoresInicial[] = await this.getData()

        return datos.length> 0 ? datos[0] : {zonal:"TODOS", pperiodo:"TODOS",tperiodo:"TODOS",calendarizacion:"TODOS"}

    }


}

const ctrlDataValoresIniciales = new CtrlDataValoresIniciales()
export default ctrlDataValoresIniciales