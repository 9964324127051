import React, { Component } from 'react'
import { IKpiSinInstructores } from '../../models/Grafica/PkiSinInstructor.model'
import EtiquetaKpiResultado from './EtiquetaKpiResultado'

import '../../styles/graficaTarjeta.css'
import ctrlZonales from '../../controller/Graficas/CtrlZonales.ctrl'
import GraficaMapsPkiSvgHoras from './GraficaMapsPkiSvgHoras'
import EtiquetaKpiTotal from './EtiquetaKpiTotal'


interface IProps{
    reportes:IKpiSinInstructores[]
    zonal:string
    title:string
    formula:string
    denominadorTitulo:string
    divisorTitulo:string
    texto1:string
    
}

interface IState{
    visible:boolean
    zonalId:string
    nrcPendiente:string
    nrcTotal:string
    result:string
    resultInt:any
    color:string
    letra:string
}

export default class GraficaKpiSvgInstructores extends Component<IProps,IState> {

constructor(props:IProps){
    super(props)

    this.state={
        visible:false,
        zonalId:'TODOS',
        nrcPendiente: '0',
        nrcTotal:'0',
        result:'0',
        resultInt:0,
        color:'',
        letra:''
    }

    this.handleVisible = this.handleVisible.bind(this)
    this.handleZonalId = this.handleZonalId.bind(this)
}

componentDidMount(){
    const dato:IKpiSinInstructores[] = this.props.reportes.filter((d:IKpiSinInstructores)=> d.zonal === 'TODOS')

    this.setState({
        result    : dato.length > 0 ? (dato[0].porcentaje_sin_inst).toString() : '',
        resultInt : dato.length > 0 ? dato[0].porcentaje_sin_inst              : 0,
        color:dato.length > 0 ? dato[0].color : '',
        letra:dato.length > 0 ? dato[0].color_letra : '',
        nrcPendiente :  dato.length > 0 ? dato[0].nrcs_pendientes_sin_inst.toString() : '',
        nrcTotal:       dato.length > 0 ? dato[0].nrcs_total.toString()               : '',
    })
}


handleVisible(visible:boolean){

    this.setState({
        visible
    })
}

handleZonalId(zonalId:string ){
    const dato:IKpiSinInstructores[] = this.props.reportes.filter((r:IKpiSinInstructores)=> r.zonal === zonalId)

    this.setState({
        zonalId,
        nrcPendiente :  dato.length > 0 ? dato[0].nrcs_pendientes_sin_inst.toString() : '',
        nrcTotal:       dato.length > 0 ? dato[0].nrcs_total.toString()               : '',
        result    :     dato.length > 0 ? dato[0].porcentaje_sin_inst.toString()      : '',
        resultInt :     dato.length > 0 ? dato[0].porcentaje_sin_inst                 : 0,
        color:          dato.length > 0 ? dato[0].color                               : '',
        letra:          dato.length > 0 ? dato[0].color_letra                         : '',
    })

    
}

render() {

    return (
        <>
            <div className='card w-100 card-alto' >
                <div className="card-header"
                    style={{
                        background :this.state.color,
                        color: this.state.letra
                    }}
                >
                    <h4 className="card-title text-center w-100">
                    { 'KPI: ' + this.props.title}
                    </h4>
                </div>
                <div className="card-body  "  style={{background: '#F4F4F4'}}> 
                    <div className="row">
                        <div className="col-5" >
                            <div className={ this.state.zonalId ==='TODOS' ? 'invisible': 'sobreimg_2 w-100'}>
                                <div className="card text-sm w-100"
                                    style={{ background:'#fff'}}
                                >
                                    <div className="card-header text-sm">
                                        <h4 className='card-title text-sm'>
                                            Z. { ctrlZonales.getZonalxId_nameZonal(this.state.zonalId) }
                                        </h4>
                                    </div>
                                    <div className='card-body p-2'>
                                        <h4 className='card-title w-100 h-100 text-sm '>
                                            <div className='d-flex justify-content-between mb-3'>
                                                <div>Carecen de instructor:</div>
                                                <div><b>{this.state.nrcPendiente}</b></div>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <div>{this.props.texto1}:</div>
                                                <div><b>{this.state.nrcTotal} </b></div>
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className='sobreimg_1 w-100'>
                                <EtiquetaKpiResultado
                                    resultado={ this.state.result }
                                    meta={'0%'}
                                    title={ this.props.formula}
                                    etiqueta={ this.state.color}
                                    color={ this.state.letra }
                                    funcionVisible={this.handleVisible}
                                />
                            </div>
                        </div>
                        <div className="col-1"></div>
                        <div className="col-6">
                             <div className={ this.state.visible? "formula w-100" : 'formula invisible'} >
                                <div className="container ">
                                    <div className="w-100">
                                        <EtiquetaKpiTotal
                                                denominadorTitulo={this.props.denominadorTitulo}
                                                divisorTitulo={this.props.divisorTitulo}
                                                title={ this.props.formula}
                                        />
                                        <EtiquetaKpiTotal
                                                denominadorTitulo={this.state.nrcPendiente}
                                                divisorTitulo={this.state.nrcTotal}
                                                title={ this.props.formula}
                                        />
                                    </div>
                                </div>
                            </div>
                            <GraficaMapsPkiSvgHoras     reportes={this.props.reportes}
                                                        zonal={this.props.zonal}
                                                        ZonalId={this.handleZonalId}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
  }
}
