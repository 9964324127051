import React, { Component } from 'react'
import EtiquetaKpiResultado from './EtiquetaKpiResultado'

import '../../styles/graficaTarjeta.css'
import ctrlZonales from '../../controller/Graficas/CtrlZonales.ctrl'
import { IKpiFueraRango } from '../../models/Grafica/PkiFueraRango.model'
import GraficaMapsPkiSvgFueraRango from './GraficaMapsPkiSvgFueraRango'
import EtiquetaKpiTotal from './EtiquetaKpiTotal'


interface IProps{
    reportes:IKpiFueraRango[]
    zonal:string
    title:string
    formula:string
    nrcbajoRango:string
    nrcSobreRango:string
    nrcTotal:string
    denominador?:string
    divisor?:string
}

interface IState{
    visible:boolean
    zonalId:string
    nrcbajo:string
    nrcalto:string
    rango_migrados:string
    nrcTotal:string
    result:string
    resultInt:any
    color:string
    letra:string
}

export default class GraficakpiSvgFueraRango extends Component<IProps,IState> {

constructor(props:IProps){
    super(props)

    this.state={
        visible:false,
        zonalId:'TODOS',
        nrcalto: '0',
        nrcbajo:'0',
        rango_migrados:'',
        nrcTotal:'0',
        result:'0',
        resultInt:0,
        color:'',
        letra:''
    }

    this.handleVisible = this.handleVisible.bind(this)
    this.handleZonalId = this.handleZonalId.bind(this)
}

componentDidMount(){
    const dato:IKpiFueraRango[] = this.props.reportes.filter((d:IKpiFueraRango)=> d.zonal === 'TODOS')

    this.setState({
        result    : dato.length > 0 ? (dato[0].porcentaje_fuera_rango_nomigrado).toString() : '',
        resultInt : dato.length > 0 ? dato[0].porcentaje_fuera_rango_nomigrado              : 0,
        color:dato.length > 0 ? dato[0].color : '',
        letra:dato.length > 0 ? dato[0].color_letra : '',
        nrcbajo :       dato.length > 0 ?   dato[0].nrcs_bajo_rango.toString()           : '',
        nrcalto :       dato.length > 0 ?   dato[0].nrcs_sobre_rango.toString()           : '',
        nrcTotal:       dato.length > 0 ?   dato[0].nrcs_fuera_rango_total.toString()    : '',
    })
}


handleVisible(visible:boolean){

    this.setState({
        visible
    })
}

handleZonalId(zonalId:string ){
    const dato:IKpiFueraRango[] = this.props.reportes.filter((r:IKpiFueraRango)=> r.zonal === zonalId)

    this.setState({
        zonalId,
        nrcbajo :       dato.length > 0 ?   dato[0].nrcs_bajo_rango.toString()           : '',
        nrcalto :       dato.length > 0 ?   dato[0].nrcs_sobre_rango.toString()           : '',
        nrcTotal:       dato.length > 0 ?   dato[0].nrcs_fuera_rango_total.toString()    : '',
        result:         dato.length > 0 ?   dato[0].porcentaje_fuera_rango_nomigrado.toString() : '',
        resultInt :     dato.length > 0 ?   dato[0].porcentaje_fuera_rango_nomigrado            : 0,
        color:          dato.length > 0 ?   dato[0].color : '',
        letra:          dato.length > 0 ?   dato[0].color_letra : '',
        rango_migrados: dato.length > 0 ?   dato[0].nrcs_fuera_rango_migrados.toString() : '',
    })

}

render() {

    return (
        <>
            <div className='card w-100 card-alto' >
                <div className="card-header"
                    style={{
                        background :this.state.color,
                        color: this.state.letra
                    }}
                >
                    <h4 className="card-title text-center w-100">
                    { 'KPI: ' + this.props.title}
                    </h4>
                </div>
                <div className="card-body  "  style={{background: '#F4F4F4'}}> 
                    <div className="row">
                        <div className="col-5" >
                            <div className={ this.state.zonalId ==='TODOS' ? 'invisible': 'sobreimg_2 w-100'}>
                                <div className="card text-sm w-100"
                                    style={{ background:'#fff'}}
                                >
                                    <div className="card-header">
                                        <h4 className='card-title text-sm' >
                                            Z. { ctrlZonales.getZonalxId_nameZonal(this.state.zonalId) }
                                        </h4>
                                    </div>
                                    <div className='card-body p-2'>
                                        <h4 className='card-title w-100 h-100 text-sm '>
                                            No han Migrado
                                            <div className="d-flex justify-content-between">
                                                <div>{'- ' + this.props.nrcbajoRango}:</div>
                                                <div><b>{ this.state.nrcbajo}</b></div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div>{'- ' +this.props.nrcSobreRango}:</div>
                                                <div><b>{ this.state.nrcalto}</b></div>
                                            </div>
                                            <br />
                                            <div className="d-flex justify-content-between">
                                                <div>{this.props.nrcTotal}:</div>
                                                <div><b>{ this.state.rango_migrados}</b></div>
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className='sobreimg_1 w-100'>
                                <EtiquetaKpiResultado
                                    resultado={ this.state.result }
                                    meta={'0%'}
                                    title={ this.props.formula}
                                    etiqueta={ this.state.color}
                                    color={ this.state.letra }
                                    funcionVisible={this.handleVisible}
                                />
                            </div>
                        </div>
                        <div className="col-1"></div>
                        <div className="col-6">
                             <div className={ this.state.visible? "formula w-100" : 'formula invisible'} >
                                <div className="container ">
                                    <div className="w-100">
                                        <EtiquetaKpiTotal
                                                denominadorTitulo={this.props.denominador?? ''}
                                                divisorTitulo={this.props.divisor ?? ''}
                                                title={ this.props.formula}
                                        />
                                        <EtiquetaKpiTotal
                                                denominadorTitulo={(parseInt(this.state.nrcbajo) + parseInt(this.state.nrcalto)).toString()}
                                                divisorTitulo={this.state.nrcTotal}
                                                title={ this.props.formula}
                                        />
                                    </div>
                                </div>
                            </div>
                            <GraficaMapsPkiSvgFueraRango    reportes={this.props.reportes}
                                                            zonal={this.props.zonal}
                                                            ZonalId={this.handleZonalId}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
  }
}
