import { IOraZonales } from "../models/oracle/OraZonales.model"
import ApiJson from '../ApiJson.json'


class BbZonalesCtrl{

  private static getBbZonales:BbZonalesCtrl
  private zonales:IOraZonales[] = []
  private auth:Boolean = false

  private constructor(){

  }

  static getZonales(){

    if(!BbZonalesCtrl.getBbZonales){
      BbZonalesCtrl.getBbZonales = new BbZonalesCtrl()
    }

    return BbZonalesCtrl.getBbZonales
  }

  async queryApi(){

    await fetch(`${ApiJson.Server}/api/oracle/zonales`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          'token': sessionStorage.getItem('token') || ''
        },
      })
      .then((db) => db.json())
      .then((data) => {

        this.zonales = (data.auth) ? data.data : []
        this.auth = (!!data.auth) ? true : false

        sessionStorage.setItem('token', data.auth)

      })
 }

  async All(){
    await this.queryApi()
    return { zonales: this.zonales , auth: this.auth }
  }

  async Auth(){
    return this.auth
  }

}

const BbZonales = BbZonalesCtrl.getZonales()
export default BbZonales
