import zonales from  '../../data/JSON_zonales.json'
import { IGZonal } from '../../models/Grafica/zonal.model'


class CtrlZonales {

  getZonales(){

    return zonales.zonales as IGZonal[]
  }

  getZonalxAbrev(abrev:string){
    return this.getZonales().filter((z:IGZonal)=> z.abrev === abrev).length > 0 ?
                          this.getZonales().filter((z:IGZonal)=> z.abrev === abrev)[0]
                          :
                          {
                            code: 'nd',
                            name: 'NACIONAL',
                            departamento:'Nivel nacional',
                            abrev: 'NACIONAL',
                            id: 'TODOS',
                            name_rrhh: 'Nivel Nacional',
                            name_abrev: 'NACIONAL',
                            name_zonal:"Todas",
                            name_rpt: 'Nacional',
                            sharePoint:'Nacional'
                          }
  }

   getZonalxId(id:string){
    return this.getZonales().filter((z:IGZonal)=> z.id === id).length > 0 ?
                          this.getZonales().filter((z:IGZonal)=> z.id === id)[0]
                          :
                          {
                            code: 'nd',
                            name: 'NACIONAL',
                            departamento:'Nivel nacional',
                            abrev: 'NACIONAL',
                            id: 'TODOS',
                            name_rrhh: 'Nivel Nacional',
                            name_abrev: 'NACIONAL',
                            name_zonal:"Todas",
                            name_rpt: 'Nacional',
                            sharePoint:'Nacional'
                          }
  }

  getZonalxId_departamento(id:string){
    return this.getZonales().filter((z:IGZonal)=> z.id === id).length > 0 ?
                          this.getZonales().filter((z:IGZonal)=> z.id === id)[0].departamento
                          :
                            {
                              departamento:'Nivel nacional',

                            }
  }

  getZonalxId_nameZonal(id:string){
    return this.getZonales().filter((z:IGZonal)=> z.id === id).length > 0 ?
    this.getZonales().filter((z:IGZonal)=> z.id === id)[0].name_zonal
    :
      {
        departamento:'Nivel nacional',

      }

  }

  getZonalxDepartamento(departamento:string){
    return this.getZonales().filter((z:IGZonal) => z.departamento === departamento).length > 0
            ?
            this.getZonales().filter((z:IGZonal) => z.departamento === departamento)[0]
            :
            {
              code: 'nd',
              name: 'NACIONAL',
              departamento:'Nivel nacional',
              abrev: 'NACIONAL',
              id: 'TODOS',
              name_rrhh: 'Nivel Nacional',
              name_abrev: 'NACIONAL',
              name_zonal:"Todas",
              name_rpt: 'Nacional',
              sharePoint:'Nacional'
            }
         

  }

  getZonalxNameZonal(namezonal:string){
    return this.getZonales().filter((z:IGZonal) => z.name_zonal === namezonal).length > 0
            ?
            this.getZonales().filter((z:IGZonal) => z.name_zonal === namezonal)[0]
            :
            {
              code: 'TODOS',
              name: 'NACIONAL',
              departamento:'Nivel nacional',
              abrev: 'NACIONAL',
              id: 'TODOS',
              name_rrhh: 'Nivel Nacional',
              name_abrev: 'NACIONAL',
              name_zonal:'Nacional',
              name_rpt: 'Nacional'
            }
  }

  getZonalxId_sharePoint(id:string){
    return this.getZonales().filter((z:IGZonal) => z.id === id).length > 0
            ?
            this.getZonales().filter((z:IGZonal) => z.id === id)[0].sharePoint
            :
             'Nacional'
            
  }
}

const ctrlZonales = new CtrlZonales()
export default ctrlZonales