import React, { Component, Fragment } from 'react'

import { OrdenarReverse } from '../lib/source'

import { IPendienteMatricula } from '../models/Grafica/PendienteMatricula.model'


import Etiqueta from './Etiqueta'

interface IProps {
  reportes:IPendienteMatricula[]
  tperiodo:string
  zonal:string
  pperiodo:string
  calendarizacion:string
}

interface IState {

}

export default class TarjetaImpedimentoCursos extends Component<IProps, IState> {


  render() {

    let Reporte:IPendienteMatricula[] = []


    Reporte = this.props.reportes.filter((rpt: IPendienteMatricula) => rpt.Zonal === this.props.zonal)
                                 .filter((rpt: IPendienteMatricula) => rpt.PartePeriodo === this.props.pperiodo)
                                 .filter((rpt: IPendienteMatricula) => rpt.TipoPeriodo === this.props.tperiodo)
                                 .filter((rpt: IPendienteMatricula) => rpt.Calendarizacion === this.props.calendarizacion)
                                 .filter((rpt: IPendienteMatricula) => rpt.Campus === 'TODOS')




    let analizo:any = [
        {motivo:'Cursos finalizado'    ,       valor: Reporte.length > 0 ? Reporte[0].Curso_Finalizado            : 0 , data: Reporte.length > 0 ? Reporte[0].Porc_Matriculas_BB  : 0 },
        {motivo:'Cursos Fuera de rango',       valor: Reporte.length > 0 ? Reporte[0].Cursos_fuera_de_rango       : 0 , data: Reporte.length > 0 ? Reporte[0].Fuera_de_rango      : 0 },
        {motivo:'Cursos sin Contrato'  ,       valor: Reporte.length > 0 ? Reporte[0].Cursos_sin_contrato         : 0 , data: Reporte.length > 0 ? Reporte[0].Sin_Contrato        : 0 },
        {motivo:'Cursos sin frecuencia',       valor: Reporte.length > 0 ? Reporte[0].Cursos_sin_frecuencia       : 0 , data: Reporte.length > 0 ? Reporte[0].Sin_Frecuencia      : 0 },
        {motivo:'Cursos sin instructor',       valor: Reporte.length > 0 ? Reporte[0].Cursos_sin_instructor       : 0 , data: Reporte.length > 0 ? Reporte[0].Instructores_sobreasignados : 0 },
        {motivo:'Instructores sobreasignados', valor: Reporte.length > 0 ? Reporte[0].Instructores_sobreasignados : 0 , data: ''},
/*        {motivo:'Sin Contrato ', valor: Reporte[0].Porc_Sin_Contrato , data: Reporte[0].Sin_Contrato},
        {motivo:'Sin instructor ', valor: Reporte[0].Porc_Sin_Instructor , data: Reporte[0].Sin_Instructor},
        {motivo:'Fuera de curricula ', valor: Reporte[0].Porc_Sin_Patron , data: Reporte[0].Sin_patron},
        {motivo:'Sin usuario ', valor: Reporte[0].Porc_Sin_Usuario , data: Reporte[0].Sin_usuario},
        {motivo:'Solo sin usuario ', valor: Reporte[0].Porc_Solo_Sin_Usuario , data: Reporte[0].Solo_sin_Usuario},*/
    ]

    //console.log(analizo)

    analizo = OrdenarReverse(analizo,'valor')



    let etiqueta = analizo.slice(0, 4).map((a:any , i:number)=>{
       return(
            <div className="col-lg-10 col-md-5 col-10" key={'etiqueta-impe-curso'+ i.toString()}>
                <Etiqueta  icon="fa fa-book"  mostrar={a.valor} text={a.motivo} background='#1ca4fc' equivalente={''} key={'etiqueta-impe-item'+ i.toString()}/>
            </div>
          
        )
    })


    return (
      <Fragment>
        <div className="row justify-content-center">
          {etiqueta}
        </div>
      </Fragment>
    );
  }
}

