import React, { Component, Fragment } from 'react'
import { DetectarLcNrc } from '../../lib/source'
import { IJSONControlAlumno } from '../../models/JSON_Data/JSONControlAlumno.model'
import AlertUno from '../alert/AlertUno'




interface IProps {
  reportes:IJSONControlAlumno[]
  periodo:string
  dato:string

}
interface IState {

}

export default class TablaControlAlumnosInst extends Component<IProps, IState> {

  render() {

    let dato:IJSONControlAlumno[]


    const NrcLc:string = DetectarLcNrc(this.props.dato)



    if(NrcLc === 'NRC')
      dato =  this.props.reportes
                              .filter(rpt=> rpt.tipo_nrc!=='HIJO')
                              .filter(rpt=> rpt.periodo === this.props.periodo)
                              .filter(rpt=> rpt.nrc === this.props.dato)

    else
       dato =  this.props.reportes
                              .filter(rpt=> rpt.tipo_nrc!=='HIJO')
                              .filter(rpt=> rpt.periodo === this.props.periodo)
                              .filter(rpt=> rpt.lc === this.props.dato)



    const list =   dato.map((rpt:IJSONControlAlumno,i:number)=>
                                                  <tr key={'repInst-' + i.toString()}>
                                                    <td align='center'>{rpt.nrc}</td>
                                                    <td align='center'>{rpt.lc}</td>
                                                    <td align='center'>{rpt.tipo_nrc}</td>
                                                    <td align='center'>{ rpt.id_inst_padre }</td>
                                                    <td align='justify'>{ rpt.id_inst }</td>
                                                    <td align='justify'>{ rpt.instructor }</td>
                                                    <td align='center'>{rpt.inicio}</td>
                                                    <td align='center'>{rpt.fin} </td>
                                                    <td align='center'>{rpt.fecha_creacion} </td>
                                                    <td align='center'><AlertUno status={rpt.tiene_frecuencia} /></td>
                                                    <td align='center'>{rpt.estado_instructorbb} </td>
                                                    <td align='center'>{rpt.ind_curso_padre} </td>
                                                  </tr>
                                            )

    return (
      <Fragment>
        <table className='table table-sm table-bordered table-striped' style={{fontSize:'small'}}>
          <thead className='bg-primary'>
            <tr>
              <td align='center' colSpan={3}>CURSO</td>
              <td align='center' colSpan={3}>INSTRUCTOR</td>
              <td align='center' colSpan={3}>FECHA</td>
              <td align='center' colSpan={3}>OBSERVACION</td>
            </tr>
            <tr>
              <td align='center' width='7%'> NRC </td>
              <td align='center' width='7%'> LC </td>
              <td align='center' width='6%'> TIPO </td>
              <td align='center' width='8%'>PADRE</td>
              <td align='center' width='8%'>ID INST</td>
              <td align='center' width=''>INSTRUCTOR</td>
              <td align='center' width='6%'>INICIO</td>
              <td align='center' width='6%'>FIN</td>
              <td align='center' width='6%'>CREADO</td>
              <td align='center' width='5%'>FRECUENCIA</td>
              <td align='center' width='5%'>EST_INST</td>
              <td align='center' width='5%'>INDUCCION</td>
            </tr>
          </thead>
          <tbody>
            {list}
          </tbody>
        </table>

      </Fragment>
    )
  }
}
