import React, { Component, Fragment } from 'react'

interface IProps {
  status:number
}
interface IState {

}

export default class AlertUno extends Component<IProps, IState> {
  render() {
    return (
      <Fragment>
        {
          this.props.status === 1 ?
            <span className='text-success'>✓</span>
            :
            <span className='text-danger'>✗</span>
        }
      </Fragment>
    )
  }
}
