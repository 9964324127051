import React, { Component } from 'react'
import ctrlControlAnexo03 from '../../controller/Seguimiento/ctrlControlAnexo03.ctrl'
import { IAnexo03Schema } from '../../models/mongo/anexo.schema'
import Moment from 'react-moment'
import {  addDaysToDate, addHoursToDays, Fecha, OrdenarReverse} from '../../lib/source'
import { IAnexo03 } from '../../models/Seguimiento/anexo03.model'
import { RouteComponentProps } from 'react-router-dom'
import AnexoSchema from '../../context/AnexoContext'
import { ExportCSV } from '../../component/Seguimiento/ExportCSV'




interface IProps extends RouteComponentProps{ }

interface IState { 

  cursos:IAnexo03Schema[]

  
  anexo: IAnexo03
  anexos:any
  vista:any

  actualizar:Boolean

  finicio:string
  ffin:string
  id_instructor:string
}

export default class Supervision extends Component<IProps,IState> {

  static contextType = AnexoSchema

  constructor(props:IProps){
    super(props)

    this.state = {
                  cursos:[],
                  vista:[],
                  
                  actualizar:false,

                  finicio: Fecha(new Date()),
                  ffin: Fecha(new Date()),
                  id_instructor:'',

                  anexos:[],
                  anexo:{  
                        periodo:'',
                        nrc:'',
                        lc:'',
                        curso:'',
                        id_inst:'',
                        tipo:'',
                        instructor: '',
                        zonal:'',
                        campus:'',
                        cod_curso:'',
                        supervisorId:'',
                        supervisor:'',
                        alumnosbb:0,
                        alumnos_asiste: 0,
                        inicio:new Date(),
                        fin:new Date(),
                        curso_cerrado:'',
                        pregunta1:'4',
                        pregunta2:'4',
                        pregunta3:'4',
                        pregunta4:'4',
                        pregunta5:'4',
                        pregunta6:'4',
                        pregunta7:'4',
                        pregunta8:'4',
                        pregunta9:'4',
                        pregunta10:'4',
                        pregunta11:'4',
                        pregunta12:'4',
                        pregunta13:'4',
                        pregunta14:'4',
                        pregunta15:'4',
                        pregunta16:'4',
                        description1:'',
                        description2:'',
                        description3:'',
                        description:'',
                        statusInst:false
                      }
                }

    this.handleFecha = this.handleFecha.bind(this)
    this.handleRefresh = this.handleRefresh.bind(this)
    this.hanlePrint = this.hanlePrint.bind(this)
    this.ListaAnexos03 = this.ListaAnexos03.bind(this)
    this.handleDonwload = this.handleDonwload.bind(this)
                
  }

  async componentDidMount(): Promise<void> {
    this.setState({ actualizar: true})

    const lstAnexos:any = await this.ListaAnexos03()

    this.setState({
      anexos: lstAnexos
                        .filter((c:IAnexo03Schema)=> new Date(c.createdAt ?? new Date()) >= addHoursToDays(new Date(this.state.finicio), 5))
                        .filter((c:IAnexo03Schema)=> new Date(c.createdAt?? new Date()) <= addDaysToDate(addHoursToDays(new Date(this.state.ffin), 5),1))

    })

    this.setState({ actualizar: false})

  }

  async ListaAnexos03(): Promise<void> {

    const {cursos}  =  await ctrlControlAnexo03.ListaCursos() 
  
      return OrdenarReverse(cursos,'createdAt')
    
    
  }


  handleFecha({target}:any){

    if(target.name==='finicio'){

      this.setState({
        finicio: target.value
      })

    }

    if(target.name ==='ffin')

    this.setState({
        ffin: target.value 
      })

    if(target.name ==='id_instructor'){

        this.setState({
            id_instructor: target.value
          })
    }

  }

  async handleRefresh(){

    this.setState({ actualizar: true})

   const lstAnexos:any = await this.ListaAnexos03()
 
    this.setState({
      anexos: lstAnexos
                        .filter((c:IAnexo03Schema) => new Date(c.createdAt ?? new Date()) >= addHoursToDays(new Date(this.state.finicio), 5))
                        .filter((c:IAnexo03Schema) => new Date(c.createdAt?? new Date()) <= addDaysToDate(addHoursToDays(new Date(this.state.ffin), 5),1))
                        .filter((c:IAnexo03Schema) => 
                          this.state.id_instructor.length > 0 ? 
                            c.id_instructor.includes(this.state.id_instructor)
                          :
                            true                           
                        )

    })

    this.setState({ actualizar: false})

  }

  hanlePrint(anexo:IAnexo03Schema){
    const { setUpdate } = this.context

    setUpdate(anexo)
    
    this.props.history.push('/seguimiento/reporteview')
  }

  handleDonwload(){



  }

  render() {

    const bodyTable = this.state.anexos.map((curso:IAnexo03Schema,i:number) => {

      return(
        <tr key={'tbl-' + i.toString()}>
          <td align='center'>{ i+1 }</td>
          <td align='center'>{ curso.cod_curso }</td>
          <td align='center'>{ curso.semana }</td>
          <td align='center'>{ curso.tipo}</td>
          <td align='center'> {curso.statusInst? 'Asistió': 'No asistió'}</td>
          <td align='center'>{ curso.id_instructor }</td>
          <td align='left'>{ curso.instructor }</td>
          <td align='center'>{ curso.id_seguimiento }</td>
          <td align='center'>{ curso.asistencia + ' de '+ curso.alumnosbb }</td>
          <td align='center'>{ curso.nota }</td>
          <td align='center'>{ curso.porcentaje? curso.porcentaje : '--' }%</td>
          <td align='center'> {<Moment date={curso.createdAt!} format="DD/MM/YYYY HH:mm" />}</td>
          <td align='center'>
            <button  className='btn btn-outline-danger btn-sm' onClick={()=>this.hanlePrint(curso)}>
              <i className="fa-solid fa-file-pdf"></i>
            </button>
          </td>
        </tr>
      )
    })

    return (
      <div>
        <div className='row justify-content-center mt-2'>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="card">
              <div className="card-body">
                <ExportCSV csvData={this.state.anexos} fileName={'REP_Listado'+this.state.finicio+'_'+this.state.ffin} />
                <button className="btn btn-app" onClick={this.handleRefresh} >
                  {this.state.actualizar? <i className='fa fa-refresh fa-spin'></i> :<i className='fa fa-refresh'></i>}
                  actualizar
                </button>
              </div>
              <div className='card-footer d-flex'>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa-sharp fa-solid fa-person-chalkboard"></i>
                    </span>
                  </div>
                  <input type="text" name='id_instructor' onChange={this.handleFecha} value={ this.state.id_instructor} placeholder='id instructor' maxLength={9}/>
                </div>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="far fa-calendar-alt"></i>{'  Inicio'}
                    </span>
                  </div>
                  <input type="date" name='finicio' onChange={this.handleFecha} value={ this.state.finicio} min={'2022-01-01'} max={'2030-12-31'}/>
                </div>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="far fa-calendar-alt"></i>{' Fin'}
                    </span>
                  </div>
                  <input type="date" name='ffin' onChange={this.handleFecha} value={ this.state.ffin}  min={'2022-01-01'} max={'2030-12-31'}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <table className="table table-bordered table-striped table-sm text-md">
          <thead>
            <tr className='bg-primary text-white'>
              <td align='center' width={'5%'}>N</td>
              <td align='center' width={'15%'}>cod_curso</td>
              <td align='center' width={'5%'}>semana</td>
              <td align='center' width={'5%'}>tipo</td>
              <td align='center' width={'5%'}>status</td>
              <td align='center' width={'7%'}>id instructor</td>
              <td align='center' width={''}>instructor</td>
              <td align='justify' width={'7%'}>id especialista</td>
              <td align='center' width={'7%'}>asistencia</td>
              <td align='center' width={'3%'}>nota</td>
              <td align='center' width={'5%'}>%</td>
              <td align='center' width={'8%'}>Fecha</td>
              <td align='center' width={'4%'}></td>
            </tr>
          </thead>
          <tbody>
            { bodyTable }
          </tbody>
        </table>
      </div>
    )
  }
}
